import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type AddBookmarkRequest = {
  age?: string;
  jobid?: number | string;
  keyword: string;
  src?: string;
};

type DeleteBookmarkRequest = {
  jobid?: number | string;
};

type Bookmark = {
  code: string;
  jobid: number;
  text: string;
  link: string;
};

type Bookmarks = Array<Bookmark>;

export const bookmarksApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    addBookmark: build.mutation<{ message: string }, AddBookmarkRequest>({
      invalidatesTags: ['Bookmarks'],
      query({ age, jobid, keyword, src }) {
        return {
          data: {
            age,
            jobid,
            keyword,
            src,
          },
          method: 'POST',
          url: '/bookmarks/set',
        };
      },
    }),
    deleteBookmark: build.mutation<{ message: string }, DeleteBookmarkRequest>({
      invalidatesTags: ['Bookmarks'],
      query({ jobid }) {
        return {
          method: 'DELETE',
          params: {
            jobid,
          },
          url: '/bookmarks/delete',
        };
      },
    }),
    getBookmarks: build.query<Bookmarks, void>({
      providesTags: ['Bookmarks'],
      query() {
        return {
          method: 'GET',
          url: '/bookmarks/get',
        };
      },
    }),
  }),
  tagTypes: ['Bookmarks'],
});

export const { useAddBookmarkMutation, useDeleteBookmarkMutation, useGetBookmarksQuery } =
  bookmarksApi;
