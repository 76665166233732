import { ReactElement, useEffect } from 'react';

import { getDateFormatsFromTranslation, initializeLanguage } from 'src/utilities/language/utils';
import { useDateFormat, useI18Initialized } from '../hooks';

type LanguageInitializer = {
  children: ReactElement;
};

export function LanguageInitializer({ children }: LanguageInitializer) {
  const isInitialized = useI18Initialized();
  const { setDateFormats } = useDateFormat();

  useEffect(() => {
    if (!isInitialized) {
      initializeLanguage().then((data) => {
        setDateFormats(getDateFormatsFromTranslation(data));
      });
    }
  }, [isInitialized]);

  return <>{children}</>;
}
