import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { PasswordCriteria } from '../PasswordCriteria';

type PasswordValidation = {
  password: string;
  onPasswordValidation: (isValidPassword: boolean) => void;
};

export function PasswordValidation({ onPasswordValidation, password }: PasswordValidation) {
  const [isValidLength, setIsValidLength] = useState(false);
  const [isValidLowerCase, setIsValidLowerCase] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidSymbol, setIsValidSymbol] = useState(false);
  const [isValidUpperCase, setIsValidUpperCase] = useState(false);
  const isValidPassword =
    isValidLength && isValidLowerCase && isValidNumber && isValidSymbol && isValidUpperCase;
  const { t } = useTranslation();

  useEffect(() => {
    setIsValidLength(password.length >= 8);
    setIsValidLowerCase(/[a-z]/.test(password));
    setIsValidUpperCase(/[A-Z]/.test(password));
    setIsValidNumber(/\d/.test(password));
    setIsValidSymbol(/[:;,!?.$/*-+_&@]/.test(password));
  }, [password]);

  useEffect(() => {
    onPasswordValidation(isValidPassword);
  }, [isValidLength, isValidLowerCase, isValidNumber, isValidSymbol, isValidUpperCase]);

  return (
    <Box bgcolor="filler.main" display="flex" flexDirection="column">
      <Box padding={1}>
        <Typography fontWeight="bold" variant="caption">
          {t('pg.usr.pwd')}
        </Typography>

        <PasswordCriteria
          criteria={t('update_password_validation_length', 'must be at least 8 characters long.')}
          isValid={isValidLength}
        />

        <PasswordCriteria
          criteria={t(
            'update_password_validation_lowercase',
            'must include at least one lowercase letter (a-z).',
          )}
          isValid={isValidLowerCase}
        />

        <PasswordCriteria
          criteria={t(
            'update_password_validation_uppercase',
            'must include at least one uppercase letter (A-Z).',
          )}
          isValid={isValidUpperCase}
        />

        <PasswordCriteria
          criteria={t('update_password_validation_number', 'must include at least one number.')}
          isValid={isValidNumber}
        />

        <PasswordCriteria
          criteria={t(
            'update_password_validation_special_character',
            'must include at least one special character (:;,!?.$/*-+_&@).',
          )}
          isValid={isValidSymbol}
        />
      </Box>
    </Box>
  );
}
