import { Pagination } from '@mui/material';

type PaginationActionsProps = {
  count: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  rowsPerPage: number;
  page: number;
};

export function PaginationActions({
  count,
  onPageChange,
  page,
  rowsPerPage,
}: PaginationActionsProps) {
  function handleChangePage(event: React.ChangeEvent<unknown>, value: number) {
    onPageChange(event as React.MouseEvent<HTMLButtonElement>, value - 1);
  }

  return (
    <Pagination
      color="primary"
      count={count === 0 ? 1 : Math.ceil(count / rowsPerPage)}
      disabled={Math.ceil(count / rowsPerPage) === 1 || count === 0}
      onChange={handleChangePage}
      page={page + 1}
      showFirstButton
      showLastButton
      sx={{ '& .MuiPagination-ul': { display: 'flex', flexWrap: 'inherit' } }}
    />
  );
}
