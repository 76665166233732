import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import { CompareReplaceTemplate } from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import { CLIPBOARD_MENU_ITEM, OPTIONS_MENU_ITEM } from 'src/features/CompareReplaceDialog/utils';

type CompareReplaceMenuProps = {
  selectedMenuItem: string;
  onSelectMenuItem: (menuItem: string) => void;
  templates: Array<CompareReplaceTemplate>;
};

function CompareReplaceMenu({
  onSelectMenuItem,
  selectedMenuItem,
  templates,
}: CompareReplaceMenuProps) {
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="filler.main"
      component={List}
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      minWidth={250}
    >
      <Box overflow="auto">
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => onSelectMenuItem(CLIPBOARD_MENU_ITEM)}
            selected={selectedMenuItem === CLIPBOARD_MENU_ITEM}
          >
            <ListItemText primary={t('clip.clipboard', 'Clipboard')} />
          </ListItemButton>
        </ListItem>

        <Box component={Divider} marginY={2} />

        {templates.map((template) => (
          <ListItem disablePadding key={template.name}>
            <ListItemButton
              onClick={() => onSelectMenuItem(template.name)}
              selected={selectedMenuItem === template.name}
            >
              <ListItemText primary={template.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </Box>

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => onSelectMenuItem(OPTIONS_MENU_ITEM)}
          selected={selectedMenuItem === OPTIONS_MENU_ITEM}
        >
          <ListItemText primary={t('lib.opt', 'Options')} />
        </ListItemButton>
      </ListItem>
    </Box>
  );
}

const MemoizedCompareReplaceMenu = memo(CompareReplaceMenu);

export { MemoizedCompareReplaceMenu as CompareReplaceMenu };
