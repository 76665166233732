import { CompareReplaceTemplate } from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import { ClipboardData } from 'src/features/JobForm/components/CopyCompareButton/type';

export const CLIPBOARD_MENU_ITEM = 'clipboard_menu_item';
export const OPTIONS_MENU_ITEM = 'options_menu_item';

export function getTemplateData(data: any, clipboardData: ClipboardData) {
  const templateData = clipboardData
    .filter((section) => data[section.sectionId]?.selected)
    .map((section) => {
      const sectionId = section.sectionId;

      const filteredFields = section.fields.filter(
        (field) => data[sectionId].fields[field.fieldId] === 'updated',
      );

      return {
        fields: filteredFields,
        sectionId,
      };
    });

  return templateData;
}

export function getIsSaveDisabled(data: {
  templateConfigurationType: string;
  templateNameNew: string;
  templateNameUpdate: string;
}): boolean {
  const { templateConfigurationType, templateNameNew, templateNameUpdate } = data;

  if (templateConfigurationType === 'clipboard') return false;

  if (templateConfigurationType === 'new') {
    return !templateNameNew;
  }

  return !templateNameUpdate;
}

export function getCopiedData(
  selectedMenuItem: string,
  clipboardData: ClipboardData,
  templates?: Array<CompareReplaceTemplate>,
): ClipboardData {
  if (selectedMenuItem === CLIPBOARD_MENU_ITEM) {
    return clipboardData;
  }

  return templates?.find((template) => template.name === selectedMenuItem)?.val || [];
}
