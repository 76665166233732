import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';

import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { EmailListItem } from 'src/features/History/components/EmailListItem';
import { StyledTableCell, StyledUnderlineButton } from 'src/features/History/components/styled';
import { GetEmailListResponse } from 'src/features/History/History.service';

interface EmailListProps {
  emails: Array<GetEmailListResponse>;
}

export function EmailList({ emails }: EmailListProps) {
  const [isEmailListExpanded, setIsExpanded] = useState(false);
  const [expandedEmailIds, setExpandedEmailIds] = useState<number[]>([]);

  function handleToggleEmailListExpand() {
    setIsExpanded(!isEmailListExpanded);
  }

  const handleToggleEmailItemExpand = useCallback((id: number) => {
    setExpandedEmailIds((prevExpandedEmailIds) => {
      if (prevExpandedEmailIds.includes(id)) {
        return prevExpandedEmailIds.filter((expandedId) => expandedId !== id);
      }

      return [...prevExpandedEmailIds, id];
    });
  }, []);

  return (
    <>
      <StyledUnderlineButton onClick={handleToggleEmailListExpand}>
        E-Mail List
      </StyledUnderlineButton>

      <Collapse in={isEmailListExpanded}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell />

                <StyledTableCell />

                <StyledTableCell>
                  <Trans i18nKey="lib.createdate">Created on</Trans>
                </StyledTableCell>

                <StyledTableCell>
                  <Trans i18nKey="lib.change">Last change</Trans>
                </StyledTableCell>

                <StyledTableCell>
                  <Trans i18nKey="lib.from">from</Trans>
                </StyledTableCell>

                <StyledTableCell>
                  <Trans i18nKey="lib.to">to</Trans>
                </StyledTableCell>

                <StyledTableCell>
                  <Trans i18nKey="lib.sbj">Subject</Trans>
                </StyledTableCell>

                <StyledTableCell />

                <StyledTableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {emails.map((emailData, index) => {
                const numberedRow = index + 1;
                const isLastEmailListItem = numberedRow === emails.length;
                const isEmailListItemExpanded = !!expandedEmailIds.find(
                  (expandedId) => expandedId === emailData.id,
                );

                return (
                  <EmailListItem
                    emailData={emailData}
                    index={index}
                    isEmailListItemExpanded={isEmailListItemExpanded}
                    isLastEmailListItem={isLastEmailListItem}
                    key={`email-list-item-${emailData.id}`}
                    onClickToggleEmailListItemExpanded={handleToggleEmailItemExpand}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
}
