import { TableBody, TableHead, TableRow } from '@mui/material';

import { CellSkeleton } from 'src/features/JobsTable/components/CellSkeleton';

type LoadingHeaderAndBodyProps = { totalColumns: number; totalRows: number };

export function LoadingHeaderAndBody({ totalColumns, totalRows }: LoadingHeaderAndBodyProps) {
  return (
    <>
      <TableHead>
        <TableRow>
          {[...Array(totalColumns)].map((column, index) => (
            <CellSkeleton key={index} />
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {[...Array(totalRows)].map((row, index) => (
          <TableRow key={index}>
            {[...Array(totalColumns)].map((column, index) => (
              <CellSkeleton key={index} />
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}
