import { useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { useGetRelatedJobsQuery } from 'src/features/JobForm/JobForm.service';
import { useRouteParams } from 'src/utilities/hooks';

export function useRelatedJobs() {
  const { age, jobId, jobType } = useRouteParams();

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const relatedJobsButton = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const { data: relatedJobs, isFetching } = useGetRelatedJobsQuery(
    { age, jobId, jobType },
    { skip: jobId === 'new' },
  );

  const relatedJobButtons = useMemo(
    () =>
      relatedJobs?.map(({ code, jobId, text }) => {
        const jobType = code.split('-')[1];

        return {
          code: `${code}-${jobId}`,
          icon: <StatusIndicator jobType={jobType} size="extraSmall" />,
          onClick: () => {
            navigate(`/jobs-${code}/${jobId}/job`);
            handleCloseSubMenu();
          },
          text,
        };
      }) ?? [],
    [relatedJobs],
  );

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  return {
    handleCloseSubMenu,
    handleToggleSubMenu,
    isFetching,
    isSubMenuOpen,
    relatedJobButtons,
    relatedJobsButton,
  };
}
