import { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { changeLanguage } from 'i18next';

import { WaveSnack } from 'src/components/WaveSnack';
import { WaveTheme } from 'src/components/WaveTheme';
import { PrivateRoute } from 'src/routes/PrivateRoute';
import { PublicRoute } from 'src/routes/PublicRoute';
import { getUserDetails, logout } from 'src/store/userSlice';
import { setupResponseInterceptor } from 'src/utilities/api';
import { useAppDispatch, useAppSelector, useI18Initialized } from 'src/utilities/hooks';

type Location = {
  state?: {
    name: string;
  };
  pathname: string;
  search: string;
};

export function App() {
  const cookie = useAppSelector((state) => state.user.cookie);
  const dispatch = useAppDispatch();
  const isInitialized = useI18Initialized();
  const location = useLocation() as Location;
  const name = useRef<string>();
  const navigate = useNavigate();
  const referrer = useRef('');
  const userID = useAppSelector((state) => state.user.details.id);
  const userLanguage = useAppSelector((state) => state.user.preferences['sys.lang']?.value);

  const sessionID = useAppSelector((state) => state.user.sessionID);

  const loggedIn = userID && cookie;

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!cookie) {
        localStorage.removeItem('cookie');
      } else {
        localStorage.setItem('cookie', cookie);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [cookie]);

  useEffect(() => {
    if (!userID) {
      if (cookie) {
        referrer.current = location.pathname + location.search;
        name.current = location.state?.name;

        dispatch(getUserDetails());
      }
    } else if (cookie) {
      navigate(referrer.current, {
        replace: true,
        state: { name: name.current },
      });
    }
  }, [cookie, userID]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!sessionID) {
        localStorage.removeItem('sessionID');
      } else {
        localStorage.setItem('sessionID', sessionID);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [sessionID]);

  useEffect(() => {
    if (userLanguage && isInitialized) {
      changeLanguage(userLanguage);
      localStorage.setItem('userLanguage', userLanguage);
    }
  }, [userLanguage, isInitialized, userID]);

  useEffect(() => {
    setupResponseInterceptor({
      onNotFound: () => navigate('404', { replace: true }),
      onUnauthorized: () => dispatch(logout('log.ses')),
    });
  }, []);

  return (
    <WaveTheme>
      <WaveSnack />

      {loggedIn ? <PrivateRoute /> : <PublicRoute />}
    </WaveTheme>
  );
}
