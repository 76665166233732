import { Theme } from '@mui/material';

import {
  Action,
  ActionTransformed,
  View,
  ViewTransformed,
} from 'src/features/JobsTable/JobsTable.service';
import { Age } from 'src/utilities/hooks/useRouteParams';

export function buildBorderValue(theme: Theme) {
  return `solid ${theme.palette.grey[300]} 1px`;
}

export function computeWaveIconCodePrefix({
  age,
  isSubJobsTable,
}: Age & { isSubJobsTable: boolean }) {
  return age === 'hom' ? 'dashboard' : isSubJobsTable ? 'sub-jobs' : 'jobs';
}

export function isArchivedStatus(status: number) {
  return status === 200;
}

export function transformSubActionsRecursively(action: Action): ActionTransformed {
  if (action.sub_actions) {
    const { sub_actions: subActions, ...rest } = action;

    const transformedSubActions = subActions.map((subAction) =>
      transformSubActionsRecursively(subAction),
    );

    return { subActions: transformedSubActions, ...rest };
  }

  return action;
}

export function transformViewType(viewType: View[]): ViewTransformed[] {
  return viewType.map(
    ({
      cols: columnIds,
      id,
      lpp: rowsPerPage,
      name,
      ord: orderByColumnAlias,
      sfie: idsOfFilterFields,
    }) => ({
      columnIds,
      id,
      idsOfFilterFields,
      name,
      orderByColumnAlias,
      rowsPerPage: rowsPerPage.toString(),
    }),
  );
}
