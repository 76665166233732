import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelection } from '@viselect/react';

import { Box, Checkbox, Skeleton, Table, TableBody, TableHead, TableRow } from '@mui/material';

import { FileColumnMappingsEnum } from 'src/pages/FileSearch/enums/FileColumnMappings';
import { JobDetailsColumnMappingsEnum } from 'src/pages/FileSearch/enums/JobDetailsColumnMappings';
import { ColumnSortType } from 'src/pages/FileSearch/FileSearch';
import { FileDataType } from 'src/pages/FileSearch/FileSearch.service';
import { FileSearchListItem } from 'src/pages/FileSearch/FileSearchListItem/FileSearchListItem';
import {
  StyledTableCell,
  StyledTableContainer,
} from 'src/pages/FileSearch/FileSearchListView/styled';
import { getIsEveryRowSelected } from 'src/pages/FileSearch/FileSearchListView/utils';
import { FileSearchSortableTableCell } from 'src/pages/FileSearch/FileSearchSortableTableCell';
import { getData } from 'src/pages/FileSearch/utils';
import { FileSearchStateType } from 'src/store/fileSearchSlice';

export const MemoizedCheckBox = memo(Checkbox);

type FileSearchListViewProps = {
  fileItems: FileDataType[];
  page: number;
  rowsPerPage: number;
  selected: FileSearchStateType;
  children?: React.ReactNode;
  isLoading: boolean;
  sortColumn?: string | null;
  sortDirection?: ColumnSortType | null;
  onSortTable: (sortName: string) => void;
};

export function FileSearchListView({
  children,
  fileItems,
  isLoading,
  onSortTable,
  page,
  rowsPerPage,
  selected,
  sortColumn,
  sortDirection,
}: FileSearchListViewProps) {
  const { t } = useTranslation();
  const selection = useSelection();
  const { columns, rows } = getData(fileItems, [
    FileColumnMappingsEnum.JOB_ID,
    FileColumnMappingsEnum.ID,
    FileColumnMappingsEnum.THUMBNAIL,
    FileColumnMappingsEnum.FILE_NAME,
    FileColumnMappingsEnum.LAST_UPDATED,
    FileColumnMappingsEnum.FILE_SIZE,
    JobDetailsColumnMappingsEnum.KEYWORDS,
    JobDetailsColumnMappingsEnum.PROJECT_NAME,
    FileColumnMappingsEnum.VERSION,
  ]);
  const { rows: additionalRowData } = getData(fileItems, [
    JobDetailsColumnMappingsEnum.TABLE_NAME,
    FileColumnMappingsEnum.SOURCE,
    FileColumnMappingsEnum.SUB,
    FileColumnMappingsEnum.JOB_ID,
  ]);

  const numberOfColumns = columns.length + 2;

  const isEveryRowSelected = getIsEveryRowSelected(rows, selected);

  const handleToggleSelectingRows = useCallback(() => {
    if (isEveryRowSelected) {
      selection?.clearSelection();
    } else {
      const elementsWithKey = Array.from(document.querySelectorAll('[data-key]'));

      selection?.select(elementsWithKey);
    }
  }, [isEveryRowSelected]);

  return (
    <StyledTableContainer>
      {children}

      <Box overflow="auto">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell key="number" />

              <StyledTableCell key="selected">
                <MemoizedCheckBox
                  checked={isEveryRowSelected}
                  onClick={handleToggleSelectingRows}
                />
              </StyledTableCell>

              {columns
                .filter((column) => column.label !== 'ID')
                .map((column) => (
                  <FileSearchSortableTableCell
                    key={column.sortName}
                    label={t(column.translationCode, column.label)}
                    onSortTable={onSortTable}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortName={column.sortName}
                  />
                ))}

              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading
              ? Array.from({ length: 50 }).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from({ length: numberOfColumns }).map((_, index) => (
                      <StyledTableCell key={index}>
                        <Skeleton height={20} variant="text" width="100%" />
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))
              : rows.map((row, index) => {
                  const isSelected = selected.some(([id]) => id === row.ID);
                  const src = additionalRowData[index].SOURCE;
                  const jobId = additionalRowData[index].JOB_ID;
                  const tableName = additionalRowData[index].TABLE_NAME;

                  return (
                    <FileSearchListItem
                      index={index}
                      isSelected={isSelected}
                      jobId={jobId}
                      key={row.ID}
                      page={page}
                      row={row}
                      rowsPerPage={rowsPerPage}
                      src={src}
                      tableName={tableName}
                    />
                  );
                })}
          </TableBody>
        </Table>
      </Box>
    </StyledTableContainer>
  );
}
