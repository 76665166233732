import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ViewSearchItemProps } from 'src/features/JobsTable/components/ViewSearchItem/ViewSearchItem';
import {
  useDeleteViewSearchMutation,
  useUpdateViewSearchMutation,
  ViewSearchName,
} from 'src/features/JobsTable/JobsTable.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import {
  useAppDispatch,
  useAppSelector,
  useFirstRender,
  usePreference,
  usePreferencePrefix,
} from 'src/utilities/hooks';
import { ActiveFilters } from 'src/utilities/hooks/usePreference';

type UseViewSearchItemProps = Omit<ViewSearchItemProps, 'isNewItemFormOpen'>;

export function useViewSearchItem({ item, onCloseDialog, variant }: UseViewSearchItemProps) {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const isFirstRender = useFirstRender();
  const { age, isSubJobsTable, jobType, preferencePrefix } = usePreferencePrefix();

  const [action, setAction] = useState<'edit' | 'delete' | undefined>();

  const activeFiltersPreference = usePreference(`${preferencePrefix}.search`, []);
  const columnsPreference = usePreference(`${preferencePrefix}.columns`, '');
  const orderByColumnAliasPreference = usePreference(`${preferencePrefix}.order`, 'jobid');
  const pagePreference = usePreference(`${preferencePrefix}.listPage`, '0');
  const rowsPerPagePreference = usePreference(`${preferencePrefix}.rowsPerPage`, '25');
  const searchFiltersPreference = usePreference(`${preferencePrefix}.filterFields`, '');

  const editRights = useAppSelector((state) => state.user.rights.edit);
  const deleteRights = useAppSelector((state) => state.user.rights.delete);

  const [deleteItem] = useDeleteViewSearchMutation();
  const [updateItem] = useUpdateViewSearchMutation();

  const { id, name } = item;

  const {
    control,
    handleSubmit: reactHookFormHandleSubmit,
    reset,
  } = useForm({
    defaultValues: { name },
    mode: 'onChange',
  });

  function handleClickApply() {
    if ('filters' in item) activeFiltersPreference.set(item.filters);
    else {
      const { columnIds, idsOfFilterFields, orderByColumnAlias, rowsPerPage } = item;

      columnsPreference.set(columnIds);
      orderByColumnAliasPreference.set(orderByColumnAlias);
      pagePreference.set('0');
      rowsPerPagePreference.set(rowsPerPage);
      searchFiltersPreference.set(idsOfFilterFields);
    }
  }

  function handleClickEdit() {
    setAction('edit');
  }

  function handleClickDelete() {
    setAction('delete');
  }

  function handleClickCancel() {
    setAction(undefined);
    reset();
  }

  async function handleClickConfirm() {
    if (action === 'delete') {
      await deleteItem({ id, variant })
        .unwrap()
        .then(() => {
          onCloseDialog();
        });
    }
  }

  async function handleSubmit({ name }: ViewSearchName) {
    await updateItem({
      age,
      filters: activeFiltersPreference.value as ActiveFilters,
      id,
      isSubJobsTable,
      jobType,
      name,
      variant,
    })
      .unwrap()
      .then(() => {
        onCloseDialog();
        dispatch(
          openWaveSnack({
            message: `${name} was successfully updated.`,
            type: 'success',
          }),
        );
      });
  }

  useEffect(() => {
    if (!isFirstRender) onCloseDialog();
  }, [
    activeFiltersPreference.value,
    columnsPreference.value,
    orderByColumnAliasPreference.value,
    rowsPerPagePreference.value,
    searchFiltersPreference.value,
  ]);

  return {
    action,
    control,
    deleteRights,
    editRights,
    handleClickApply,
    handleClickCancel,
    handleClickConfirm,
    handleClickDelete,
    handleClickEdit,
    onSubmit: reactHookFormHandleSubmit(handleSubmit),
    translate,
  };
}
