import { MouseEvent, useState } from 'react';

import { PopoverOrigin } from '@mui/material';

import { IconBoxButton } from 'src/components/IconBoxButton';
import { StyledBlockFieldButtonsContainer } from 'src/features/JobForm/components/BlockFieldButtons/styled';
import { BlockMoveType } from 'src/features/JobForm/components/BlocksFieldSection';
import { SubMenu, SubMenuItemType } from 'src/features/SubMenu';

const anchorOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'top' };

type BlockFieldButtonsProps = {
  isFirstBlockRow: boolean;
  isLastBlockRow: boolean;
  isNewBlockRow: boolean;
  onMoveBlockRow: (direction: BlockMoveType) => void;
  isDisabled: boolean;
};

export function BlockFieldButtons({
  isDisabled,
  isFirstBlockRow,
  isLastBlockRow,
  isNewBlockRow,
  onMoveBlockRow,
}: BlockFieldButtonsProps) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const subMenuItems: SubMenuItemType[] = [
    {
      code: 'move-first',
      disabled: isFirstBlockRow,
      onClick: handleMoveFirst,
      text: 'To The Top',
    },
    {
      code: 'move-last',
      disabled: isLastBlockRow,
      onClick: handleMoveLast,
      text: 'To The End',
    },
  ];

  function handleMoveUp() {
    onMoveBlockRow('up');
  }

  function handleMoveDown() {
    onMoveBlockRow('down');
  }

  function handleMoveFirst() {
    onMoveBlockRow('first');
    handleCloseSubMenu();
  }

  function handleMoveLast() {
    onMoveBlockRow('last');
    handleCloseSubMenu();
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  function handleOpenSubMenu(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setIsSubMenuOpen(true);
  }

  return (
    <StyledBlockFieldButtonsContainer isHidden={isNewBlockRow}>
      <IconBoxButton
        code="keyboard-arrow-down"
        disabled={isLastBlockRow || isDisabled}
        onClick={handleMoveDown}
      />

      <IconBoxButton
        code="keyboard-arrow-up"
        disabled={isFirstBlockRow || isDisabled}
        onClick={handleMoveUp}
      />

      <IconBoxButton
        code="more-vert"
        disabled={isDisabled}
        onClick={handleOpenSubMenu}
        variant="text"
      />

      {isSubMenuOpen && (
        <SubMenu
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          items={subMenuItems}
          onClose={handleCloseSubMenu}
          open
          transformOrigin={transformOrigin}
        />
      )}
    </StyledBlockFieldButtonsContainer>
  );
}
