import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import i18next from 'i18next';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Option } from 'src/components/RHF/Dropdown/Dropdown';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import {
  useLazyGetLanguageFieldQuery,
  useUpdateTranslationMutation,
} from 'src/features/JobForm/components/TranslationDialog/Translation.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { Options } from 'src/pages/Job/Job.service';

type TranslationDialogProps = {
  isOpen: boolean;
  onSetIsOpen: (arg: boolean) => void;
  value: string;
};
type TranslateForm = {
  source_language: Option | null;
  target_language: Option | null;
};
export function TranslationDialog({ isOpen, onSetIsOpen, value }: TranslationDialogProps) {
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit: rhfSubmit,
    reset,
    watch,
  } = useForm<TranslateForm>({
    defaultValues: {
      source_language: null,
      target_language: null,
    },
    mode: 'onChange',
  });

  const { t } = useTranslation();
  const REQUIRED_RULE = { required: { message: `${t('lib.required')}`, value: true } };
  const [sourceField, { data: sourceFieldData = [], isFetching: areSourceFieldFetching }] =
    useLazyGetLanguageFieldQuery();
  const [targetField, { data: targetFieldData = [], isFetching: areTargetFieldFetching }] =
    useLazyGetLanguageFieldQuery();
  const [targetLanguageValue, setTargetLanguage] = useState('');
  const [updateTranslation, { isLoading }] = useUpdateTranslationMutation();
  const watchSourceLanguage = watch('source_language');
  const watchTargetLanguage = watch('target_language');
  const { language } = i18next;
  const translatedNameField = `name_${language}`;
  const translatedSourceName = sourceFieldData[0]?.[translatedNameField as any];
  const translatedTargetName = targetFieldData[0]?.[translatedNameField as any];

  function decodeHtmlCharCodes(text: string) {
    const decodedString = document.createElement('textarea');

    decodedString.innerHTML = text;

    return decodedString.value;
  }

  async function handleTranslate({
    source_language: sourceLanguage,
    target_language: targetLanguage,
  }: TranslateForm) {
    updateTranslation({
      source_language: sourceLanguage?.value,
      target_language: targetLanguage?.value,
      text: value,
    })
      .unwrap()
      .then((response) => {
        setTargetLanguage(response.text);
      });
  }

  function handleCloseDialog() {
    reset();
    onSetIsOpen(false);
  }

  function transformFieldData(fieldData: Record<number, string> | Options) {
    const _fieldData = Object?.entries(fieldData)
      .filter(([value]) => value !== '')
      .map(([value, label]) => {
        return {
          label,
          value,
        };
      });

    return _fieldData;
  }

  function onInvalid(errors: any) {
    console.error(errors);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && isOpen) {
      sourceField({ alias: 'source_language', load_data: 1 });
      targetField({ alias: 'target_language', load_data: 1 });
    }

    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  return (
    <Dialog fullWidth maxWidth={'md'} onClose={handleCloseDialog} open={isOpen}>
      <WaveDialogTitle
        onClose={handleCloseDialog}
        title={t('content_translation', 'Content Translation')}
      />

      <form onSubmit={rhfSubmit(handleTranslate, onInvalid)}>
        <DialogContent>
          <Grid container direction="column">
            <Grid container marginY={2} spacing={2}>
              <Grid xs>
                <Autocomplete
                  control={control}
                  disabled={isLoading}
                  isFetching={areSourceFieldFetching}
                  label={translatedSourceName}
                  name="source_language"
                  options={
                    sourceFieldData.length
                      ? transformFieldData(sourceFieldData[0].field_data)
                      : [{ label: '', value: '' }]
                  }
                  rules={REQUIRED_RULE}
                />
              </Grid>

              <Grid xs>
                <Autocomplete
                  control={control}
                  disabled={isLoading}
                  isFetching={areTargetFieldFetching}
                  label={translatedTargetName}
                  name="target_language"
                  options={
                    targetFieldData.length
                      ? transformFieldData(targetFieldData[0].field_data)
                      : [{ label: '', value: '' }]
                  }
                  rules={REQUIRED_RULE}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs>
                <Box component={Paper} elevation={1} height="100%" padding={2} width="100%">
                  <Typography fontWeight="bold" paragraph={true} variant="subtitle2">
                    {watchSourceLanguage?.value ? watchSourceLanguage?.value : translatedSourceName}
                  </Typography>

                  <Typography variant="body2">{value}</Typography>
                </Box>
              </Grid>

              <Grid xs>
                <Box component={Paper} elevation={1} height="100%" padding={2} width="100%">
                  <Typography fontWeight="bold" paragraph={true} variant="subtitle2">
                    {watchTargetLanguage?.value ? watchTargetLanguage?.value : translatedTargetName}
                    :
                  </Typography>

                  <Typography variant="body2">
                    {decodeHtmlCharCodes(targetLanguageValue)}
                  </Typography>

                  <Box display="flex" justifyContent="end">
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.writeText(decodeHtmlCharCodes(targetLanguageValue))
                      }
                    >
                      <WaveIcon code="translate-copy" color="primary" fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <Box component={DialogActions} justifyContent="space-between" m={2}>
          <Button color="warning" disabled={isLoading} onClick={handleCloseDialog}>
            <Trans i18nKey="close_button">Close</Trans>
          </Button>

          <WaveTooltip
            body={
              !isDirty || !isValid
                ? (t(
                    'select_source_target_langauge',
                    'Please select source and target language.',
                  ) as string)
                : ''
            }
            component={
              <LoadingButton
                disabled={!isDirty || !isValid || isLoading}
                loading={isLoading}
                loadingPosition="start"
                startIcon={<WaveIcon code="job-form-language" />}
                type="submit"
              >
                <Trans i18nKey="translate">Translate</Trans>
              </LoadingButton>
            }
            type="simple"
          />
        </Box>
      </form>
    </Dialog>
  );
}
