import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

type RecentJob = {
  code: string;
  text: string;
  link: string;
  jobid: number;
};

type NewRecentJobRequest = {
  jobid?: string | number;
  src?: string;
  age?: ExpandedAges;
};

export const recentJobsApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    addRecentJob: build.mutation<void, NewRecentJobRequest>({
      invalidatesTags: ['RecentJobs'],
      query(data) {
        return {
          data,
          method: 'POST',
          url: '/jobs/recent',
        };
      },
    }),
    getRecentJobs: build.query<RecentJob[], void>({
      providesTags: ['RecentJobs'],
      query() {
        return {
          method: 'GET',
          url: '/jobs/recent',
        };
      },
    }),
  }),
  reducerPath: 'recentJobsApi',
  tagTypes: ['RecentJobs'],
});

export const { useAddRecentJobMutation, useGetRecentJobsQuery } = recentJobsApi;
