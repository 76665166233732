import { HeaderCellProps } from 'src/features/JobsTable/components/HeaderCell/HeaderCell';
import { usePreference, usePreferencePrefix } from 'src/utilities/hooks';

type UseHeaderCellProps = Pick<HeaderCellProps, 'fieldAlias'>;

export function useHeaderCell({ fieldAlias }: UseHeaderCellProps) {
  const { preferencePrefix } = usePreferencePrefix();

  const orderByColumnAliasPreference = usePreference(`${preferencePrefix}.order`, 'jobid');

  const orderByColumnAliasPreferenceValue = orderByColumnAliasPreference.value as string;
  const isDescending = orderByColumnAliasPreferenceValue.charAt(0) === '-';
  const orderByColumnAlias = isDescending
    ? orderByColumnAliasPreferenceValue.slice(1)
    : orderByColumnAliasPreferenceValue;
  const orderDirection = isDescending ? 'desc' : 'asc';
  const isActive = fieldAlias === orderByColumnAlias;

  function handleClickSort() {
    orderByColumnAliasPreference.set(orderDirection === 'desc' ? fieldAlias : `-${fieldAlias}`);
  }

  return { handleClickSort, isActive, orderDirection } as const;
}
