import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SerializedError } from '@reduxjs/toolkit';

import { useLocation } from 'react-router-dom';

import {
  UploadFileResponse as UploadGeneralFileResponse,
  useUploadFileMutation as useUploadGeneralFileMutation,
} from 'src/features/FileExplorer/FileExplorer.service';
import {
  UploadFileResponse as UploadJobFileResponse,
  useUploadFileMutation as useUploadJobFileMutation,
} from 'src/features/JobFiles/JobFiles.service';
import { DialogProps } from 'src/features/UploadFiles/components/Dialog/Dialog';
import { useGetCategoriesQuery } from 'src/features/UploadFiles/components/Dialog/Dialog.service';
import { computeSuccessfulUploadData } from 'src/features/UploadFiles/components/Dialog/utilities';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

import { ApiError } from 'src/utilities/types';

export type FormValues = { fileGroups: Files };
type Files = {
  category: { label: string; value: number } | null;
  comment: string;
  file: File;
  progress: number;
}[];
export type Responses = (
  | { data: UploadGeneralFileResponse | UploadJobFileResponse }
  | {
      error: ApiError | SerializedError;
    }
)[];

export function useDialog({ destination, files, isOpen, onCloseDialog, variant }: DialogProps) {
  const { age, jobId, jobType } = useRouteParams();
  const dispatch = useAppDispatch();
  const isGeneralFiles = useLocation().pathname.includes('/general-files');
  const { t: translate } = useTranslation();

  const shouldGetCategories =
    isOpen && destination !== 'dalim' && !isGeneralFiles && variant !== 'jobForm';
  const { data: categories } = useGetCategoriesQuery(undefined, { skip: !shouldGetCategories });

  const [uploadGeneralFile] = useUploadGeneralFileMutation();
  const [uploadJobFile] = useUploadJobFileMutation();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit: reactHookFormHandleSubmit,
    setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const {
    fields: fileGroups,
    remove: handleDeleteFiles,
    replace,
  } = useFieldArray({
    control,
    name: 'fileGroups',
  });

  async function handleSubmit(formValues: FormValues) {
    await Promise.all(
      formValues.fileGroups.map(({ category, comment, file }, index) =>
        isGeneralFiles
          ? uploadGeneralFile({
              destination,
              file,
              onUploadProgress: ({ progress }) => {
                setProgress(index, progress);
              },
            })
          : uploadJobFile({
              age,
              categoryId: category?.value || '',
              destination,
              file,
              jobId,
              jobType,
              onUploadProgress: ({ progress }) => {
                setProgress(index, progress);
              },
              ...(comment && { comment }),
            }),
      ),
    ).then((responses: Responses) => {
      const { areAllUploadsSuccessful, filenamesOfSuccessfulUploads, indexesOfSuccessfulUploads } =
        computeSuccessfulUploadData(responses);

      if (filenamesOfSuccessfulUploads.length) {
        handleDeleteFiles(indexesOfSuccessfulUploads);
        dispatch(
          openWaveSnack({
            message: `${filenamesOfSuccessfulUploads.join(', ')} ${translate('lib.file.uploaded')}`,
            type: 'success',
          }),
        );
      }

      if (areAllUploadsSuccessful) onCloseDialog(false);
    });
  }

  function setProgress(index: number, progress = 0) {
    setValue(`fileGroups.${index}.progress`, progress * 100);
  }

  useEffect(() => {
    if (files) {
      replace(
        Object.values(files).map((file) => ({
          category: null,
          comment: '',
          file,
          progress: 0,
        })),
      );
    }
  }, [files]);

  return {
    categories,
    control,
    fileGroups,
    handleDeleteFiles,
    isSubmitting,
    onSubmit: reactHookFormHandleSubmit(handleSubmit),
    shouldGetCategories,
    translate,
  };
}
