import DOMPurify from 'dompurify';

import { Typography } from '@mui/material';

type FormattedTextProps = {
  text: string;
};

export function FormattedText({ text }: FormattedTextProps) {
  const formattedBody = text
    .split('\n')
    .map((str, index, array) => (index === array.length - 1 ? str : `${str}<br/>`))
    .join('');

  const sanitizedBody = DOMPurify.sanitize(formattedBody);

  return <Typography dangerouslySetInnerHTML={{ __html: sanitizedBody }} />;
}
