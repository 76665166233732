import { useEffect, useState } from 'react';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { NavBar } from 'src/components/NavBar';
import { Spinner } from 'src/components/Spinner';
import { Job } from 'src/pages/Job';
import { Login } from 'src/pages/Login';
import { UpdatePassword } from 'src/pages/UpdatePassword';
import { Viewer } from 'src/pages/Viewer';
import { useAuthenticateExternalRevisorQuery } from 'src/routes/ExternalRevisor.service';
import { getExternalRevisorDetails } from 'src/store/userSlice';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useIsExternalRevisor } from 'src/utilities/hooks';

export function PublicRoute() {
  const { installationLogo } = useTheme();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const isExternalRevisor = useIsExternalRevisor();
  const age = isExternalRevisor ? path[3].split('-')[1] : path[1].split('-')[1];
  const jobId = isExternalRevisor ? path[4] : path[2];
  const jobType = isExternalRevisor ? path[3].split('-')[2] : path[1].split('-')[2];
  const tab = isExternalRevisor ? path[5] : path[3];
  const token = isExternalRevisor ? path[2] : '';

  const { error, isError, isSuccess } = useAuthenticateExternalRevisorQuery(
    {
      age,
      jobId,
      jobType,
      token,
    },
    { skip: !isExternalRevisor && tab !== 'external-viewer' },
  );

  function hasQueryParams(search: string) {
    return new URLSearchParams(search).toString() !== '';
  }

  useEffect(() => {
    if (isError) {
      navigate('/login');
      dispatch(
        openWaveSnack({
          message: error.message,
          type: 'error',
        }),
      );
    } else if (isSuccess) {
      (async () => {
        await dispatch(getExternalRevisorDetails());
        setIsLoading(false);
      })();
    }
  }, [isError, isSuccess]);

  return (
    <>
      {installationLogo ? (
        <Routes>
          <Route
            element={
              <Navigate
                replace
                state={{
                  name: (location.state as { name: string })?.name,
                }}
                to="/login"
              />
            }
            path="/*"
          />

          <Route element={<Login />} path="/login" />

          <Route element={<UpdatePassword />} path="/update-password/:secretKey" />

          <Route
            element={
              isLoading ? (
                <Spinner />
              ) : (
                <Box height={'calc(100vh - 288px)'}>
                  {hasQueryParams(location.search) ? (
                    <Viewer />
                  ) : (
                    <>
                      <NavBar />

                      <Job />
                    </>
                  )}
                </Box>
              )
            }
            path="external-revisor/:token/jobs-:age-:jobType/:jobId"
          />
        </Routes>
      ) : null}
    </>
  );
}
