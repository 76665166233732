import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, IconProps } from '@mui/material';

import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';
import { StyledContainer } from 'src/pages/FileSearch/FileSearchListItem/styled';

const ACTIONS = [
  {
    code: 'info',
    color: 'primary' as IconProps['color'],
    text: 'View Info',
    translationCode: 'file-ser.view_info',
  },
  {
    code: 'photo-library',
    color: 'primary' as IconProps['color'],
    text: 'View in Gallery',
    translationCode: 'file-ser.view_in_gallery',
  },
  {
    code: 'download',
    color: 'primary' as IconProps['color'],
    text: 'Download',
    translationCode: 'lib.file.download',
  },
  // {
  //   code: 'email',
  //   color: 'primary',
  //   text: 'Send Via Email',
  // },
];

type FileSearchDropdownButton = {
  onOpenPhotoSwipe: () => void;
  onAddToGroupSelection: () => void;
  onOpenJobFilesTab: () => void;
  onDownload: () => void;
  isListView: boolean;
};

export function FileSearchDropdownButton({
  isListView,
  onAddToGroupSelection,
  onDownload,
  onOpenJobFilesTab,
  onOpenPhotoSwipe,
}: FileSearchDropdownButton) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const isOpen = Boolean(anchorEl);
  const { t } = useTranslation();

  const actions = useMemo(() => {
    return ACTIONS.map((action) => ({
      code: action.code,
      icon: <WaveIcon code={action.code} color={action.color} fontSize="small" />,
      onClick: () => handleMenuClick(action.code),
      text: t(action.translationCode, action.text),
    }));
  }, []);

  function handleMenuClick(code: string) {
    switch (code) {
      case 'info':
        onOpenJobFilesTab();
        break;
      case 'photo-library':
        onOpenPhotoSwipe();
        break;
      case 'create-new-folder':
        onAddToGroupSelection();
        break;
      case 'download':
        onDownload();
        break;
      case 'email':
        console.log('email');
        break;
      default:
        console.error(`Unknown action code: ${code}`);
    }

    setAnchorEl(null);
  }

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setAnchorElWidth(e.currentTarget.offsetWidth);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <StyledContainer isListView={isListView}>
      <IconButton className="ignore-selection" onClick={handleClick}>
        <WaveIcon code="more-vert" />
      </IconButton>

      <SubMenu
        anchorEl={anchorEl}
        anchorElMinWidth={anchorElWidth}
        anchorOriginHorizontalSubitem="right"
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={actions}
        onClose={handleClose}
        open={isOpen}
        transformOriginHorizontalSubitem="left"
        transformOriginVerticalSubitem="top"
      />
    </StyledContainer>
  );
}
