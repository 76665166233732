import { styled } from '@mui/material';
import Link from '@mui/material/Link';

export const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
