import { useFormState } from 'react-hook-form';

import { useGetTasksQuery } from 'src/features/JobForm/JobForm.service';
import { useRouteParams } from 'src/utilities/hooks';

export function useTasks() {
  const { age, jobId, jobType } = useRouteParams();

  const { data: tasks = [], isFetching } = useGetTasksQuery(
    { age, jobId, jobType },
    { skip: jobId === 'new' || age === 'arc' },
  );

  const { isDirty } = useFormState();

  return { isDirty, isFetching, tasks };
}
