import { Button } from 'src/components/Button';
import { WaveIcon } from 'src/features/WaveIcon';

type PropsOfFiltersButton = { code: string; onToggleActiveFiltersVisibility: () => void };

export function FiltersButton({ code, onToggleActiveFiltersVisibility }: PropsOfFiltersButton) {
  return (
    <Button
      color="info"
      onClick={onToggleActiveFiltersVisibility}
      startIcon={<WaveIcon code={code} />}
    >
      lib.filter
    </Button>
  );
}
