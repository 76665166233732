import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";

import { toBool } from "../../../../utilities/helperFunctions";

export function CheckboxField({
  checkRequiredFields,
  disabled,
  id,
  indeterminate,
  isError,
  label,
  onChange,
  required,
  value,
}) {
  function handleOnChange(e) {
    e.target.id = id;
    e.target.name = label;
    e.target.value = e.target.checked;

    onChange(e);
  }

  function handleOnClick(e) {
    e.stopPropagation();
  }

  return (
    <FormControl component="fieldset" disabled={disabled} required={required}>
      <FormControlLabel
        label={
          <FormLabel
            classes={{
              root:
                (checkRequiredFields && required && !value) || isError
                  ? "error-txt"
                  : "primary-txt",
            }}
          >
            {label}
          </FormLabel>
        }
        control={
          <Checkbox
            checked={toBool(value)}
            classes={{
              root: indeterminate ? "secondary-txt" : "",
            }}
            color="primary"
            indeterminate={indeterminate}
            onChange={handleOnChange}
            size="small"
          />
        }
        onClick={handleOnClick}
      />
    </FormControl>
  );
}
