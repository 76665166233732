import { Fragment } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18next from 'i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from 'src/components/Button';
import { Checkbox } from 'src/components/RHF/Checkbox';
import {
  COLUMN_WIDTH,
  getFieldLabel,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { ClipboardSection } from 'src/features/JobForm/components/CopyCompareButton/type';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldsTransformed, JobValues } from 'src/pages/Job/Job.service';
import { FieldLayout, useJobContext } from 'src/pages/Job/JobContext';

const StyledWaveIcon = styled(WaveIcon)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledCheckBox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.common.white};

  &.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.common.white};
  }

  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.white};
  }

  &.Mui-checked .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`;

const StyledTypography = styled(Typography)`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(1)};
  min-height: ${({ theme }) => theme.spacing(3)};
  overflow-wrap: anywhere;
`;

type CompareReplaceTemplateAccordionProps = {
  section: ClipboardSection;
  fieldLayout?: FieldLayout;
  isExpanded: boolean;
  jobData: JobValues;
  control: Control<any>;
  onAccordionChange: (sectionId: string) => void;
  updatedCount: number;
  onDeselectEmptyFields: (sectionId: string) => void;
};

export function CompareReplaceTemplateAccordion({
  control,
  fieldLayout,
  isExpanded,
  jobData,
  onAccordionChange,
  onDeselectEmptyFields,
  section,
  updatedCount,
}: CompareReplaceTemplateAccordionProps) {
  const { language } = i18next;
  const { t } = useTranslation();
  const { fields: jobFields } = useJobContext();

  return (
    <Box key={`${section.sectionId}_copyReplace`} marginX={2}>
      <Accordion
        disableGutters
        expanded={isExpanded}
        onChange={() => {
          onAccordionChange(section.sectionId);
        }}
      >
        <AccordionSummary expandIcon={<StyledWaveIcon code="expand-more" />}>
          <Box marginTop={3} width={COLUMN_WIDTH}>
            <StyledCheckBox
              control={control}
              label={
                fieldLayout?.sections.find(
                  (fieldLayoutSection) => fieldLayoutSection.code === section.sectionId,
                )?.title
              }
              name={`${section.sectionId}.selected`}
            />
          </Box>

          <Box alignItems="center" display="flex" flex="1">
            <Typography fontWeight={600}>{`${t(
              'updated_value',
              'Updated Value',
            )} (${updatedCount})`}</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {section.fields.map((field) => {
            const translatedNameField = `name_${language}`;

            return (
              <Fragment key={`${field.fieldId}_template_selection`}>
                <Box display="flex" key={field.fieldId}>
                  <Box alignItems="center" display="flex" width={COLUMN_WIDTH}>
                    <Checkbox
                      control={control}
                      label={jobFields?.[field.fieldId]?.[translatedNameField as any] ?? ''}
                      name={`${section.sectionId}.fields.${field.fieldId}`}
                    />
                  </Box>

                  <Box alignItems="center" bgcolor="filler.main" display="flex" flex="1">
                    <StyledTypography>
                      {getFieldLabel(
                        field.fieldId,
                        jobData,
                        jobFields as FieldsTransformed,
                        field.value,
                      )}
                    </StyledTypography>
                  </Box>
                </Box>

                <Divider />
              </Fragment>
            );
          })}

          <Box display="flex">
            <Box width={COLUMN_WIDTH} />

            <Box alignItems="center" display="flex" flex="1" marginTop={2}>
              <Box
                component={Button}
                onClick={() => onDeselectEmptyFields(section.sectionId)}
                startIcon={<WaveIcon code="disabled-by-default" />}
              >
                Deselect empty
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
