import { Dispatch, Fragment, SetStateAction } from 'react';

import {
  Box,
  Collapse,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';
import { FoldersType } from '../../FileExplorer.service';
import { CreateOrRenameFolderButton } from '../CreateOrRenameFolderButton';
import { DeleteButton } from '../DeleteButton';

type FoldersProps = {
  expandedFolders: Record<string, boolean>;
  folders: FoldersType;
  hasSubFoldersInTree: boolean;
  isSubFolder?: boolean;
  onToggleExpandFolder: (itemId: string) => void;
  parentFolderPadding?: number;
  selectedFolder: string;
  setSelectedFolder: Dispatch<SetStateAction<string>>;
};

export function Folders({
  expandedFolders,
  folders,
  hasSubFoldersInTree,
  isSubFolder,
  onToggleExpandFolder,
  parentFolderPadding = 0,
  selectedFolder,
  setSelectedFolder,
}: FoldersProps) {
  return (
    <>
      {folders.map(
        ({
          code,
          file_count: fileCount,
          name,
          path_name: pathName,
          rights: { delete: canDelete, edit: canEdit, insert: canInsert },
          subItems: subFolders,
        }) => {
          const hasSubFolders = subFolders.length;
          const folderPadding =
            !isSubFolder && hasSubFoldersInTree && !hasSubFolders
              ? 6
              : isSubFolder && hasSubFolders
              ? parentFolderPadding + 4
              : isSubFolder && hasSubFoldersInTree && !hasSubFolders
              ? parentFolderPadding + 9
              : 1;

          return (
            <Fragment key={code}>
              <Box
                component={ListItemButton}
                onClick={() => setSelectedFolder(code)}
                pl={folderPadding}
                selected={selectedFolder === code}
                {...((hasSubFoldersInTree || isSubFolder) && { height: 48 })}
              >
                {hasSubFolders ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleExpandFolder(code);
                    }}
                  >
                    <WaveIcon
                      code={
                        expandedFolders[code]
                          ? 'general-files-folder-expand-less'
                          : 'general-files-folder-expand-more'
                      }
                    />
                  </IconButton>
                ) : null}

                <ListItemIcon>
                  <WaveIcon className="material-icons-outlined" code="general-files-folder" />
                </ListItemIcon>

                <Box component={ListItemText} pr={2} primary={name} />

                <Box alignItems="center" display="flex">
                  {canEdit ? (
                    <CreateOrRenameFolderButton
                      expandedFolders={expandedFolders}
                      folderCode={code}
                      onToggleExpandFolder={onToggleExpandFolder}
                      previousFoldername={name}
                      setSelectedFolder={setSelectedFolder}
                      variant="rename"
                    />
                  ) : null}

                  {canInsert ? (
                    <CreateOrRenameFolderButton
                      expandedFolders={expandedFolders}
                      folderCode={code}
                      onToggleExpandFolder={onToggleExpandFolder}
                      setSelectedFolder={setSelectedFolder}
                      variant="create"
                    />
                  ) : null}

                  {canDelete ? (
                    <DeleteButton
                      folderCode={code}
                      folderPathName={pathName}
                      location="general-files-folder"
                      setSelectedFolder={setSelectedFolder}
                      variant="folderIcon"
                    />
                  ) : null}

                  <ListItemText primary={fileCount} />
                </Box>
              </Box>

              {hasSubFolders && expandedFolders[code] ? (
                <Box component={Collapse} in>
                  <Folders
                    expandedFolders={expandedFolders}
                    folders={subFolders}
                    hasSubFoldersInTree={folders.some(
                      ({ subItems: subFolders }) => subFolders.length,
                    )}
                    isSubFolder
                    onToggleExpandFolder={onToggleExpandFolder}
                    parentFolderPadding={folderPadding}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                  />
                </Box>
              ) : null}
            </Fragment>
          );
        },
      )}
    </>
  );
}
