import dayjs from 'dayjs';

import { Approval } from 'src/features/Approvals/Approvals.service';
import { ApprovalCell } from 'src/features/JobsTable/components/ApprovalCell';
import { transformFieldAlias } from 'src/features/JobsTable/components/Cell/utilities';
import { DateCell } from 'src/features/JobsTable/components/DateCell';
import { FlagCell } from 'src/features/JobsTable/components/FlagCell';
import { PreviewCell } from 'src/features/JobsTable/components/PreviewCell';
import { UseRowProps } from 'src/features/JobsTable/components/Row/useRow';
import { StatusCell } from 'src/features/JobsTable/components/StatusCell';
import { TasksCell } from 'src/features/JobsTable/components/TasksCell';
import { TextCell } from 'src/features/JobsTable/components/TextCell';
import { FlagTransformed, RowTransformed, Task } from 'src/features/JobsTable/JobsTable.service';
import { isZeroFormatDate } from 'src/utilities/helperFunctions';
import { isNumber, isString } from 'src/utilities/helperFunctions2';

import { CommonCellProps, IsDense, JobLink } from 'src/features/JobsTable/types';
export type CellProps = { fieldAlias: string; status: RowTransformed['status'] } & CommonCellProps &
  IsDense &
  JobLink &
  Pick<UseRowProps, 'deadline' | 'row'>;

export function Cell({
  deadline,
  fieldAlias,
  isDense,
  isSubRow,
  jobLink,
  paddingY,
  row,
  status,
}: CellProps) {
  const jobId = row.jobId;
  const fieldAliasTransformed = transformFieldAlias(fieldAlias);
  const value = row[fieldAliasTransformed];

  const isDate =
    isString(value) || isNumber(value) || value === undefined
      ? dayjs(value, 'YYYY-MM-DD HH:mm:ss', true).isValid() ||
        dayjs(value, 'YYYY-MM-DD', true).isValid() ||
        (isString(value) && isZeroFormatDate(value))
      : false;

  // TODO: Remove typecasting by using type guards.
  if (fieldAliasTransformed === 'apl') {
    return (
      <ApprovalCell
        isSubRow={isSubRow}
        jobId={jobId}
        key={fieldAliasTransformed}
        paddingY={paddingY}
        rowJobType={row.jobType}
        status={status}
        value={value as Approval}
      />
    );
  }

  if (fieldAliasTransformed === 'flags') {
    return (
      <FlagCell
        flags={value as FlagTransformed[]}
        isSubRow={isSubRow}
        jobLink={jobLink}
        key={fieldAliasTransformed}
        paddingY={paddingY}
      />
    );
  }

  if (
    fieldAliasTransformed === 'preview' &&
    value &&
    !isNumber(value) &&
    !isString(value) &&
    !Array.isArray(value) &&
    'url' in value
  ) {
    return (
      <PreviewCell
        isDense={isDense}
        isSubRow={isSubRow}
        jobId={jobId}
        jobLink={jobLink}
        key={fieldAliasTransformed}
        paddingY={paddingY}
        preview={value}
        rowJobType={row.jobType}
      />
    );
  }

  if (fieldAliasTransformed === 'tasks') {
    return (
      <TasksCell
        isSubRow={isSubRow}
        jobLink={jobLink}
        key={fieldAliasTransformed}
        paddingY={paddingY}
        tasks={value as Task[]}
      />
    );
  }

  if (fieldAliasTransformed === 'status' && isNumber(value)) {
    return (
      <StatusCell
        isSubRow={isSubRow}
        jobLink={jobLink}
        key={fieldAliasTransformed}
        paddingY={paddingY}
        rowJobType={row.jobType}
        value={value}
      />
    );
  }

  if (isDate && isString(value)) {
    return (
      <DateCell
        deadlineValue={deadline[fieldAliasTransformed]}
        isSubRow={isSubRow}
        key={fieldAliasTransformed}
        paddingY={paddingY}
        value={value}
      />
    );
  }

  return (
    <TextCell
      isSubRow={isSubRow}
      jobLink={jobLink}
      key={fieldAliasTransformed}
      paddingY={paddingY}
      value={value as string}
    />
  );
}
