import { Control } from 'react-hook-form';

import { sortBy } from 'lodash';

import { Box, IconButton, Typography } from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { ProgressBar } from 'src/components/RHF/ProgressBar';
import { TextField } from 'src/components/RHF/TextField';
import { Categories } from 'src/features/UploadFiles/components/Dialog/Dialog.service';
import { FormValues } from 'src/features/UploadFiles/components/Dialog/useDialog';
import { useFileGroup } from 'src/features/UploadFiles/components/FileGroup/useFileGroup';
import { WaveIcon } from 'src/features/WaveIcon';
import { formatBytes } from 'src/utilities/helperFunctions';

export type FileGroupProps = {
  categories?: Categories;
  control: Control<FormValues, any>;
  file: File;
  index: number;
  isOneFile: boolean;
  isSubmitting: boolean;
  onDeleteFiles: (index: number) => void;
  shouldRenderCategorySelection: boolean;
};

const selectFieldHeight = 40;
const helperTextHeight = 20;
const stylingForEqualSizedFlexItems = { flexBasis: 0, flexGrow: 1, flexShrink: 1 } as const;

export function FileGroup({
  categories,
  control,
  file,
  index,
  isOneFile,
  isSubmitting,
  onDeleteFiles,
  shouldRenderCategorySelection,
}: FileGroupProps) {
  const { isGeneralFiles, translate } = useFileGroup();

  const sortedCategories = sortBy(categories, ['label']);

  function handleDeleteFile() {
    onDeleteFiles(index);
  }

  return (
    <>
      <Box alignItems="center" display="flex" gap={2} justifyContent="space-between">
        <Typography
          color="primary"
          height={selectFieldHeight}
          variant="subtitle2"
          {...stylingForEqualSizedFlexItems}
        >
          {file.name}
        </Typography>

        <Typography
          color="primary"
          height={selectFieldHeight}
          variant="subtitle2"
          {...stylingForEqualSizedFlexItems}
        >
          {formatBytes(file.size)}
        </Typography>

        {shouldRenderCategorySelection ? (
          <Box {...stylingForEqualSizedFlexItems}>
            <Autocomplete
              control={control}
              disabled={isSubmitting}
              isFetching={!categories}
              label={translate('lib.file.cat')}
              name={`fileGroups.${index}.category`}
              options={sortedCategories || []}
              rules={{ required: { message: `${translate('lib.required')}`, value: true } }}
              shouldShowHelperText
            />
          </Box>
        ) : null}

        <Box height={selectFieldHeight} {...stylingForEqualSizedFlexItems}>
          <ProgressBar control={control} name={`fileGroups.${index}.progress`} />
        </Box>

        {!isOneFile ? (
          <Box height={selectFieldHeight + helperTextHeight}>
            <IconButton disabled={isSubmitting} onClick={handleDeleteFile}>
              <WaveIcon code="delete" color="primary" fontSize="small" />
            </IconButton>
          </Box>
        ) : null}
      </Box>

      {!isGeneralFiles ? (
        <TextField
          control={control}
          disabled={isSubmitting}
          label={translate('lib.file.edit')}
          name={`fileGroups.${index}.comment`}
          sx={{ pt: 1 }}
          type="memo"
        />
      ) : null}
    </>
  );
}
