import React from "react";

import { TextField } from "@mui/material";

export function Hidden({ id, value }) {
  return (
    <TextField
      className="d-none"
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      type="hidden"
      value={value || ""}
    />
  );
}
