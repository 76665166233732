import { InputAdornment, TextField } from "@mui/material";

import { WaveIcon } from "../../../WaveIcon";

export function Barcode({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onBlur,
  onChange,
  required,
  value,
}) {
  const invalidBarcode = value?.length > 0 && !validBarcode(value);
  const waveIconClass = !value?.length
    ? "primary-txt"
    : invalidBarcode
    ? "error-txt"
    : "success-txt";
  const waveIconCode = !value?.length
    ? "input-field-empty"
    : invalidBarcode
    ? "input-field-invalid"
    : "input-field-valid";

  function handleOnBlur(e) {
    e.target.name = label;

    if (onBlur) onBlur(e);
  }

  function validBarcode(barcode) {
    const barcodeLengths = [8, 12, 13, 14, 17, 18];
    const barcodeString = barcode.toString();
    const length = barcodeString.length;

    if (barcode && barcodeLengths.includes(length)) {
      let sum = 0;
      let multiplier = length % 2 === 0 ? 3 : 1;

      for (let i = 0; i <= length - 2; i++) {
        const currentDigit = parseInt(barcodeString[i]);
        sum += currentDigit * multiplier;
        multiplier = multiplier === 3 ? 1 : 3;
      }

      const checkDigit = 10 - (sum % 10);
      const lastDigit = parseInt(barcodeString[length - 1]);

      return checkDigit === lastDigit;
    } else {
      return false;
    }
  }

  return (
    <TextField
      autoComplete="off"
      disabled={disabled}
      error={
        (checkRequiredFields && required && !value) || invalidBarcode || isError
      }
      fullWidth
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <WaveIcon
              className={waveIconClass}
              code={waveIconCode}
              fontSize="small"
            />
          </InputAdornment>
        ),
      }}
      label={label}
      onBlur={handleOnBlur}
      onChange={onChange}
      required={required}
      size="small"
      type="number"
      value={value || ""}
      variant="outlined"
    />
  );
}
