import React, { ChangeEvent } from 'react';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Checkbox, Unstable_Grid2 as Grid2 } from '@mui/material';

import { SliderValue } from 'src/features/History/History.types';

const sxTimelineDotStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  margin: 0,
  padding: 0,
};

const sxTimelineItemStyle = {
  '&:before': {
    content: 'none',
  },
  minHeight: '60px',
};

export function HistoryTimeline({
  onValueChange,
  selectedValues,
  timelines,
}: {
  timelines: SliderValue[];
  selectedValues: SliderValue[];
  onValueChange: (sliderValues: SliderValue[]) => void;
}) {
  function onChangeCheckboxValue(value: SliderValue, event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      onValueChange([...selectedValues, value]);
    } else {
      onValueChange(selectedValues.filter((v) => v.value !== value.value));
    }
  }

  function isItemChecked(sliderValue: SliderValue) {
    return selectedValues.map((item) => item.value).includes(sliderValue.value);
  }

  return (
    <Grid2 margin={0} padding={0}>
      <Timeline sx={{ margin: 0, padding: '5px 32px' }}>
        <TimelineItem sx={sxTimelineItemStyle}>
          <TimelineSeparator>
            <TimelineDot sx={sxTimelineDotStyle}>
              <Checkbox
                checked={timelines.length === selectedValues.length}
                name="history-timeline-all"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onValueChange(event.target.checked ? timelines : [])
                }
                sx={{ padding: 0 }}
              />
            </TimelineDot>
          </TimelineSeparator>

          <TimelineContent sx={{ fontWeight: 500, paddingTop: 0 }}>All Month</TimelineContent>
        </TimelineItem>

        {timelines.map((timeline, index) => (
          <TimelineItem key={timeline.value} sx={sxTimelineItemStyle}>
            <TimelineSeparator>
              <TimelineDot sx={sxTimelineDotStyle}>
                <Checkbox
                  checked={selectedValues.map((t) => t.value).includes(timeline.value)}
                  name={`history-timeline-${timeline.value}`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChangeCheckboxValue(timeline, event)
                  }
                  sx={{ padding: 0 }}
                  value={timeline.value}
                />
              </TimelineDot>

              {timelines.length - 1 > index && (
                <TimelineConnector sx={{ backgroundColor: '#ccc' }} />
              )}
            </TimelineSeparator>

            <TimelineContent
              sx={{ fontWeight: isItemChecked(timeline) ? 500 : 400, paddingTop: 0 }}
            >
              {timeline.label}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Grid2>
  );
}
