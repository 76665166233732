import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Popper,
  PopperProps,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Button } from 'src/components/Button';
import {
  EmailTemplateItem,
  useGetEmailTemplatesQuery,
  useLazyGetEmailUserListQuery,
  useSendEmailFromCartMutation,
  useSendEmailFromJobMutation,
} from 'src/components/SendEmailDialog/EmailNotification.service';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { Options } from 'src/pages/Job/Job.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { currentDate } from 'src/utilities/constants';
import { useAppSelector, useDebounce, useRouteParams } from 'src/utilities/hooks';

type SendEmailDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  isShoppingCart?: boolean;
  title: string;
};

export function SendEmailDialog({
  isOpen,
  isShoppingCart = false,
  onClose,
  title,
}: SendEmailDialogProps) {
  const { t } = useTranslation();
  const { age, jobId, jobType, tab } = useRouteParams();

  const [additionalRecipients, setAdditionalRecipients] = useState<Options>([]);
  const dispatch = useDispatch();
  const [emailListUser, setEmailListUser] = useState<Array<{ label: string; value: number }>>();
  const [searchAdditionalRecipients, setSearchAdditionalRecipients] = useState<string>('');
  const debouncedValue = useDebounce(searchAdditionalRecipients, 500);
  const [searchEmailList, { isFetching: isEmailListLoading }] = useLazyGetEmailUserListQuery();
  const [sendEmailFromCart] = useSendEmailFromCartMutation();
  const [sendEmailFromJob] = useSendEmailFromJobMutation();
  const userId = useAppSelector((state) => state.user.details.id);
  const [message, setMessage] = useState<string | undefined>();
  const [subject, setSubject] = useState<string | undefined>();
  const [recipients, setRecipients] = useState<Array<EmailTemplateItem>>();
  const [linkExpirationDate, setLinkExpirationDate] = useState<Date | null>();

  const { data: emailTemplates, isFetching: isLoading } = useGetEmailTemplatesQuery(
    {
      age,
      jobid: jobId,
      src: jobType,
      tab: isShoppingCart ? 'cart' : tab === 'his' ? 'job' : tab,
    },
    { skip: !isOpen },
  );

  const isDisabled = !isShoppingCart
    ? recipients?.every((item) => !item.checked) && !additionalRecipients.length
    : !additionalRecipients.length;

  function handleSubjectChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setSubject(event.target.value);
  }

  function handleMessageChange(event: ChangeEvent<HTMLInputElement>) {
    setMessage(event.target.value);
  }

  function handleSearchAdditionalRecipients(search: string) {
    setSearchAdditionalRecipients?.(search);
    setEmailListUser([]);
  }

  function handleChangeToRoles(selectedRole: string, selectedId: number) {
    setRecipients?.(
      recipients?.map((role) =>
        role.role === selectedRole && role.id === selectedId
          ? { ...role, checked: !role.checked }
          : role,
      ),
    );
  }

  function handleSendEmailNotification() {
    if (!recipients) return;

    if (isShoppingCart) {
      const idsOfRecipients = additionalRecipients.map(({ value }) => value);

      sendEmailFromCart({
        message,
        receiver_id: idsOfRecipients,
        sender_id: userId,
        subject,
      })
        .unwrap()
        .then((message) => {
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          onClose();
        });
    } else {
      const receiverId = recipients.reduce<number[]>((acc, { checked, id }) => {
        if (checked) acc.push(id);

        return acc;
      }, []);
      const receiverTypes = recipients
        .filter((recipient) => recipient.checked)
        .map((recipient) => recipient.type)
        .filter((value, index, self) => index == self.indexOf(value));

      const idsOfRecipients = additionalRecipients.map(({ value }) => value);

      const payload: any = {
        age,
        jobId,
        message,
        src: jobType,
        subject,
        tab: tab === 'his' ? 'job' : tab,
      };

      if (idsOfRecipients.length) {
        payload.additionalUserIds = idsOfRecipients;
      }

      if (receiverId.length) {
        payload.userIds = receiverId;
      }

      if (receiverTypes.length) {
        payload.mailType = receiverTypes;
      }

      sendEmailFromJob(payload)
        .unwrap()
        .then((message) => {
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          onClose();
        });
    }
  }

  useEffect(() => {
    if (!debouncedValue) return;

    searchEmailList({ term: debouncedValue })
      .unwrap()
      .then((recipients) => {
        setEmailListUser(recipients);
      });
  }, [debouncedValue]);

  useEffect(() => {
    if (!emailTemplates) return;

    const {
      expiration_date: expirationDate,
      template: { message, subject },
      to_roles: toRoles,
    } = emailTemplates;

    setLinkExpirationDate(expirationDate ? new Date(expirationDate) : null);
    setMessage(message);
    setRecipients(toRoles || []);
    setSubject(subject);
  }, [emailTemplates]);

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
      <WaveDialogTitle onClose={onClose} title={<Trans>{title}</Trans>} />

      <DialogContent>
        <Stack spacing={2}>
          {linkExpirationDate && (
            <Grid container>
              <Grid xs={3}>
                {isLoading ? (
                  <Skeleton height={40} variant="rounded" width="100%" />
                ) : (
                  <WaveTooltip
                    body={emailTemplates?.expiration_tooltip}
                    component={
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={emailTemplates?.expiration_label}
                          maxDate={
                            emailTemplates?.expiration_date
                              ? new Date(emailTemplates.expiration_date)
                              : undefined
                          }
                          minDate={currentDate}
                          onChange={setLinkExpirationDate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                            />
                          )}
                          value={linkExpirationDate}
                        />
                      </LocalizationProvider>
                    }
                    placement="right"
                    type="simple"
                  />
                )}
              </Grid>
            </Grid>
          )}

          {recipients?.length ? (
            <Grid alignItems="center" container display="flex" gap={2}>
              <Grid>
                <Typography>{`${t('to', 'To')}: `}</Typography>
              </Grid>

              <Stack direction="column">
                {recipients?.map((role) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={role.checked}
                        onChange={() => handleChangeToRoles(role.role, role.id)}
                      />
                    }
                    key={role.role}
                    label={`${role.role} - ${role.name}`}
                  />
                ))}
              </Stack>
            </Grid>
          ) : null}

          {isLoading ? (
            <Skeleton height={40} variant="rounded" width="100%" />
          ) : (
            <Autocomplete
              forcePopupIcon={false}
              fullWidth
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              loading={isEmailListLoading}
              multiple
              onChange={(event, newValue) => {
                setAdditionalRecipients(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                handleSearchAdditionalRecipients(newInputValue);
              }}
              options={emailListUser ?? []}
              PopperComponent={(props: PopperProps) =>
                emailListUser?.length ? <Popper {...props} disablePortal /> : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isEmailListLoading ? <CircularProgress color="inherit" size={20} /> : null}

                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  label={
                    isShoppingCart
                      ? t('to', 'To')
                      : t('additional_recipient', 'Additional Recipients')
                  }
                />
              )}
              size="small"
              value={additionalRecipients}
            />
          )}

          {isLoading ? (
            <Skeleton height={40} variant="rounded" width="100%" />
          ) : (
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={<Trans>lib.sbj</Trans>}
              onChange={handleSubjectChange}
              size="small"
              value={subject}
              variant="outlined"
            />
          )}

          {isLoading ? (
            <Skeleton height={270} variant="rounded" width="100%" />
          ) : (
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={<Trans>sys.message</Trans>}
              multiline
              onChange={handleMessageChange}
              rows={11}
              size="small"
              value={message}
              variant="outlined"
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="warning" onClick={onClose}>
          lib.cancel
        </Button>

        <WaveTooltip
          body={isDisabled ? `${t('recipient_required', 'Recipient is required')}` : ''}
          component={
            <Button disabled={isDisabled} onClick={handleSendEmailNotification}>
              lib.send
            </Button>
          }
          placement="right"
          type="simple"
        />
      </DialogActions>
    </Dialog>
  );
}
