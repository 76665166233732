import { useEffect, useState } from 'react';

interface ImageDimensions {
  width: number;
  height: number;
}

export function useImageDimensions(url: string): {
  dimensions: ImageDimensions | null;
  isLoading: boolean;
  error: Error | null;
} {
  const [dimensions, setDimensions] = useState<ImageDimensions | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const img = new Image();

    img.onload = function () {
      setDimensions({ height: img.height, width: img.width });
      setIsLoading(false);
    };

    img.onerror = function () {
      setError(new Error('Failed to load image'));
      setIsLoading(false);
    };

    img.src = url;
  }, [url]);

  return { dimensions, error, isLoading };
}
