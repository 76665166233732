import { useMemo, useRef, useState } from 'react';

import { Flag, useGetFlagsQuery } from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useRouteParams } from 'src/utilities/hooks';

export function useFlags() {
  const { age, jobId, jobType } = useRouteParams();

  const [flag, setFlag] = useState<Flag>({} as Flag);
  const [isJobFlagDialogOpen, setIsJobFlagDialogOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const flagsButton = useRef<HTMLButtonElement | null>(null);

  const { data: flags, isFetching } = useGetFlagsQuery(
    { age, jobId, jobType },
    { skip: jobId === 'new' },
  );

  const flagButtons = useMemo(
    () =>
      flags?.map((flag) => {
        const { code, text } = flag;

        return {
          code,
          icon: (
            <WaveIcon
              code={`job-form-action-bar-flags-flag-button-${code}`}
              sx={{
                color:
                  code === 'onhold'
                    ? 'warning.main'
                    : code === 'resume' || code === 'revive'
                    ? 'success.main'
                    : 'error.main',
                fontsize: 'small',
              }}
            />
          ),
          onClick: () => {
            handleOpenJobFlagDialog(flag);
          },
          text,
        };
      }) ?? [],
    [flags],
  );

  function handleCloseJobFlagDialog(isSubmitting: boolean) {
    if (isSubmitting) return;

    setIsJobFlagDialogOpen(false);
  }

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleOpenJobFlagDialog(flag: Flag) {
    handleCloseSubMenu();
    setFlag(flag);
    setIsJobFlagDialogOpen(true);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  return {
    flag,
    flagButtons,
    flagsButton,
    handleCloseJobFlagDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isFetching,
    isJobFlagDialogOpen,
    isSubMenuOpen,
  };
}
