import { IconButton, styled } from '@mui/material';

import { SubMenu } from 'src/features/SubMenu';

type StyledIconButtonProps = {
  isSubMenuOpen: boolean;
};

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isSubMenuOpen',
})<StyledIconButtonProps>`
  background-color: ${({ isSubMenuOpen, theme }) =>
    isSubMenuOpen ? theme.palette.secondary.main : ''};
  color: ${({ isSubMenuOpen, theme }) =>
    isSubMenuOpen ? theme.palette.primary.main : theme.palette.secondary.main};
`;

export const StyledSubMenu = styled(SubMenu)`
  background-color: ${({ theme }) => theme.palette.filler.main};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
