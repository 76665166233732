import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FileSearchSelectedData = [number, string, string];
export type FileSearchStateType = Array<FileSearchSelectedData>;

export const fileSearchSlice = createSlice({
  initialState: [] as FileSearchStateType,
  name: 'fileSearch',
  reducers: {
    addSelected: (state, action: PayloadAction<[number, string, string]>) => {
      const [id, jobId] = action.payload;
      const doesExist = state.some(
        ([existingId, existingJobId]) => existingId === id && existingJobId === jobId,
      );

      if (!doesExist) {
        state.push(action.payload);
      }
    },

    clearSelected: () => {
      return [];
    },

    removeSelected: (state, action: PayloadAction<[number, string, string]>) => {
      const [id, jobId] = action.payload;

      return state.filter(
        ([existingId, existingJobId]) => existingId !== id || existingJobId !== jobId,
      );
    },
  },
});

export const { addSelected, clearSelected, removeSelected } = fileSearchSlice.actions;

// eslint-disable-next-line import/no-default-export
export default fileSearchSlice.reducer;
