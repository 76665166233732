import { Location } from 'react-router-dom';

import { MainMenu, MainMenus } from 'src/store/userSlice';

export type LocationState = {
  code: string;
  name: string;
};

export type ExtendedLocation = Location & { state: LocationState };

type BreadcrumbData = {
  code?: string;
  link?: string;
  name: string;
};

export function getSelectedMenuData(menus: MainMenus, urlPath: string): MainMenu | null {
  for (const menu of menus) {
    if (menu.link.includes(urlPath)) {
      return menu;
    }

    const subMenuData = menu.subitems ? getSelectedMenuData(menu.subitems, urlPath) : null;

    if (subMenuData !== null) {
      return subMenuData;
    }
  }

  return null;
}

export function getBreadCrumbs(
  mainMenu: MainMenus,
  location: ExtendedLocation,
): Array<BreadcrumbData> {
  const urlSegments = extractUrlSegments(location);

  return urlSegments
    .map((segment, index) => {
      switch (index) {
        case 0:
          return getFirstBreadcrumb(mainMenu, segment, location);
        case 1:
          return getSecondBreadcrumb(segment, urlSegments[0]);
        case 2:
          return getThirdBreadcrumb(segment);
        default:
          return null;
      }
    })
    .filter((breadcrumb): breadcrumb is BreadcrumbData => breadcrumb !== null);
}

function extractUrlSegments(location: ExtendedLocation) {
  return location.pathname.substring(1).split('/');
}

// handles first menuitem from menu items from the backend or location for static
// frontend menu items
function getFirstBreadcrumb(
  mainMenu: MainMenus,
  firstSegment: string,
  location: ExtendedLocation,
): BreadcrumbData {
  const menuData = getSelectedMenuData(mainMenu, firstSegment);

  if (menuData) {
    return { code: menuData.code, link: menuData.link, name: menuData.name };
  }

  return { code: location.state.code, link: location.pathname, name: location.state.name };
}

// handles job number
function getSecondBreadcrumb(segment: string, previousSegment: string): BreadcrumbData {
  if (segment === 'new') {
    return {
      code: 'lib.new',
      name: 'New',
    };
  }

  return { link: `/${previousSegment}/${segment}`, name: segment };
}

// Handles compare and assign views
function getThirdBreadcrumb(segment: string): BreadcrumbData | null {
  const specialBreadcrumbs: Array<BreadcrumbData> = [
    { code: 'breadcrumb_assign', link: 'assign', name: 'Assign' },
    { code: 'breadcrumb_compare', link: 'compare', name: 'Compare' },
  ];
  const breadcrumb = specialBreadcrumbs.find((breadcrumb) => breadcrumb.link === segment);

  return breadcrumb || null;
}
