import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";

import { closeWaveSnack } from "../../store/waveSnackSlice";

import { WaveIcon } from "../../features/WaveIcon";

function formatMessage(message) {
  return Array.isArray(message)
    ? message.map((messageLine) => (
        <Typography component="p" key={messageLine} variant="body2">
          {messageLine}
        </Typography>
      ))
    : message;
}

export function WaveSnack() {
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const dispatch = useDispatch();
  const message = useSelector((state) => state.waveSnack.message);
  const [open, setOpen] = useState(false);
  const type = useSelector((state) => state.waveSnack.type);

  function handleOnClick() {
    dispatch(closeWaveSnack());
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setAutoHideDuration(type === "success" ? 3000 : null);
      setOpen(type ? true : false);
    }

    return () => (isMounted = false);
  }, [type]);

  return open ? (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      autoHideDuration={autoHideDuration}
      onClose={handleOnClick}
      open={open}
    >
      <SnackbarContent
        action={
          type !== "success" && (
            <IconButton color="inherit" onClick={handleOnClick} size="small">
              <WaveIcon code="close" fontSize="small" />
            </IconButton>
          )
        }
        classes={{ message: "m-auto", root: type }}
        message={formatMessage(message)}
      />
    </Snackbar>
  ) : null;
}
