import {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';

const LISTBOX_PADDING = 8;

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

export const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactElement[] = [];

    function renderRow(props: ListChildComponentProps) {
      const { data, index, style } = props;
      const dataSet = data[index][1];
      const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
      };

      return (
        <ListItem disablePadding key={dataSet.value} style={inlineStyle}>
          <ListItemButton dense {...data[index][0]}>
            <ListItemText primary={dataSet.label} />
          </ListItemButton>
        </ListItem>
      );
    }

    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;

    function getChildSize() {
      return 36;
    }

    function getHeight() {
      if (itemCount > 8) {
        return 8 * 36;
      }

      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    }

    const gridRef = useResetCache(itemCount);

    return (
      <Box ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            height={getHeight() + 2 * LISTBOX_PADDING}
            innerElementType="ul"
            itemCount={itemCount}
            itemData={itemData}
            itemSize={() => 36}
            outerElementType={OuterElementType}
            overscanCount={5}
            ref={gridRef}
            width="100%"
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </Box>
    );
  },
);
