import React from "react";

import { FormControlLabel, Switch } from "@mui/material";

export function SwitchField({ disabled, id, label, onChange, value }) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          disabled={disabled}
          id={id}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
}
