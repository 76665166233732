import type { ButtonProps, CSSObject } from '@mui/material';

const defaultButtonStyles = {
  defaultProps: {
    disableElevation: true,
    size: 'medium',
    variant: 'contained',
  } as ButtonProps,
  styleOverrides: {
    root: {
      borderRadius: 10,
      textTransform: 'none' as CSSObject['textTransform'],
    },
  },
};

export function Button() {
  return {
    MuiButton: defaultButtonStyles,
    MuiLoadingButton: defaultButtonStyles,
  };
}
