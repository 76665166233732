import { CopyJobDialogProps } from 'src/features/JobsTable/components/CopyJobDialog/CopyJobDialog';
import { CopyToJobType } from 'src/features/JobsTable/components/CopyJobDialog/useCopyJobDialog';
import { CreateCopyButtonTransformed } from 'src/features/JobsTable/JobsTable.service';

type SelectedCopyOptionData = CreateCopyButtonTransformed['data'];

export function buildPath(
  copyToJobType: CopyToJobType,
  jobId: CopyJobDialogProps['jobId'],
  jobType: CopyJobDialogProps['jobType'],
  mode: 'copy-in-project' | 'copy',
  selectedCopyOptionData?: SelectedCopyOptionData,
) {
  const copyToProjectParams = buildCopyToProjectParams(selectedCopyOptionData);

  return `/jobs-job-${copyToJobType}/new/job?mode=${mode}&copyFromJobId=${jobId}&copyFromJobType=${jobType}${copyToProjectParams}`;
}

function buildCopyToProjectParams(selectedCopyOptionData?: SelectedCopyOptionData) {
  if (selectedCopyOptionData && 'jobid' in selectedCopyOptionData) {
    const copyToProjectId = selectedCopyOptionData.jobid;
    const copyToProjectType = selectedCopyOptionData.src;

    return `&copyToProject=${copyToProjectId}&copyToProjectType=${copyToProjectType}`;
  }

  return '';
}
