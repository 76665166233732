import { useEffect, useRef, useState } from 'react';
import { Control, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, InputAdornment } from '@mui/material';

import { PasswordMeter } from 'src/components/PasswordMeter';
import { TextField } from 'src/components/RHF/TextField';
import { PasswordValidation } from 'src/features/PasswordConfirm/components/PasswordValidation';
import { WaveIcon } from 'src/features/WaveIcon';

export type FormData = {
  confirmPassword: string;
  newPassword: string;
} & Record<string, any>;

type PasswordControl = {
  control: Control<FormData>;
  trigger: UseFormTrigger<FormData>;
  watch: UseFormWatch<FormData>;
};

export function PasswordConfirm({ control, trigger, watch }: PasswordControl) {
  const [isValidPassword, setIsValidPassword] = useState(false);
  const newPassword = watch('newPassword');
  const mounted = useRef(false);
  const isPasswordMatch = watch('confirmPassword') === newPassword;
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] = useState(false);
  const [shouldShowNewPassword, setShouldShowNewPassword] = useState(false);
  const { t } = useTranslation();

  function handleClickShowConfirmPassword() {
    setShouldShowConfirmPassword(!shouldShowConfirmPassword);
  }

  function handleClickShowNewPassword() {
    setShouldShowNewPassword(!shouldShowNewPassword);
  }

  useEffect(() => {
    if (mounted.current) {
      trigger();
    } else {
      mounted.current = true;
    }
  }, [isValidPassword, isPasswordMatch]);

  return (
    <>
      <TextField
        control={control}
        InputLabelProps={{ shrink: true }}
        inputProps={{ autoComplete: 'new-password' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClickShowNewPassword}>
                {shouldShowNewPassword ? (
                  <WaveIcon code="visibility" />
                ) : (
                  <WaveIcon code="visibility_off" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t('hom.pwd.new')}
        name="newPassword"
        rules={{
          required: { message: `${t('lib.required')}`, value: true },
          validate: () =>
            (isValidPassword && newPassword !== '') ||
            (t('update_password_criteria_not_met', 'Password criteria is not met') as string),
        }}
        type={shouldShowNewPassword ? 'text' : 'password'}
      />

      <TextField
        control={control}
        InputLabelProps={{ shrink: true }}
        inputProps={{ autoComplete: 'new-password' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClickShowConfirmPassword}>
                {shouldShowConfirmPassword ? (
                  <WaveIcon code="visibility" />
                ) : (
                  <WaveIcon code="visibility_off" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t('hom.pwd.confirm')}
        name="confirmPassword"
        rules={{
          required: { message: `${t('lib.required')}`, value: true },
          validate: () => {
            return isPasswordMatch || `${t('pwd.restrictions.new.cnf')}`;
          },
        }}
        type={shouldShowConfirmPassword ? 'text' : 'password'}
      />

      <Box marginBottom={2}>
        <PasswordMeter newPassword={newPassword} />
      </Box>

      <PasswordValidation onPasswordValidation={setIsValidPassword} password={newPassword} />
    </>
  );
}
