import { ReactNode, useState } from 'react';

import { Box } from '@mui/material';

import { NavBar } from 'src/components/NavBar';
import { NavSideBar } from 'src/features/Frame/components/NavSideBar';

type FrameProps = { children: ReactNode };

export function Frame({ children }: FrameProps) {
  const [isOpen, setIsOpen] = useState(true);

  function handleToggleDrawer() {
    setIsOpen((previousIsOpen) => !previousIsOpen);
  }

  return (
    <>
      <NavBar isOpen={isOpen} onToggleDrawer={handleToggleDrawer} />

      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        <NavSideBar isOpen={isOpen} onToggleDrawer={handleToggleDrawer} />

        {children}
      </Box>
    </>
  );
}
