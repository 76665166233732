import { MouseEvent, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { Typography } from 'src/components/Typography';
import { WaveTooltip } from '../../../../components/WaveTooltip';
import { WaveIcon } from '../../../WaveIcon';

import type { SubMenuItemType } from '../../SubMenu.types';

type SubMenuItemProps = {
  checkedItems: Array<{
    code: string;
    text: string;
  }>;
  fontSize?: string;
  isDisabled?: boolean;
  isTooltipDisabled?: boolean;
  item: SubMenuItemType;
  items: Array<SubMenuItemType>;
  onClose: () => void;
  onSubMenuClick: (e: MouseEvent, code: string) => void;
  subItemsOpen: Record<string, boolean>;
  tooltipBody?: string;
  tooltipHeader?: string;
};

export function SubMenuItem({
  checkedItems,
  fontSize,
  isDisabled,
  isTooltipDisabled,
  item,
  items,
  onClose,
  onSubMenuClick,
  subItemsOpen,
  tooltipBody,
  tooltipHeader,
}: SubMenuItemProps) {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [isLastItem, setIsLastItem] = useState(false);
  const [listItemClass, setListItemClass] = useState('p-5');

  function handleCheckToggle(
    checkedItems: SubMenuItemProps['checkedItems'],
    item: SubMenuItemType,
  ) {
    if (item.onClick) {
      item.onClick(
        !isChecked
          ? [...checkedItems, { code: item.code, text: item.text }]
          : checkedItems.filter((checkedItem) => checkedItem.code !== item.code),
      );
    }
  }

  function handleDeleteClick(e: MouseEvent) {
    e.stopPropagation();

    if (item.onDelete) {
      item.onDelete();
    } else if (item.onClick) {
      item.onClick();
    }
  }

  function handleListItemClick(e: MouseEvent) {
    if (item.subItems) {
      onSubMenuClick(e, item.code as string);
    } else if (item.link) {
      navigate(item.link);
      onClose();
    } else if (item.checkbox) {
      handleCheckToggle(checkedItems, item);
    } else if (item.onClick) {
      item.onClick();
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && checkedItems) {
      setIsChecked(checkedItems.some((checkedItem) => checkedItem.code === item.code));
    }

    return () => {
      isMounted = false;
    };
  }, [checkedItems, item]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setIsLastItem(item === items[items.length - 1]);

    return () => {
      isMounted = false;
    };
  }, [item, items]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setListItemClass(subItemsOpen[`${item.code}Open`] ? 'filler-bg p-5' : 'p-5');

    return () => {
      isMounted = false;
    };
  }, [item, subItemsOpen]);

  return (
    <WaveTooltip
      body={tooltipBody}
      component={
        <ListItem disablePadding>
          <ListItemButton
            className={listItemClass}
            disabled={isDisabled}
            divider={!isLastItem}
            onClick={handleListItemClick}
          >
            {(item.icon || item.checkbox) && (
              <ListItemIcon className="min-w-0" sx={{ marginRight: 0.5 }}>
                {item.icon || (
                  <Checkbox checked={isChecked} className="p-0" color="primary" size="small" />
                )}
              </ListItemIcon>
            )}

            <ListItemText
              primary={
                fontSize === 'caption' ? (
                  <Typography variant="caption">{item.text}</Typography>
                ) : (
                  <Trans>{item.text}</Trans>
                )
              }
              primaryTypographyProps={{ sx: { flexGrow: 1 } }}
              sx={{ display: 'flex' }}
            />

            {item.delete && (
              <ListItemIcon className="min-w-0 ml-5">
                <IconButton color="primary" onClick={handleDeleteClick}>
                  <WaveIcon code="delete" fontSize="small" />
                </IconButton>
              </ListItemIcon>
            )}

            {item.subItems && item.navigateIcon}
          </ListItemButton>
        </ListItem>
      }
      disabled={isTooltipDisabled}
      header={tooltipHeader}
      placement="right-start"
      type="simple"
    />
  );
}
