import { useRef } from 'react';

import { TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Calendar } from 'primereact/calendar';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { WaveIcon } from '../../../WaveIcon';

import { yearRangeMax, yearRangeMin } from '../../../../utilities/constants';
import { formatDate } from '../../../../utilities/helperFunctions';
import { getPhpDateFormatToPrimeCalendar } from './utils';

export function DateField({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  maxDate,
  minDate,
  onChange,
  required,
  selectionMode = 'single',
  value,
}) {
  const { t } = useTranslation();
  const dateFormatTranslation = t('lib.date.short', 'd-m-y');
  const primeDateFormat = getPhpDateFormatToPrimeCalendar(dateFormatTranslation);

  const calendar = useRef();
  value = Array.isArray(value) || !value ? value : new Date(`${value}T00:00:00`);

  function handleClickClearButton() {
    calendar.current.hideOverlay();
  }

  function handleOnChange(e) {
    // Adding calendarOverlay dom element to event so that it can be manipulated in Filter.js
    e.calendarOverlay = calendar.current;
    e.target.name = label;
    if (selectionMode === 'single') {
      e.target.value = formatDate(e.target.value, 'yyyy-mm-dd');
    }

    onChange(e);
  }

  return (
    <TextField
      disabled={disabled}
      error={(checkRequiredFields && required && !value) || isError}
      fullWidth
      InputProps={{
        className: 'pl-0', // Classes applied to input wrapper, allows InputAdornment (Prime React component) to be all the way to the left edge of the MUI input
        inputProps: { className: 'pr-0 w-0' }, // Classes applied to the mui input that comes after the InputAdornment
        startAdornment: (
          <InputAdornment className="primary-txt w-100p" position="start">
            <Calendar
              appendTo={document.body}
              className="flex-grow-1"
              clearButtonClassName="primary-bg"
              dateFormat={primeDateFormat}
              disabled={disabled}
              id={id}
              maxDate={maxDate}
              minDate={minDate}
              monthNavigator={true}
              onChange={handleOnChange}
              onClearButtonClick={handleClickClearButton}
              readOnlyInput={maxDate || minDate ? true : false}
              ref={calendar}
              required={required}
              selectionMode={selectionMode}
              showButtonBar={true}
              todayButtonClassName="d-none"
              value={value}
              yearNavigator={true}
              yearRange={yearRangeMin + ':' + yearRangeMax}
            />
            <WaveIcon code="date-range" />
          </InputAdornment>
        ),
      }}
      label={label}
      required={required}
      size="small"
      variant="outlined"
    />
  );
}
