import { FieldType } from 'src/pages/Job/Job.service';

export function isSelectField(type: FieldType) {
  return (
    type === 'ccomplete' ||
    type === 'cselect' ||
    type === 'gselect' ||
    type === 'pick' ||
    type === 'pickselect' ||
    type === 'resselect' ||
    type === 'select' ||
    type === 'tselect' ||
    type === 'uselect' ||
    type === 'valselect'
  );
}

export function isTextField(type: FieldType) {
  return (
    type === 'ean' || type === 'memo' || type === 'string' || type === 'color' || isNumber(type)
  );
}

export function isNumber(type: FieldType) {
  return (
    type === 'bigint' ||
    type === 'bigintnull' ||
    type === 'int' ||
    type === 'mediumint' ||
    type === 'smallint' ||
    type === 'tinyint'
  );
}
