import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function Memo({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onBlur,
  onChange,
  required,
  value,
}) {
  const { t } = useTranslation();

  function handleOnBlur(e) {
    e.target.name = label;

    if (onBlur) onBlur(e);
  }

  return (
    <TextField
      disabled={disabled}
      error={(checkRequiredFields && required && !value) || isError}
      fullWidth
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      label={t(label)}
      multiline
      onBlur={handleOnBlur}
      onChange={onChange}
      required={required}
      rows={5}
      variant="outlined"
      value={value || ''}
    />
  );
}
