import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
const initialState = { message: null, type: null };

export const waveSnackSlice = createSlice({
  initialState,
  name: 'waveSnack',
  reducers: {
    closeWaveSnack: () => initialState,
    openWaveSnack: (state, action) => ({
      message: i18n.t(action.payload.message),
      type: action.payload.type,
    }),
  },
});

export const { closeWaveSnack, openWaveSnack } = waveSnackSlice.actions;

export default waveSnackSlice.reducer;
