import { useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Skeleton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { FlagDialog } from 'src/features/JobForm/components/FlagDialog';
import { useFlags } from 'src/features/JobForm/components/Flags/useFlags';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export function Flags() {
  const { t } = useTranslation();
  const {
    flag,
    flagButtons,
    flagsButton,
    handleCloseJobFlagDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isFetching,
    isJobFlagDialogOpen,
    isSubMenuOpen,
  } = useFlags();

  const { isDirty } = useFormState();

  if (isFetching) return <Skeleton height={30} variant="rounded" width={80} />;

  if (!flagButtons.length) return null;

  return (
    <>
      <WaveTooltip
        component={
          <Button
            disabled={isDirty}
            onClick={handleToggleSubMenu}
            ref={flagsButton}
            startIcon={<WaveIcon code="job-actions-flags" />}
            sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
          >
            <Trans i18nKey="flags_button_tooltip">Flags</Trans>
          </Button>
        }
        placement="top"
        type="simple"
        {...(isDirty && {
          body: t(
            'form_save_other_actions',
            'Save the job to apply your changes or refresh the page to undo them. Only then, can you perform other job actions.',
          ) as string,
        })}
        {...(isDirty && { header: t('form_changes_made', 'Changes Have Been Made') as string })}
      />

      <SubMenu
        anchorEl={flagsButton.current}
        anchorElMinWidth={flagsButton.current?.offsetWidth}
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={flagButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginVerticalSubitem="top"
      />

      <FlagDialog flag={flag} isOpen={isJobFlagDialogOpen} onClose={handleCloseJobFlagDialog} />
    </>
  );
}
