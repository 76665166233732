import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { Typography } from '@mui/material';

import { StatusIcon } from '../StatusIcon';

type SideBarItemProps = {
  count: string;
  label?: string;
  rowIndex: string;
};

export function SideBarItem<T extends FieldValues>({
  control,
  count,
  label,
  name,
  rowIndex,
}: UseControllerProps<T> & SideBarItemProps) {
  const {
    field: { value },
    formState: { errors },
  } = useController({
    control,
    name,
  });
  const [categoryIndex, itemIndex] = rowIndex.split('_');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage = errors?.categories?.[categoryIndex]?.items?.[itemIndex]?.message;

  return (
    <>
      <StatusIcon status={value} />

      <Typography color={errorMessage ? 'error.main' : 'primary'} fontWeight="inherit">
        {label}

        {count}
      </Typography>
    </>
  );
}
