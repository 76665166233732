import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  Skeleton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { WaveIcon } from 'src/features/WaveIcon';
import { useExpand, useRouteParams } from 'src/utilities/hooks';
import { parseRecordToLogicalObject } from '../../../../utilities/parsers';
import {
  useGetCategoriesQuery,
  useGetContentListQuery,
  useGetLanguagesQuery,
} from '../../content.service';
import { ActionBar } from '../ActionBar';
import { StatusIcon } from '../StatusIcon';

import type { ContentItem } from '../../Content.types';

const CategoryContent = styled(Box)`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;

export function ContentList() {
  const { age, jobId, jobType } = useRouteParams();
  const { data: contentList, isFetching: isContentListFetching } = useGetContentListQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: languages, isFetching: areLanguagesFetching } = useGetLanguagesQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: categoriesList = [], isFetching: areCategoriesFetching } = useGetCategoriesQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  const { areAllItemsExpanded, expandedItems, toggleExpandAll, toggleExpandItem } = useExpand(
    parseRecordToLogicalObject(contentList || {}),
  );

  function renderCategoryItem(item: ContentItem, index: number) {
    return (
      <Grid key={index} xs={4}>
        <Card>
          <Box className="filler-bg" component={CardActions} justifyContent="space-between" p={0}>
            <Box display="flex">
              <Typography pl={1.5} pt={0} textTransform="capitalize">
                {languages?.[item.language] ?? item.language}
              </Typography>
            </Box>

            <Box alignItems="center" display="flex">
              <StatusIcon status={item.status} />
            </Box>
          </Box>

          <CategoryContent dangerouslySetInnerHTML={{ __html: item.text }} />
        </Card>
      </Grid>
    );
  }

  return (
    <Box p={2}>
      <ActionBar
        areAllCategoriesExpanded={areAllItemsExpanded}
        areAllCategoriesSelected={false}
        onExpandAllClick={toggleExpandAll}
        onSelectAllClick={() => null}
      />

      {!languages ||
      !categoriesList.length ||
      !contentList ||
      isContentListFetching ||
      areLanguagesFetching ||
      areCategoriesFetching
        ? [...Array(3)].map((_, index) => (
            <Box key={index} py={0.5}>
              <Skeleton height={30} variant="rounded" />
            </Box>
          ))
        : Object.keys(contentList).map((categoryId) => (
            <Accordion expanded={expandedItems[categoryId] || false} key={categoryId}>
              <AccordionSummary
                className={'h-35 min-height-35 primary-bg rounded-top white-txt'}
                expandIcon={<WaveIcon className="white-txt" code="expand-more" />}
                id={categoryId}
                onClick={() => toggleExpandItem(categoryId)}
              >
                <Typography>
                  {categoriesList.find((category) => category.code === categoryId)?.value ||
                    categoryId}
                </Typography>
              </AccordionSummary>

              <Box component={AccordionDetails} p={1}>
                <Grid container justifyContent="stretch" m={0} spacing={2}>
                  {contentList[categoryId].map(renderCategoryItem)}
                </Grid>
              </Box>
            </Accordion>
          ))}
    </Box>
  );
}
