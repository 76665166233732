import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type BaseParams = UserId;
export type UserId = { userId?: number };
export type UserDetailsType = {
  company: string;
  department: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  location: string;
  phoneNumber: string;
  username: string;
};
type UserProfileTransformed = {
  company: string;
  department: string;
  domain: string;
  emailAddress: string;
  employeeNumber: string;
  firstName: string;
  lastName: string;
  location: string;
  phoneNumber: string;
  siteId: string;
  username: string;
};
type UserProfileRaw = {
  company: string;
  department: string;
  domain: string;
  email: string;
  firstname: string;
  lastname: string;
  location: string;
  phone: string;
  pnr: string;
  site_id: string;
  user: string;
};
type UpdateUserProfileParams = {
  company: string;
  department?: string;
  firstName: string;
  lastName: string;
  location: string;
  phoneNumber?: string;
  username: string;
} & BaseParams;

export const userProfileApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getUserProfile: build.query<UserProfileTransformed, BaseParams>({
      providesTags: ['userProfile'],
      query({ userId }) {
        return {
          method: 'GET',
          url: `/users/${userId}/profile`,
        };
      },
      transformResponse({
        company,
        department,
        domain,
        email: emailAddress,
        firstname: firstName,
        lastname: lastName,
        location,
        phone: phoneNumber,
        pnr: employeeNumber,
        site_id: siteId,
        user: username,
      }: UserProfileRaw): UserProfileTransformed {
        return {
          company,
          department,
          domain,
          emailAddress,
          employeeNumber,
          firstName,
          lastName,
          location,
          phoneNumber,
          siteId,
          username,
        };
      },
    }),
    updateUserProfile: build.mutation<{ message: string }, UpdateUserProfileParams>({
      invalidatesTags: ['userProfile'],
      query({ company, department, firstName, lastName, location, phoneNumber, userId, username }) {
        return {
          method: 'PUT',
          params: {
            company,
            department,
            firstname: firstName,
            lastname: lastName,
            location,
            phone: phoneNumber,
            user: username,
          },
          url: `/users/${userId}/profile`,
        };
      },
    }),
  }),
  reducerPath: 'userProfileApi',
  tagTypes: ['userProfile'],
});

export const { useGetUserProfileQuery, useUpdateUserProfileMutation } = userProfileApi;
