import { useEffect, useState } from 'react';

type Items = Record<string | number, boolean>;

export function useSelect(initialItems: Items, defaultState = false) {
  const [selectedItems, setSelectedItems] = useState<Items>({});
  const [areAllItemsSelected, setAreAllItemsSelected] = useState(defaultState);

  const selectedItemIds = computeSelectedItemIds(selectedItems);

  function computeSelectedItemIds(items: Items) {
    const keysOfItems = Object.keys(items);
    const isStringBased = isNaN(parseInt(keysOfItems[0]));
    const selectedItemIds = Object.keys(items).filter(
      (itemKey) => items[isStringBased ? itemKey : parseInt(itemKey)],
    );

    if (isStringBased) return selectedItemIds;

    return selectedItemIds.map((selectedItemId) => parseInt(selectedItemId));
  }

  function toggleSelectAll(selected?: boolean) {
    const updatedItems = {} as Record<string, boolean>;

    Object.keys(selectedItems).forEach((key) => {
      updatedItems[key] = selected ?? !areAllItemsSelected;
    });

    setSelectedItems(updatedItems);
    setAreAllItemsSelected(selected ?? !areAllItemsSelected);
  }

  function toggleSelectItem(itemId: number) {
    const updatedItems = {
      ...selectedItems,
      [itemId]: !selectedItems[itemId],
    };

    setSelectedItems(updatedItems);

    if (Object.values(updatedItems).every((selectedState) => selectedState)) {
      setAreAllItemsSelected(true);
    }

    if (Object.values(updatedItems).every((selectedState) => !selectedState)) {
      setAreAllItemsSelected(false);
    }
  }

  function toggleSelectMultiple(itemIds: number[], selected: boolean) {
    const updatedItems = {} as Record<string, boolean>;

    itemIds.forEach((item) => {
      updatedItems[item] = selected;
    });

    const stateToUpdate = {
      ...selectedItems,
      ...updatedItems,
    };

    setSelectedItems(stateToUpdate);
    if (Object.values(stateToUpdate).every((selectedState) => selectedState)) {
      setAreAllItemsSelected(true);
    }

    if (Object.values(stateToUpdate).every((selectedState) => !selectedState)) {
      setAreAllItemsSelected(false);
    }
  }

  useEffect(() => {
    setSelectedItems(initialItems);
  }, [
    Object.keys(initialItems).length,
    Object.keys(initialItems).filter((x) => initialItems[+x]).length,
  ]);

  return {
    areAllItemsSelected,
    selectedItemIds,
    selectedItems,
    toggleSelectAll,
    toggleSelectItem,
    toggleSelectMultiple,
  };
}
