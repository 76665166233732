import { Trans } from 'react-i18next';

import { Typography as MUITypography, TypographyProps } from '@mui/material';

export function Typography({ children, ...rest }: TypographyProps) {
  return (
    <MUITypography {...rest}>
      <Trans>{children}</Trans>
    </MUITypography>
  );
}
