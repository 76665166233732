import { Dayjs } from 'dayjs';

import { useDateFormat } from 'src/utilities/hooks';

export function isDayjs(date: any): date is Dayjs {
  return (
    typeof date === 'object' &&
    date !== null &&
    'toDate' in date &&
    typeof date.toDate === 'function'
  );
}

export function getDateFormat(date: Dayjs | Date, languageCode: string): string {
  const normalizedDate = isDayjs(date) ? date.toDate() : date;

  return new Intl.DateTimeFormat(languageCode).format(normalizedDate);
}

export function getIsDayInRange(
  day: Dayjs,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
): boolean {
  const isDayInRange =
    day.isSame(startDate, 'day') ||
    day.isSame(endDate, 'day') ||
    (day.isAfter(startDate, 'day') && day.isBefore(endDate, 'day'));

  return isDayInRange;
}

export function getFormattedTextFieldValue(
  isRange: boolean,
  date: Dayjs | null,
  dateRange: [Dayjs | null, Dayjs | null],
): string {
  const { formatDate } = useDateFormat();
  
  let textFieldValue = '';

  if (isRange) {
    if (dateRange[0] && dateRange[1]) {
      const startDate = formatDate('lib.date.long', dateRange[0].format('YYYY-MM-DD'));
      const endDate = formatDate('lib.date.long', dateRange[1].format('YYYY-MM-DD'));

      textFieldValue = `${startDate} - ${endDate}`;
    } else if (dateRange[0]) {
      textFieldValue = formatDate('lib.date.long', dateRange[0].format('YYYY-MM-DD')) as string;
    }
  } else {
    if (date) {
      textFieldValue = formatDate('lib.date.long', date.format('YYYY-MM-DD')) as string;
    }
  }

  return textFieldValue;
}

export function transformDateFormat(format: string) {
  // Define a mapping of PHP format characters to JavaScript format characters
  const formatMapping: { [key: string]: string } = {
    'A': 'A', 
    'B': '', 
    'D': 'dd',
    'F': 'MMMM',
    'G': 'H', 
    'H': 'HH', 
    'I': '',
    'L': '',
    'M': 'MMM', 
    'N': 'E', 
    'O': 'ZZ', 
    'P': 'Z',
    'S': 'o', 
    'T': '',
    'W': 'W', 
    'Y': 'YYYY',
    'Z': '', 
    'a': 'a',
    'd': 'D', 
    'dd': 'DD', 
    'e': '',
    'g': 'h', 
    'h': 'hh', 
    'i': 'mm', 
    'j': 'd', 
    'l': 'dddd', 
    'm': 'MM',
    'n': 'M',
    'o': 'YYYY',  
    'rok': 'YY',
    's': 'ss',
    't': '', 
    'u': 'SSS',
    'w': 'e',
    'y': 'YY',    
    'z': 'D',            
  };

  return format.replace(/./g, (formatChar) => formatMapping[formatChar] || formatChar);
}
