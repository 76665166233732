import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBarProps } from 'src/features/JobsTable/components/ActionBar/ActionBar';
import { useGetActionsQuery } from 'src/features/JobsTable/JobsTable.service';
import { useJobTableVariants } from 'src/features/JobsTable/utilities/hooks';
import { usePreference } from 'src/utilities/hooks';

type UseActionBarProps = Pick<
  ActionBarProps,
  'idsOfExpandedJobs' | 'jobIdsWithSubJobs' | 'setAreAllSubJobsExpanded'
>;

export function useActionBar({
  idsOfExpandedJobs,
  jobIdsWithSubJobs,
  setAreAllSubJobsExpanded,
}: UseActionBarProps) {
  const { t: translate } = useTranslation();

  const { age, isSubJobsTable, jobType, tab } = useJobTableVariants();

  const clientIdPreference = usePreference('sys.mid', '');
  const { data: actions, refetch: refetchActions } = useGetActionsQuery({
    age,
    isAssignJobTable: tab === 'assign',
    isSubJobsTable,
    jobType,
  });

  useEffect(() => {
    refetchActions();

    // clientIdPreference is a dependency so that the actions are reloaded
    // on the dashboard when switching clients.
  }, [clientIdPreference.value]);

  useEffect(() => {
    const couldHaveSubJobs = idsOfExpandedJobs && jobIdsWithSubJobs && setAreAllSubJobsExpanded;
    const hasSubJobs = couldHaveSubJobs && jobIdsWithSubJobs?.length;

    if (hasSubJobs) {
      setAreAllSubJobsExpanded(idsOfExpandedJobs.length === jobIdsWithSubJobs.length);
    }
  }, [idsOfExpandedJobs]);

  return { actions, age, isSubJobsTable, translate };
}
