import React, { forwardRef, RefAttributes, SyntheticEvent, useState } from 'react';

import { Skeleton } from '@mui/material';

import { StyledImage } from 'src/pages/FileSearch/FileSearchImage/styled';

import defaultThumbnail from '../../../images/thumbnails/default_thumbnail.png';

type FileSearchImageProps = {
  onClick: () => void;
  src: string;
} & RefAttributes<HTMLImageElement>;

function FileSearchImage({ onClick, src }: FileSearchImageProps, ref: React.Ref<HTMLImageElement>) {
  const [isLoaded, setIsLoaded] = useState(false);

  function handleImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    const target = event.target as HTMLImageElement;

    target.src = defaultThumbnail;
  }

  return (
    <>
      <StyledImage
        draggable={false}
        onClick={onClick}
        onError={handleImageError}
        onLoad={() => setIsLoaded(true)}
        ref={ref}
        src={src}
        style={{ display: isLoaded ? 'block' : 'none' }}
      />

      {!isLoaded && <Skeleton animation="wave" height="200px" variant="rectangular" width="100%" />}
    </>
  );
}

export const FileSearchImageWithRef = forwardRef(FileSearchImage);
