import { Fragment } from 'react';

import { Box, Grid, Paper, Tooltip, TooltipProps, Typography, useTheme } from '@mui/material';

type GeneralProps = {
  component: JSX.Element;
  disabled?: boolean;
  header?: string;
  name?: string;
  placement?: TooltipProps['placement'];
};

type SimpleTooltip = {
  body?: string;
  type: 'simple';
};

type CustomTooltip = {
  body: JSX.Element | null;
  type: 'custom';
};

type ListTooltip = {
  body: Array<{ name: string }>;
  type: 'list';
};

type AnnotationsTooltip = {
  body: Array<{ comment: string; date: string; title: string; page: number }>;
  type: 'annotation';
};

type VariationProps = SimpleTooltip | CustomTooltip | ListTooltip | AnnotationsTooltip;

type WaveTooltipProps = GeneralProps & VariationProps;

export function WaveTooltip({
  body,
  component,
  disabled,
  header,
  placement = 'bottom',
  type,
}: WaveTooltipProps) {
  const { palette } = useTheme();

  function renderBody() {
    if (!body) return null;

    if (type === 'simple') {
      return (
        <Grid item padding={1}>
          <Typography color="primary" variant="caption">
            {body}
          </Typography>
        </Grid>
      );
    }

    if (type === 'custom') {
      return (
        <Grid item xs={12}>
          {body}
        </Grid>
      );
    }

    if (type === 'annotation') {
      return (
        <Box component={Paper} elevation={3} maxHeight="25vh" overflow="auto">
          {body.map((item, index) => (
            <Box
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              bgcolor={index % 2 !== 0 ? palette.oddRow.main : 'white'}
              borderBottom={index !== body.length - 1 ? 1 : 0}
              borderColor="rgba(0, 0, 0, 0.12)"
              key={item.comment}
              paddingX={1}
              paddingY={1.5}
            >
              <Typography
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                color={palette.oddRow.contrastText}
                display="block"
                fontWeight="bold"
                variant="caption"
              >
                {`${item.title} | ${item.date} | Page ${item.page}`}
              </Typography>

              <Typography
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                color={palette.oddRow.contrastText}
                variant="caption"
              >
                {`${item.comment}`}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Grid item p={1}>
        {body
          .filter((detail, index) => index < 5)
          .concat(body.length >= 5 ? [{ name: '...' }] : [])
          .map((detail, index, body) => (
            <Fragment key={detail.name}>
              <Typography color="primary" variant="caption">
                {detail.name}
              </Typography>

              {index !== body.length - 1 ? (
                <Typography color="primary" variant="caption">
                  ,&nbsp;
                </Typography>
              ) : null}
            </Fragment>
          ))}
      </Grid>
    );
  }

  return (
    <Tooltip
      arrow={!!(header || body)}
      classes={{
        arrow: 'filler-txt',
        tooltip: 'p-0',
        tooltipArrow: 'filler-bg',
      }}
      disableHoverListener={disabled}
      placement={placement}
      title={
        <Grid className="rounded-bottom" container>
          {header && (
            <Grid className="primary-bg p-5 rounded-top white-txt" container item>
              <Typography className="va-top" variant="caption">
                <strong>{header}</strong>
              </Typography>
            </Grid>
          )}

          {renderBody()}
        </Grid>
      }
    >
      <span>{component}</span>
    </Tooltip>
  );
}
