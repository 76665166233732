import { MouseEvent, useMemo, useState } from 'react';

import { Badge } from '@mui/material';

import {
  useDeleteBookmarkMutation,
  useGetBookmarksQuery,
} from 'src/features/Frame/components/Bookmarks/Bookmarks.service';
import { StyledIconButton, StyledSubMenu } from 'src/features/Frame/components/styled';
import { jobFormApi } from 'src/features/JobForm/JobForm.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';
import { SubMenuItemType } from '../../../SubMenu';
import { WaveIcon } from '../../../WaveIcon';

export function Bookmarks() {
  const { data: bookmarks = [] } = useGetBookmarksQuery();
  const [deleteBookmark] = useDeleteBookmarkMutation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const dispatch = useAppDispatch();

  const items = useMemo<SubMenuItemType[]>(() => {
    return bookmarks.map((bookmark) => {
      return {
        code: bookmark['code'],
        delete: true,
        link: bookmark['link'],
        onClick: () => {
          deleteBookmark({
            jobid: bookmark['jobid'],
          })
            .unwrap()
            .then((message) => {
              dispatch(
                openWaveSnack({
                  message,
                  type: 'success',
                }),
              );
            });
          setAnchorEl(null);
          setIsSubMenuOpen(false);
          dispatch(jobFormApi.util.invalidateTags(['Actions']));
        },
        text: bookmark['text'],
      };
    });
  }, [bookmarks.length]);

  function handleClickBookmarksIcon(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setIsSubMenuOpen(true);
  }

  function handleCloseBookmarks() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  if (bookmarks.length === 0) return null;

  return (
    <>
      <StyledIconButton
        isSubMenuOpen={isSubMenuOpen}
        onClick={handleClickBookmarksIcon}
        size="large"
      >
        <Badge badgeContent={bookmarks.length} color="info">
          <WaveIcon code="bookmark" />
        </Badge>
      </StyledIconButton>

      {isSubMenuOpen && (
        <StyledSubMenu
          anchorEl={anchorEl}
          items={items}
          onClose={handleCloseBookmarks}
          open={isSubMenuOpen}
        />
      )}
    </>
  );
}
