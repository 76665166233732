export const ICON_COLOR_MAP = {
  'approval-approve': 'success',
  'approval-conditional': 'warning',
  'approval-user-added': 'success',
  'approval-user-deleted': 'error',
  'checklist-ok': 'success',
  'checklist-reject': 'error',
  comment: 'warning',
  email: 'warning',
  'flag-activate': 'success',
  'flag-change': 'warning',
  'job-edit': 'warning',
  'phrase-edit': 'warning',
  'question-answered': 'warning',
} as const;
