import { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import { Button } from '../Button';

import { WaveDialogTitle } from '../WaveDialogTitle';

import { getUserDetails, logout } from '../../store/userSlice';
import { Trans, useTranslation } from 'react-i18next';

export function Idle() {
  const logoutCountdownValue = 30000;

  const dispatch = useDispatch();
  const idleCountdown = useRef(null);
  const logoutCountdown = useRef(null);
  const [logoutCountdownDisplayValue, setLogoutCountdownDisplayValue] = useState(
    logoutCountdownValue / 1000,
  );
  const [open, setOpen] = useState(false);

  const idleCountdownValue = 1800000;
  const idleCountdownDisplayValue = idleCountdownValue / 60000;
  const { t } = useTranslation();

  function handleLogout() {
    dispatch(logout());
    clearInterval(logoutCountdown.current);
  }

  function handleStay() {
    dispatch(getUserDetails());
    resetLogoutCountdown();
    setOpen(false);
  }

  function resetLogoutCountdown() {
    clearInterval(logoutCountdown.current);
    setLogoutCountdownDisplayValue(logoutCountdownValue / 1000);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const main = document.getElementsByTagName('main')[0];

      function displayWarning() {
        setOpen(true);
      }

      function resetIdleCountdown() {
        clearTimeout(idleCountdown.current);
        idleCountdown.current = setTimeout(displayWarning, idleCountdownValue);
      }

      idleCountdown.current = setTimeout(displayWarning, idleCountdownValue);

      main.onload = resetIdleCountdown;
      main.onmousemove = resetIdleCountdown;
      main.onmousedown = resetIdleCountdown; // Catches touchscreen presses as well
      main.ontouchstart = resetIdleCountdown; // Catches touchscreen swipes as well
      main.onclick = resetIdleCountdown; // Catches touchpad clicks as well
      main.onkeydown = resetIdleCountdown;
      main.addEventListener('scroll', resetIdleCountdown, true);
    }

    return () => (isMounted = false);
  }, [idleCountdownValue]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && open) {
      let timer = logoutCountdownDisplayValue;

      logoutCountdown.current = setInterval(function () {
        setLogoutCountdownDisplayValue(timer - 1);
        --timer;

        if (timer === 0) {
          handleLogout();
        }
      }, 1000);
    }

    return () => (isMounted = false);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleStay}>
      <WaveDialogTitle title={t('idle_title', 'Idle Threshold Reached')} />

      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey="you_have_been_idol_countdown"
            values={{ idleCountdownDisplayValue, logoutCountdownDisplayValue }}
          >
            You have been idle for {{ idleCountdownDisplayValue }} minutes. You will be
            automatically logged out in {{ logoutCountdownDisplayValue }} seconds unless you choose
            to stay.
          </Trans>
        </DialogContentText>
      </DialogContent>

      <DialogActions className="space-between">
        <Button onClick={handleLogout}>{t('logout_button', 'Logout')}</Button>

        <Button onClick={handleStay}>{t('stay_button', 'Stay')}</Button>
      </DialogActions>
    </Dialog>
  );
}
