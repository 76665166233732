import { FormEvent, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  jobFormApi,
  TaskActionCode,
  TaskActionTransformed,
  useUpdateTaskMutation,
} from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldValues, jobApi, OtherJobValues, useGetJobQuery } from 'src/pages/Job/Job.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

type UseTaskProps = { code: string; taskActions: TaskActionTransformed[] };

export function useTask({ code, taskActions }: UseTaskProps) {
  const { age, jobId, jobType } = useRouteParams();

  const dispatch = useAppDispatch();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isTaskDialogOpen, setIsTaskDialogOpen] = useState(false);
  const [taskAction, setTaskAction] = useState<TaskActionTransformed>();
  const taskButton = useRef<HTMLButtonElement | null>(null);
  const [updateTask] = useUpdateTaskMutation();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit: reactHookFormHandleSubmit,
    reset,
  } = useForm({
    defaultValues: { comment: '' },
    mode: 'onChange',
  });

  const {
    data: { values } = {
      values: {} as FieldValues,
    },
  } = useGetJobQuery({
    age,
    jobId,
    jobType,
  });

  const taskActionButtons = useMemo(
    () =>
      taskActions.map(({ code, isFlagComment, text }) => ({
        code,
        icon: (
          <WaveIcon
            code={`job-form-action-bar-task-${code}`}
            fontSize="small"
            sx={{ color: determineIconColor(code) }}
          />
        ),
        onClick: () => {
          handleClickTaskAction({ code, isFlagComment, text });
        },
        text,
      })),
    [taskActions],
  );

  function determineIconColor(iconCode: TaskActionCode) {
    return iconCode === 'flag-approve'
      ? 'success.main'
      : iconCode === 'flag-amendment'
      ? 'error.main'
      : 'warning.main';
  }

  function handleClickTaskAction(taskAction: TaskActionTransformed) {
    handleCloseSubMenu();
    setIsTaskDialogOpen(true);
    setTaskAction(taskAction);
  }

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleCloseTaskDialog() {
    setIsTaskDialogOpen(false);
    setTaskAction(undefined);
    reset();
  }

  async function handleSubmit({ comment }: { comment: string }) {
    await updateTask({
      action: taskAction?.code ?? '',
      age,
      code: code.slice(5),
      comment,
      jobId,
      jobStatus: (values as OtherJobValues).webstatus,
      jobType,
    })
      .unwrap()
      .then(({ message }) => {
        dispatch(jobApi.util.invalidateTags(['Job']));
        dispatch(jobFormApi.util.invalidateTags(['Steps', 'Tasks']));
        dispatch(
          openWaveSnack({
            message,
            type: 'success',
          }),
        );
        handleCloseTaskDialog();
      })
      .catch(({ message }) =>
        dispatch(
          openWaveSnack({
            message,
            type: 'error',
          }),
        ),
      );
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();

    return reactHookFormHandleSubmit(handleSubmit)(e);
  }

  return {
    control,
    determineIconColor,
    handleCloseSubMenu,
    handleCloseTaskDialog,
    handleToggleSubMenu,
    isSubMenuOpen,
    isSubmitting,
    isTaskDialogOpen,
    onSubmit,
    taskAction,
    taskActionButtons,
    taskButton,
  };
}
