import { createApi } from '@reduxjs/toolkit/query/react';

import { OnUploadProgress } from 'src/features/FileExplorer/FileExplorer.service';
import { BaseRequest, JobId } from 'src/pages/Job/Job.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

type Actions = {
  main_actions: FileAction[];
  other_actions: FileAction[];
};
type BaseParams = {
  userId?: number;
} & BaseRequest;
type BaseParamsAndJobId = BaseParams & JobId;
export type CategorizedFiles = Record<string, RawFiles>;
type CompareFilesRequest = {
  fileIds: number[];
} & BaseParamsAndJobId;
type DeleteFileRequest = {
  fileId: number;
} & Pick<BaseParams, 'userId'>;
export type JobFile = {
  can_delete: boolean;
  category: string;
  comment: string;
  date: string;
  filename: string;
  filesize: string;
  id: FileId;
  jobid: number;
  src: string;
  type: FileType;
  thumbnail_url: string;
  user: string;
  version: number;
};
type FileAction = {
  code: string;
  text: string;
  subItems?: FileAction[];
};
export type FileId = number;
type Files = { categorized: CategorizedFiles; raw: RawFiles };
export type FileType = string;
type Folder = {
  code: FolderCode;
  age?: ExpandedAges;
  name: string;
  src?: string;
  path_name: FolderPath;
  file_count: number;
  subItems: Folders;
};
export type Folders = Folder[];
export type FolderCode = string;
type FolderCodeObject = { folderCode: FolderCode };
export type FolderPath = string;
type GetActionsRequest = BaseParamsAndJobId & FolderCodeObject;
type GetDownloadUrlRequest = {
  fileIds: number[];
} & Pick<BaseParams, 'userId'>;
type GetFilesRequest = GetActionsRequest;
type GetFoldersRequest = BaseParamsAndJobId;
export type RawFiles = JobFile[];
type UploadFileRequest = BaseRequest &
  JobId &
  OnUploadProgress & {
    categoryId?: number | string;
    comment?: string;
    destination: string;
    file: File;
  };
export type UploadFileResponse = [{ filename: string }];

export const jobFilesApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    compareFiles: build.query<string, CompareFilesRequest>({
      query({ age, fileIds, jobId, jobType, userId }) {
        return {
          method: 'GET',
          params: { age, file_id: fileIds, id: userId, jobid: jobId, src: jobType },
          url: '/jobs/files/compare',
        };
      },
    }),
    deleteFile: build.mutation<{ message: string }, DeleteFileRequest>({
      invalidatesTags: ['Files', 'Folders'],
      query({ fileId, userId }) {
        return {
          method: 'DELETE',
          params: { file_id: fileId, id: userId },
          url: '/jobs/files/delete',
        };
      },
    }),
    getActions: build.query<Actions, GetActionsRequest>({
      query({ age, folderCode, jobId, jobType, userId }) {
        return {
          method: 'GET',
          params: { age, id: userId, jobid: jobId, src: jobType, sub: folderCode },
          url: '/jobs/files/buttons',
        };
      },
    }),
    getDownloadUrl: build.query<{ url: string }, GetDownloadUrlRequest>({
      query({ fileIds, userId }) {
        return {
          method: 'GET',
          params: { file_id: fileIds, id: userId },
          url: '/files/download',
        };
      },
    }),
    getFiles: build.query<Files, GetFilesRequest>({
      providesTags: ['Files'],
      query({ age, folderCode, jobId, jobType, userId }) {
        return {
          method: 'GET',
          params: { age, id: userId, jobid: jobId, src: jobType, sub: folderCode },
          url: '/jobs/files/get',
        };
      },
      transformResponse(raw: RawFiles): {
        categorized: CategorizedFiles;
        raw: RawFiles;
      } {
        const categorizedFiles: CategorizedFiles = {};

        raw.forEach((file) => {
          if (categorizedFiles[file.category]) categorizedFiles[file.category].push(file);
          else categorizedFiles[file.category] = [file];
        });

        return {
          categorized: categorizedFiles,
          raw,
        };
      },
    }),
    getFolders: build.query<Folders, GetFoldersRequest>({
      providesTags: ['Folders'],
      query({ age, jobId, jobType, userId }) {
        return {
          method: 'GET',
          params: { age, id: userId, jobid: jobId, src: jobType },
          url: '/jobs/files/folders',
        };
      },
    }),
    uploadFile: build.mutation<UploadFileResponse, UploadFileRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Files', 'Folders']),
      query({ age, categoryId, comment, destination, file, jobId, jobType, onUploadProgress }) {
        const formData = new FormData();

        formData.append('age', age as string);
        formData.append('file', file);
        formData.append('jobid', jobId as string);
        formData.append('src', jobType as string);
        formData.append('sub', destination as string);
        if (categoryId) formData.append('category', categoryId as string);

        if (comment) formData.append('comment', comment as string);

        return {
          data: formData,
          formData: true,
          method: 'POST',
          onUploadProgress,
          url: '/jobs/files/create',
        };
      },
    }),
  }),
  reducerPath: 'jobFilesApi',
  tagTypes: ['Files', 'Folders'],
});

export const {
  useDeleteFileMutation,
  useGetActionsQuery,
  useGetFilesQuery,
  useGetFoldersQuery,
  useLazyCompareFilesQuery,
  useLazyGetDownloadUrlQuery,
  useUploadFileMutation,
} = jobFilesApi;
