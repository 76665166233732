import { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';

import { Thumbnail } from 'src/components/Thumbnail';
import { JobFile } from 'src/features/JobFiles/JobFiles.service';
import { formatDate } from 'src/utilities/helperFunctions';
import { useViewer } from 'src/utilities/hooks';

type ApprovalFileProps = {
  file: JobFile;
  isExpanded: boolean;
  isSelected: boolean;
  onToggleExpand: (itemId: number) => void;
  onToggleSelect: (itemId: number) => void;
};

export function ApprovalFile({
  file,
  isExpanded,
  isSelected,
  onToggleExpand,
  onToggleSelect,
}: ApprovalFileProps) {
  const { t } = useTranslation();

  const { openViewerPage } = useViewer();

  function handleClickThumbnailContainer(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    openViewerPage(file.id);
  }

  function handleSelectFile(e: MouseEvent<HTMLButtonElement>, id: number) {
    e.stopPropagation();
    onToggleSelect(id);
  }

  return (
    <Accordion expanded={isExpanded || false}>
      <AccordionSummary
        onClick={() => onToggleExpand(file.id)}
        sx={{ backgroundColor: 'grey.600', color: 'white', pl: 0 }}
      >
        <Box alignItems="center" display="flex">
          <Checkbox
            checked={isSelected || false}
            onClick={(e) => handleSelectFile(e, file.id)}
            sx={{
              '&.Mui-checked': {
                color: 'white',
              },
              color: 'white',
            }}
          />

          <Typography fontWeight={500}>{`${t('lib.version', 'Version')} ${
            file.version
          }`}</Typography>
        </Box>
      </AccordionSummary>

      <Box component={AccordionDetails} p={0}>
        <Box onClick={handleClickThumbnailContainer} sx={{ cursor: 'pointer' }}>
          <Thumbnail
            alt="file thumbnail"
            hasExternalLink
            isSourceExternal={file.type === 'cloudflow' || file.type === 'dalim'}
            size="100%"
            url={file.thumbnail_url}
          />
        </Box>

        <Box p={1}>
          <Typography variant="subtitle2">{file.filename}</Typography>

          <Typography variant="subtitle2">
            <Trans i18nKey="lib.file.owner">{'Uploaded By: '}</Trans>

            <Typography display="inline" variant="body2">
              {file.user}
            </Typography>
          </Typography>

          <Typography variant="subtitle2">
            <Trans i18nKey="lib.date">{'Date '}</Trans>

            <Typography display="inline" variant="body2">
              {formatDate(file.date)}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Accordion>
  );
}
