import React, { createContext, ReactNode, useContext, useState } from 'react';

type FullScreenContextProps = {
  isFullScreen: boolean;
  isSemiFullScreen: boolean;
  handleChangeFullScreen: () => void;
  handleChangeSemiFullScreen: () => void;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

const FullScreenContext = createContext<FullScreenContextProps>({
  handleChangeFullScreen: () => {
    //
  },
  handleChangeSemiFullScreen: () => {
    //
  },
  isFullScreen: false,
  isSemiFullScreen: false,
  setIsFullScreen: () => {
    //
  },
});

function FullScreenProvider({ children }: { children: ReactNode }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isSemiFullScreen, setIsSemiFullScreen] = useState(false);

  function handleChangeFullScreen() {
    setIsFullScreen((prev) => !prev);
    if (!isFullScreen) setIsSemiFullScreen(false);
  }

  function handleChangeSemiFullScreen() {
    setIsSemiFullScreen((prev) => !prev);
    if (!isSemiFullScreen) setIsFullScreen(false);
  }

  return (
    <FullScreenContext.Provider
      value={{
        handleChangeFullScreen,
        handleChangeSemiFullScreen,
        isFullScreen,
        isSemiFullScreen,
        setIsFullScreen,
      }}
    >
      {children}
    </FullScreenContext.Provider>
  );
}

function useFullScreen() {
  return useContext(FullScreenContext);
}

export { FullScreenProvider, useFullScreen };
