// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Chip } from '@mui/material';

import { ActiveFilter } from 'src/utilities/hooks/usePreference';

import { Filter } from 'src/features/History/History.types';

type ActiveFilterProps = {
  filter: ActiveFilter | Filter;
  filterLabel?: string;
  onDeleteActiveFilter: (filter: ActiveFilter) => void;
};

export function ActiveFilter({ filter, filterLabel, onDeleteActiveFilter }: ActiveFilterProps) {
  const filterKey = Object.keys(filter)[0];
  const label =
    filterKey === 'code'
      ? (filterLabel ? `${filterLabel}: ` : '') + `${filter.text}`
      : `${filter.label}: ${filter.textValue || filter[filterKey]}`;

  function handleDelete() {
    onDeleteActiveFilter(filter);
  }

  return <Chip label={label} onDelete={handleDelete} />;
}
