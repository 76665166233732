import { SubTableProps } from 'src/features/JobsTable/components/SubTable/SubTable';
import { useGetJobsQuery } from 'src/features/JobsTable/JobsTable.service';
import { useRouteParams } from 'src/utilities/hooks';

type UseSubTable = Omit<SubTableProps, 'numberOfParentColumns'>;

export function useSubTable({ isExpanded, parentRowJobId, parentRowJobType }: UseSubTable) {
  const { age } = useRouteParams();
  const { data: jobs, isFetching: areJobsFetching } = useGetJobsQuery(
    {
      age,
      endpoint: `jobs/${parentRowJobId}/subjobs`,
      jobType: parentRowJobType,
      shouldIncludeArchiveStatus: true,
    },
    { skip: !isExpanded },
  );

  const columns = jobs?.columns || [];
  const deadlines = jobs?.deadlines || {};
  const rows = jobs?.rows || [];

  return { areJobsFetching, columns, deadlines, rows };
}
