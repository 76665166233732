import { createApi } from '@reduxjs/toolkit/query/react';

import { CategorizedFiles, Folders, RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

type BaseParams = {
  age?: ExpandedAges;
  id?: number;
  src?: string;
  jobId?: number | string;
};

type CompareFilesRequest = {
  file_id: number[];
  jobid?: number | string;
} & BaseParams;

type FoldersResponse = {
  code: string;
  name: string;
  file_count: number;
  path_name: string;
  subItems: Array<{
    age: ExpandedAges;
    jobid: number;
    src: string;
    name: string;
    file_count: number;
    path_name: string;
  }>;
};

export const compareFilesApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    compareFiles: build.query<string, CompareFilesRequest>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/jobs/files/compare',
        };
      },
    }),
    getFiles: build.query<{ categorizedFiles: CategorizedFiles; rawFiles: RawFiles }, BaseParams>({
      query({ age, id, jobId, src }) {
        return {
          method: 'GET',
          params: {
            age,
            id,
            jobid: jobId,
            src,
            sub: 'cloudflow',
          },
          url: '/jobs/files/get',
        };
      },
      transformResponse(response: RawFiles): {
        categorizedFiles: CategorizedFiles;
        rawFiles: RawFiles;
      } {
        const categorizedResponse: CategorizedFiles = {};

        response.forEach((file) => {
          if (categorizedResponse[file.category]) {
            categorizedResponse[file.category].push(file);
          } else {
            categorizedResponse[file.category] = [file];
          }
        });

        return {
          categorizedFiles: categorizedResponse,
          rawFiles: response,
        };
      },
    }),
    getFolders: build.query<Folders, BaseParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: `/jobs/${params.jobId}/compare/folders`,
        };
      },
      transformResponse(response: Array<FoldersResponse>): Folders {
        return response.map(
          ({ code, file_count: fileCount, name, path_name: pathName, subItems }) => ({
            code,
            file_count: fileCount,
            name,
            path_name: pathName,
            subItems: subItems.map(
              ({ age, file_count: fileCount, jobid, name, path_name: pathName, src }) => ({
                age,
                code: jobid.toString(),
                file_count: fileCount,
                name,
                path_name: pathName,
                src,
                subItems: [],
              }),
            ),
          }),
        );
      },
    }),
  }),
  reducerPath: 'compareFilesApi',
});

export const { useGetFoldersQuery, useLazyCompareFilesQuery, useLazyGetFilesQuery } =
  compareFilesApi;
