export function getPhpDateFormatToPrimeCalendar(phpDateFormat: string): string {
  const formatMap: Record<string, string> = {
    // Year
    Y: 'yy',
    a: 'yy',
    // Day
    d: 'dd',
    // Day of the month, 2 digits with leading zeros
    j: 'd',
    // Day of the month without leading zeros
    // Month
    m: 'mm',
    // Numeric representation of a month, with leading zeros
    n: 'm',
    // A two digit representation of a year
    y: 'yy',
  };

  let primeReactFormat = phpDateFormat
    .split('')
    .map((char) => {
      return formatMap[char] || char;
    })
    .join('');

  // Handle separators by checking the format and replacing accordingly
  primeReactFormat = primeReactFormat.replace(/\//g, '/').replace(/-/g, '.').replace(/\./g, '.');

  return primeReactFormat;
}
