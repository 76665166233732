import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Step, StepLabel, Stepper } from '@mui/material';

import { useRouteParams } from '../../../../utilities/hooks';

import type { CreationStep } from '../../Content.types';

type CreationHeaderProps = {
  currentStep: CreationStep;
  onCategoryChange: (category: string) => void;
  onContentSave: () => void;
  onStepChange: (step: CreationStep) => void;
  isSubmitDisabled: boolean;
};

export function CreationHeader({
  currentStep,
  isSubmitDisabled,
  onContentSave,
  onStepChange,
}: CreationHeaderProps) {
  const { jobId, jobType } = useRouteParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleCancel() {
    navigate(`/jobs-job-${jobType}/${jobId}/cms`);
  }

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid rgba(0, 0, 0, 0.12)"
      display="flex"
      justifyContent="space-between"
      pb={1}
      px={3}
    >
      <Box display="flex" flex="1 1 0px" gap={1} />

      <Box flex="1 1 0px">
        <Stepper activeStep={currentStep === 'TEMPLATE_SELECTION' ? 0 : 1}>
          <Step completed={false}>
            <StepLabel>{t('apl.phrase.window.tempsel', 'Template Selection')}</StepLabel>
          </Step>

          <Step completed={false}>
            <StepLabel>{t('apl.phrase.window.contsel', 'Content Selection')}</StepLabel>
          </Step>
        </Stepper>
      </Box>

      <Box display="flex" flex="1 1 0px" gap={1} justifyContent="flex-end">
        {currentStep !== 'TEMPLATE_SELECTION' ? (
          <Box
            component={Button}
            onClick={() => onStepChange('TEMPLATE_SELECTION')}
            variant="contained"
          >
            {t('lib.back', 'Back')}
          </Box>
        ) : (
          <Button color="warning" onClick={handleCancel}>
            {t('lib.cancel', 'Cancel')}
          </Button>
        )}

        <LoadingButton
          color="primary"
          loading={isSubmitDisabled}
          onClick={() =>
            currentStep === 'TEMPLATE_SELECTION'
              ? onStepChange('CONTENT_SELECTION')
              : onContentSave()
          }
          variant="contained"
        >
          {currentStep === 'TEMPLATE_SELECTION' ? t('lib.next', 'Next') : t('clip.save', 'Save')}
        </LoadingButton>
      </Box>
    </Box>
  );
}
