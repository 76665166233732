import type { CSSObject } from '@mui/material';

export function Tabs() {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: 1.625,
          textTransform: 'capitalize' as CSSObject['textTransform'],
        },
      },
    },
  };
}
