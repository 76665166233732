import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Table, TableBody, TableContainer, Typography } from '@mui/material';

import { Header } from 'src/features/JobsTable/components/Header';
import { LoadingHeaderAndBody } from 'src/features/JobsTable/components/LoadingHeaderAndBody';
import { Row } from 'src/features/JobsTable/components/Row';
import { Toolbar } from 'src/features/JobsTable/components/Toolbar';
import {
  ColumnTransformed,
  Deadlines,
  JobIdsWithSubJobs,
  RowTransformed,
} from 'src/features/JobsTable/JobsTable.service';
import { useJobsTable } from 'src/features/JobsTable/utilities/hooks';
import {
  IdsOfExpandedJobs,
  OpenConfirmationDialogParameters,
  SelectedRows,
} from 'src/features/JobsTable/utilities/hooks/useJobs';

export type Common = {
  areJobsFetching: boolean;
  columns: ColumnTransformed[];
  deadlines: Deadlines;
  rows: RowTransformed[];
  setPageHistory: Dispatch<SetStateAction<number>>;
  totalRowCount: number;
};
type WithConfirmationDialog = {
  onOpenConfirmationDialog: ({ jobId, jobType }: OpenConfirmationDialogParameters) => void;
};
export type WithoutConfirmationDialog = { onOpenConfirmationDialog?: never };
export type WithExpandableRows = {
  idsOfExpandedJobs: IdsOfExpandedJobs;
  jobIdsWithSubJobs: JobIdsWithSubJobs;
  setIdsOfExpandedJobs: Dispatch<SetStateAction<IdsOfExpandedJobs>>;
};
export type WithoutExpandableRows = {
  idsOfExpandedJobs?: never;
  jobIdsWithSubJobs?: never;
  setIdsOfExpandedJobs?: never;
};
export type WithCheckbox = {
  onClickRowCheckbox: (rowId: number) => void;
  onToggleSelectAllRows: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedRows: SelectedRows;
  setSelectedRows: Dispatch<SetStateAction<SelectedRows>>;
} & WithoutRadio;
type WithoutCheckbox = { onClickRowCheckbox?: never; onToggleSelectAllRows?: never };
type WithRadio = {
  onClickRowRadio: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  selectedRows: SelectedRows;
  setSelectedRows: Dispatch<SetStateAction<SelectedRows>>;
} & WithoutCheckbox;
export type WithoutRadio = { onClickRowRadio?: never };
export type WithoutSelectableRow = {
  selectedRows?: never;
  setSelectedRows?: never;
} & WithoutCheckbox &
  WithoutRadio;
export type Main = WithExpandableRows & WithCheckbox & WithConfirmationDialog;
export type Dashboard = WithoutExpandableRows & WithoutSelectableRow & WithoutConfirmationDialog;
export type Assignment = WithRadio & WithoutExpandableRows & WithoutConfirmationDialog;
type PropsOfJobsTable = (Common & Dashboard) | (Common & (Main | Assignment));

export function JobsTable({
  areJobsFetching,
  columns,
  deadlines,
  idsOfExpandedJobs = [],
  jobIdsWithSubJobs = [],
  onClickRowCheckbox,
  onClickRowRadio,
  onOpenConfirmationDialog,
  onToggleSelectAllRows,
  rows,
  selectedRows = [],
  setIdsOfExpandedJobs,
  setPageHistory,
  setSelectedRows,
  totalRowCount,
}: PropsOfJobsTable) {
  const {
    doesTableHaveACopyableRow,
    doesTableHaveADeletableRow,
    handleChangeTableDensity,
    isDense,
  } = useJobsTable({
    rows,
  });

  function renderHeader() {
    const basicHeaderProps = {
      columns,
      doesTableHaveACopyableRow,
      doesTableHaveADeletableRow,
      totalRowCount,
    };

    // Main
    if (jobIdsWithSubJobs && onToggleSelectAllRows) {
      return (
        <Header
          jobIdsWithSubJobs={jobIdsWithSubJobs}
          onToggleSelectAllRows={onToggleSelectAllRows}
          selectedRows={selectedRows}
          {...basicHeaderProps}
        />
      );
    }

    // Assignment or Dashboard
    return <Header {...basicHeaderProps} />;
  }

  function renderRow(row: RowTransformed, rowId: number) {
    const basicRowProps = {
      columns,
      deadline: deadlines[row.jobId],
      isDense,
      key: row.jobId,
      row,
      rowId,
      totalRowCount,
    };

    // Main
    if (jobIdsWithSubJobs && onOpenConfirmationDialog) {
      return (
        <Row
          doesTableHaveADeletableRow={doesTableHaveADeletableRow}
          idsOfExpandedJobs={idsOfExpandedJobs}
          jobIdsWithSubJobs={jobIdsWithSubJobs}
          onClickRowCheckbox={onClickRowCheckbox}
          onClickRowRadio={onClickRowRadio}
          onOpenConfirmationDialog={onOpenConfirmationDialog}
          selectedRows={selectedRows}
          setIdsOfExpandedJobs={setIdsOfExpandedJobs}
          {...basicRowProps}
        />
      );
    }

    // Assignment
    if (onClickRowRadio) {
      return (
        <Row onClickRowRadio={onClickRowRadio} selectedRows={selectedRows} {...basicRowProps} />
      );
    }

    // Dashboard
    return <Row {...basicRowProps} />;
  }

  return (
    <>
      {!areJobsFetching ? (
        <Toolbar
          isDense={isDense}
          onChangeTableDensity={handleChangeTableDensity}
          setPageHistory={setPageHistory}
          totalRowCount={totalRowCount}
          {...(setSelectedRows && { setSelectedRows })}
        />
      ) : null}

      <TableContainer sx={{ flexShrink: 0, overflow: 'auto' }}>
        <Table size="small">
          {areJobsFetching ? (
            <LoadingHeaderAndBody totalColumns={8} totalRows={10} />
          ) : (
            <>
              {renderHeader()}

              {!rows.length ? (
                <Typography
                  component="caption"
                  sx={{ fontSize: 'inherit !important', textAlign: 'center !important' }}
                >
                  Nothing to Show
                </Typography>
              ) : (
                <TableBody>{rows.map((row, index) => renderRow(row, index))}</TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
