import { Button } from '@mui/material';

import { useCompareButton } from 'src/features/JobFiles/components/CompareButton/useCompareButton';
import { FileIds } from 'src/features/JobFiles/JobFiles';
import { FolderCode, RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

type CompareButtonProps = {
  files: RawFiles;
  onToggleSelectAll: (isSelected?: boolean) => void;
  selectedFileIds: FileIds;
  selectedFolderCode: FolderCode;
};

export function CompareButton({
  files,
  onToggleSelectAll,
  selectedFileIds,
  selectedFolderCode,
}: CompareButtonProps) {
  const {
    code,
    compareButton,
    compareButtons,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isSubMenuOpen,
    text,
  } = useCompareButton({
    files,
    onToggleSelectAll,
    selectedFileIds,
    selectedFolderCode,
  });

  return (
    <>
      <Button
        onClick={handleToggleSubMenu}
        ref={compareButton}
        startIcon={<WaveIcon code={`job-files-action-bar-${code}`} fontSize="small" />}
        {...(isSubMenuOpen && { sx: { bgcolor: 'primary.light' } })}
      >
        {text}
      </Button>

      <SubMenu
        anchorEl={compareButton.current}
        anchorOriginHorizontalSubitem="left"
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={compareButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginHorizontalSubitem="right"
        transformOriginVerticalSubitem="top"
      />
    </>
  );
}
