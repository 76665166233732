import type { BaseQueryFn } from '@reduxjs/toolkit/query';

import { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios';

import { API } from './api';

import { ApiError } from 'src/utilities/types';

const DEFAULT_ERROR = {
  error: {
    code: 0,
    data: '',
    message: 'Unexpected error',
    title: 'Unexpected error',
  },
};

export function axiosBaseQuery(): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  },
  unknown,
  ApiError
> {
  return async ({ data, method, onUploadProgress, params, url }) => {
    try {
      const result = await API({ data, method, onUploadProgress, params, url });

      return { data: result.data.data };
    } catch (err) {
      if (err instanceof AxiosError<ApiError>) {
        if (err.response) {
          return {
            error: {
              code: err.response.data.code,
              data: err.response.data,
              message: err.response.data.message,
              title: err.response.data.title,
            },
          };
        }
      }

      return DEFAULT_ERROR;
    }
  };
}
