import {
  ExternalUserRevisor,
  GroupRevisor,
  UserRevisor,
} from 'src/features/JobForm/JobForm.service';

export function determineRevisorId(revisor: ExternalUserRevisor | GroupRevisor | UserRevisor) {
  if (revisor.type === 'email_ext') {
    return revisor.email;
  } else if (revisor.type === 'email_gru' || revisor.type === 'email_gruasrole') {
    return revisor.group_id.toString();
  } else {
    return revisor.user_id.toString();
  }
}
