import { Trans } from 'react-i18next';

import { Button, Skeleton } from '@mui/material';

import { useSubscriptionsList } from 'src/features/JobForm/components/SubscriptionsList/useSubscriptionsList';
import { SubMenu } from 'src/features/SubMenu/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export function SubscriptionsList() {
  const {
    handleCloseSubMenu,
    handleToggleSubMenu,
    isFetching,
    isSubMenuOpen,
    subscriptionsList,
    subscriptionsListButton,
    subscriptionsListItems,
  } = useSubscriptionsList();

  function renderSubscriptionList() {
    return (
      <>
        <Button
          onClick={handleToggleSubMenu}
          ref={subscriptionsListButton}
          startIcon={<WaveIcon code="job-form-action-bar-subscriptions-list" />}
          sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
        >
          <Trans i18nKey="subscriptions_button">Subscriptions</Trans>
        </Button>

        <SubMenu
          anchorEl={subscriptionsListButton.current}
          anchorElMinWidth={subscriptionsListButton.current?.offsetWidth}
          anchorOriginVerticalSubitem="top"
          fontSize="caption"
          items={subscriptionsListItems}
          onClose={handleCloseSubMenu}
          open={isSubMenuOpen}
          transformOriginVerticalSubitem="top"
        />
      </>
    );
  }

  return isFetching ? (
    <Skeleton height={30} variant="rounded" width={80} />
  ) : subscriptionsList?.length ? (
    renderSubscriptionList()
  ) : null;
}
