import { css, styled, Unstable_Grid2 as Grid } from '@mui/material';

type StyledGridProps = {
  isFixedFields?: boolean;
  isNewBlockRow?: boolean;
};

const scrollStyle = css`
  background-attachment: local, local, scroll, scroll;
  background-image: linear-gradient(to right, white, white), linear-gradient(to left, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0.25)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0.25));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
`;

const fixedStyle = css`
  flex-direction: column;
  justify-content: space-around;
  max-width: fit-content;
`;

const hideScrollbarStyle = `
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

export const StyledGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isFixedFields' && prop !== 'isNewBlockRow',
})<StyledGridProps>`
  overflow: auto;
  ${({ isFixedFields }) => (isFixedFields ? fixedStyle : scrollStyle)}
  ${({ isNewBlockRow }) => (isNewBlockRow === true ? null : hideScrollbarStyle)}
`;
