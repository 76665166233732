import { Dispatch, SetStateAction } from 'react';

import { Button } from 'src/components/Button';
import { useActionsButton } from 'src/features/JobsTable/components/ActionsButton/useActionsButton';
import { ViewSearchDialog } from 'src/features/JobsTable/components/ViewSearchDialog';
import { ActionTransformed } from 'src/features/JobsTable/JobsTable.service';
import { computeWaveIconCodePrefix } from 'src/features/JobsTable/utilities/helperFunctions';
import { BulkEditType, SelectedRows } from 'src/features/JobsTable/utilities/hooks/useJobs';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export type PropsOfActionsButton = {
  code: string;
  dashboardUserId?: number;
  selectedRows: SelectedRows;
  setBulkEditType: Dispatch<SetStateAction<BulkEditType>>;
  subActions: ActionTransformed[];
};

export function ActionsButton({
  code,
  dashboardUserId,
  selectedRows,
  setBulkEditType,
  subActions,
}: PropsOfActionsButton) {
  const {
    age,
    anchorEl,
    dialogVariantData,
    handleCloseSubMenu,
    handleCloseViewSearchDialog,
    handleOpenSubMenu,
    handleSubActions,
    isSubJobsTable,
    isSubMenuOpen,
    isViewSearchDialogOpen,
  } = useActionsButton({ dashboardUserId, selectedRows, setBulkEditType });

  const areMultipleJobsSelected = selectedRows.length > 1;
  const subMenuActions = subActions.map(({ code, subActions, text }) => {
    const isBulkEdit = code === 'bulk';
    const isDisabled = isBulkEdit && !areMultipleJobsSelected;

    return {
      code,
      disabled: isDisabled,
      icon: (
        <WaveIcon
          code={`${computeWaveIconCodePrefix({
            age,
            isSubJobsTable,
          })}-action-bar-${code}`}
          color="primary"
          fontSize="small"
        />
      ),
      navigateIcon: <WaveIcon code="navigate-next" color="primary" fontSize="small" />,
      onClick: () => {
        handleSubActions(code.split('-'), text);
      },
      subItems: subActions?.map(({ code, text }) => ({
        code,
        onClick: () => {
          handleSubActions(code.split('-'));
        },
        text,
      })),
      text,
      tooltipBody: isDisabled ? 'At least two jobs need to be selected.' : '',
    };
  });

  return (
    <>
      <Button
        color="secondary"
        onClick={handleOpenSubMenu}
        startIcon={<WaveIcon code={code} />}
        {...(isSubMenuOpen && { sx: { bgcolor: 'secondary.dark' } })}
      >
        lib.actions
      </Button>

      {isSubMenuOpen ? (
        <SubMenu
          anchorEl={anchorEl}
          anchorOriginHorizontalSubitem="left"
          anchorOriginVerticalSubitem="top"
          fontSize="caption"
          items={subMenuActions}
          onClose={handleCloseSubMenu}
          open={isSubMenuOpen}
          transformOriginHorizontalSubitem="right"
          transformOriginVerticalSubitem="top"
        />
      ) : null}

      <ViewSearchDialog
        isOpen={isViewSearchDialogOpen}
        onClose={handleCloseViewSearchDialog}
        variantData={dialogVariantData}
      />
    </>
  );
}
