import { FileExplorer } from 'src/features/FileExplorer';
import { BreadCrumbs } from '../../components/BreadCrumbs';

export function GeneralFiles() {
  return (
    <>
      <BreadCrumbs />

      <FileExplorer />
    </>
  );
}
