import dayjs from 'dayjs';

import { evenlyDistributedFilter } from '../../../utilities/helperFunctions';

import { HistoryItemType, SliderValue } from '../History.types';

export function mutateHistorySliderValues(sliderValues: string[]) {
  sliderValues = [...new Set(sliderValues)].reverse();
  sliderValues = evenlyDistributedFilter(sliderValues, 14);

  return sliderValues.map((label, index) => ({ label, value: index }));
}

/**
 * Polyfill for some browsers ex firefox
 * @param dateString string
 * @returns string
 */
function parseCustomDateFormat(dateString: string) {
  const parts = dateString.split(' ');
  const month = parts[0];
  const year = parseInt(parts[1]);

  return month + ' 1, ' + year;
}


/**
 * Filters history data based on slider/timeline value (month yyyy)
 * 
 * @param history HistoryItemType[]
 * @param sliderValues SliderValue[]
 * 
 * @returns HistoryItemType[]
 */
export function filterHistoryByMonths(history: HistoryItemType[], sliderValues: SliderValue[]) {
  const months = sliderValues.map((value) => {
    const date = dayjs(value.label);

    if (date.isValid()) {
      return date;
    }

    return dayjs(parseCustomDateFormat(value.label));
  });

  const filteredHistory: HistoryItemType[] = [];

  history.forEach((item) => {
    months.forEach((date) => {
      if (date.isSame(item.datum, 'month')) {
        filteredHistory.push(item);
      }
    });
  });

  return filteredHistory;
}