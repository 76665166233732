import { useEffect, useState } from 'react';

import { Box, CircularProgress, Skeleton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';

import defaultThumbnail from '../../images/thumbnails/default_thumbnail.png';
import defaultPdfThumbnail from '../../images/thumbnails/pdf_thumbnail.png';
import defaultZipThumbnail from '../../images/thumbnails/zip_thumbnail.png';

type ThumbnailProps = {
  alt: string;
  fileExtension?: '7z' | 'pdf' | 'rar' | 'zip';
  isExternalLinkLoading?: boolean;
  hasExternalLink?: boolean;
  isSourceExternal?: boolean;
  /** Correlates to the height and width of the thumbnail. e.g. ("100px","100%", 100 )*/
  size: number | string;
  url: string;
};

function computeFullImageUrl({
  isSourceExternal,
  url,
}: {
  isSourceExternal: boolean;
  url: string;
}) {
  return isSourceExternal ? url : `${import.meta.env.VITE_API}${url}`;
}

export function Thumbnail({
  alt,
  fileExtension,
  hasExternalLink,
  isExternalLinkLoading,
  isSourceExternal = false,
  size,
  url,
}: ThumbnailProps) {
  const [imageSource, setImageSource] = useState(computeFullImageUrl({ isSourceExternal, url }));
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  function handleFinishLoadingImage() {
    setIsImageLoading(false);
  }

  function handleImageError() {
    setImageSource(
      !fileExtension
        ? defaultThumbnail
        : fileExtension === 'zip' || fileExtension === 'rar' || fileExtension === '7z'
        ? defaultZipThumbnail
        : defaultPdfThumbnail,
    );
    setIsImageError(true);
  }

  useEffect(() => {
    setImageSource(computeFullImageUrl({ isSourceExternal, url }));
    setIsImageError(false);
  }, [url]);

  return (
    <WaveTooltip
      body={
        !isImageError && !isImageLoading ? (
          <Box
            alt={alt}
            component="img"
            onError={handleImageError}
            onLoad={handleFinishLoadingImage}
            src={imageSource}
          />
        ) : null
      }
      component={
        isImageLoading ? (
          <>
            <Box
              alt={alt}
              component="img"
              onError={handleImageError}
              onLoad={handleFinishLoadingImage}
              src={imageSource}
              sx={{ display: 'none' }}
            />

            <Skeleton height={size} sx={{ cursor: 'default' }} variant="rectangular" width={size} />
          </>
        ) : (
          <Box
            sx={{
              height: size,
              position: 'relative',
              width: size,
            }}
          >
            <Box
              alt={alt}
              component="img"
              onError={handleImageError}
              onLoad={handleFinishLoadingImage}
              src={imageSource}
              sx={{
                cursor: 'default',
                height: size,
                width: size,
              }}
            />

            {hasExternalLink ? (
              isExternalLinkLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    left: '50%',
                    marginLeft: -1.5,
                    marginTop: -1.5,
                    position: 'absolute',
                    top: '50%',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    opacity: 1,
                    position: 'absolute',
                    top: '0',
                    width: '100%',
                  }}
                >
                  <WaveIcon
                    code="files_zoom_out_map"
                    fontSize="small"
                    sx={{
                      transitionDuration: '.5s',
                      ...(hasExternalLink && { ':hover': { fontSize: 'x-large', opacity: 0.5 } }),
                    }}
                  />
                </Box>
              )
            ) : null}
          </Box>
        )
      }
      placement="right"
      type="custom"
    />
  );
}
