import { useEffect, useState } from 'react';

import { ColorsColumn, useLazyGetColorsQuery } from 'src/features/JobForm/JobForm.service';
import { useAppSelector, useDebounce, useFirstRender, usePreference } from 'src/utilities/hooks';

type Rows = {
  name: string;
  book: string;
  description: string;
};

export function useColorDialog() {
  const bookPreference = usePreference('colorbook.book', '');
  const bookPreferenceValue = useAppSelector(
    (state) => state.user.preferences['colorbook.book']?.value,
  );
  const [columns, setColumns] = useState<Array<ColorsColumn>>([]);

  const isFirstRender = useFirstRender();
  const pagePreference = usePreference('colorbook.listPage', '0');
  const rowsPerPagePreference = usePreference('colorbook.rowsPerPage', '25');
  const [rows, setRows] = useState<Array<Rows>>([]);
  const [getColors, { isFetching: isFetchingGetColor }] = useLazyGetColorsQuery();
  const searchPreference = usePreference('colorbook.search', '');
  const searchPreferenceValue = useAppSelector(
    (state) => state.user.preferences['colorbook.search']?.value,
  );
  const [searchColor, setSearchColor] = useState(searchPreference.value);
  const debouncedValue = useDebounce(searchColor, 500);
  const [totalRowCount, setTotalRowCount] = useState<number>();

  async function getColorsData() {
    await getColors()
      .unwrap()
      .then((res) => {
        setTotalRowCount(res.count);
        setColumns(res.columns);
        setRows(res.rows);
      });
  }

  useEffect(() => {
    getColorsData();
  }, [
    bookPreference.value,
    pagePreference.value,
    rowsPerPagePreference.value,
    searchPreference.value,
  ]);

  useEffect(() => {
    if (!isFirstRender && (!bookPreferenceValue || !searchPreferenceValue)) {
      getColorsData();
    }
  }, [bookPreferenceValue, isFirstRender, searchPreferenceValue]);

  return {
    columns,
    debouncedValue,
    isFetchingGetColor,
    rows,
    searchColor,
    setSearchColor,
    totalRowCount,
  };
}
