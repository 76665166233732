import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';

import { Header } from 'src/features/JobsTable/components/Header';
import { LoadingHeaderAndBody } from 'src/features/JobsTable/components/LoadingHeaderAndBody';
import { Row } from 'src/features/JobsTable/components/Row';
import { useSubTable } from 'src/features/JobsTable/components/SubTable/useSubTable';
import { JobIdValue } from 'src/pages/Job/Job.service';

export type SubTableProps = {
  isExpanded: boolean;
  numberOfParentColumns: number;
  parentRowJobId: JobIdValue;
  parentRowJobType: string;
};

export function SubTable({
  isExpanded,
  numberOfParentColumns,
  parentRowJobId,
  parentRowJobType,
}: SubTableProps) {
  const { areJobsFetching, columns, deadlines, rows } = useSubTable({
    isExpanded,
    parentRowJobId,
    parentRowJobType,
  });

  return (
    <TableRow sx={{ bgcolor: `${parentRowJobType}.main` }}>
      <TableCell colSpan={numberOfParentColumns + 5}>
        {/* colSpan is + 5 for the rowId, expand/collapse, checkbox, copy and delete   */}
        {/* TODO: tie this +5 to a variable so if the columns change,
        there won't be a misalignment */}
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Table size="small" sx={{ borderCollapse: 'separate' }}>
            {areJobsFetching ? (
              <LoadingHeaderAndBody totalColumns={8} totalRows={10} />
            ) : (
              <>
                <Header columns={columns} isSubHeader />

                <TableBody>
                  {rows.map((row, index) => (
                    <Row
                      columns={columns}
                      deadline={deadlines[row.jobId]}
                      isSubRow
                      key={row.jobId}
                      row={row}
                      rowId={index}
                    />
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
