import { Box, Skeleton } from '@mui/material';

import { Task } from 'src/features/JobForm/components/Task';
import { useTasks } from 'src/features/JobForm/components/Tasks/useTasks';

export function Tasks() {
  const { isDirty, isFetching, tasks } = useTasks();

  if (isFetching) return <Skeleton height={30} variant="rounded" width={80} />;

  if (!tasks.length) return null;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {tasks.map((task) => (
        <Task isDirty={isDirty} key={task.code} task={task} />
      ))}
    </Box>
  );
}
