import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { FormData, PasswordConfirm } from 'src/features/PasswordConfirm';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppSelector } from 'src/utilities/hooks';
import { useChangePasswordMutation } from './ChangePassword.service';

type ChangePasswordDialog = {
  isOpen: boolean;
  onClose: () => void;
};
export function ChangePasswordDialog({ isOpen, onClose }: ChangePasswordDialog) {
  const [changePassword] = useChangePasswordMutation();
  const userId = useAppSelector((state) => state.user.details.id);
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      confirmPassword: '',
      newPassword: '',
      oldPassword: '',
    },
    mode: 'onChange',
  });

  const [shouldShowOldPassword, setShouldShowOldPassword] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClickShowOldPassword() {
    setShouldShowOldPassword(!shouldShowOldPassword);
  }

  function handleOnClose() {
    reset();
    setShouldShowOldPassword(false);
    onClose();
  }

  async function onSubmit({ confirmPassword, newPassword, oldPassword }: FormData) {
    await changePassword({ confirmPassword, id: userId, newPassword, oldPassword })
      .unwrap()
      .then((message) => {
        dispatch(
          openWaveSnack({
            message: message,
            type: 'success',
          }),
        );
        handleOnClose();
      })
      .catch((error) => {
        dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      });
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <WaveDialogTitle
          onClose={handleOnClose}
          title={t('user_details_change_password_button', 'Change Password')}
        />

        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              control={control}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'new-password' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleClickShowOldPassword}>
                      {shouldShowOldPassword ? (
                        <WaveIcon code="visibility" />
                      ) : (
                        <WaveIcon code="visibility_off" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t('hom.pwd.old', 'Old Password')}
              name="oldPassword"
              rules={{
                required: { message: `${t('lib.required')}`, value: true },
              }}
              type={shouldShowOldPassword ? 'text' : 'password'}
            />

            <Box paddingY={1}>
              <Divider />
            </Box>

            <PasswordConfirm control={control} trigger={trigger} watch={watch} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Box display="flex" flex={1} justifyContent="space-between">
            <Button color="warning" onClick={handleOnClose}>
              <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
            </Button>

            <WaveTooltip
              body={!isValid ? 'The form is invalid.' : ''}
              component={
                <Button disabled={!isDirty || !isValid} type="submit">
                  <Trans i18nKey="lib.confirm">Confirm</Trans>
                </Button>
              }
              type="simple"
            />
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
