import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import { Trans } from 'react-i18next';

export function RadioField({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onChange,
  options,
  required,
  value,
}) {
  function handleOnChange(e) {
    e.target.id = id;
    onChange(e);
  }

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!label || (checkRequiredFields && required && !value) || isError}
      required={required}
    >
      <FormLabel component="legend">
        <Trans>{label}</Trans>
      </FormLabel>

      <RadioGroup key={id} onChange={handleOnChange} value={value || ''}>
        {Object.entries(options).map((option) => {
          return (
            <FormControlLabel
              control={<Radio color="primary" />}
              key={option[0]}
              label={option[1]}
              value={option[0]}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
