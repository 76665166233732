import i18next from 'i18next';

import { ClipboardData } from 'src/features/JobForm/components/CopyCompareButton/type';
import { FieldsTransformed, FieldValue, JobValues } from 'src/pages/Job/Job.service';

export const COLUMN_WIDTH = 200;
export const OFFSET_COLUMN_WIDTH = COLUMN_WIDTH + 36;

export function getFilterSameValues(data: ClipboardData, jobData: JobValues) {
  const filteredSections = data.filter((section) => {
    const areAllFieldsSame = section.fields.every((field) => {
      return field.value === jobData?.[field.fieldId];
    });

    return !areAllFieldsSame;
  });

  const filteredFields = filteredSections.map((section) => {
    const filteredFields = section.fields.filter((field) => {
      return field.value !== jobData?.[field.fieldId];
    });

    return {
      ...section,
      fields: filteredFields,
    };
  });

  return filteredFields;
}

export function getFilterSearchTerm(
  data: ClipboardData,
  jobFields: FieldsTransformed,
  searchTerm: string,
) {
  const filteredSections = data.filter((section) => {
    const isAnyFieldMatchSearch = section.fields.some((field) => {
      const { language } = i18next;
      const translatedFieldName = `name_${language}`;
      const fieldName = jobFields?.[field.fieldId]?.[translatedFieldName as any];

      return fieldName?.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return isAnyFieldMatchSearch;
  });

  const filteredFields = filteredSections.map((section) => {
    const matchingFields = section.fields.filter((field) => {
      const { language } = i18next;
      const translatedFieldName = `name_${language}`;
      const fieldName = jobFields?.[field.fieldId]?.[translatedFieldName as any];

      return fieldName?.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return {
      ...section,
      fields: matchingFields,
    };
  });

  return filteredFields;
}

export function getDefaultValues(
  data: ClipboardData,
  compareDialogOpenSection: string,
  shouldSelectAllBlocksOption: boolean,
) {
  const defaultValues = data.reduce((acc, section) => {
    const fields = section.fields.reduce((fieldAcc, field) => {
      return {
        ...fieldAcc,
        [field.fieldId]: 'updated',
      };
    }, {});

    acc[section.sectionId] = {
      fields,
      group: 'updated',
      selected: shouldSelectAllBlocksOption ? true : section.sectionId === compareDialogOpenSection,
    };

    return acc;
  }, {} as Record<string, any>);

  return defaultValues;
}

export function getIsAccordionExpandedValues(
  data: ClipboardData,
  compareDialogOpenSection: string,
  shouldSelectAllBlocksOption: boolean,
) {
  const defaultSectionValues = data.reduce((acc, data) => {
    acc[`${data.sectionId}_selected`] = shouldSelectAllBlocksOption
      ? true
      : data.sectionId === compareDialogOpenSection;

    return acc;
  }, {} as Record<string, boolean>);

  return defaultSectionValues;
}

export function getCountOfUpdatedFields(
  sectionId: string,
  watchedFormData: any,
  filteredSameValuesData: ClipboardData,
) {
  const fields = watchedFormData[sectionId]?.fields || {};
  const filterSectionFields =
    filteredSameValuesData.find((section) => section.sectionId === sectionId)?.fields ?? [];
  const updatedFieldsCount =
    Object.keys(fields).filter((field) => {
      const value = fields[field];

      const hasField = filterSectionFields.some((filteredField) => filteredField.fieldId === field);

      return value === 'updated' && hasField;
    })?.length || 0;

  return updatedFieldsCount;
}

function isNotEmptyObject(obj?: {}) {
  return obj !== undefined && obj !== null && Object.keys(obj).length !== 0;
}

function hasFieldOptions(jobFields: FieldsTransformed, fieldId: string): boolean {
  return isNotEmptyObject(jobFields?.[fieldId]?.fieldData);
}

function getFieldOptions(jobFields: FieldsTransformed, fieldId: string) {
  return jobFields?.[fieldId]?.fieldData || null;
}

function convertBooleanValue(value: boolean): string {
  return value ? 'True' : 'False';
}

export function getFieldLabel(
  fieldId: string,
  jobData: JobValues,
  jobFields: FieldsTransformed,
  fieldValue?: FieldValue,
) {
  const fieldValueFromData = fieldValue !== undefined ? fieldValue : jobData?.[fieldId];

  if (hasFieldOptions(jobFields, fieldId)) {
    const fieldOptions = getFieldOptions(jobFields, fieldId);

    if (Array.isArray(fieldOptions)) {
      const option = fieldOptions.find(
        (option) => option.label === fieldValueFromData || option.value === fieldValueFromData,
      );

      return option ? option.label : '';
    } else {
      return fieldOptions?.[fieldValueFromData as string] || (fieldValueFromData as string) || '';
    }
  } else if (typeof fieldValueFromData === 'boolean') {
    return convertBooleanValue(fieldValueFromData);
  } else {
    return fieldValueFromData?.toString() || '';
  }
}
