import { MouseEvent, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import i18next from 'i18next';

import { IconProps } from '@mui/material';
import Button from '@mui/material/Button';

import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

const ACTIONS = [
  {
    code: 'save',
    color: 'primary' as IconProps['color'],
    text: 'Save Template',
    translationKey: 'save_template',
  },
  {
    code: 'edit',
    color: 'primary' as IconProps['color'],
    text: 'Rename Template',
    translationKey: 'rename_template',
  },
  {
    code: 'delete',
    color: 'error' as IconProps['color'],
    text: 'Delete Template',
    translationKey: 'delete_template',
  },
];

type CompareReplaceDropdownButtonProps = {
  onOpenDeleteTemplate: () => void;
  onOpenRenameTemplate: () => void;
  onOpenSaveTemplate: () => void;
};

export function CompareReplaceDropdownButton({
  onOpenDeleteTemplate,
  onOpenRenameTemplate,
  onOpenSaveTemplate,
}: CompareReplaceDropdownButtonProps) {
  const { language } = i18next;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const isOpen = Boolean(anchorEl);

  function handleMenuClick(code: string) {
    switch (code) {
      case 'save':
        onOpenSaveTemplate();
        break;
      case 'edit':
        onOpenRenameTemplate();
        break;
      case 'delete':
        onOpenDeleteTemplate();
        break;
      default:
        console.error(`Unknown action code: ${code}`);
    }

    setAnchorEl(null);
  }

  const actions = useMemo(() => {
    return ACTIONS.map((action) => ({
      code: action.code,
      icon: <WaveIcon code={action.code} color={action.color} fontSize="small" />,
      onClick: () => handleMenuClick(action.code),
      text: t(action.translationKey, action.text),
    }));
  }, [language]);

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setAnchorElWidth(e.currentTarget.offsetWidth);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        color="secondary"
        onClick={handleClick}
        startIcon={<WaveIcon code="more-vert" />}
        sx={{ bgcolor: `${isOpen ? 'secondary.dark' : ''}` }}
      >
        <Trans i18nKey="lib.actions">Actions</Trans>
      </Button>

      <SubMenu
        anchorEl={anchorEl}
        anchorElMinWidth={anchorElWidth}
        anchorOriginHorizontalSubitem="right"
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={actions}
        onClose={handleClose}
        open={isOpen}
        transformOriginHorizontalSubitem="left"
        transformOriginVerticalSubitem="top"
      />
    </>
  );
}
