import { createApi } from '@reduxjs/toolkit/query/react';

import { Field } from 'src/pages/Job/Job.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';

type GetLanguageFieldRequest = {
  load_data: number;
  alias: string;
};
type UpdateTranslation = {
  source_language?: string;
  target_language?: string;
  text: string;
};

export const translationApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getLanguageField: build.query<Field[], GetLanguageFieldRequest>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/fields/get',
        };
      },
    }),
    updateTranslation: build.mutation<UpdateTranslation, UpdateTranslation>({
      query(params) {
        return {
          method: 'POST',
          params,
          url: '/translate',
        };
      },
    }),
  }),
  reducerPath: 'translationApi',
});

export const { useLazyGetLanguageFieldQuery, useUpdateTranslationMutation } = translationApi;
