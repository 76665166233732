import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Skeleton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { StepDialog } from 'src/features/JobForm/components/StepDialog';
import { useSteps } from 'src/features/JobForm/components/Steps/useSteps';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export function Steps() {
  const { t } = useTranslation();
  const {
    handleCloseStepDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isFetching,
    isStatusChanging,
    isStepDialogOpen,
    isSubMenuOpen,
    step,
    stepButtons,
    stepsButton,
  } = useSteps();

  return isFetching ? (
    <Skeleton height={30} variant="rounded" width={80} />
  ) : stepButtons.length ? (
    <>
      <WaveTooltip
        component={
          <LoadingButton
            disabled={isDirty}
            loading={isStatusChanging}
            onClick={handleToggleSubMenu}
            ref={stepsButton}
            startIcon={<WaveIcon code="job-form-action-bar-steps" />}
          >
            <Trans i18nKey="lib.steps">Steps</Trans>
          </LoadingButton>
        }
        placement="top"
        type="simple"
        {...(isDirty && {
          body: t(
            'form_save_other_actions',
            'Save the job to apply your changes or refresh the page to undo them. Only then, can you perform other job actions.',
          ) as string,
        })}
        {...(isDirty && { header: t('form_changes_made', 'Changes Have Been Made') as string })}
      />

      <SubMenu
        anchorEl={stepsButton.current}
        anchorElMinWidth={stepsButton.current?.offsetWidth}
        anchorOriginHorizontalSubitem="right"
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={stepButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginVerticalSubitem="top"
      />

      {step ? (
        <StepDialog isOpen={isStepDialogOpen} onClose={handleCloseStepDialog} step={step} />
      ) : null}
    </>
  ) : null;
}
