import { useEffect, useState } from 'react';

import {
  FieldsTransformed,
  Flags,
  JobValues,
  Tasks,
  useGetJobQuery,
} from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';
import { usePreference, useRouteParams, useViewer } from 'src/utilities/hooks';

type HeaderLayout = {
  column1Fields?: string[];
  column2Fields?: string[];
  column3Fields?: string[];
};

export function useJobHeader() {
  const { age, jobId, jobType } = useRouteParams();

  const clientIdPreference = usePreference('sys.mid', '');
  const [headerLayout, setHeaderLayout] = useState<HeaderLayout>({});

  const installationModules = import.meta.glob(
    '/src/installations/*/client_*/form/*/headerLayout.*',
  );

  const { preview } = useJobContext();

  const {
    data: { fields: jobFields, values: jobValues } = {
      fields: {} as FieldsTransformed,
      values: {} as JobValues,
    },
    isFetching,
  } = useGetJobQuery({
    age,
    jobId,
    jobType,
  });

  const { openViewerPage } = useViewer();

  const defaultHeaderLayout = { column1Fields: ['stichw'] };

  const flags = !isFetching ? Object.values(jobValues.flags as Flags) : [];
  const tasks = !isFetching ? Object.values(jobValues.tasks as Tasks) : [];

  function handleClickThumbnailContainer(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    openViewerPage(preview?.file_id);
  }

  useEffect(() => {
    (async () => {
      try {
        const headerLayoutPath = `/src/installations/${import.meta.env.VITE_INSTALLATION}/client_${
          clientIdPreference.value
        }/form/${jobType}/headerLayout.js`;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { headerLayout } = await installationModules[headerLayoutPath]();

        setHeaderLayout(headerLayout);
      } catch (error) {
        console.log(error);
        setHeaderLayout(defaultHeaderLayout);
      }
    })();
  }, [jobType]);

  return {
    flags,
    handleClickThumbnailContainer,
    headerLayout,
    isFetching,
    jobFields,
    jobValues,
    preview,
    tasks,
  };
}
