import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useGetCategoriesQuery } from '../../content.service';
import { RichTextLayout } from '../Layouts/RichTextLayout';
import { StandardLayout } from '../Layouts/StandardLayout';
import { Metadata } from '../Metadata';
import { SearchResults } from '../SearchResults';
import { SideBar } from '../SideBar';

import type { TemplateSelectionParams } from '../../Content.types';

type ContentSelectionProps = {
  onCategoryChange: (category: string) => void;
  selectedCategory: string;
};

export function ContentSelection({ onCategoryChange, selectedCategory }: ContentSelectionProps) {
  const [categoryIndex, itemIndex] = selectedCategory.split('_');
  const { control } = useFormContext<TemplateSelectionParams>();
  const { fields: categories, update } = useFieldArray({
    control,
    name: 'categories',
  });

  const { data: categoriesList = [] } = useGetCategoriesQuery({});
  const selectedCategoryObject = categoriesList.find(
    (item) => item.code === (categories[+categoryIndex].categoryId || ''),
  );

  useEffect(() => {
    categories
      .filter((category) => category.categoryId)
      .forEach((category, index) => {
        const amount = +(category.amount || 0);

        if (category.items) {
          if (category.items.length <= (category.amount || 0)) {
            update(index, {
              ...category,
              items: category.items.concat(
                ...Array(amount - category.items.length).fill({ status: 'NEEDED' }),
              ),
            });
          } else {
            update(index, {
              ...category,
              items: category.items.splice(category.items.length - amount, amount),
            });
          }
        } else {
          update(index, {
            ...category,
            items: Array(amount).fill({ status: 'NEEDED' }),
          });
        }
      });
  }, []);

  function renderLayoutComponent(layout: string) {
    if (layout === 'memo') {
      return (
        <StandardLayout
          selectedCategory={selectedCategoryObject}
          selectedCategoryIndex={selectedCategory}
        />
      );
    }

    if (layout === 'rich') {
      return (
        <RichTextLayout
          selectedCategory={selectedCategoryObject}
          selectedCategoryIndex={selectedCategory}
        />
      );
    }

    return null;
  }

  return (
    <Grid container flexGrow={1} id="scroll-parent" overflow="auto">
      <Grid
        borderRight={1}
        height="100%"
        id="scroll-child"
        overflow="auto"
        sx={{ borderColor: 'grey.500' }}
        xs={2.5}
      >
        <SideBar
          categories={categories}
          onCategoryChange={onCategoryChange}
          selectedCategory={selectedCategory}
        />
      </Grid>

      <Grid display="flex" flexDirection="column" height="100%" xs>
        <Typography color="primary" fontWeight="600" pb={1.5} pt={0.5} px={1}>
          {selectedCategoryObject?.value}

          {(categories[+categoryIndex].items || []).length > 1 && ` ${+itemIndex + 1}`}
        </Typography>

        <Grid container flexGrow={1} gap={1} m={0} overflow="auto" p={1}>
          <Grid xs>
            <Grid container direction="column" gap={1} height="100%">
              <Grid flex="1 1 0px">{renderLayoutComponent(categories[+categoryIndex].layout)}</Grid>

              <Metadata selectedCategory={selectedCategory} />
            </Grid>
          </Grid>

          <Grid height="100%" xs>
            <SearchResults
              selectedCategory={selectedCategoryObject}
              selectedCategoryIndex={selectedCategory}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
