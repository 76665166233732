import { Button, styled } from '@mui/material';

export const StyledButton = styled(Button)`
  min-width: auto;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: 4px;

  span {
    font-size: 16px;
  }
`;
