import { Box, Tooltip, Typography } from '@mui/material';

import { StyledTypographyWhiteSpace } from 'src/pages/FileSearch/styled';

type FileSearchTextGroupProps = {
  label: string;
  value: string | number;
};

export function FileSearchTextGroup({ label, value }: FileSearchTextGroupProps) {
  return (
    <Box display="flex">
      <StyledTypographyWhiteSpace fontWeight="700" marginRight={1}>
        {label}:
      </StyledTypographyWhiteSpace>

      <Tooltip title={value}>
        <Typography noWrap>{value}</Typography>
      </Tooltip>
    </Box>
  );
}
