import { memo } from 'react';
import { Control } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Checkbox } from 'src/components/RHF/Checkbox';
import { CompareReplaceAccordionDetails } from 'src/features/CompareReplaceDialog/components/CompareReplaceAccordionDetails';
import { OFFSET_COLUMN_WIDTH } from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { ClipboardSection } from 'src/features/JobForm/components/CopyCompareButton/type';
import { WaveIcon } from 'src/features/WaveIcon';
import { JobValues } from 'src/pages/Job/Job.service';
import { FieldLayout } from 'src/pages/Job/JobContext';

const StyledWaveIcon = styled(WaveIcon)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledCheckBox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.common.white};

  &.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.common.white};
  }

  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.white};
  }

  &.Mui-checked .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`;

type CompareReplaceAccordionProps = {
  control: Control<any>;
  fieldLayout?: FieldLayout;
  isExpanded: boolean;
  jobData: JobValues;
  onAccordionChange: (sectionId: string) => void;
  onChangeField: (sectionId: string, fieldId: string) => void;
  onChangeGroup: (sectionId: string, value: string) => void;
  onDeselectEmptyFields: (sectionId: string) => void;
  onSelectNonEmptyFields: (sectionId: string) => void;
  section: ClipboardSection;
  updatedCount: number;
};

function CompareReplaceAccordion({
  control,
  fieldLayout,
  isExpanded,
  jobData,
  onAccordionChange,
  onChangeField,
  onChangeGroup,
  onDeselectEmptyFields,
  onSelectNonEmptyFields,
  section,
  updatedCount,
}: CompareReplaceAccordionProps) {
  const { t } = useTranslation();

  if (isExpanded === undefined) return null;

  return (
    <Box key={`${section.sectionId}_copyReplace`} marginX={2}>
      <Accordion
        disableGutters
        expanded={isExpanded}
        onChange={() => {
          onAccordionChange(section.sectionId);
        }}
      >
        <AccordionSummary expandIcon={<StyledWaveIcon code="expand-more" />}>
          <Box marginTop={3} width={OFFSET_COLUMN_WIDTH}>
            <StyledCheckBox
              control={control}
              label={
                fieldLayout?.sections.find(
                  (fieldLayoutSection) => fieldLayoutSection.code === section.sectionId,
                )?.title
              }
              name={`${section.sectionId}.selected`}
            />
          </Box>

          <Box alignItems="center" display="flex" flex="1" marginRight={4.5}>
            <Typography fontWeight={600}>
              <Trans i18nKey="current_value">Current Value</Trans>
            </Typography>
          </Box>

          <Box alignItems="center" display="flex" flex="1">
            <Typography fontWeight={600}>{`${t(
              'updated_value',
              'Updated Value',
            )} (${updatedCount}/${section.fields.length})`}</Typography>
          </Box>
        </AccordionSummary>

        <CompareReplaceAccordionDetails
          control={control}
          jobData={jobData}
          onChangeField={onChangeField}
          onChangeGroup={onChangeGroup}
          onDeselectEmptyFields={onDeselectEmptyFields}
          onSelectNonEmptyFields={onSelectNonEmptyFields}
          section={section}
        />
      </Accordion>
    </Box>
  );
}

const MemoizedCompareReplaceAccordion = memo(CompareReplaceAccordion);

export { MemoizedCompareReplaceAccordion as CompareReplaceAccordion };
