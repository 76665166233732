import { Dispatch, SetStateAction } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { List, Paper } from '@mui/material';

import { Indexes } from 'src/features/JobsTable/components/FieldSelector/useFieldSelector';
import { FieldSelectorField } from 'src/features/JobsTable/components/FieldSelectorField/FieldSelectorField';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { Preference } from 'src/utilities/hooks/usePreference';

export type FieldSelectorFieldWellProps = (AvailableFields | SelectedFields) & BasicProps;
type BasicProps = { fieldPreference: Preference; fields: FieldTransformed[] };
type AvailableFields = {
  checkedFields: FieldTransformed[];
  setCheckedAvailableFields: Dispatch<SetStateAction<FieldTransformed[]>>;
  setIndexesOfCheckedSelectedFields?: never;
  type: 'available';
};
type SelectedFields = {
  checkedFields: Indexes;
  setCheckedAvailableFields?: never;
  setIndexesOfCheckedSelectedFields: Dispatch<SetStateAction<Indexes>>;
  type: 'selected';
};

export function FieldSelectorFieldWell({
  // react/prop-types false positives.
  // eslint-disable-next-line react/prop-types
  checkedFields,
  fieldPreference,
  fields,
  // eslint-disable-next-line react/prop-types
  setCheckedAvailableFields,
  // eslint-disable-next-line react/prop-types
  setIndexesOfCheckedSelectedFields,
  // eslint-disable-next-line react/prop-types
  type,
}: FieldSelectorFieldWellProps) {
  return (
    <Paper className="field-well" variant="outlined">
      <List component="nav" disablePadding>
        <AutoSizer>
          {({ height, width }: { height: number; width?: number }) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <FixedSizeList
              height={height}
              itemCount={fields.length}
              itemData={{
                checkedFields,
                fieldPreference,
                fields,
                setCheckedAvailableFields,
                setIndexesOfCheckedSelectedFields,
                type,
              }}
              itemSize={50}
              width={width || height}
            >
              {FieldSelectorField}
            </FixedSizeList>
          )}
        </AutoSizer>
      </List>
    </Paper>
  );
}
