import { useTranslation } from 'react-i18next';

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { common, grey } from '@mui/material/colors';

import { WaveIcon } from 'src/features/WaveIcon';

type SummaryTableProps = {
  isSummaryTableOpen: boolean;
  isTranslationApproval: boolean;
};

const ICON_COLORS = {
  amendment: 'error' as const,
  approval: 'success' as const,
  conditional: 'warning' as const,
  unactioned: 'primary.main' as const,
};

const summaryTableColumn = [
  { code: 'lib.category', text: 'Category' },
  { code: 'lib.content', text: 'Content' },
  { code: 'lib.translation', text: 'Translation' },
  { code: 'lib.suggestion', text: 'Suggestion' },
  { code: 'lib.msg', text: 'Comment' },
];

const summaryTableData = [
  {
    category: 'Product Description 1',
    comment: '',
    content: 'It is a long established fact that a reader will be distracted',
    suggestion: '',
    translation: 'It is a long established fact that a reader will be distracted',
    type: 'approval' as const,
  },
  {
    category: 'Product Description 2',
    comment: '',
    content: 'It is a long established fact that a reader will be distracted',
    suggestion: 'This is the suggestion',
    translation: 'It is a long established fact that a reader will be distracted',
    type: 'conditional' as const,
  },
  {
    category: 'Ingredient Declaration',
    comment: 'Please review this content one more time',
    content: 'It is a long established fact that a reader will be distracted',
    suggestion: '',
    translation: 'It is a long established fact that a reader will be distracted',
    type: 'amendment' as const,
  },
  {
    category: 'Summary',
    comment: '',
    content: '',
    suggestion: '',
    translation: 'It is a long established fact that a reader will be distracted',
    type: 'approval' as const,
  },
];

export function SummaryTable({ isSummaryTableOpen, isTranslationApproval }: SummaryTableProps) {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <TableRow sx={{ backgroundColor: grey[100] }}>
      <TableCell colSpan={11} sx={{ paddingBottom: 0, paddingTop: 0 }}>
        <Collapse in={isSummaryTableOpen} timeout="auto" unmountOnExit>
          <Box component={Paper} marginY={1.5}>
            <Table size="small" sx={{ backgroundColor: common.white }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} />

                  {summaryTableColumn.map(({ code, text }) =>
                    code === 'translation' && !isTranslationApproval ? null : (
                      <TableCell key={code}>{t(code, text)}</TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {summaryTableData.map(
                  ({ category, comment, content, suggestion, translation, type }, index) => (
                    <TableRow key={category}>
                      <TableCell sx={{ backgroundColor: palette.oddRow.main }}>
                        {index + 1}
                      </TableCell>

                      <TableCell>
                        <WaveIcon
                          code={`job-approvals-revisor-summary-table-${type}`}
                          color={ICON_COLORS[type]}
                        />
                      </TableCell>

                      <TableCell>{category}</TableCell>

                      <TableCell
                        sx={{
                          backgroundColor:
                            !suggestion && content && !isTranslationApproval
                              ? palette.oddRow.main
                              : '',
                        }}
                        width={300}
                      >
                        {content}
                      </TableCell>

                      {isTranslationApproval ? (
                        <TableCell
                          sx={{
                            backgroundColor: !suggestion ? palette.oddRow.main : '',
                          }}
                          width={300}
                        >
                          {translation}
                        </TableCell>
                      ) : null}

                      <TableCell
                        sx={{
                          backgroundColor: suggestion ? palette.oddRow.main : '',
                        }}
                        width={300}
                      >
                        {suggestion}
                      </TableCell>

                      <TableCell width={300}>{comment}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
