import { Button, Dialog, DialogContent, Grid, List, ListSubheader } from '@mui/material';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { SavedViewSearchDialogVariantData } from 'src/features/JobsTable/components/ActionsButton/useActionsButton';
import { useViewSearchDialog } from 'src/features/JobsTable/components/ViewSearchDialog/useViewSearchDialog';
import { ViewSearchItem } from 'src/features/JobsTable/components/ViewSearchItem';
import { ViewSearchNewItemForm } from 'src/features/JobsTable/components/ViewSearchNewItemForm';
import { WaveIcon } from 'src/features/WaveIcon';

export type ViewSearchDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  variantData?: SavedViewSearchDialogVariantData;
};

export function ViewSearchDialog({ isOpen, onClose, variantData }: ViewSearchDialogProps) {
  const {
    handleCloseNewItemForm,
    handleToggleIsNewItemFormOpen,
    isNewItemFormOpen,
    isSavedViews,
    newLabel,
    savedSearches,
    savedViews,
  } = useViewSearchDialog({ isOpen, variantData });

  return (
    <Dialog fullWidth={true} onClose={onClose} open={isOpen}>
      <WaveDialogTitle
        onClose={onClose}
        title={
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>{variantData?.text}</Grid>

            <Grid item>
              <Button
                onClick={handleToggleIsNewItemFormOpen}
                size="small"
                startIcon={<WaveIcon code="add-box" />}
                sx={{ bgcolor: 'white !important', color: 'primary.main', mr: 1 }}
              >
                {newLabel}
              </Button>
            </Grid>
          </Grid>
        }
      />

      <DialogContent>
        <List disablePadding>
          {isSavedViews ? (
            <>
              {savedViews?.globalViews.length ? (
                <>
                  <ListSubheader disableSticky sx={{ mb: -2.5 }}>
                    Global Views
                  </ListSubheader>

                  {savedViews.globalViews.map((view) => (
                    <ViewSearchItem
                      isNewItemFormOpen={isNewItemFormOpen}
                      item={view}
                      key={view.id}
                      onCloseDialog={onClose}
                      variant="globalView"
                    />
                  ))}
                </>
              ) : null}

              {savedViews?.userViews.length ? (
                <>
                  <ListSubheader disableSticky sx={{ mb: -2.5 }}>
                    User Views
                  </ListSubheader>

                  {savedViews.userViews.map((view) => (
                    <ViewSearchItem
                      isNewItemFormOpen={isNewItemFormOpen}
                      item={view}
                      key={view.id}
                      onCloseDialog={onClose}
                      variant="userView"
                    />
                  ))}
                </>
              ) : null}
            </>
          ) : null}

          {!isSavedViews && savedSearches?.length
            ? savedSearches.map((search) => (
                <ViewSearchItem
                  isNewItemFormOpen={isNewItemFormOpen}
                  item={search}
                  key={search.id}
                  onCloseDialog={onClose}
                  variant="search"
                />
              ))
            : null}
        </List>
      </DialogContent>

      {isNewItemFormOpen ? (
        <ViewSearchNewItemForm
          isSavedViews={isSavedViews}
          newLabel={newLabel}
          onCloseNewItemForm={handleCloseNewItemForm}
        />
      ) : null}
    </Dialog>
  );
}
