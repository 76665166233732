import { useEffect, useState } from 'react';

import i18n from 'i18next';

export function useI18Initialized() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    function handleInitialized() {
      return setIsInitialized(true);
    }

    i18n.on('initialized', handleInitialized);

    return () => {
      i18n.off('initialized', handleInitialized);
    };
  }, []);

  return isInitialized;
}
