import { ChangeEvent, Dispatch, Fragment, SetStateAction } from 'react';

import { Box, InputAdornment, TextField } from '@mui/material';

import { ExpandAllButton } from 'src/components/ExpandAllButton';
import { useActionBar } from 'src/features/JobsTable/components/ActionBar/useActionBar';
import { ActionsButton } from 'src/features/JobsTable/components/ActionsButton';
import { ColumnsButton } from 'src/features/JobsTable/components/ColumnsButton';
import { FiltersButton } from 'src/features/JobsTable/components/FiltersButton';
import { NewJobButton } from 'src/features/JobsTable/components/NewJobButton';
import { computeWaveIconCodePrefix } from 'src/features/JobsTable/utilities/helperFunctions';
import {
  BulkEditType,
  IdsOfExpandedJobs,
  SelectedRows,
} from 'src/features/JobsTable/utilities/hooks/useJobs';
import { WaveIcon } from 'src/features/WaveIcon';

export type ActionBarProps = {
  areAllSubJobsExpanded?: boolean;
  dashboardUserId?: number;
  idsOfExpandedJobs?: IdsOfExpandedJobs;
  jobIdsWithSubJobs?: number[];
  onChangePageSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onToggleActiveFiltersVisibility: () => void;
  onToggleAreAllSubJobsExpanded?: () => void;
  searchTerm: string;
  selectedRows?: SelectedRows;
  setAreAllSubJobsExpanded?: (isExpanded: boolean) => void;
  setBulkEditType?: Dispatch<SetStateAction<BulkEditType>>;
};
export function ActionBar({
  areAllSubJobsExpanded = false,
  dashboardUserId,
  idsOfExpandedJobs = [],
  jobIdsWithSubJobs = [],
  onChangePageSearch,
  onToggleActiveFiltersVisibility,
  onToggleAreAllSubJobsExpanded,
  searchTerm,
  selectedRows = [],
  setAreAllSubJobsExpanded,
  setBulkEditType,
}: ActionBarProps) {
  const { actions, age, isSubJobsTable, translate } = useActionBar({
    idsOfExpandedJobs,
    jobIdsWithSubJobs,
    setAreAllSubJobsExpanded,
  });

  return (
    <Box display="flex" flexWrap="wrap" gap={2} justifyContent="space-between" mt={1}>
      <Box alignItems="center" display="flex" gap={2}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WaveIcon code="search" />
              </InputAdornment>
            ),
          }}
          label={translate('page_search_label', 'Page Search')}
          onChange={onChangePageSearch}
          size="small"
          type="search"
          value={searchTerm}
        />

        {actions?.map(({ code }) =>
          code === 'filters' ? (
            <FiltersButton
              code={`${computeWaveIconCodePrefix({
                age,
                isSubJobsTable,
              })}-action-bar-${code}`}
              key={code}
              onToggleActiveFiltersVisibility={onToggleActiveFiltersVisibility}
            />
          ) : null,
        )}
      </Box>

      <Box alignItems="center" display="flex" gap={2}>
        {actions?.map(({ code, subActions }) => (
          <Fragment key={code}>
            {/* {code === "list-view-options" && (
              <ListViewButton
                code={`jobs-action-bar-${code}`}
                text={text}
              />
            )} */}

            {code === 'expand-all' && jobIdsWithSubJobs?.length && onToggleAreAllSubJobsExpanded ? (
              <ExpandAllButton
                collapsedIcon="jobs-action-bar-keyboard-arrow-down"
                expandedIcon="jobs-action-bar-keyboard-arrow-up"
                isExpanded={areAllSubJobsExpanded}
                onToggleAreAllSubJobsExpanded={onToggleAreAllSubJobsExpanded}
              />
            ) : null}

            {code === 'new' ? (
              <NewJobButton
                code={`${computeWaveIconCodePrefix({
                  age,
                  isSubJobsTable,
                })}-action-bar-${code}-job`}
                subActions={subActions}
              />
            ) : null}

            {code === 'columns' ? (
              <ColumnsButton
                code={`${computeWaveIconCodePrefix({
                  age,
                  isSubJobsTable,
                })}-action-bar-${code}`}
              />
            ) : null}

            {code === 'actions' && setBulkEditType && subActions ? (
              <ActionsButton
                code={`${computeWaveIconCodePrefix({
                  age,
                  isSubJobsTable,
                })}-action-bar-${code}`}
                dashboardUserId={dashboardUserId}
                selectedRows={selectedRows}
                setBulkEditType={setBulkEditType}
                subActions={subActions}
              />
            ) : null}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}
