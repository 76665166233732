import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';

export function MainMenu({ onToggleDrawer, isOpen }) {
  const location = useLocation();
  const menuItems = useSelector((state) => state.user.mainMenu);
  const [menuOpen, setMenuOpen] = useState({});
  const navigate = useNavigate();

  const currentPage = window.location.origin + location.pathname;

  function handleMenuClick(code) {
    if (!isOpen) {
      // if navsidebar is collapsed, expand and expand tiered menu
      setMenuOpen({ [code]: true });
      onToggleDrawer();
    } else {
      // toggle tiered menu expansion
      setMenuOpen({ [code]: !menuOpen[code] });
    }
  }

  return (
    <List sx={{ py: 0 }}>
      {menuItems.map(({ code, link, name, subitems }) => {
        let mainItemClass = !isOpen
          ? 'primary-bg'
          : link && currentPage.includes(link)
          ? 'secondary-bg'
          : 'filler-bg';

        if (subitems?.length > 0) {
          for (const subItems of subitems) {
            if (
              subItems.link &&
              currentPage.includes(window.location.origin + subItems.link) &&
              isOpen
            ) {
              mainItemClass = 'secondary-bg';
            }
          }
        }

        return (
          <React.Fragment key={code}>
            <ListItem
              button
              className={mainItemClass}
              onClick={
                subitems?.length
                  ? () => {
                      handleMenuClick(code);
                    }
                  : () => {
                      navigate(link);
                    }
              }
            >
              <ListItemIcon className={isOpen ? 'primary-txt' : 'min-w-0 secondary-txt'}>
                <WaveIcon code={`main-menu-${code}`} />
              </ListItemIcon>

              <ListItemText className={!isOpen ? 'd-none' : null} primary={name} />

              {subitems?.length > 0 &&
                (!isOpen ? (
                  <WaveIcon className="secondary-txt" code="navigate-next" />
                ) : menuOpen[code] ? (
                  <WaveIcon code="expand-less" />
                ) : (
                  <WaveIcon code="expand-more" />
                ))}
            </ListItem>

            {subitems?.length > 0 && (
              <Collapse component="li" in={isOpen && menuOpen[code]} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {subitems.map(({ code, link, name }) => {
                    const subItemClass = !isOpen
                      ? 'primary-bg'
                      : link && currentPage.includes(link)
                      ? 'secondary-bg-light'
                      : 'filler-bg';

                    return (
                      <ListItem
                        button
                        className={subItemClass}
                        key={code}
                        onClick={() => {
                          navigate(link);
                        }}
                      >
                        <ListItemText className="no-item-icon" primary={name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
}
