import { GetEmailListResponse } from 'src/features/History/History.service';

export function getGeneratedEmailFile(emailData: GetEmailListResponse) {
  const emlContent = `
From: ${emailData.from_name} <${emailData.from_mail}>
To: ${emailData.to_name} <${emailData.to_mail}>
Subject: ${emailData.mail_subject}
Date: ${emailData.mail_date}
Content-Type: text/plain; charset="utf-8"

${emailData.mail_body}
`;

  const blob = new Blob([emlContent], { type: 'message/rfc822' });

  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = `${emailData.mail_subject}.eml`;
  link.style.display = 'none';
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
