import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type BaseParams = { id?: number };
type CreateAvatarRequest = { formData: FormData } & BaseParams;

export const avatarApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createAvatar: build.mutation<{ message: string }, CreateAvatarRequest>({
      query({ formData, id }) {
        return {
          data: formData,
          method: 'POST',
          url: `/users/${id}/avatar`,
        };
      },
    }),
    deleteAvatar: build.mutation<{ message: string }, BaseParams>({
      query({ id }) {
        return {
          method: 'DELETE',
          url: `/users/${id}/avatar`,
        };
      },
    }),
  }),
  reducerPath: 'avatarApi',
});

export const { useCreateAvatarMutation, useDeleteAvatarMutation } = avatarApi;
