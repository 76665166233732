import { Button, styled, Typography } from '@mui/material';

import {
  FILE_SEARCH_INFO_SECTION_HEADER_HEIGHT,
  FILE_SEARCH_INFO_SECTION_HEIGHT,
} from 'src/pages/FileSearch/FileSearchGridView/utils';

type StyledInfoSectionProps = {
  isExpanded: boolean;
  shouldDisplaySideBar: boolean;
  shouldStartAnimation: boolean;
};

export const StyledInfoSection = styled('div')<StyledInfoSectionProps>`
  flex-direction: column;
  position: absolute;
  bottom: -${FILE_SEARCH_INFO_SECTION_HEIGHT - FILE_SEARCH_INFO_SECTION_HEADER_HEIGHT}px;
  left: 0;
  width: 100%;
  // The gallery library uses z-index: 100000 for the lightbox.
  z-index: 100001;
  height: ${FILE_SEARCH_INFO_SECTION_HEIGHT}px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  opacity: ${({ shouldStartAnimation }) => (shouldStartAnimation ? 1 : 0)};
  transform: ${({ isExpanded }) =>
    !isExpanded
      ? 'translateY(0)'
      : `translateY(-${
          FILE_SEARCH_INFO_SECTION_HEIGHT - FILE_SEARCH_INFO_SECTION_HEADER_HEIGHT
        }px)`};
  display: ${({ shouldDisplaySideBar }) => (shouldDisplaySideBar ? 'flex' : 'none')};
  transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const StyledInfoSectionHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${FILE_SEARCH_INFO_SECTION_HEADER_HEIGHT}px;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  :hover {
    background-color: ${({ theme }) => theme.palette.filler.main};
  }
`;

export const StyledTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 52px;
`;
