import { Fragment, memo } from 'react';
import { Control } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import i18next from 'i18next';

import { AccordionDetails, Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from 'src/components/Button';
import { RadioButton, RadioGroup } from 'src/components/RHF/RadioButton';
import {
  COLUMN_WIDTH,
  getFieldLabel,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { ClipboardSection } from 'src/features/JobForm/components/CopyCompareButton/type';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldsTransformed, JobValues } from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';

const StyledRadioButton = styled(RadioButton)`
  margin: 0;
  width: 100%;

  .MuiFormControlLabel-label {
    overflow-wrap: anywhere;
    width: 100%;
    min-height: ${({ theme }) => theme.spacing(3)};
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  &.MuiFormGroup-root {
    flex-wrap: nowrap;
    min-height: 28px;
  }
`;

type CompareReplaceAccordionDetailsProps = {
  section: ClipboardSection;
  control: Control<any>;
  jobData: JobValues;
  onChangeGroup: (sectionId: string, value: string) => void;
  onChangeField: (sectionId: string, fieldId: string) => void;
  onSelectNonEmptyFields: (sectionId: string) => void;
  onDeselectEmptyFields: (sectionId: string) => void;
};

function CompareReplaceAccordionDetails({
  control,
  jobData,
  onChangeField,
  onChangeGroup,
  onDeselectEmptyFields,
  onSelectNonEmptyFields,
  section,
}: CompareReplaceAccordionDetailsProps) {
  const { language } = i18next;
  const { t } = useTranslation();
  const { fields: jobFields } = useJobContext();

  return (
    <AccordionDetails>
      <StyledRadioGroup
        control={control}
        name={`${section.sectionId}.group`}
        onChange={(value) => onChangeGroup(section.sectionId, value)}
      >
        <Box display="flex">
          <Box width={COLUMN_WIDTH} />

          <Box alignItems="center" display="flex" flex="1">
            <StyledRadioButton
              label={t('select_all_current_values', 'Select All Current Values')}
              size="small"
              value="current"
            />
          </Box>

          <Box alignItems="center" display="flex" flex="1">
            <StyledRadioButton
              label={t('select_all_current_values', 'Select All Updated Values')}
              size="small"
              value="updated"
            />

            <StyledRadioButton label="Select All Current Values" size="small" value="current" />
          </Box>
        </Box>
      </StyledRadioGroup>

      <Divider />

      {section.fields.map((field) => {
        const translatedNameField = `name_${language}`;

        return (
          <Fragment key={`${field.fieldId}_compare_selection`}>
            <StyledRadioGroup
              control={control}
              defaultValue="updated"
              name={`${section.sectionId}.fields.${field.fieldId}`}
              onChange={() => onChangeField(section.sectionId, field.fieldId)}
            >
              <Box display="flex" key={field.fieldId}>
                <Box alignItems="center" display="flex" width={COLUMN_WIDTH}>
                  <Typography>
                    {jobFields?.[field.fieldId]?.[translatedNameField as any] ?? ''}
                  </Typography>
                </Box>

                <Box alignItems="center" display="flex" flex="1">
                  <StyledRadioButton
                    label={getFieldLabel(field.fieldId, jobData, jobFields as FieldsTransformed)}
                    size="small"
                    value="current"
                  />
                </Box>

                <Box alignItems="center" bgcolor="filler.main" display="flex" flex="1">
                  <StyledRadioButton
                    label={getFieldLabel(
                      field.fieldId,
                      jobData,
                      jobFields as FieldsTransformed,
                      field.value,
                    )}
                    size="small"
                    value="updated"
                  />
                </Box>
              </Box>
            </StyledRadioGroup>

            <Divider />
          </Fragment>
        );
      })}

      <Box display="flex">
        <Box width={COLUMN_WIDTH} />

        <Box alignItems="center" display="flex" flex="1" marginTop={2}>
          <Box
            component={Button}
            marginLeft={1.5}
            onClick={() => onSelectNonEmptyFields(section.sectionId)}
            startIcon={<WaveIcon code="check-box" />}
          >
            <Trans i18next="select_non_empty">Select non-empty</Trans>
          </Box>
        </Box>

        <Box alignItems="center" display="flex" flex="1" marginTop={2}>
          <Box
            component={Button}
            marginLeft={1.5}
            onClick={() => onDeselectEmptyFields(section.sectionId)}
            startIcon={<WaveIcon code="disabled-by-default" />}
          >
            <Trans i18nKey="deselect_empty">Deselect empty</Trans>
          </Box>
        </Box>
      </Box>
    </AccordionDetails>
  );
}

const MemoizedCompareReplaceAccordionDetails = memo(CompareReplaceAccordionDetails);

export { MemoizedCompareReplaceAccordionDetails as CompareReplaceAccordionDetails };
