import { styled } from '@mui/material';

type StyledBlockFieldButtonsContainerProps = {
  isHidden: boolean;
};

export const StyledBlockFieldButtonsContainer = styled(
  'div',
)<StyledBlockFieldButtonsContainerProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : undefined)};
`;
