import { BasicAge, useRouteParams } from 'src/utilities/hooks/useRouteParams';

type PropsOfUseJobTableVariants = { forcedAge?: BasicAge };

export function useJobTableVariants(parameters?: PropsOfUseJobTableVariants) {
  const forcedAge = parameters?.forcedAge;

  const { age, jobId, jobType, tab } = useRouteParams({
    forcedAge,
  });

  return {
    age,
    isAssignJobTable: tab === 'assign',
    isSubJobsTable: tab === 'sub-jobs',
    jobId,
    jobType,
    tab,
  };
}
