import { ButtonProps } from '@mui/material';

import { Button } from 'src/components/Button';
import { WaveIcon } from 'src/features/WaveIcon';

type ExpandAllButtonProps = {
  collapsedIcon: string;
  expandedIcon: string;
  isExpanded: boolean;
  onToggleAreAllSubJobsExpanded: () => void;
  size?: ButtonProps['size'];
  // TODO: Is the size property needed?
};

// TODO: Not sure that we need separate component for such functionality
// (single component wrapper without custom logic)
export function ExpandAllButton({
  collapsedIcon,
  expandedIcon,
  isExpanded,
  onToggleAreAllSubJobsExpanded,
  size = 'medium',
}: ExpandAllButtonProps) {
  return (
    <Button
      onClick={onToggleAreAllSubJobsExpanded}
      size={size}
      startIcon={<WaveIcon code={isExpanded ? expandedIcon : collapsedIcon} />}
    >
      {isExpanded ? 'lib.collapseall' : 'expand_all_button'}
    </Button>
  );
}
