import { Button, Chip, Drawer, keyframes, styled } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const grow = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

export const StyledDrawer = styled(Drawer)<{ width: number }>`
  .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.palette.filler.main};
    height: 300px;
    padding: ${({ theme }) => theme.spacing(2)};
    width: ${({ width }) => width}px;
    left: auto;
    box-shadow: ${({ theme }) => theme.shadows[6]};
  }
`;

export const StyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  animation: ${fadeIn} 0.3s ease-in-out forwards, ${grow} 0.3s ease-in-out forwards;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
