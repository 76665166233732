import { useJobTableVariants } from 'src/features/JobsTable/utilities/hooks';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

export function usePreferencePrefix(parameters?: { forcedAge?: BasicAge }) {
  const { age, isAssignJobTable, isSubJobsTable, jobId, jobType, tab } = useJobTableVariants({
    forcedAge: parameters?.forcedAge,
  });

  return {
    age,
    isAssignJobTable,
    isSubJobsTable,
    jobId,
    jobType,
    preferencePrefix: isSubJobsTable
      ? `${age}-${jobType}-sub`
      : `${jobType === 'dash' ? 'hom' : age}-${jobType}`,
    tab,
  };
}
