import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFieldSelectorFields } from 'src/features/JobsTable/utilities/hooks';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { usePreferencePrefix } from 'src/utilities/hooks';
import { usePreference } from 'src/utilities/hooks/usePreference';

export function useColumnsButton() {
  const { preferencePrefix } = usePreferencePrefix();
  const columnsPreference = usePreference(`${preferencePrefix}.columns`, '');

  const [isFieldSelectorOpen, setIsFieldSelectorOpen] = useState(false);
  const { savedAvailableFields, savedSelectedFields } = useFieldSelectorFields({
    fieldPreference: columnsPreference,
    isFieldSelectorOpen,
    type: 'column',
  });
  const { t: translate } = useTranslation();

  const text = 'lib.columns';
  const fieldSelectorType = translate(text, 'Columns');

  function handleApplyFieldSelector(selectedFields?: FieldTransformed[]) {
    columnsPreference.set(selectedFields?.map(({ id }) => id).join() || '');
  }

  function handleOpenFieldSelector() {
    setIsFieldSelectorOpen(true);
  }

  function handleCloseFieldSelector() {
    setIsFieldSelectorOpen(false);
  }

  return {
    columnsPreference,
    fieldSelectorType,
    handleApplyFieldSelector,
    handleCloseFieldSelector,
    handleOpenFieldSelector,
    isFieldSelectorOpen,
    savedAvailableFields,
    savedSelectedFields,
    text,
  };
}
