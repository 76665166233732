import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { ReactQuillProps } from 'react-quill';

import { useTheme } from '@mui/material';

import { StyledReactQuill } from './styled';

type RichTextProps = {
  disabled?: boolean;
  onChange?: ReactQuillProps['onChange'];
  placeholder?: string;
  fieldValueIndex?: number;
  toolbar?: Array<
    Array<Record<string, string | (string | boolean)[] | undefined> | string | boolean>
  >;
};

const DEFAULT_MODULES = {
  toolbar: [
    [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
  ],
};
const FORMATS = [
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'align',
  'font',
  'size',
];

export function RichText<T extends FieldValues>({
  control,
  disabled = false,
  fieldValueIndex,
  name,
  onChange,
  placeholder,
  rules,
  toolbar = DEFAULT_MODULES.toolbar,
  ...rest
}: Omit<UseControllerProps<T>, 'defaultValue'> & RichTextProps) {
  const {
    field: { onChange: onFieldChange, value, ...field },
  } = useController({
    control,
    name,
    rules,
  });
  const theme = useTheme();

  const fieldValue = fieldValueIndex !== undefined ? value[fieldValueIndex] : value;

  return (
    <StyledReactQuill
      formats={FORMATS}
      modules={{ toolbar }}
      muiTheme={theme}
      onChange={(fieldValue, delta, source, editor) => {
        onFieldChange(fieldValue);

        if (onChange) {
          onChange(fieldValue, delta, source, editor);
        }
      }}
      placeholder={placeholder}
      readOnly={disabled}
      value={fieldValue || ''}
      {...field}
      {...rest}
    />
  );
}
