import React, { SyntheticEvent, useMemo } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  FilterOptionsState,
  TextField,
  Typography,
} from '@mui/material';

import {
  getAutoCompleteValuesResults,
  getFieldValue,
  getFormattedAutocompleteResults,
  getIsOption,
  getOptionLabel,
  getOptionValue,
} from 'src/components/RHF/Autocomplete/utils';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { Option } from 'src/pages/Job/Job.service';
import { usePreference } from 'src/utilities/hooks';
import { ListboxComponent } from './VirtualList';

import { AutocompleteProps } from './types';

// Needed to provide correct height for empty fields.
const EMPTY_STRING_FALLBACK = '\u00A0';

export function MultipleAutocomplete<
  T extends string | Option,
  P extends FieldValues,
  Multiple extends boolean,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  control,
  description,
  disabled,
  fieldValueIndex,
  freeSolo,
  getChipColor,
  isError = false,
  isFetching = false,
  isRawText = false,
  isReadOnly = false,
  label,
  name,
  onChange,
  onChangeLogicBuilderField,
  onChangeSteeredField,
  options,
  rules,
  shouldShowHelperText,
  shouldVirtualize,
  ...rest
}: Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'defaultValue' | 'renderInput'
> &
  Omit<UseControllerProps<P>, 'defaultValue'> &
  AutocompleteProps<T>) {
  const showTooltipsPreference = usePreference('job.feldtips', 'Y');

  const {
    field: { onChange: onFieldChange, ref, value: reactHookFormValue = null, ...field },
    fieldState: { error },
  } = useController<P>({
    control,
    name,
    rules,
  });

  const memoizedOptions = !freeSolo
    ? useMemo(() => {
        let resultingOptions = Array.isArray(options) ? [...options] : [...Object.values(options)];

        if (Array.isArray(reactHookFormValue) && !(reactHookFormValue as Option[]).length) {
          return resultingOptions;
        }

        if (reactHookFormValue !== null && reactHookFormValue !== '') {
          const values = (reactHookFormValue as unknown as number).toString().split(',');

          values.forEach((value) => {
            if (
              !Object.values(options).some(
                (option) => getIsOption(option) && option.value === value,
              )
            ) {
              resultingOptions = [{ label: value, value } as T, ...resultingOptions];
            }
          });
        }

        return resultingOptions as T[];
      }, [options])
    : options;

  const filter = createFilterOptions<T>();

  function filterOptions(options: T[], state: FilterOptionsState<T>) {
    const { inputValue: filterInputValue } = state;
    const filteredOptions = filter(options, state);
    const isValueAnExistingOption = options.some((option) =>
      typeof option === 'string' ? filterInputValue === option : filterInputValue === option.value,
    );

    if (freeSolo && filterInputValue !== '' && !isValueAnExistingOption) {
      return suggestCreationOfNewOption(filteredOptions, filterInputValue);
    }

    return filteredOptions;
  }

  function suggestCreationOfNewOption<Option>(filteredOptions: Option[], filterInputValue: string) {
    const newOption = {
      label: `Add "${filterInputValue}"`,
      value: filterInputValue,
    } as Option;

    return [...filteredOptions, newOption];
  }

  const value = getFieldValue<T>(reactHookFormValue, memoizedOptions, fieldValueIndex);

  function handleChange(
    event: SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) {
    const result = getAutoCompleteValuesResults<T>(value);

    const formattedResults = getFormattedAutocompleteResults(result, freeSolo);

    if (fieldValueIndex !== undefined && reactHookFormValue !== null) {
      const updatedValues: Array<any> = [...reactHookFormValue];

      updatedValues[fieldValueIndex] = formattedResults;

      onFieldChange(updatedValues);
    } else {
      onFieldChange(formattedResults);
    }

    if (onChange) {
      onChange(event, value, reason, details);
    }

    if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);

    if (onChangeSteeredField) onChangeSteeredField(name);
  }

  if (isReadOnly) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography>{reactHookFormValue || EMPTY_STRING_FALLBACK}</Typography>
      </Box>
    );
  }

  if (isRawText) {
    return <Typography>{reactHookFormValue || EMPTY_STRING_FALLBACK}</Typography>;
  }

  return (
    <>
      <MuiAutocomplete
        disableCloseOnSelect
        disabled={disabled || isFetching}
        disableListWrap
        filterOptions={filterOptions}
        freeSolo={freeSolo as FreeSolo}
        fullWidth
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => getOptionValue(option) === getOptionValue(value)}
        multiple={true as Multiple}
        onChange={handleChange}
        options={memoizedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error || isError}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...params.inputProps,
              required: !!rules?.required && (value as [])?.length === 0,
            }}
            inputRef={ref}
            label={label}
            required={!!rules?.required}
          />
        )}
        {...(!shouldVirtualize &&
          memoizedOptions.length && {
            renderOption: (props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} {...field} />

                {getOptionLabel(option)}
              </li>
            ),
          })}
        {...(shouldVirtualize && {
          ListboxComponent: ListboxComponent,
          renderOption: (props, option, state) => [props, option, state.index] as React.ReactNode,
        })}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={getOptionLabel(option)}
              size="small"
              variant="outlined"
              {...getTagProps({ index })}
              key={getOptionValue(option)}
              {...(getChipColor && { color: getChipColor(option) })}
            />
          ))
        }
        size="small"
        value={
          value as unknown as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | undefined
        }
        // https://mui.com/material-ui/react-text-field/#helper-text
        {...(shouldShowHelperText && { helperText: (error?.message && error.message) ?? ' ' })}
        {...(isFetching && { popupIcon: <CircularProgress size={24} /> })}
        {...field}
        {...rest}
      />

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </>
  );
}
