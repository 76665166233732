import { Skeleton, Unstable_Grid2 as Grid } from '@mui/material';

import { useRouteParams } from '../../../../utilities/hooks';
import { useGetPackagingTypesQuery, useGetTranslationsQuery } from '../../content.service';
import { PackagingTypes } from '../PackagingTypes';
import { Translations } from '../Translations';

type MetadataProps = {
  selectedCategory: string;
};

export function Metadata({ selectedCategory }: MetadataProps) {
  const { age, jobId, jobType } = useRouteParams();
  const { data: translations, isFetching: areTranslationsFetching } = useGetTranslationsQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: packagingTypes, isFetching: arePackTypesFetching } = useGetPackagingTypesQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  if (!packagingTypes || !translations || areTranslationsFetching || arePackTypesFetching) {
    return (
      <Grid display="flex" flex="1 1 0px" gap={1}>
        <Skeleton sx={{ transform: 'none', width: 1 }} />
      </Grid>
    );
  }

  if (
    packagingTypes &&
    translations &&
    !Object.keys(packagingTypes).length &&
    !Object.keys(translations).length
  ) {
    return null;
  }

  return (
    <Grid display="flex" flex="1 1 0px" gap={1}>
      {translations && Object.keys(translations).length ? (
        <Grid xs={Object.keys(packagingTypes).length ? 6 : 12}>
          <Translations
            columns={Object.keys(packagingTypes).length ? 6 : 3}
            data={translations}
            selectedCategory={selectedCategory}
          />
        </Grid>
      ) : null}

      {packagingTypes && Object.keys(packagingTypes).length ? (
        <Grid xs={Object.keys(translations).length ? 6 : 12}>
          <PackagingTypes
            columns={Object.keys(translations).length ? 6 : 3}
            data={packagingTypes}
            selectedCategory={selectedCategory}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
