import { useTranslation } from 'react-i18next';

import { Button, Skeleton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { useCreateCopy } from 'src/features/JobForm/components/CreateCopy/useCreateCopy';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export function CreateCopy() {
  const { t } = useTranslation();
  const {
    createCopyButton,
    createCopyButtons,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isFetching,
    isSubMenuOpen,
  } = useCreateCopy();

  if (isFetching) return <Skeleton height={30} variant="rounded" width={80} />;

  if (!createCopyButtons.length) return null;

  return (
    <>
      <WaveTooltip
        component={
          <Button
            disabled={isDirty}
            onClick={handleToggleSubMenu}
            ref={createCopyButton}
            startIcon={<WaveIcon code="job-form-action-bar-create-copy" />}
            sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
          >
            {t('create_copy_button', 'Create/Copy')}
          </Button>
        }
        placement="top"
        type="simple"
        {...(isDirty && {
          body: t(
            'form_save_other_actions',
            'Save the job to apply your changes or refresh the page to undo them. Only then, can you perform other job actions.',
          ) as string,
        })}
        {...(isDirty && { header: t('form_changes_made', 'Changes Have Been Made') as string })}
      />

      <SubMenu
        anchorEl={createCopyButton.current}
        anchorElMinWidth={createCopyButton.current?.offsetWidth}
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={createCopyButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginVerticalSubitem="top"
      />
    </>
  );
}
