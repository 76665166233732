import { memo } from 'react';

import { FormControlLabel, FormControlLabelProps, Radio, RadioProps } from '@mui/material';

type UpdatedFormControlLabelProps = Omit<FormControlLabelProps, 'control'> & RadioProps;

function RadioButton({ className, label, value, ...rest }: UpdatedFormControlLabelProps) {
  return (
    <FormControlLabel
      className={className}
      control={<Radio {...rest} />}
      label={label}
      value={value || false}
    />
  );
}

const MemoizedRadioButton = memo(RadioButton);

export { MemoizedRadioButton as RadioButton };
