import { useTranslation } from 'react-i18next';

import { TableCell, Typography } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { Task } from 'src/features/JobsTable/JobsTable.service';
import { subRowBorderStyles } from 'src/features/JobsTable/utilities/constants';
import { WaveIcon } from 'src/features/WaveIcon';

import { CommonCellProps, JobLink } from 'src/features/JobsTable/types';

type TasksCellProps = { tasks: Task[] } & CommonCellProps & JobLink;

export function TasksCell({ isSubRow, jobLink, paddingY, tasks }: TasksCellProps) {
  const { t: translate } = useTranslation();

  return (
    <TableCell sx={{ paddingY, ...(isSubRow && subRowBorderStyles) }}>
      <StyledLink href={jobLink}>
        {tasks.map(({ id, name, value }) => (
          <WaveTooltip
            body={
              <Typography color="text.primary" variant="caption">
                <strong> {translate('lib.status')} </strong>

                {value.charAt(0).toUpperCase() + value.slice(1)}
              </Typography>
            }
            component={<WaveIcon code={`job-tasks-${value}`} />}
            header={name}
            key={id}
            placement="top"
            type="custom"
          />
        ))}
      </StyledLink>
    </TableCell>
  );
}
