import { TableCell } from '@mui/material';

import { Thumbnail } from 'src/components/Thumbnail';
import { PreviewTransformed } from 'src/features/JobForm/JobForm.service';
import { usePreviewCell } from 'src/features/JobsTable/components/PreviewCell/usePreviewCell';
import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { subRowBorderStyles } from 'src/features/JobsTable/utilities/constants';
import { JobIdRequired } from 'src/pages/Job/Job.service';

import { CommonCellProps, IsDense, JobLink, RowJobType } from 'src/features/JobsTable/types';

export type PreviewCellProps = { preview: PreviewTransformed } & CommonCellProps &
  IsDense &
  JobIdRequired &
  JobLink &
  RowJobType;

export function PreviewCell({
  isDense,
  isSubRow,
  jobId,
  jobLink,
  paddingY,
  preview,
  rowJobType,
}: PreviewCellProps) {
  const { handleClickThumbnailContainer } = usePreviewCell({ jobId, preview, rowJobType });

  return (
    <TableCell
      onClick={handleClickThumbnailContainer}
      sx={{
        paddingY,
        position: 'relative',
        whiteSpace: 'nowrap',
        ...(isSubRow && subRowBorderStyles),
      }}
    >
      <StyledLink href={jobLink}>
        {preview.url.length ? (
          <Thumbnail
            alt={`latest artwork thumbnail for job ${jobId}`}
            hasExternalLink
            size={isDense ? 24 : 32}
            url={preview.url}
          />
        ) : null}
      </StyledLink>
    </TableCell>
  );
}
