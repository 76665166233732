import React from "react";

import { Slider, Typography } from "@mui/material";

export function ProgressBar({ disabled, id, label, onChange, value }) {
  return (
    <React.Fragment>
      <Typography gutterBottom id={id}>
        {label}
      </Typography>
      <Slider
        disabled={disabled}
        marks
        max={300}
        min={0}
        onChange={onChange}
        step={10}
        style={{ width: "300px" }}
        value={value}
        valueLabelDisplay="auto"
      />
    </React.Fragment>
  );
}
