import { useMemo } from 'react';

import { useGetFieldSelectorFieldsQuery } from 'src/features/JobsTable/JobsTable.service';
import { useJobTableVariants } from 'src/features/JobsTable/utilities/hooks/useJobTableVariants';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions';
import { Preference } from 'src/utilities/hooks/usePreference';

type UseFieldSelectorFields = {
  fieldPreference: Preference;
  isFieldSelectorOpen: boolean;
  type: 'column' | 'search';
};

export function useFieldSelectorFields({
  fieldPreference,
  isFieldSelectorOpen,
  type,
}: UseFieldSelectorFields) {
  const { isSubJobsTable, jobType } = useJobTableVariants();

  const { data } = useGetFieldSelectorFieldsQuery(
    {
      isSubJobsTable,
      jobType,
      type,
    },
    { skip: !isFieldSelectorOpen },
  );

  const { savedAvailableFields, savedSelectedFields } = useMemo(
    () => splitFields(),
    [data, fieldPreference.value],
  );

  function splitFields(): {
    savedAvailableFields?: FieldTransformed[];
    savedSelectedFields?: FieldTransformed[];
  } {
    if (data) {
      const idsOfSavedSelectedFields =
        fieldPreference.value && typeof fieldPreference.value === 'string'
          ? fieldPreference.value.split(',')
          : [];
      const savedAvailableFields = Object.keys(data).reduce((savedAvailableFields, fieldId) => {
        if (!idsOfSavedSelectedFields.includes(fieldId)) savedAvailableFields.push(data[fieldId]);

        return savedAvailableFields;
      }, [] as FieldTransformed[]);
      const savedSelectedFields = idsOfSavedSelectedFields.reduce(
        (savedSelectedFields, fieldId) => {
          const savedSelectedField = data[fieldId];

          if (savedSelectedField) savedSelectedFields.push(savedSelectedField);

          return savedSelectedFields;
        },
        [] as FieldTransformed[],
      );

      return {
        savedAvailableFields: sortArrayOfObjectsByKey({ array: savedAvailableFields, key: 'name' }),
        savedSelectedFields,
      };
    }

    return {};
  }

  return { savedAvailableFields, savedSelectedFields };
}
