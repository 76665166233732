import { Trans } from 'react-i18next';

import { Button } from '@mui/material';

import { SendEmailDialog } from 'src/components/SendEmailDialog';
import { useActionsButton } from 'src/features/JobFiles/components/ActionsButton/useActionsButton';
import { FileIds } from 'src/features/JobFiles/JobFiles';
import { FolderCode } from 'src/features/JobFiles/JobFiles.service';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

type ActionsButtonProps = {
  onToggleSelectAll: (isSelected?: boolean) => void;
  selectedFileIds: FileIds;
  selectedFolderCode: FolderCode;
};

export function ActionsButton({
  onToggleSelectAll,
  selectedFileIds,
  selectedFolderCode,
}: ActionsButtonProps) {
  const {
    actionsButton,
    buttonsOfActions,
    handleCloseDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isEmailDialogOpen,
    isSubMenuOpen,
  } = useActionsButton({
    onToggleSelectAll,
    selectedFileIds,
    selectedFolderCode,
  });

  return (
    <>
      <Button
        color="secondary"
        onClick={handleToggleSubMenu}
        ref={actionsButton}
        startIcon={<WaveIcon code="job-files-action-bar-actions" fontSize="small" />}
        {...(isSubMenuOpen && { sx: { bgcolor: 'secondary.dark' } })}
      >
        <Trans i18nKey="lib.actions">Actions</Trans>
      </Button>

      <SubMenu
        anchorEl={actionsButton.current}
        anchorOriginHorizontalSubitem="left"
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={buttonsOfActions}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginHorizontalSubitem="right"
        transformOriginVerticalSubitem="top"
      />

      <SendEmailDialog
        isOpen={isEmailDialogOpen}
        onClose={handleCloseDialog}
        title="lib.email.new"
      />
    </>
  );
}
