import { useMemo } from 'react';

import {
  deleteUserPreference,
  setUserPreferenceDefault,
  updateUserPreference,
} from 'src/store/userSlice';
import { useAppDispatch } from 'src/utilities/hooks/useAppDispatch';
import { useAppSelector } from 'src/utilities/hooks/useAppSelector';

type LegacyActiveFilters = Record<string, string>;
export type ActiveFilters = ActiveFilter[];
export type ActiveFilter = { [alias: string]: string; label: string; textValue: string };
type OtherPreferences = Record<string, boolean | number | string>;
export type PreferenceValue =
  | string
  | string[]
  | ActiveFilters
  | LegacyActiveFilters
  | OtherPreferences;
type SavedPreference = { loading: boolean; value: PreferenceValue } | undefined;
type TransformedPreferenceValue = Exclude<PreferenceValue, LegacyActiveFilters>;
export type Preference = {
  isLoading: boolean;
  set: (preferenceValue: TransformedPreferenceValue) => void;
  value: TransformedPreferenceValue;
};

export function usePreference(code: string, defaultValue: string | string[]) {
  const dispatch = useAppDispatch();
  const cookie = useAppSelector((state) => state.user.cookie);
  const userId = useAppSelector((state) => state.user.details.id);
  const isLoggedIn = !!userId && !!cookie;

  const savedPreference = useAppSelector(
    (state) => state.user.preferences[code],
  ) as SavedPreference;
  const savedPreferenceValue = useMemo(
    () => transformPreferenceValue(savedPreference?.value),
    [savedPreference?.value],
  );

  function transformPreferenceValue(
    savedPreferenceValue?: PreferenceValue,
  ): TransformedPreferenceValue {
    const doesCodeStartWithJob = code.slice(3) === 'job';
    const doesCodeEndWithSer = code.slice(-3) === 'search';
    const isPreferenceLegacyActiveFilters =
      doesCodeStartWithJob &&
      doesCodeEndWithSer &&
      !!savedPreferenceValue &&
      typeof savedPreferenceValue !== 'string' &&
      !Array.isArray(savedPreferenceValue);

    if (isPreferenceLegacyActiveFilters) {
      return mutateLegacyActiveFilters(savedPreferenceValue as LegacyActiveFilters);
    }

    if (savedPreferenceValue !== undefined) return savedPreferenceValue;

    return defaultValue;
  }

  function mutateLegacyActiveFilters(legacyActiveFilters: LegacyActiveFilters): ActiveFilters {
    // Mutate from object to array of objects
    return Object.keys(legacyActiveFilters).map((jobFieldAlias) => ({
      [jobFieldAlias]: legacyActiveFilters[jobFieldAlias],
      label: jobFieldAlias,
      textValue: jobFieldAlias,
    }));
  }

  function setPreference(preferenceValue: TransformedPreferenceValue) {
    if (!isLoggedIn) return;

    const isPreferenceValueDifferent = preferenceValue !== savedPreferenceValue;

    if (!isPreferenceValueDifferent) return;

    const isPreferenceValueDeleted =
      (Array.isArray(preferenceValue) && preferenceValue.length === 0) || preferenceValue === '';

    if (isPreferenceValueDeleted) {
      if (savedPreference?.value) {
        // Delete savedPreference.value and use defaultValue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(deleteUserPreference({ code, defaultValue }));
      } else dispatch(setUserPreferenceDefault({ code, defaultValue }));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else dispatch(updateUserPreference({ code, value: preferenceValue }));
  }

  return {
    isLoading: savedPreference?.loading ?? false,
    set: setPreference,
    value: savedPreferenceValue,
  };
}
