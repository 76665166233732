import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { useJobField } from 'src/features/BulkEditJobFields/components/JobField.tsx/useJobField';
import { FieldMapper2 } from 'src/features/JobForm/components/FieldMapper2';
import { FieldTransformed } from 'src/pages/Job/Job.service';

type JobFieldProps = { activeStep: number; field: FieldTransformed };

export function JobField({
  activeStep,
  field: { alias, description, fieldData, isDisabled, isError, isRequired, name, type },
}: JobFieldProps) {
  const { associatedCheckboxAlias, handleClickAssociatedCheckbox, shouldRender } = useJobField({
    activeStep,
    alias,
  });

  if (!shouldRender) return null;

  return (
    <Grid2
      alignItems="center"
      container
      flexWrap="nowrap"
      gap={1}
      md={4}
      padding={1}
      paddingRight={2}
      sm={6}
      xs={12}
    >
      <FieldMapper2
        alias={associatedCheckboxAlias}
        isDisabled={isDisabled || activeStep === 1}
        onClickAssociatedCheckbox={handleClickAssociatedCheckbox}
        type="boolean"
      />

      <FieldMapper2
        alias={alias}
        description={description}
        fieldData={fieldData}
        isDisabled={isDisabled || activeStep === 1}
        isError={isError}
        isRequired={isRequired}
        name={name}
        type={type}
      />
    </Grid2>
  );
}
