import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { t } from 'i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveIcon } from 'src/features/WaveIcon';

type NutritionLayoutProps = {
  alias: string;
  control: Control<any>;
  setValue: UseFormSetValue<FieldValues>;
};

export function NutritionLayout({ alias, control, setValue }: NutritionLayoutProps) {
  const nutritionTable = [
    { alias: 'energy', component: 'Energy (KJ/kcal)', gram: 0, portion: 0, ri: 0 },
    { alias: 'fat', component: 'Fat (g)', gram: 0, portion: 0, ri: 0 },
    { alias: 'saturated', component: 'of which Saturates (g)', gram: 0, portion: 0, ri: 0 },
    { alias: 'carbohydrates', component: 'Carbohydrates (g)', gram: 0, portion: 0, ri: 0 },
    { alias: 'sugar', component: 'of which Sugars (g)', gram: 0, portion: 0, ri: 0 },
    { alias: 'fibre', component: 'Fibre (g)', gram: 0, portion: 0, ri: 0 },
    { alias: 'protein', component: 'Protein (g)', gram: 0, portion: 0, ri: 0 },
    { alias: 'sodium', component: 'Sodium (g)', gram: 0, portion: 0, ri: 0 },
  ];

  function renderNutritionTable(type: string) {
    const isDisabled = type === 'Master Content' ? true : false;
    const variant = type === 'Master Content' ? 'filled' : 'outlined';
    const isMasterContent = type === 'Master Content';

    return (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              <Trans i18nKey="phrase.nutrititle">Sorting</Trans>
            </TableCell>

            <TableCell>
              <TextField
                control={control}
                disabled={isDisabled}
                label={t('lib.value', 'Value')}
                name={`${alias}.sorting`}
                shouldShowHelperText={false}
                variant={variant}
              />
            </TableCell>

            <TableCell colSpan={2} />
          </TableRow>

          <TableRow>
            <TableCell />

            <TableCell>
              <TextField
                control={control}
                disabled={isDisabled}
                label={t('lib.value', 'Value')}
                name={`${alias}.ri[0]`}
                shouldShowHelperText={false}
                variant={variant}
              />
            </TableCell>

            <TableCell>
              <TextField
                control={control}
                disabled={isDisabled}
                label={t('lib.value', 'Value')}
                name={`${alias}.ri[1]`}
                shouldShowHelperText={false}
                variant={variant}
              />
            </TableCell>

            <TableCell>
              <Trans i18nKey="recommended_intake_percent">%RI</Trans>
            </TableCell>
          </TableRow>

          {nutritionTable.map((nutrition) => {
            return (
              <TableRow key={nutrition.component}>
                <TableCell>{nutrition.component}</TableCell>

                <TableCell>
                  <TextField
                    control={control}
                    disabled={isDisabled}
                    label={t('lib.value', 'Value')}
                    name={`${alias}.${nutrition.alias}`}
                    shouldShowHelperText={false}
                    value={isMasterContent ? nutrition.gram : ''}
                    variant={variant}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    control={control}
                    disabled={isDisabled}
                    label={t('lib.value', 'Value')}
                    name={`${alias}.${nutrition.alias}`}
                    shouldShowHelperText={false}
                    value={isMasterContent ? nutrition.portion : ''}
                    variant={variant}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    control={control}
                    disabled={isDisabled}
                    label={t('lib.value', 'Value')}
                    name={`${alias}.${nutrition.alias}`}
                    shouldShowHelperText={false}
                    value={isMasterContent ? nutrition.ri : ''}
                    variant={variant}
                  />
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell />

            <TableCell>
              <Typography fontWeight="bold" variant="caption">
                <Trans i18nKey="lib.cms.ridesc">RI (Reference Intake) =</Trans>
              </Typography>
            </TableCell>

            <TableCell colSpan={2}>
              <Typography variant="caption">
                <Trans i18nKey="phrase.ritext">
                  Reference amount for an average adult (8400 kJ / 2000 kcal)
                </Trans>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid xs>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              sx={{ backgroundColor: 'primary.main', color: 'white', padding: '5px' }}
              title={
                <Box display="flex" justifyContent="space-between">
                  <Typography component="p">
                    <Trans i18nKey="approval_content_button">Master Content</Trans>
                  </Typography>

                  <IconButton size="small" sx={{ color: 'white' }}>
                    <WaveIcon code="copy" fontSize="small" />
                  </IconButton>
                </Box>
              }
            />

            <CardContent>
              <Box bgcolor="info.main" color="white" display="flex" padding={1}>
                <WaveIcon code="info" />

                <Typography paddingLeft={1}>
                  Energy values must be specified without a decimal, nutritional values with 1
                  decimal (except salinity, this must always be entered with 2 decimals)
                </Typography>
              </Box>

              {renderNutritionTable('Master Content')}
            </CardContent>
          </Card>
        </Grid>

        <Grid xs>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              sx={{ backgroundColor: 'primary.main', color: 'white', padding: '8px' }}
              title={<Typography component="p">Suggestion</Typography>}
            />

            <CardContent sx={{ paddingBottom: '0px !important' }}>
              {renderNutritionTable('Suggestion')}
            </CardContent>
          </Card>
        </Grid>

        <Grid xs>
          <TextField control={control} label={t('lib.msg', 'Comment')} name="comment" type="memo" />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid>
          <Button
            color="warning"
            onClick={() => setValue(`${alias}.type`, 'conditional')}
            startIcon={<WaveIcon code="job-approvals-revisor-status-conditional" />}
            type="submit"
            value="conditional"
          >
            <Trans i18nKey="make_suggestion">Make Suggestion</Trans>
          </Button>
        </Grid>

        <Grid>
          <Button
            color="success"
            onClick={() => setValue(`${alias}.type`, 'approval')}
            startIcon={<WaveIcon code="job-approvals-revisor-status-approval" />}
            type="submit"
            value="approval"
          >
            <Trans i18nKey="accept_supplier_version">Accept Supplier Version</Trans>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
