import { Grid, styled } from '@mui/material';

import { getPaletteColorForJobType } from 'src/components/WaveTheme/utils';

type StyledGridProps = {
  jobType?: string;
};

export const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'jobType',
})<StyledGridProps>`
  min-height: ${({ theme }) => theme.spacing(5)};
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ jobType, theme }) => getPaletteColorForJobType(theme, jobType).main};
  color: ${({ theme }) => theme.palette.common.white};
`;
