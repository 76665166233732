import React from 'react';

import { IconButton, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';
import { ShoppingCartItem as ShoppingCartItemType } from '../../ShoppingCart.service';

type ShoppingCartItemProps = {
  item: ShoppingCartItemType;
  onRemoveClick: (id: number[]) => void;
};

export function ShoppingCartItem({ item, onRemoveClick }: ShoppingCartItemProps) {
  function handleDeleteItem() {
    onRemoveClick([item.id]);
  }

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="flex-start"
      key={item.id}
      padding="5px"
      sx={{ border: '1px solid #ccc' }}
    >
      <Grid px="20px" xs={2}>
        <Typography color="primary" variant="subtitle2">
          {item.jobid}
        </Typography>
      </Grid>

      <Grid px="20px" xs={4}>
        <Typography color="primary" variant="subtitle2">
          {item.keyword}
        </Typography>
      </Grid>

      <Grid xs={5}>
        <Typography color="primary" flexGrow={1} variant="subtitle2">
          {item.filename}
        </Typography>
      </Grid>

      <Grid>
        <IconButton onClick={handleDeleteItem}>
          <WaveIcon code="delete" color="primary" fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
