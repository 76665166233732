import { createApi } from '@reduxjs/toolkit/query/react';

import { ClipboardData } from 'src/features/JobForm/components/CopyCompareButton/type';
import { axiosBaseQuery } from 'src/utilities/baseQuery';

type Fields = {
  alias: string;
  description: string;
  name: string;
  typ: string;
  field_data: Record<string, string>;
  is_disable: boolean;
  is_hidden: boolean;
  is_mandatory: boolean;
  is_error: boolean;
};

type Values = {
  name: string;
  type: string;
  value: boolean;
};

export type CompareReplaceOptions = {
  fields: Record<string, Fields>;
  values: Record<string, Values>;
};

type CompareReplaceBaseParams = {
  id?: number;
  mand?: number;
};

type UpdateCompareReplaceOptionsParams = CompareReplaceBaseParams & {
  val: Record<string, number>;
};

type GetCompareReplaceTemplatesParams = CompareReplaceBaseParams & {
  src?: string;
};

export type CompareReplaceTemplate = {
  id: number;
  mand: number;
  name: string;
  src: string;
  user_id: number;
  val: ClipboardData;
};

type CreateCompareReplaceTemplateParams = {
  src?: string;
  sections: any;
  name: string;
};

type UpdateCompareReplaceTemplateParams = CreateCompareReplaceTemplateParams & {
  templateId?: number;
};

type DeleteCompareReplaceTemplateParams = CompareReplaceBaseParams & {
  templateId?: number;
  src?: string;
};

export const compareReplaceApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createCompareReplaceTemplate: build.mutation<
      { message: string },
      CreateCompareReplaceTemplateParams
    >({
      invalidatesTags: ['CompareReplaceTemplates'],
      query({ name, sections, src }) {
        return {
          data: { sections, src, template_name: name },
          method: 'POST',
          url: '/clipboard/templates',
        };
      },
    }),
    deleteCompareReplaceTemplate: build.mutation<
      { message: string },
      DeleteCompareReplaceTemplateParams
    >({
      invalidatesTags: ['CompareReplaceTemplates'],
      query({ src, templateId }) {
        return {
          method: 'DELETE',
          params: { src },
          url: `/clipboard/templates/${templateId}`,
        };
      },
    }),
    getCompareReplaceOptions: build.query<CompareReplaceOptions, CompareReplaceBaseParams>({
      providesTags: ['CompareReplaceOptions'],
      query() {
        return {
          method: 'GET',
          url: '/clipboard-options',
        };
      },
    }),
    getCompareReplaceTemplates: build.query<
      Array<CompareReplaceTemplate>,
      GetCompareReplaceTemplatesParams
    >({
      providesTags: ['CompareReplaceTemplates'],
      query({ src }) {
        return {
          method: 'GET',
          params: { src },
          url: '/clipboard/templates',
        };
      },
    }),
    updateCompareReplaceOptions: build.mutation<
      { message: string },
      UpdateCompareReplaceOptionsParams
    >({
      invalidatesTags: ['CompareReplaceOptions'],
      query({ val }) {
        return {
          method: 'PUT',
          params: { val },
          url: '/clipboard-options',
        };
      },
    }),
    updateCompareReplaceTemplate: build.mutation<
      { message: string },
      UpdateCompareReplaceTemplateParams
    >({
      invalidatesTags: ['CompareReplaceTemplates'],
      query({ name, sections, src, templateId }) {
        return {
          data: { sections, src, template_name: name },
          method: 'PUT',
          url: `/clipboard/templates/${templateId}`,
        };
      },
    }),
  }),
  reducerPath: 'compareReplaceApi',
  tagTypes: [
    'CompareReplaceOptions',
    'UpdateCompareReplaceOptionsParams',
    'CompareReplaceTemplates',
  ],
});

export const {
  useCreateCompareReplaceTemplateMutation,
  useDeleteCompareReplaceTemplateMutation,
  useGetCompareReplaceOptionsQuery,
  useGetCompareReplaceTemplatesQuery,
  useUpdateCompareReplaceOptionsMutation,
  useUpdateCompareReplaceTemplateMutation,
} = compareReplaceApi;
