import { useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';

import { BlocksFieldRow } from 'src/features/JobForm/components/BlocksFieldRow';
import {
  getAreAllFieldsDisabled,
  getBlockFieldNames,
  getMovedFieldValues,
  getNormalisedFieldsValues,
  getNumberOfFieldRows,
} from 'src/features/JobForm/components/BlocksFieldSection/utils';
import { Block, useJobContext } from 'src/pages/Job/JobContext';

import { BlockMoveType } from 'src/features/JobForm/components/BlocksFieldSection/types';

type BlocksFieldSectionProps = {
  block: Block;
};

export function BlocksFieldsSection({ block }: BlocksFieldSectionProps) {
  const isTable = !!block.table;
  const addBlockRowMethods = useForm();
  const { fields, handleChangeLogicBuilderField, handleChangeSteeredField, isJobFetching } =
    useJobContext();
  const { control, getValues, setValue } = useFormContext();
  const fieldNames = getBlockFieldNames(block, fields);
  const scrollRefs = useRef<(HTMLDivElement | null)[]>([]);
  const fieldValues = useWatch({
    control,
    name: fieldNames,
  });
  const normalisedFieldValues = getNormalisedFieldsValues(fieldValues);
  const numberOfFieldsInBlock = getNumberOfFieldRows(normalisedFieldValues);
  const areAllFieldsDisabled = getAreAllFieldsDisabled(fieldNames, fields);

  function handleRemoveBlockRow(fieldValueIndex: number) {
    const filteredValues = normalisedFieldValues.map((field) =>
      field?.filter((_: any, index: number) => index !== fieldValueIndex),
    );

    fieldNames.forEach((fieldName, index) => {
      const filteredValue = filteredValues[index];

      setValue(fieldName, filteredValue, { shouldDirty: true });
    });
  }

  function handleMoveBlockRow(direction: BlockMoveType, fieldValueIndex: number) {
    const movedFieldValues = getMovedFieldValues(direction, normalisedFieldValues, fieldValueIndex);

    fieldNames.forEach((fieldName, index) => {
      const movedValue = movedFieldValues[index];

      setValue(fieldName, movedValue, { shouldDirty: true });
    });
  }

  function handleAddBlockRow() {
    const values = addBlockRowMethods.watch(fieldNames);

    fieldNames.forEach((fieldName, index) => {
      const newValue = values[index] || null;
      const fieldValue = normalisedFieldValues[index] ?? [];

      setValue(fieldName, [...fieldValue, newValue], { shouldDirty: true });
      addBlockRowMethods.reset();
    });
  }

  useEffect(() => {
    function syncScroll(sourceRef: HTMLDivElement) {
      const { scrollLeft } = sourceRef;

      scrollRefs.current.forEach((ref) => {
        if (ref && ref !== sourceRef) {
          ref.scrollLeft = scrollLeft;
        }
      });
    }

    function handleScroll(event: Event) {
      const sourceRef = event.target as HTMLDivElement;

      syncScroll(sourceRef);
    }

    scrollRefs.current.forEach((ref) => {
      ref?.addEventListener('scroll', handleScroll);
    });

    return () => {
      scrollRefs.current.forEach((ref) => {
        ref?.removeEventListener('scroll', handleScroll);
      });
    };
  }, [numberOfFieldsInBlock]);

  const setScrollRefs = useMemo(() => {
    return Array.from({ length: numberOfFieldsInBlock + 1 }).map((_, index) => {
      return (element: HTMLDivElement | null) => {
        scrollRefs.current[index] = element;
      };
    });
  }, [numberOfFieldsInBlock]);

  if (fieldNames.length === 0) return null;

  useEffect(() => {
    fieldNames.forEach((fieldName) => {
      if (!getValues(fieldName)) setValue(fieldName, [''], { shouldDirty: true });
    });
  }, []);

  return (
    <>
      {Array.from({ length: numberOfFieldsInBlock }).map((_, index) => (
        <BlocksFieldRow
          block={block}
          fields={fields}
          fieldValueIndex={index}
          handleChangeLogicBuilderField={handleChangeLogicBuilderField}
          handleChangeSteeredField={handleChangeSteeredField}
          isDisabled={areAllFieldsDisabled}
          isFirstBlockRow={index === 0}
          isJobFetching={isJobFetching}
          isLastBlockRow={index === numberOfFieldsInBlock - 1}
          isTable={isTable}
          key={`block_field_row_${index}`}
          onMoveBlockRow={handleMoveBlockRow}
          onRemoveBlockRow={handleRemoveBlockRow}
          scrollRef={setScrollRefs[index]}
        />
      ))}

      <FormProvider {...addBlockRowMethods}>
        <BlocksFieldRow
          block={block}
          fields={fields}
          handleChangeLogicBuilderField={handleChangeLogicBuilderField}
          handleChangeSteeredField={handleChangeSteeredField}
          isDisabled={areAllFieldsDisabled}
          isFirstBlockRow={numberOfFieldsInBlock === 0}
          isJobFetching={isJobFetching}
          isNewBlockRow
          isTable={isTable}
          onAddBlockRow={handleAddBlockRow}
          scrollRef={setScrollRefs[numberOfFieldsInBlock]}
        />
      </FormProvider>
    </>
  );
}
