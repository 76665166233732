import { ContentCategoriesList } from './Content.types';

function containsAllElements(source: string[], target: string[]) {
  return target.every((element) => source.includes(element));
}

export function getCategoriesList(
  categoriesList: ContentCategoriesList[],
  getCurrentlySelectedLayouts: (categoryId: string) => string[],
) {
  return categoriesList
    .filter((category) => {
      const currentlySelectedLayouts = getCurrentlySelectedLayouts(category.code);

      return !containsAllElements(currentlySelectedLayouts, Object.keys(category.layouts));
    })
    .map((category) => {
      const currentlySelectedLayouts = getCurrentlySelectedLayouts(category.code);

      return {
        ...category,
        layouts: Object.fromEntries(
          Object.entries(category.layouts).filter(
            ([key]) => !currentlySelectedLayouts.includes(key),
          ),
        ),
      };
    });
}
