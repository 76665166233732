import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@mui/material';

type RadioGroupProps = {
  children: ReactNode;
  defaultValue?: any;
  name: string;
  control: any;
  MuiRadioGroupProps?: MuiRadioGroupProps;
  onChange?: (value: any) => void;
};

export function RadioGroup({
  MuiRadioGroupProps,
  children,
  control,
  defaultValue,
  name,
  onChange,
}: RadioGroupProps) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange: fieldOnChange, value } }) => {
        return (
          <MuiRadioGroup
            onChange={(event) => {
              fieldOnChange(event);
              if (onChange) onChange(event.target.value);
            }}
            value={value}
            {...(MuiRadioGroupProps && { ...MuiRadioGroupProps })}
          >
            {children}
          </MuiRadioGroup>
        );
      }}
    />
  );
}
