import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { PropsOfActionsButton } from 'src/features/JobsTable/components/ActionsButton/ActionsButton';
import { useLazyDownloadJobsQuery } from 'src/features/JobsTable/JobsTable.service';
import { useJobTableVariants } from 'src/features/JobsTable/utilities/hooks';
import { BulkEditType } from 'src/features/JobsTable/utilities/hooks/useJobs';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';

export type SavedViewSearchDialogVariantData = {
  code: SavedViewSearchCode;
  text: string;
};
type SavedViewSearchCode = 'saved-searches' | 'saved-views';
type PropsOfUseActionsButton = Pick<
  PropsOfActionsButton,
  'dashboardUserId' | 'selectedRows' | 'setBulkEditType'
>;

export function useActionsButton({
  dashboardUserId,
  selectedRows,
  setBulkEditType,
}: PropsOfUseActionsButton) {
  const { age, isSubJobsTable, jobType } = useJobTableVariants();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [dialogVariantData, setDialogVariantData] = useState<SavedViewSearchDialogVariantData>();
  const [isViewSearchDialogOpen, setIsViewSearchDialogOpen] = useState(false);

  const [downloadJobs] = useLazyDownloadJobsQuery();

  function handleSubActions(codes: string[], text?: string) {
    const subActionType = codes[0];
    const isBulkEdit = subActionType === 'bulk';
    const isExportJobs = subActionType === 'export';
    const isSavedViewSearch = subActionType === 'saved';

    if (isBulkEdit) {
      const bulkEditType = codes[1] as BulkEditType;
      const isProjectBulkEdit = bulkEditType === 'project';

      setBulkEditType(bulkEditType);

      if (isProjectBulkEdit) {
        navigate(`/jobs-job-${jobType}/project-bulk-edit/assign`, {
          state: { isProjectBulkEdit, selectedJobIds: selectedRows },
        });
      }
    }

    if (isExportJobs) handleClickExports(codes);

    if (isSavedViewSearch && text) {
      const savedType = codes[1] as 'searches' | 'views';
      const code: SavedViewSearchCode = `${subActionType}-${savedType}`;

      handleOpenViewSearchDialog(code, text);
    }

    handleCloseSubMenu();
  }

  function handleClickExports(codes: string[]) {
    const fileType = codes[1] !== 'excel' ? 'csv' : ('xlsx' as const);
    const shouldSendByEmail = codes[2] === 'email';

    downloadJobs({
      age,
      dashboardUserId,
      fileType,
      jobType,
      shouldSendByEmail,
    })
      .unwrap()
      .then(({ url }) => {
        if (!shouldSendByEmail) window.open(`${import.meta.env.VITE_API}${url}`);
        else {
          dispatch(
            openWaveSnack({
              message: 'Request added to email queue',
              type: 'success',
            }),
          );
        }
      });
  }

  function handleOpenViewSearchDialog(code: SavedViewSearchCode, text: string) {
    setDialogVariantData({ code, text });
    setIsViewSearchDialogOpen(true);
  }

  function handleCloseViewSearchDialog() {
    setIsViewSearchDialogOpen(false);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  function handleOpenSubMenu({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(currentTarget);
    setIsSubMenuOpen(true);
  }

  return {
    age,
    anchorEl,
    dialogVariantData,
    handleCloseSubMenu,
    handleCloseViewSearchDialog,
    handleOpenSubMenu,
    handleSubActions,
    isSubJobsTable,
    isSubMenuOpen,
    isViewSearchDialogOpen,
  };
}
