import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FormData, PasswordConfirm } from 'src/features/PasswordConfirm';
import {
  useCheckPasswordTokenQuery,
  useUpdatePasswordMutation,
} from 'src/features/UserDetails/components/ChangePasswordDialog/ChangePassword.service';
import { Spinner } from '../../components/Spinner';
import fiveFlow from '../../images/5Flow.svg';
import { openWaveSnack } from '../../store/waveSnackSlice';
import { useRouteParams } from '../../utilities/hooks';

import waveLogoSlogan from '../../images/waveLogoSlogan.png';

export function UpdatePassword() {
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      confirmPassword: '',
      newPassword: '',
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  const { installationLogo } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { secretKey } = useRouteParams();
  const { error, isError, isSuccess } = useCheckPasswordTokenQuery({
    secretKey: secretKey ?? '',
  });
  const [updatePassword] = useUpdatePasswordMutation();

  async function onSubmit({ confirmPassword, newPassword }: FormData) {
    const token = await recaptchaRef.current?.executeAsync();

    if (!token) return;

    setIsLoading(true);

    await updatePassword({ confirmPassword, newPassword, secretKey: secretKey ?? '' })
      .unwrap()
      .then((message) => {
        setIsLoading(false);
        dispatch(
          openWaveSnack({
            message,
            type: 'success',
          }),
        );
        navigate('/login');
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      });
  }

  useEffect(() => {
    if (isError) {
      navigate('/login');
      dispatch(
        openWaveSnack({
          message: error.message,
          type: 'error',
        }),
      );
      setIsLoading(false);
    } else if (isSuccess) {
      setIsLoading(false);
    }
  }, [isError, isSuccess]);

  return isLoading ? (
    <Spinner />
  ) : (
    <Box className="public-background vh-100" display="flex" flexDirection="column">
      <Box className="ml-20 mt-20 my-20 p-absolute">
        <img alt="5Flow Logo" className="w-100" src={fiveFlow} />
      </Box>

      <Box alignItems="center" alignSelf="center" className="updatePasswordBox" display="flex">
        <Box display="flex">
          <Box
            className="b-radius-bl-10 b-radius-tl-10 primary-bg w-200"
            display="flex"
            flexDirection="column"
          >
            <Box className="m-5">
              <img alt="Wave Logo Slogan" className="w-100" src={waveLogoSlogan} />
            </Box>

            <Box alignSelf="center" className="d-flex flex-grow-1 w-100p">
              {installationLogo && (
                <img
                  alt="Installation Logo"
                  className="contain-image p-10 w-100p"
                  src={installationLogo}
                />
              )}
            </Box>
          </Box>

          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              className="filler-bg b-radius-br-10 b-radius-tr-10"
              display="flex"
              flexDirection="column"
              gap={2}
              padding={3}
            >
              <PasswordConfirm control={control} trigger={trigger} watch={watch} />

              <Box display="flex" justifyContent="flex-end">
                <Button disabled={!isDirty || !isValid} type="submit">
                  <Trans i18nKey="lib.confirm">Cancel</Trans>
                </Button>
              </Box>
            </Box>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={
                import.meta.env.VITE_RECAPTCHA_KEY || '6LcDyK4pAAAAAL1YKcS34HM7Psy8Ez6Xij3rz9r5'
              }
              size="invisible"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
