import { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CompareReplaceDropdownButton } from 'src/features/CompareReplaceDialog/components/CompareReplaceDropdownButton';
import { WaveIcon } from 'src/features/WaveIcon';

const StyledHeaderContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(2)};
`;

type CompareReplaceHeaderBar = {
  onCollapseAll: () => void;
  onExpandAll: () => void;
  onOpenSaveTemplate: () => void;
  onOpenDeleteTemplate: () => void;
  onOpenRenameTemplate: () => void;
  percentAccordionsExpanded: number;
  shouldHideSameValues: boolean;
  onToggleHideSameValues: () => void;
  searchTerm: string;
  onSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
  isSaveTemplate: boolean;
  isClipboard: boolean;
};

export function CompareReplaceHeaderBar({
  isClipboard,
  isSaveTemplate,
  onCollapseAll,
  onExpandAll,
  onOpenDeleteTemplate,
  onOpenRenameTemplate,
  onOpenSaveTemplate,
  onSearchTerm,
  onToggleHideSameValues,
  percentAccordionsExpanded,
  searchTerm,
  shouldHideSameValues,
}: CompareReplaceHeaderBar) {
  const { t } = useTranslation();
  const shouldCollapseAll = percentAccordionsExpanded >= 50;

  return (
    <StyledHeaderContainer>
      <Box display="flex">
        <Box marginRight={2} width={300}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WaveIcon code="search" />
                </InputAdornment>
              ),
            }}
            label={t('search_fields_field', 'Search Fields')}
            onChange={onSearchTerm}
            size="small"
            value={searchTerm}
          />
        </Box>

        {!isSaveTemplate && (
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldHideSameValues}
                onClick={onToggleHideSameValues}
                value={shouldHideSameValues}
              />
            }
            label={t('hide_same_values', 'Hide Same Values')}
          />
        )}
      </Box>

      <Box alignItems="center" display="flex">
        <Box marginRight={2}>
          <Button
            onClick={shouldCollapseAll ? onCollapseAll : onExpandAll}
            startIcon={<WaveIcon code={shouldCollapseAll ? 'expand-less' : 'expand-more'} />}
          >
            {shouldCollapseAll
              ? t('lib.collapseall', 'Collapse All')
              : t('lib.expandall', 'Expand All')}
          </Button>
        </Box>

        {!isSaveTemplate && isClipboard && (
          <Button onClick={onOpenSaveTemplate} startIcon={<WaveIcon code="save" />}>
            <Trans i18next="save_template">Save Template</Trans>
          </Button>
        )}

        {!isSaveTemplate && !isClipboard && (
          <CompareReplaceDropdownButton
            onOpenDeleteTemplate={onOpenDeleteTemplate}
            onOpenRenameTemplate={onOpenRenameTemplate}
            onOpenSaveTemplate={onOpenSaveTemplate}
          />
        )}
      </Box>
    </StyledHeaderContainer>
  );
}
