import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type IFrameRequest = {
  id?: number;
  tabcode?: string;
  age?: string;
  src?: string;
  jobid?: string | number;
  sessionId: string | null;
};

type IFrameResponse = {
  token: string;
  url: string;
};

export const iFrameApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    authenticateIFrame: build.mutation<IFrameResponse, IFrameRequest>({
      query({ age, jobid, sessionId, src, tabcode }) {
        return {
          method: 'POST',
          params: { age, jobid, session_id: sessionId, src, tabcode },
          url: `/tabs/${tabcode}/authenticate`,
        };
      },
    }),
  }),
  reducerPath: 'iFrameApi',
});

export const { useAuthenticateIFrameMutation } = iFrameApi;
