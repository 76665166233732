import { useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';

import { DatePickerProps } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useDateFormat } from 'src/utilities/hooks';

type DateTimeFieldOwnProps = {
  isRawText?: boolean;
  isReadOnly?: boolean;
};

// Needed to provide correct height for empty fields.
const EMPTY_STRING_FALLBACK = '\u00A0';

export function DateTime<T extends FieldValues>({
  control,
  disabled,
  isRawText,
  isReadOnly,
  label,
  name,
  onChange,
  rules,
  ...rest
}: DatePickerProps<T> & DateTimeFieldOwnProps) {
  const { formatDate } = useDateFormat();

  const [isOpen, setIsOpen] = useState(false);

  const {
    field: { onChange: onFieldChange, ref, value, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  function handleCloseCalendarOverlay() {
    setIsOpen(false);
  }

  function handleOpenCalendarOverlay() {
    setIsOpen(true);
  }

  if (isRawText) {
    return <Typography>{value || ''}</Typography>;
  }

  if (isReadOnly) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography>
          {value ? formatDate('lib.datetime.long', value) : EMPTY_STRING_FALLBACK}
        </Typography>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        disabled={disabled}
        inputRef={ref}
        label={label}
        onChange={(event) => {
          onFieldChange(event);

          if (onChange) {
            onChange(event);
          }
        }}
        onClose={handleCloseCalendarOverlay}
        onOpen={handleOpenCalendarOverlay}
        open={isOpen}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={handleOpenCalendarOverlay}
            required={Boolean(rules?.required)}
            size="small"
            sx={{
              svg: { color: 'primary.main' },
            }}
          />
        )}
        value={value ? formatDate('lib.datetime.long', value) : null}
        {...field}
        {...rest}
      />
    </LocalizationProvider>
  );
}
