import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import zxcvbn from 'zxcvbn';

import { Box, Typography } from '@mui/material';

type PasswordMeter = {
  newPassword: string;
};

export type PasswordStrength = 'weak' | 'fair' | 'good' | 'strong';

export function PasswordMeter({ newPassword }: PasswordMeter) {
  const [progressClass, setProgressClass] = useState('');
  const [score, setScore] = useState(0);

  useEffect(() => {
    const _score = zxcvbn(newPassword).score;
    const passwordStrength: PasswordStrength =
      _score === 2 ? 'fair' : _score === 3 ? 'good' : _score === 4 ? 'strong' : 'weak';

    setProgressClass(`password-strength-meter-progress strength-${passwordStrength}`);
    setScore(_score);
  }, [newPassword]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box className={progressClass} component="progress" max="4" value={score} />

      <Typography variant="caption">
        <Trans i18nKey="pwd.strength">Password Strength</Trans>
      </Typography>
    </Box>
  );
}
