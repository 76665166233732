import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type UpdateActiveClientRequest = { clientId: string };

export const userMenuApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    updateActiveClient: build.mutation<unknown, UpdateActiveClientRequest>({
      query({ clientId }) {
        return {
          data: { mand: clientId },
          method: 'POST',
          url: '/clients/switchclient',
        };
      },
    }),
  }),
  reducerPath: 'userMenuApi',
});

export const { useUpdateActiveClientMutation } = userMenuApi;
