import { UploadFileResponse as UploadGeneralFileResponse } from 'src/features/FileExplorer/FileExplorer.service';
import { UploadFileResponse as UploadJobFileResponse } from 'src/features/JobFiles/JobFiles.service';
import { Responses } from 'src/features/UploadFiles/components/Dialog/useDialog';

export function computeSuccessfulUploadData(responses: Responses) {
  let areAllUploadsSuccessful = true;
  const filenamesOfSuccessfulUploads: string[] = [];
  const indexesOfSuccessfulUploads: number[] = [];

  responses.forEach((response, index) => {
    const isUploadSuccessful = 'data' in response;

    if (isUploadSuccessful) {
      filenamesOfSuccessfulUploads.push(extractFilenameFromResponse(response.data));
      indexesOfSuccessfulUploads.push(index);
    } else {
      areAllUploadsSuccessful = false;
    }
  });

  return { areAllUploadsSuccessful, filenamesOfSuccessfulUploads, indexesOfSuccessfulUploads };
}

function extractFilenameFromResponse(response: UploadGeneralFileResponse | UploadJobFileResponse) {
  const isUploadGeneralFileResponse = typeof response === 'string';

  if (isUploadGeneralFileResponse) {
    const [, filename] = response.split(': ');

    return filename;
  } else return response[0].filename;
}
