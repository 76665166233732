import { Autocomplete, CircularProgress, TextField } from "@mui/material";

import {
  isObject,
  objectToOrderedArray,
} from "../../../../utilities/helperFunctions";

export function AutoComplete({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  loadingCascadingFields,
  onChange,
  options: propOptions,
  required,
  value,
}) {
  const loadingOptions = loadingCascadingFields.includes(id);
  const options = isObject(propOptions) // HubX color field returns an empty object so we need this until changed in BE
    ? objectToOrderedArray(
        value && !propOptions.hasOwnProperty(value)
          ? { ...propOptions, [value]: value }
          : propOptions
      )
    : propOptions;

  function handleOnChange(e, newValue) {
    e.target = {
      ...[e.target],
      id: id,
      name: label,
      value: newValue?.value || null,
    };

    onChange(e);
  }

  return (
    <div className="p-relative flex-grow-1">
      {loadingOptions && (
        <CircularProgress size={24} className="buttonProgress" />
      )}
      <Autocomplete
        blurOnSelect
        disabled={loadingOptions || disabled}
        disablePortal
        fullWidth
        id={id}
        isOptionEqualToValue={(option, value) => option.value === value}
        onChange={handleOnChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            error={(checkRequiredFields && required && !value) || isError}
            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
            label={label}
            required={required}
          />
        )}
        size="small"
        value={value}
      />
    </div>
  );
}
