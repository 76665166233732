import { FormProvider } from 'react-hook-form';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { Box, Skeleton, Step, StepLabel, Stepper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { ActionBarButton } from 'src/features/BulkEditJobFields/components/ActionBarButton';
import { JobField } from 'src/features/BulkEditJobFields/components/JobField.tsx';
import { JobId } from 'src/features/BulkEditJobFields/components/JobId';
import { useBulkEditJobFields } from 'src/features/BulkEditJobFields/useBulkEditJobFields';
import { SelectedRows } from 'src/features/JobsTable/utilities/hooks/useJobs';

type AutoSizerProps = { height: string | number; width: string | number };
export type BulkEditJobFieldsProps = {
  onBulkEditSuccess: () => void;
  onCancelBulkEdit: () => void;
  selectedRows: SelectedRows;
};

export function BulkEditJobFields({
  onBulkEditSuccess,
  onCancelBulkEdit,
  selectedRows,
}: BulkEditJobFieldsProps) {
  const {
    actionBar,
    activeStep,
    codesOfButtonsThatShouldBeVisible,
    contentAreaHeight,
    fields,
    handleClickActionBarButton,
    onSubmit,
    useFormReturn,
  } = useBulkEditJobFields({ onBulkEditSuccess, onCancelBulkEdit, selectedRows });

  return (
    <FormProvider {...useFormReturn}>
      <Box component="form" display="flex" flexDirection="column" flexGrow={1} onSubmit={onSubmit}>
        <Grid2 container id="actionBar">
          <Grid2 container justifyContent="end" marginTop={2} xs={8}>
            <Grid2 xs={8}>
              {!actionBar ? (
                <Skeleton height={40} variant="rounded" />
              ) : (
                <Stepper activeStep={activeStep} alternativeLabel>
                  {actionBar.steps.map(({ code, name }) => (
                    <Step key={code}>
                      <StepLabel>{name}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </Grid2>
          </Grid2>

          <Grid2 container justifyContent="end" xs>
            {!actionBar
              ? [...Array(2)].map((_, index) => (
                  <Grid2 key={index} marginX={1} marginY={2}>
                    <Skeleton height={40} variant="rounded" width={90} />
                  </Grid2>
                ))
              : actionBar.buttons.map((button) => (
                  <ActionBarButton
                    button={button}
                    codesOfButtonsThatShouldBeVisible={codesOfButtonsThatShouldBeVisible}
                    key={button.code}
                    onClickActionBarButton={handleClickActionBarButton}
                  />
                ))}
          </Grid2>
        </Grid2>

        <Grid2 container flexGrow={1} xs>
          {!fields ? (
            [...Array(30)].map((_, index) => (
              <Grid2 key={index} md={4} padding={1} sm={6} xs={12}>
                <Skeleton height={40} variant="rounded" />
              </Grid2>
            ))
          ) : (
            <Box display="flex" flexGrow={1} height={contentAreaHeight}>
              {activeStep === 1 ? (
                <Box flex="none" width={175}>
                  <AutoSizer>
                    {({ height, width }: AutoSizerProps) => (
                      <FixedSizeList
                        height={height}
                        itemCount={selectedRows.length}
                        itemData={{ selectedRows }}
                        itemSize={35}
                        width={width}
                      >
                        {JobId}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                </Box>
              ) : null}

              <Grid2 alignItems="flex-start" container flexGrow={1}>
                {Object.values(fields).map((field) => (
                  <JobField activeStep={activeStep} field={field} key={field.alias} />
                ))}
              </Grid2>
            </Box>
          )}
        </Grid2>
      </Box>
    </FormProvider>
  );
}
