import { Fragment } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  Divider,
} from '@mui/material';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useDialog } from 'src/features/UploadFiles/components/Dialog/useDialog';
import { FileGroup } from 'src/features/UploadFiles/components/FileGroup';

export type DialogProps = {
  destination: string;
  files: FileList | null;
  isOpen: boolean;
  onCloseDialog: (isSubmitting: boolean) => void;
  variant: 'jobForm' | 'fileExplorer';
};

export function Dialog({ destination, files, isOpen, onCloseDialog, variant }: DialogProps) {
  const {
    categories,
    control,
    fileGroups,
    handleDeleteFiles,
    isSubmitting,
    onSubmit,
    shouldGetCategories,
    translate,
  } = useDialog({
    destination,
    files,
    isOpen,
    onCloseDialog,
    variant,
  });

  function handleCloseDialog() {
    onCloseDialog(isSubmitting);
  }

  function handleClickCancel() {
    onCloseDialog(false);
  }

  return (
    <MuiDialog fullWidth={true} maxWidth="md" onClose={handleCloseDialog} open={isOpen}>
      <WaveDialogTitle onClose={handleCloseDialog} title={translate('lib.file.up')} />

      <form onSubmit={onSubmit}>
        <DialogContent>
          <Card>
            {fileGroups.map(({ file }, index) => {
              const isLastFile = index === fileGroups.length - 1;

              return (
                <Fragment key={file.name}>
                  <CardContent {...(index % 2 !== 0 && { sx: { bgcolor: 'oddRow.main' } })}>
                    <FileGroup
                      categories={categories}
                      control={control}
                      file={file}
                      index={index}
                      isOneFile={fileGroups.length === 1}
                      isSubmitting={isSubmitting}
                      onDeleteFiles={handleDeleteFiles}
                      shouldRenderCategorySelection={shouldGetCategories}
                    />
                  </CardContent>

                  {!isLastFile ? <Divider /> : null}
                </Fragment>
              );
            })}
          </Card>
        </DialogContent>

        <DialogActions className="space-between">
          <Button color="warning" disabled={isSubmitting} onClick={handleClickCancel}>
            {translate('lib.cancel')}
          </Button>

          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            {translate('lib.confirm')}
          </LoadingButton>
        </DialogActions>
      </form>
    </MuiDialog>
  );
}
