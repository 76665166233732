import { Theme } from '@mui/material/styles';

import { Accordion } from './Accordion';
import { Button } from './Button';
import { Dialog } from './Dialog';
import { Tabs } from './Tabs';
import { TextField } from './TextField';

export function componentsOverrides(theme: Theme) {
  return Object.assign(Button(), Accordion(theme), Dialog(theme), Tabs(), TextField());
}
