import { Trans } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { ActiveFilter } from 'src/features/ActiveFilters/components/ActiveFilter';
import { WaveIcon } from 'src/features/WaveIcon';
import { ActiveFilter as ActiveFilterType, ActiveFilters } from 'src/utilities/hooks/usePreference';

import { Filter } from 'src/features/History/History.types';

type ActiveFiltersProps = {
  filterLabel?: string;
  activeFilters: ActiveFilters | Filter[];
  onDeleteActiveFilter: (filter: ActiveFilterType) => void;
  onDeleteAllActiveFilters: () => void;
};

export function ActiveFilters({
  activeFilters,
  filterLabel,
  onDeleteActiveFilter,
  onDeleteAllActiveFilters,
}: ActiveFiltersProps) {
  if (!activeFilters.length) return null;

  return (
    <Box alignItems="center" display="flex" gap={1} my={2}>
      {activeFilters.length > 1 ? (
        <Button onClick={onDeleteAllActiveFilters} startIcon={<WaveIcon code="close" />}>
          <Trans i18nKey="remove_all_button">Remove all</Trans>
        </Button>
      ) : null}

      <Box display="flex" flexWrap="wrap" gap={1}>
        {activeFilters.map((filter) => (
          <ActiveFilter
            filter={filter}
            filterLabel={filterLabel}
            key={'textValue' in filter ? filter.textValue : filter.text}
            onDeleteActiveFilter={onDeleteActiveFilter}
          />
        ))}
      </Box>
    </Box>
  );
}
