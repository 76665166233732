// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { naturalSort } from 'src/utilities/helperFunctions';
import { API } from '../utilities/api';
import { openWaveSnack } from './waveSnackSlice';

export type MainMenus = Array<MainMenu>;

export type MainMenu = {
  code: string;
  link: string;
  name: string;
  subitems?: MainMenus;
};

type InitialState = {
  clients: Record<string, string>;
  cookie: string | null;
  details: {
    avatar_url: string;
    department: string;
    firstname: string;
    lastname: string;
    user: string;
    id?: number;
  };
  loading: boolean;
  preferences: Record<string, { loading: boolean; value: string }>;
  rights: Rights;
  sessionID: string | null;
  mainMenu: MainMenus;
};

type Rights = { delete: string[]; edit: string[]; insert: string[]; read: string[] };

export const deleteUserPreference = createAsyncThunk(
  'user/deleteUserPreference',
  async ({ code, defaultValue }, thunkAPI) => {
    await API.delete('/preferences/delete', {
      onError: (error) => {
        thunkAPI.dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      },
      params: { code },
    });

    return { code, defaultValue };
  },
);

export const getAUserRight = createAsyncThunk<void, string>(
  'getAUserRight',
  async (userRightCode, thunkAPI) => {
    const {
      data: { data },
    } = await API.get('/rights/get', {
      onError: (error) => {
        thunkAPI.dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      },
      params: {
        code: userRightCode,
      },
    });

    return data;
  },
);

export const getExternalRevisorDetails = createAsyncThunk(
  'user/getExternalRevisorDetails',
  async (thunkAPI) => {
    const {
      data: { data },
    } = await API.get('/external-revisor/getfulluserdata', {
      onError: ({ message }) => {
        thunkAPI.dispatch(
          openWaveSnack({
            message: message,
            type: 'error',
          }),
        );
      },
    });

    const preferences = Object.entries(data.preferences).reduce(
      (preferences, preference) => ({
        // TODO: This reduce shouldn't spread on every loop
        ...preferences,
        [preference[0]]: {
          loading: false,
          value: preference[1],
        },
      }),
      {},
    );

    return { ...data, preferences };
  },
);

export const getUserDetails = createAsyncThunk('user/getUserDetails', async (thunkAPI) => {
  const {
    data: { data },
  } = await API.get('/users/getfulluserdata', {
    onError: (error) => {
      thunkAPI.dispatch(
        openWaveSnack({
          message: error.message,
          type: 'error',
        }),
      );
    },
  });

  const details = {
    ...data.details,
    avatar_url: data.details.avatar_url
      ? `/users/${data.details.id}/avatar#timestamp=${Date.now()}`
      : '',
  };

  const clients = Object.values(data.clients).reduce(
    (clients, client) => ({
      ...clients,
      [client.id]: client.name_en,
    }),
    {},
  );

  const preferences = Object.entries(data.preferences).reduce(
    (preferences, preference) => ({
      // TODO: This reduce shouldn't spread on every loop
      ...preferences,
      [preference[0]]: {
        loading: false,
        value: preference[1],
      },
    }),
    {},
  );

  return { ...data, clients, details, preferences };
});

export const login = createAsyncThunk<void, { password: string; user: string }>(
  'user/login',
  async (params, thunkAPI) => {
    const response = await API.post(
      '/login',
      { ...params },
      {
        onError: (error) => {
          thunkAPI.dispatch(
            openWaveSnack({
              message: error.message,
              type: 'error',
            }),
          );
        },
      },
    );

    return response.data.data.session_id;
  },
);

export const logout = createAsyncThunk<void, string>(
  'user/logout',
  async (forcedLogOutMessage, thunkAPI) => {
    await API.post(
      '/logout',
      { session_id: thunkAPI.getState().user.sessionID },
      {
        onError: (error) => {
          thunkAPI.dispatch(
            openWaveSnack({
              message: error.message,
              type: 'error',
            }),
          );
        },
        onSuccess: (response) => {
          thunkAPI.dispatch(
            openWaveSnack({
              message: forcedLogOutMessage || response,
              type: forcedLogOutMessage ? 'error' : 'success',
            }),
          );
        },
      },
    );
  },
);

export const updateUserPreference = createAsyncThunk(
  'user/updateUserPreference',
  async ({ code, value }, thunkAPI) => {
    const response = await API.put(
      '/preferences',
      {
        code,
        val: value,
      },
      {
        onError: (error) => {
          thunkAPI.dispatch(
            openWaveSnack({
              message: error.message,
              type: 'error',
            }),
          );
        },
      },
    );

    // Search values job-art.ser need to be stored in the user state in [{}] format not like
    // "a:1:{i:0;a:2:{s:13:"business_unit";s:8:"Baby Care";s:5:"label";s:13:"Business Unit";}}"
    if (code.slice(-3) === 'ser') response.data.data.val = value;

    return { code: response.data.data.code, value: response.data.data.val };
  },
);

const initialState: InitialState = {
  cookie: localStorage.getItem('cookie'),
  details: {},
  loading: false,
  mainMenu: {},
  preferences: {},
  rights: { delete: [], edit: [], insert: [], read: [] },
  sessionID: localStorage.getItem('sessionID'),
};

export const userSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      deleteUserPreference.fulfilled,
      (state, { payload: { code, defaultValue } }) => {
        state.preferences[code] = { loading: false, value: defaultValue };
      },
    );
    builder.addCase(
      deleteUserPreference.pending,
      (
        state,
        {
          meta: {
            arg: { code },
          },
        },
      ) => {
        state.preferences[code].loading = true;
      },
    );
    builder.addCase(
      deleteUserPreference.rejected,
      (
        state,
        {
          meta: {
            arg: { code },
          },
        },
      ) => {
        state.preferences[code] = { ...state.preferences[code], loading: false };
      },
    );
    builder.addCase(
      getAUserRight.fulfilled,
      ({ rights }: InitialState, { payload }: { payload: Rights }) => {
        rights.delete = [...rights.delete, ...payload.delete].sort((previousCode, nextCode) =>
          naturalSort({ nextValue: nextCode, previousValue: previousCode }),
        );
        rights.edit = [...rights.edit, ...payload.edit].sort((previousCode, nextCode) =>
          naturalSort({ nextValue: nextCode, previousValue: previousCode }),
        );
        rights.insert = [...rights.insert, ...payload.insert].sort((previousCode, nextCode) =>
          naturalSort({ nextValue: nextCode, previousValue: previousCode }),
        );
        rights.read = [...rights.read, ...payload.read].sort((previousCode, nextCode) =>
          naturalSort({ nextValue: nextCode, previousValue: previousCode }),
        );
      },
    );
    builder.addCase(getExternalRevisorDetails.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
      return { ...state, loading: false, ...payload };
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(logout.fulfilled, () => {
      return { ...initialState, cookie: false, sessionID: null };
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.cookie = true;
      state.sessionID = payload;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserPreference.fulfilled, (state, { payload: { code, value } }) => {
      state.preferences[code] = { loading: false, value };
    });
    builder.addCase(
      updateUserPreference.pending,
      (
        state,
        {
          meta: {
            arg: { code },
          },
        },
      ) => {
        state.preferences[code] = { ...state.preferences[code], loading: true };
      },
    );
    builder.addCase(
      updateUserPreference.rejected,
      (
        state,
        {
          meta: {
            arg: { code },
          },
        },
      ) => {
        state.preferences[code] = { ...state.preferences[code], loading: false };
      },
    );
  },
  initialState,
  name: 'user',
  reducers: {
    setUserPreferenceDefault(state, { payload: { code, defaultValue } }) {
      state.preferences[code] = { loading: false, value: defaultValue };
    },
  },
});

export const { setUserPreferenceDefault } = userSlice.actions;

// eslint-disable-next-line import/no-default-export
export default userSlice.reducer;
