import { MutableRefObject, useEffect, useState } from 'react';

export function useParentWidth(ref: MutableRefObject<HTMLElement | null>): number {
  const [parentWidth, setParentWidth] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) return;

    function setFromRef() {
      if (ref.current) {
        setParentWidth(ref.current.offsetWidth);
      }
    }

    // Initialize the width
    setFromRef();

    let resizeObserver: ResizeObserver | null = new ResizeObserver(() => {
      setFromRef();
    });

    resizeObserver.observe(ref.current);

    return () => {
      if (resizeObserver && ref.current) {
        resizeObserver.unobserve(ref.current);
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    };
  }, [ref]);

  return parentWidth;
}
