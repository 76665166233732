import { memo } from 'react';

import { Avatar, Paper, styled, TableCell } from '@mui/material';

export const BaseStyledTableCell = styled(TableCell)`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing(1)};
  user-select: none;
  cursor: pointer;
`;

export const StyledTableCell = memo(BaseStyledTableCell);

export const StyledSearchResultNumberCell = styled(StyledTableCell)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  text-align: center;
  vertical-align: middle;
`;

export const StyledTableContainer = styled(Paper)`
  overflow: hidden;
`;

const BaseStyledAvatar = styled(Avatar)`
  height: ${({ theme }) => theme.spacing(3)};
  width: ${({ theme }) => theme.spacing(3)};
`;

export const StyledAvatar = memo(BaseStyledAvatar);
