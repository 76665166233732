import { Trans } from 'react-i18next';

import { Button as MuiButton, ButtonProps } from '@mui/material';

export function Button({ children, ...rest }: ButtonProps) {
  return (
    <MuiButton {...rest}>
      <Trans>{children}</Trans>
    </MuiButton>
  );
}
