import { memo } from 'react';

import { Box, Button, Card, CardContent, CardHeader, Divider, IconButton } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';
import { StyledFilleSearchFilerContainer } from 'src/pages/FileSearch/FileSearchFilterCard/styled';

type FileSearchFilterCardProps = {
  isFilterOpen: boolean;
  onToggleIsFilterOpen: () => void;
};

function FileSearchFilterCard({ isFilterOpen, onToggleIsFilterOpen }: FileSearchFilterCardProps) {
  return (
    <StyledFilleSearchFilerContainer isFilterOpen={isFilterOpen}>
      <Box width="500px">
        <Card>
          <CardHeader
            action={
              <IconButton onClick={onToggleIsFilterOpen}>
                <WaveIcon code="close" />
              </IconButton>
            }
            title="Filter Selection"
          />

          <CardContent>
            <Box component={Divider} mb={2} />

            <Button startIcon={<WaveIcon code="add" />}>More Filters</Button>
          </CardContent>
        </Card>
      </Box>
    </StyledFilleSearchFilerContainer>
  );
}

const MemoizedFileSearchFilterCard = memo(FileSearchFilterCard);

export { MemoizedFileSearchFilterCard as FileSearchFilterCard };
