import { Trans } from 'react-i18next';

import { Button, Skeleton } from '@mui/material';

import { useRelatedJobs } from 'src/features/JobForm/components/RelatedJobs/useRelatedJobs';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export function RelatedJobs() {
  const {
    handleCloseSubMenu,
    handleToggleSubMenu,
    isFetching,
    isSubMenuOpen,
    relatedJobButtons,
    relatedJobsButton,
  } = useRelatedJobs();

  return isFetching ? (
    <Skeleton height={30} variant="rounded" width={80} />
  ) : relatedJobButtons.length ? (
    <>
      <Button
        onClick={handleToggleSubMenu}
        ref={relatedJobsButton}
        startIcon={<WaveIcon code="job-form-action-bar-related-jobs" />}
        sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
      >
        <Trans i18nKey="related_jobs_button">Related Jobs</Trans>
      </Button>

      <SubMenu
        anchorEl={relatedJobsButton.current}
        anchorElMinWidth={relatedJobsButton.current?.offsetWidth}
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={relatedJobButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginVerticalSubitem="top"
      />
    </>
  ) : null;
}
