export function getRandomInt(index: number): [number, number] {
  const values = [1000, 1100, 1200, 1300, 1400, 1500, 1600, 1350, 1250, 1010, 1524, 1200];

  const adjustedIndex1 = index % values.length;

  const adjustedIndex2 = (index + 1) % values.length;

  return [values[adjustedIndex1], values[adjustedIndex2]];
}

export function getBytesToMegabytes(bytes: number) {
  return (bytes / 1048576).toFixed(1);
}

export function getFormattedDate(isoString: string): string {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(isoString);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function getDataAttributes(elements: Element[]): Array<[number, string, string]> {
  return (
    // get data-key and data-jobid attributes
    elements
      .map(
        (element) =>
          [
            element.getAttribute('data-key'),
            element.getAttribute('data-jobid'),
            element.getAttribute('data-thumb'),
          ] as [string | null, string | null, string | null],
      )
      // filter nulls
      .filter((selectedData) => Boolean(selectedData[0]) && Boolean(selectedData[1]))
      // convert to number and string
      .map((selectedData) => [
        Number(selectedData[0]),
        selectedData[1] as string,
        selectedData[2] as string,
      ])
  );
}
