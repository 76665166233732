import { styled } from '@mui/material';

type StyledFilleSearchFilerContainerProps = {
  isFilterOpen: boolean;
};

export const StyledFilleSearchFilerContainer = styled('div')<StyledFilleSearchFilerContainerProps>`
  width: ${({ isFilterOpen }) => (isFilterOpen ? '500px' : '0')};
  opacity: ${({ isFilterOpen }) => (isFilterOpen ? '1' : '0')};
  margin-right: ${({ isFilterOpen, theme }) => (isFilterOpen ? theme.spacing(3) : '0')};
  transition: width 0.2s ease-in-out ${({ isFilterOpen }) => (isFilterOpen ? '0s' : '0.1s')},
    opacity 0.2s ease-in-out ${({ isFilterOpen }) => (isFilterOpen ? '0.1s' : '0s')},
    margin-right 0.2s ease-in-out ${({ isFilterOpen }) => (isFilterOpen ? '0s' : '0.1s')}; /* Added this line */
`;
