import PhotoSwipeLightboxStub from 'react-photoswipe-gallery/dist/lightbox-stub';

import { PhotoSwipe } from 'photoswipe/dist/types/core/base';
import Slide from 'photoswipe/dist/types/slide/slide';

interface PhotoSwipeSlideOffsetOptions {
  slideOffsetValue: number;
}

const defaultOptions: PhotoSwipeSlideOffsetOptions = {
  slideOffsetValue: 0,
};

export class PhotoSwipeSlideOffset {
  private options: PhotoSwipeSlideOffsetOptions;
  private lightbox: PhotoSwipeLightboxStub;
  private photoSwipe: PhotoSwipe | null;
  private adjustedPanAreaSize: { x: number; y: number };
  private originalPanAreaSize: { x: number; y: number };

  constructor(lightbox: any, options: Partial<PhotoSwipeSlideOffsetOptions>) {
    this.options = {
      ...defaultOptions,
      ...options,
    };

    this.lightbox = lightbox;
    this.photoSwipe = null;
    this.adjustedPanAreaSize = { x: 0, y: 0 };
    this.originalPanAreaSize = { x: 0, y: 0 };

    this.lightbox.on('init', () => {
      this.adjustedPanAreaSize = { x: 0, y: 0 };
      this.originalPanAreaSize = { x: 0, y: 0 };
      this.photoSwipe = this.lightbox.pswp;
      this.initSlideOffset();
    });
  }

  initSlideOffset() {
    this.photoSwipe?.on('calcSlideSize', (e) => {
      return this.onCalcSlideSize(e);
    });

    this.photoSwipe?.on('zoomPanUpdate', ({ slide }) => {
      this.adjustPanArea(slide, slide.currZoomLevel);
    });

    this.photoSwipe?.on('beforeZoomTo', (e) => {
      if (this.photoSwipe?.currSlide) {
        this.adjustPanArea(this.photoSwipe.currSlide, e.destZoomLevel);
      }
    });
  }

  adjustPanArea(slide: Slide, zoomLevel: number) {
    if (zoomLevel > slide.zoomLevels.initial) {
      slide.panAreaSize.x = this.originalPanAreaSize.x;
      slide.panAreaSize.y = this.originalPanAreaSize.y;
    } else {
      // Restore panAreaSize after we zoom back to initial position
      slide.panAreaSize.x = this.adjustedPanAreaSize.x;
      slide.panAreaSize.y = this.adjustedPanAreaSize.y;
    }
  }

  onCalcSlideSize(e: any) {
    const slide: Slide = e.slide;

    this.storeOriginalPanAreaSize(slide);

    slide.bounds.update(slide.zoomLevels.initial);

    const imageHeight = Math.ceil(slide.height * slide.zoomLevels.initial);

    const slideOffset = this.options.slideOffsetValue;

    // vertical ending of the image
    const verticalEnding = imageHeight + slide.bounds.center.y;

    // height between bottom of the screen and ending of the image
    // (before any adjustments applied)
    const verticalLeftover = slide.panAreaSize.y - verticalEnding;

    if (verticalLeftover <= slideOffset) {
      // lift up the image to give more space for offset
      slide.panAreaSize.y -= Math.min((slideOffset - verticalLeftover) * 2, slideOffset);

      // we reduce viewport size, thus we need to update zoom level and pan bounds
      this.recalculateZoomLevelAndBounds(slide);
    }

    this.storeAdjustedPanAreaSize(slide);
  }

  recalculateZoomLevelAndBounds(slide: Slide) {
    slide.zoomLevels.update(slide.width, slide.height, slide.panAreaSize);
    slide.bounds.update(slide.zoomLevels.initial);
  }

  storeAdjustedPanAreaSize(slide: Slide) {
    this.adjustedPanAreaSize.x = slide.panAreaSize.x;
    this.adjustedPanAreaSize.y = slide.panAreaSize.y;
  }

  storeOriginalPanAreaSize(slide: Slide) {
    this.originalPanAreaSize.x = slide.panAreaSize.x;
    this.originalPanAreaSize.y = slide.panAreaSize.y;
  }

  public setSlideOffsetValue(slideOffsetValue: number) {
    this.options.slideOffsetValue = slideOffsetValue;
    // force update size - would like to animate this in the future
    // but not enough time for now
    this.photoSwipe?.updateSize(true);
  }
}
