import { memo } from 'react';

import { Box, Grid, Skeleton } from '@mui/material';

import { FileDataType } from 'src/pages/FileSearch/FileSearch.service';
import { FileSearchGridItem } from 'src/pages/FileSearch/FileSearchGridItem';
import { FileSearchStateType } from 'src/store/fileSearchSlice';

type FileSearchGridViewProps = {
  fileItems: FileDataType[];
  isCompact: boolean;
  selected: FileSearchStateType;
  isLoading: boolean;
};

function FileSearchGridView({
  fileItems,
  isCompact,
  isLoading,
  selected,
}: FileSearchGridViewProps) {
  if (isLoading) {
    return (
      <Grid container spacing={3}>
        {Array.from({ length: 50 }).map((_, index) => (
          <Grid
            item
            key={index}
            lg={isCompact ? 2 : 3}
            md={isCompact ? 3 : 4}
            sm={isCompact ? 4 : 6}
            xl={isCompact ? 1.5 : 3}
            xs={isCompact ? 6 : 12}
          >
            {!isCompact && (
              <Box
                component={Skeleton}
                height="30px"
                marginBottom={3}
                marginTop={1.5}
                width="100%"
              />
            )}

            <Box component={Skeleton} height="200px" variant="rectangular" width="100%" />

            {!isCompact && (
              <>
                <Box
                  component={Skeleton}
                  height="30px"
                  marginBottom={1}
                  marginTop={1}
                  width="100%"
                />

                <Box component={Skeleton} height="30px" marginBottom={0.5} width="100%" />

                <Box component={Skeleton} height="30px" marginBottom={0.5} width="100%" />

                <Box component={Skeleton} height="30px" marginBottom={0.5} width="100%" />

                <Box component={Skeleton} height="30px" marginBottom={0.5} width="100%" />

                <Box component={Skeleton} height="30px" marginBottom={0.5} width="100%" />
              </>
            )}
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {fileItems.map((fileItem, index) => (
        <Grid
          item
          key={fileItem.id}
          lg={isCompact ? 2 : 3}
          md={isCompact ? 3 : 4}
          sm={isCompact ? 4 : 6}
          xl={isCompact ? 1.5 : 3}
          xs={isCompact ? 6 : 12}
        >
          <FileSearchGridItem
            fileItem={fileItem}
            isCompact={isCompact}
            isSelected={selected.some(([id]) => id === fileItem.id)}
            pageIndex={index}
          />
        </Grid>
      ))}
    </Grid>
  );
}

const MemoizedFileSearchGridView = memo(FileSearchGridView);

export { MemoizedFileSearchGridView as FileSearchGridView };
