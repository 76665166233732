import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import { WaveDialogTitle } from '../WaveDialogTitle';

type ConfirmationDialogProps = {
  isDisabled?: boolean;
  isOpen: boolean;
  message: string;
  onConfirmation: () => void;
  onDialogClose: () => void;
};

export function ConfirmationDialog({
  isDisabled,
  isOpen,
  message,
  onConfirmation,
  onDialogClose,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();

  function handleClickConfirm(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    onConfirmation();
  }

  function handleClickCancel(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    onDialogClose();
  }

  return (
    <Dialog fullWidth onClose={onDialogClose} open={isOpen}>
      <WaveDialogTitle onClose={onDialogClose} title="lib.confirmation" />

      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      <DialogActions className="space-between">
        <Button color="warning" disabled={isDisabled} onClick={handleClickCancel}>
          {t('lib.cancel', 'Cancel')}
        </Button>

        <LoadingButton
          disabled={isDisabled}
          loading={isDisabled}
          onClick={handleClickConfirm}
          variant="contained"
        >
          {t('lib.confirm', 'Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
