import { IconBoxButton } from 'src/components/IconBoxButton';

type BlockFieldAddRemoveButtonProps = {
  isNewBlockRow?: boolean;
  onRemoveBlockRow?: () => void;
  onAddBlockRow?: () => void;
  isDisabled: boolean;
};

export function BlockFieldAddRemoveButton({
  isDisabled,
  isNewBlockRow = false,
  onAddBlockRow,
  onRemoveBlockRow,
}: BlockFieldAddRemoveButtonProps) {
  return isNewBlockRow ? (
    <IconBoxButton code="add-box" disabled={isDisabled} onClick={onAddBlockRow} />
  ) : (
    <IconBoxButton
      code="indeterminate-check-box"
      color="error"
      disabled={isDisabled}
      onClick={onRemoveBlockRow}
    />
  );
}
