import { Box, Paper } from '@mui/material';

import { Typography } from 'src/components/Typography';

type WidgetBaseProps = {
  children: React.ReactNode;
  title: string;
  action: React.ReactNode;
  id?: string;
  headerId?: string;
};

export function WidgetBase({ action, children, headerId, id, title }: WidgetBaseProps) {
  return (
    <Box
      component={Paper}
      display="flex"
      elevation={3}
      flexDirection="column"
      height="100%"
      id={id}
    >
      <Box
        alignItems="center"
        bgcolor="var(--filler)"
        borderBottom={1}
        borderRadius="4px 4px 0 0"
        display="flex"
        id={headerId}
        justifyContent="space-between"
        pl={2}
        sx={{ borderColor: 'grey.500' }}
      >
        <Typography fontWeight={600}>{title}</Typography>

        {action}
      </Box>

      <Box display="flex" flexGrow={1} overflow="auto" position="relative">
        {children}
      </Box>
    </Box>
  );
}
