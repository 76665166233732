import { useTheme } from "@mui/material/styles";
import { CircularProgress, Grid } from "@mui/material";

export function Spinner() {
  const { installationLogo } = useTheme();

  return (
    <Grid alignItems="center" className="public-background vh-100" container>
      <Grid container item justifyContent="center">
        <Grid item>
          <CircularProgress size={180} className="spinner-progress" />
        </Grid>

        <Grid className="spinner-logo-container" item>
          <img
            alt="Spinner Logo"
            className="spinner-logo"
            src={installationLogo}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
