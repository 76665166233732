import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { UploadButton } from 'src/components/RHF/UploadButton';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useSetApproverStatusMutation } from 'src/features/Approvals/Approvals.service';
import { AplAction } from 'src/features/Approvals/components/ActionBar/ActionBar';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { currentDate } from 'src/utilities/constants';
import { formatBytes, formatDate } from 'src/utilities/helperFunctions';
import { isNumber, isString } from 'src/utilities/helperFunctions2';
import {
  useAppDispatch,
  useAppSelector,
  useIsExternalRevisor,
  usePreference,
  useRouteParams,
} from 'src/utilities/hooks';
import { useFullScreen } from 'src/utilities/hooks/FullScreenContext';

type ActionDialogProps = {
  aplAction?: AplAction;
  isOpen: boolean;
  onClose: () => void;
};

export function ActionDialog({ aplAction, isOpen, onClose }: ActionDialogProps) {
  const { age, jobId, jobType } = useRouteParams();
  const { isFullScreen, setIsFullScreen } = useFullScreen();
  const aplRedirect = usePreference('apl.redirect', 'apl');
  const dispatch = useAppDispatch();
  const firstName = useAppSelector((state) => state.user.details.firstname);
  const lastName = useAppSelector((state) => state.user.details.lastname);
  const navigate = useNavigate();
  const [setApproverStatus] = useSetApproverStatusMutation();
  const { t } = useTranslation();

  const isExternalRevisorView = useIsExternalRevisor();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    resetField,
    watch,
  } = useForm({
    defaultValues: { comment: '', file: '' },
    mode: 'onChange',
  });
  const {
    palette: {
      error: { main: error },
      primary: { contrastText },
      success: { main: success },
      warning: { main: warning },
    },
  } = useTheme();

  const color =
    aplAction?.code === 'amendment'
      ? error
      : aplAction?.code === 'conditional'
      ? warning
      : aplAction?.code === 'approval'
      ? success
      : contrastText;
  const file = watch('file') as unknown as File;

  async function onSubmit({ comment }: { comment: string }) {
    const params = {
      age,
      flag: aplAction?.code,
      jobid: isNumber(jobId) ? jobId.toString() : jobId,
      message: comment,
      src: jobType,
    };

    const formData = new FormData();

    for (const key in params) {
      const value = params[key as keyof typeof params];

      if (value) {
        formData.append(key, value);
      }
    }

    if (file) {
      formData.append('file', file);
    }

    await setApproverStatus(formData)
      .unwrap()
      .then(({ message }) => {
        dispatch(
          openWaveSnack({
            message,
            type: 'success',
          }),
        );

        if (!isExternalRevisorView && isString(aplRedirect.value) && aplRedirect.value !== 'apl') {
          if (isFullScreen) setIsFullScreen(false);

          navigate(
            aplRedirect.value.includes('home')
              ? '/jobs-hom-dash'
              : `/jobs-${age}-${jobType}/${jobId}/${aplRedirect.value}`,
          );
        }

        onClose();
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={onClose} open={isOpen}>
      <WaveDialogTitle
        onClose={onClose}
        title={
          <Box display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex" gap={2}>
              <WaveIcon code="job-approvals-action-dialog-account-circle" fontSize="small" />

              <Typography variant="subtitle1">{`${lastName}, ${firstName}`}</Typography>

              <Typography variant="subtitle1">
                {formatDate(currentDate, 'yyyy/mm/dd hh:mm:ss')}
              </Typography>
            </Box>

            <Box alignItems="center" display="flex" gap={2}>
              <Typography color={color} variant="subtitle1">
                {aplAction?.text}
              </Typography>

              <WaveIcon
                code={`job-approvals-action-dialog-${aplAction?.code}`}
                fontSize="small"
                sx={{ color }}
              />
            </Box>
          </Box>
        }
      />

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {aplAction?.code !== 'approval' ? (
            <Box alignItems="center" display="flex" gap={2} mb={2}>
              <Box flexGrow={0}>
                <UploadButton
                  control={control}
                  disabled={isSubmitting}
                  label={t('select_file', 'Select File') as string}
                  name="file"
                />
              </Box>

              {file ? (
                <Box alignItems="center" display="flex" gap={1}>
                  <Typography variant="subtitle2">{file.name}</Typography>

                  <Typography variant="subtitle2">{formatBytes(file.size)}</Typography>

                  <IconButton
                    color="warning"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetField('file');
                    }}
                  >
                    <WaveIcon code="job-approvals-action-dialog-delete" fontSize="small" />
                  </IconButton>
                </Box>
              ) : null}
            </Box>
          ) : null}

          <TextField
            control={control}
            disabled={isSubmitting}
            label={<Trans i18key="lib.msg">Comment</Trans>}
            minRows={4}
            multiline
            name="comment"
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            <Trans i18key="lib.confirm">Confirm</Trans>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
