import { useEffect, useState } from 'react';

import { WorkflowProps } from 'src/features/Workflow/Workflow';
import { useGetDeadlineQuery, useGetWorkflowQuery } from 'src/features/Workflow/Workflow.service';
import { usePreference, usePreferencePrefix } from 'src/utilities/hooks';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

type JobsPerStatus = Record<number, number>;
type UseWorkflowProps = WorkflowProps;

export function useWorkflow({ rows }: UseWorkflowProps) {
  const { age, jobId, jobType, preferencePrefix } = usePreferencePrefix();
  const isAgeBasic = age === 'arc' || age === 'job';
  const { data: workflow, isFetching } = useGetWorkflowQuery(
    {
      age: age as BasicAge,
      jobType,
    },
    { skip: !isAgeBasic },
  );
  const { data: deadlines } = useGetDeadlineQuery(
    {
      age: age as BasicAge,
      jobId,
      jobType,
    },
    { skip: !isAgeBasic || !jobId },
  );
  const displayHeaderPreference = usePreference(`${preferencePrefix}.header`, 'true');
  const [jobsPerStatus, setJobsPerStatus] = useState<JobsPerStatus>({});

  const jobStatusValue = deadlines?.jobStatusValue;
  const hasJobId = !!jobId;

  useEffect(() => {
    if (rows) {
      const jobsPerStatus = {} as JobsPerStatus;

      rows.forEach(({ status }) => {
        jobsPerStatus[status] = (jobsPerStatus[status] ?? 0) + 1;
      });

      setJobsPerStatus(jobsPerStatus);
    }
  }, [rows]);

  return {
    deadlines,
    displayHeaderPreference,
    hasJobId,
    isFetching,
    jobStatusValue,
    jobType,
    jobsPerStatus,
    workflow,
  };
}
