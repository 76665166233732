import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ExpandedAges } from '../../utilities/hooks/useRouteParams';
import { useLazyNotifyBackendQuery, ViewerCallbackParams } from './ViewerCallback.service';

export function ViewerCallback() {
  const location = useLocation();
  const [notifyBackend] = useLazyNotifyBackendQuery();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const params: ViewerCallbackParams = {
    age: queryParams.get('age') as ExpandedAges,
    id: Number(queryParams.get('id')),
    jobid: queryParams.get('jobid') || undefined,
    src: queryParams.get('src') || undefined,
  };

  useEffect(() => {
    notifyBackend(params).then(() => {
      navigate(
        `/jobs-${queryParams.get('age')}-${queryParams.get('src')}/${queryParams.get('jobid')}/apl`,
        {
          replace: true,
        },
      );
    });
  }, []);

  return <></>;
}
