import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Box, Button, TextField } from '@mui/material';

import { ForgotPassword } from '../../components/ForgotPassword';
import { Spinner } from '../../components/Spinner';

import { login } from '../../store/userSlice';

import fiveFlow from '../../images/5Flow.svg';
import waveLogoSlogan from '../../images/waveLogoSlogan.png';
import { useLocalStorage } from 'src/utilities/hooks';
import { Trans, useTranslation } from 'react-i18next';

export function Login() {
  const [clipboardData, setClipboardData] = useLocalStorage('clipboard', []);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loading);
  const { installationLogo } = useTheme();
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const { t } = useTranslation();

  function handleChangePassword({ target: { value } }) {
    setPassword(value);
  }

  function handleChangeUsername({ target: { value } }) {
    setUsername(value);
  }

  function handleSubmitLogin(e) {
    e.preventDefault();
    setClipboardData([]);
    dispatch(
      login({
        user: username,
        password,
      }),
    );
  }

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <Box className="public-background vh-100" display="flex" flexDirection="column">
        <Box className="ml-20 mt-20 p-absolute">
          <img src={fiveFlow} alt="5Flow Logo" className="w-100" />
        </Box>

        <Box alignItems="center" alignSelf="center" display="flex" flexGrow={1}>
          <Box display="flex">
            <Box
              className="b-radius-bl-10 b-radius-tl-10 primary-bg w-200 "
              display="flex"
              flexDirection="column"
            >
              <Box className="m-5">
                <img alt="Wave Logo Slogan" className="w-100" src={waveLogoSlogan} />
              </Box>

              <Box alignSelf="center" className="d-flex flex-grow-1 w-100p">
                {installationLogo && (
                  <img
                    alt="Installation Logo"
                    className="contain-image w-100p p-10"
                    src={installationLogo}
                  />
                )}
              </Box>
            </Box>

            <Box
              alignItems="center"
              className="b-radius-br-10 b-radius-tr-10 filler-bg py-30 w-300"
              display="flex"
              flexDirection="column"
            >
              <form onSubmit={handleSubmitLogin}>
                <Box alignItems="center" display="flex" flexDirection="column">
                  <TextField
                    autoFocus
                    className="w-250"
                    label={t('usr.name')}
                    onChange={handleChangeUsername}
                    required
                    size="small"
                    type="search"
                    value={username}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="w-250 my-20"
                    label={t('pg.usr.pwd')}
                    onChange={handleChangePassword}
                    required
                    size="small"
                    type="password"
                    value={password}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button className="w-150" type="submit">
                    <Trans i18nKey="login_sign_in_button">Sign In</Trans>
                  </Button>
                </Box>
              </form>
              <ForgotPassword />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
