import { styled } from '@mui/material';

type StyledBlockContainer = {
  isTable: boolean;
};

export const StyledBlockContainer = styled('div')<StyledBlockContainer>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  border: ${({ isTable, theme }) => !isTable && `1px solid ${theme.palette.grey[400]}`};
  width: 100%;
`;

export const StyledBlocksFieldSection = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`;
