import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'react-photoswipe-gallery';

import { useNavigate } from 'react-router-dom';

import { useSelection } from '@viselect/react';

import { Box, CardContent, Checkbox, Divider, Stack, Typography } from '@mui/material';

import { useLazyGetDownloadUrlQuery } from 'src/features/JobFiles/JobFiles.service';
import { FileColumnMappingsEnum } from 'src/pages/FileSearch/enums/FileColumnMappings';
import { JobDetailsColumnMappingsEnum } from 'src/pages/FileSearch/enums/JobDetailsColumnMappings';
import { FileDataType } from 'src/pages/FileSearch/FileSearch.service';
import { FileSearchDropdownButton } from 'src/pages/FileSearch/FileSearchDropdownButton';
import {
  StyledCard,
  StyledHeaderContainer,
  StyledImageContainer,
  StyledWaveIcon,
} from 'src/pages/FileSearch/FileSearchGridItem/styled';
import {
  getBytesToMegabytes,
  getFormattedDate,
} from 'src/pages/FileSearch/FileSearchGridItem/utils';
import { FileSearchImage } from 'src/pages/FileSearch/FileSearchImage';
import { FileSearchTextGroup } from 'src/pages/FileSearch/FileSearchTextGroup';
import { getFilteredRowLabelsAndData, getNavigationUrl } from 'src/pages/FileSearch/utils';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useAppSelector, useImageDimensions } from 'src/utilities/hooks';

import defaultThumbnail from 'src/images/thumbnails/default_thumbnail.png';

let clickTimeout: NodeJS.Timeout | null = null;

type FileSearchGridItemProps = {
  fileItem: FileDataType;
  isCompact: boolean;
  pageIndex: number;
  isSelected: boolean;
};

function FileSearchGridItem({
  fileItem,
  isCompact,
  isSelected,
  pageIndex,
}: FileSearchGridItemProps) {
  const [downloadFiles] = useLazyGetDownloadUrlQuery();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.details.id);
  const selection = useSelection();
  const openRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    FILE_NAME,
    FILE_SIZE,
    ID,
    JOB_ID,
    KEYWORDS,
    LAST_UPDATED,
    PRODUCT_NAME,
    PROJECT_NAME,
    SUB,
    THUMBNAIL,
    VERSION,
  } = getFilteredRowLabelsAndData(fileItem, [
    FileColumnMappingsEnum.LAST_UPDATED,
    FileColumnMappingsEnum.THUMBNAIL,
    FileColumnMappingsEnum.SUB,
    FileColumnMappingsEnum.JOB_ID,
    FileColumnMappingsEnum.ID,
    FileColumnMappingsEnum.FILE_NAME,
    FileColumnMappingsEnum.FILE_SIZE,
    FileColumnMappingsEnum.VERSION,
    JobDetailsColumnMappingsEnum.KEYWORDS,
    JobDetailsColumnMappingsEnum.PROJECT_NAME,
    JobDetailsColumnMappingsEnum.PRODUCT_NAME,
  ]);

  const thumbnail =
    SUB.value === 'doc' ? `${import.meta.env.VITE_API}${THUMBNAIL.value}` : THUMBNAIL.value;
  const sizeInMegaBytes = getBytesToMegabytes(FILE_SIZE.value);
  const formattedLastUpdated = getFormattedDate(LAST_UPDATED.value);
  const { dimensions } = useImageDimensions(thumbnail);

  function handleDoubleClick() {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = null;

      openRef.current(pageIndex);
    } else {
      clickTimeout = setTimeout(() => {
        clickTimeout = null;
      }, 160);
    }
  }

  function handleOpenPhotoSwipe() {
    openRef.current(pageIndex);
  }

  function handleAddToGroupSelection() {
    const element = document.querySelector(`[data-key="${fileItem.id}"]`);

    if (element) {
      selection?.select(element);
    }
  }

  function handleOpenJobFilesTab() {
    const src = fileItem.src;
    const tableName = fileItem.job.db;
    const jobId = fileItem.jobid.toString();

    const url = getNavigationUrl({ jobId, src, tableName });

    navigate(url);
  }

  function handleDownload() {
    downloadFiles({
      fileIds: [fileItem?.id],
      userId,
    })
      .unwrap()
      .then(({ url }) => {
        window.open(`${import.meta.env.VITE_API}${url}`);

        dispatch(
          openWaveSnack({
            message: t('download_successful_content', 'Download was successful'),
            type: 'success',
          }),
        );
      });
  }

  return (
    <Box position="relative">
      <FileSearchDropdownButton
        isListView={false}
        onAddToGroupSelection={handleAddToGroupSelection}
        onDownload={handleDownload}
        onOpenJobFilesTab={handleOpenJobFilesTab}
        onOpenPhotoSwipe={handleOpenPhotoSwipe}
      />

      <StyledCard
        className="selectable"
        data-jobid={JOB_ID.value}
        data-key={ID.value}
        data-thumb={thumbnail}
        selected={isSelected}
      >
        {isCompact && isSelected && <StyledWaveIcon code="check-box" />}

        {!isCompact && (
          <Box alignItems="center" display="flex" justifyContent="space-between" margin={1}>
            <StyledHeaderContainer>
              <Checkbox checked={isSelected} />

              <Typography noWrap variant="subtitle1">
                {JOB_ID.value}
              </Typography>
            </StyledHeaderContainer>
          </Box>
        )}

        <StyledImageContainer display="flex" justifyContent="center">
          <Item
            cropped
            height={dimensions?.height || 500}
            original={thumbnail || defaultThumbnail}
            thumbnail={thumbnail || defaultThumbnail}
            width={dimensions?.width || 500}
          >
            {({ open, ref }) => {
              openRef.current = open;

              return (
                <FileSearchImage
                  onClick={handleDoubleClick}
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  src={thumbnail}
                />
              );
            }}
          </Item>
        </StyledImageContainer>

        {!isCompact && (
          <CardContent>
            <Typography gutterBottom>{formattedLastUpdated}</Typography>

            <Box component={Divider} marginBottom={1} />

            <Stack spacing={1}>
              <FileSearchTextGroup
                label={t(FILE_NAME.translationCode, FILE_NAME.label)}
                value={FILE_NAME.value}
              />

              <FileSearchTextGroup
                label={t(KEYWORDS.translationCode, KEYWORDS.label)}
                value={KEYWORDS.value}
              />

              <FileSearchTextGroup
                label={t(PROJECT_NAME.translationCode, PROJECT_NAME.label)}
                value={PRODUCT_NAME.value}
              />

              <FileSearchTextGroup
                label={t(VERSION.translationCode, VERSION.label)}
                value={VERSION.value}
              />

              <FileSearchTextGroup
                label={t(FILE_SIZE.translationCode, FILE_SIZE.label)}
                value={`${sizeInMegaBytes}MB`}
              />
            </Stack>
          </CardContent>
        )}
      </StyledCard>
    </Box>
  );
}

const MemoizedFileSearchGridItem = memo(FileSearchGridItem);

export { MemoizedFileSearchGridItem as FileSearchGridItem };
