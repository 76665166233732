import { Box, Typography } from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';

type PasswordCriteria = {
  criteria: string;
  isValid: boolean;
};

export function PasswordCriteria({ criteria, isValid }: PasswordCriteria) {
  return (
    <Box display="flex" margin={1}>
      {isValid ? (
        <WaveIcon code="check-circle" color="success" fontSize="small" />
      ) : (
        <WaveIcon code="cancel" color="error" fontSize="small" />
      )}

      <Typography marginX={1} variant="caption">
        {criteria}
      </Typography>
    </Box>
  );
}
