import { ChangeEvent, ReactNode } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { usePreference } from 'src/utilities/hooks';

type CheckboxOwnProps = {
  description?: string;
  isError?: boolean;
  label?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  onClickAssociatedCheckbox?: (isChecked: boolean) => void;
  shouldShowHelperText?: boolean;
  fieldValueIndex?: number;
};
type CheckboxProps = Omit<
  FormControlLabelProps,
  'control' | 'defaultValue' | 'label' | 'onChange' | 'required'
> &
  Omit<MuiCheckboxProps, 'defaultValue' | 'required'> &
  CheckboxOwnProps;

export function Checkbox<T extends FieldValues>({
  control,
  description,
  fieldValueIndex,
  indeterminate,
  isError = false,
  label,
  name,
  onChange,
  onChangeLogicBuilderField,
  onClickAssociatedCheckbox,
  rules,
  shouldShowHelperText = true,
  ...rest
}: CheckboxProps & Omit<UseControllerProps<T>, 'defaultValue'>) {
  const showTooltipsPreference = usePreference('job.feldtips', 'Y');

  const {
    field: { onChange: onFieldChange, ref, value: isChecked = false, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const fieldValue =
    typeof isChecked === 'boolean' || fieldValueIndex === undefined
      ? isChecked
      : isChecked[fieldValueIndex] || false;

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const hasChecked = event.target.checked;

    if (fieldValueIndex !== undefined && Array.isArray(isChecked)) {
      const updatedValues = [...isChecked];

      updatedValues[fieldValueIndex] = hasChecked;

      onFieldChange(updatedValues);
    } else {
      onFieldChange(hasChecked);
    }

    if (onChange) {
      const target = event.target as HTMLInputElement;

      onChange(event, target.checked);
    }

    if (onClickAssociatedCheckbox) onClickAssociatedCheckbox(event.target.checked);

    if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);
  }

  function stopPropagation(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
  }

  return (
    <>
      <Box>
        {label ? (
          <FormControlLabel
            control={
              <MuiCheckbox
                {...field}
                checked={fieldValue}
                color={indeterminate ? 'secondary' : 'primary'}
                indeterminate={indeterminate}
                inputRef={ref}
                onChange={handleCheckboxChange}
                onClick={stopPropagation}
                required={!!rules?.required}
              />
            }
            label={<span onClick={stopPropagation}>{label}</span>}
            {...(isError && { sx: { color: 'error.main' } })}
            {...rest}
          />
        ) : (
          <MuiCheckbox
            {...field}
            checked={fieldValue}
            color={indeterminate ? 'secondary' : 'primary'}
            indeterminate={indeterminate}
            inputRef={ref}
            onChange={handleCheckboxChange}
            onClick={stopPropagation}
            required={!!rules?.required}
            {...rest}
          />
        )}

        {shouldShowHelperText ? (
          <FormHelperText error>
            {/* https://mui.com/material-ui/react-text-field/#helper-text */}
            {error && error.message ? error.message : ' '}
          </FormHelperText>
        ) : null}
      </Box>

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </>
  );
}
