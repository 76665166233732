import { Box, IconButton, ListItem, ListItemText } from '@mui/material';

import { Button } from 'src/components/Button';
import { TextField } from 'src/components/RHF/TextField';
import { useViewSearchItem } from 'src/features/JobsTable/components/ViewSearchItem/useViewSearchItem';
import { SearchTransformed, ViewTransformed } from 'src/features/JobsTable/JobsTable.service';
import { WaveIcon } from 'src/features/WaveIcon';

export type ViewVariant = { item: ViewTransformed; variant: ViewType };
export type ViewType = 'userView' | 'globalView';
export type SearchVariant = { item: SearchTransformed; variant: 'search' };
type Basic = { isNewItemFormOpen: boolean; onCloseDialog: () => void };
export type ViewSearchItemProps = (ViewVariant | SearchVariant) & Basic;

export function ViewSearchItem({
  isNewItemFormOpen,
  item,
  onCloseDialog,
  variant,
}: ViewSearchItemProps) {
  const {
    action,
    control,
    deleteRights,
    editRights,
    handleClickApply,
    handleClickCancel,
    handleClickConfirm,
    handleClickDelete,
    handleClickEdit,
    onSubmit,
    translate,
  } = useViewSearchItem({
    item,
    onCloseDialog,
    variant,
  });

  const hasEditAndDeleteRights = editRights.includes('vie') && deleteRights.includes('vie');
  const shouldShowEditAndDeleteButtons =
    (variant === 'globalView' && hasEditAndDeleteRights) || variant === 'userView';
  const isApplyDisabled = !!action || isNewItemFormOpen;

  function computeItemText() {
    if (action === 'edit') {
      return (
        <TextField
          autoFocus
          control={control}
          label="Name"
          name="name"
          rules={{ required: { message: `${translate('lib.required', 'Required')}`, value: true } }}
        />
      );
    }

    if (action === 'delete') return `Are you sure want to delete ${item.name}?`;

    return item.name;
  }

  return (
    <ListItem>
      <Box
        alignItems="flex-start"
        component="form"
        display="flex"
        flexGrow={1}
        gap={2}
        onSubmit={onSubmit}
      >
        <ListItemText inset={variant !== 'search'} primary={computeItemText()} />

        <Button
          disabled={isApplyDisabled}
          onClick={handleClickApply}
          startIcon={<WaveIcon code="jobs-view-search-preview" />}
          {...(action === 'edit' && { sx: { mt: 0.5 } })}
        >
          apply_button
        </Button>

        {action ? (
          <>
            <IconButton
              color="primary"
              onClick={handleClickCancel}
              {...(action === 'edit' && { sx: { mt: 0.5 } })}
            >
              <WaveIcon code="close" fontSize="small" />
            </IconButton>

            <IconButton
              color="primary"
              onClick={handleClickConfirm}
              {...(action === 'edit' && { sx: { mt: 0.5 }, type: 'submit' })}
            >
              <WaveIcon code="check" fontSize="small" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              color="primary"
              disabled={isNewItemFormOpen}
              onClick={handleClickEdit}
              {...(!shouldShowEditAndDeleteButtons && { sx: { visibility: 'hidden' } })}
            >
              <WaveIcon code="edit" fontSize="small" />
            </IconButton>

            <IconButton
              color="primary"
              disabled={isNewItemFormOpen}
              onClick={handleClickDelete}
              {...(!shouldShowEditAndDeleteButtons && { sx: { visibility: 'hidden' } })}
            >
              <WaveIcon code="delete" fontSize="small" />
            </IconButton>
          </>
        )}
      </Box>
    </ListItem>
  );
}
