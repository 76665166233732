import { Theme } from '@mui/material/styles';

export function Dialog(theme: Theme) {
  return {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 3, 3, 3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&+.MuiDialogContent-root': {
            paddingTop: `${theme.spacing(3)} !important`,
          },
          alignItems: 'center',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          display: 'flex',
          fontSize: 16,
          fontWeight: 700,
          justifyContent: 'space-between',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          padding: theme.spacing(1, 3),
        },
      },
    },
  };
}
