import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { MenuItem, TextField, TextFieldProps } from '@mui/material';

export type Option = { label: string; value: string };

type Options = {
  options: Option[];
};

export function Dropdown<T extends FieldValues>({
  SelectProps,
  control,
  defaultValue,
  label,
  name,
  onBlur,
  onChange,
  options,
  rules,
  ...rest
}: TextFieldProps & Options & UseControllerProps<T>) {
  const {
    field: { onBlur: onFieldBlur, onChange: onFieldChange, ref, value, ...field },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
  });

  function generateOptions() {
    if (
      value !== '' &&
      value !== undefined &&
      value !== null &&
      !SelectProps?.multiple &&
      !options.some((option) => option.value === value)
    ) {
      // Add the value as a new option if the value isn't already an option
      options = [...options, { label: value, value }];
    }

    return options.map((option: Option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  }

  return (
    <TextField
      error={!!error}
      fullWidth
      helperText={error?.message || null}
      inputRef={ref}
      label={<Trans>{label}</Trans>}
      onBlur={(event) => {
        onFieldBlur();

        if (onBlur) {
          onBlur(event);
        }
      }}
      onChange={(event) => {
        onFieldChange(event);

        if (onChange) {
          onChange(event);
        }
      }}
      required={Boolean(rules?.required)}
      select
      SelectProps={SelectProps}
      size="small"
      value={value ? value : SelectProps?.multiple ? [] : ''}
      {...field}
      {...rest}
    >
      {generateOptions()}
    </TextField>
  );
}
