import { useLocation } from 'react-router-dom';

import { ContentCreate } from './components/ContentCreate';
import { ContentList } from './components/ContentList';

export function Content() {
  const location = useLocation();

  // Temporal solution until we migrate to react-router-dom 6.5+ and will have optional params
  const isNewContent = location.pathname.includes('/new');

  return isNewContent ? <ContentCreate /> : <ContentList />;
}
