import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

export type EmailTemplateItem = {
  role: string;
  name: string;
  id: number;
  type: string;
  checked: boolean;
};

type EmailTemplates = {
  expiration_date: Date;
  expiration_label: string;
  expiration_tooltip: string;
  template: { subject: string; message: string };
  to_roles?: Array<EmailTemplateItem>;
};

type EmailTemplatePayload = {
  age?: ExpandedAges;
  src?: string;
  jobid?: number | string;
  tab?: string;
};

type EmailUserListPayload = {
  term: string;
};

type SendEmailNotificationPayload = {
  receiver_id?: Array<number | string>;
  subject?: string;
  message?: string;
  sender_id?: number;
};

type SendEmailFromJobPayload = {
  jobId: string | number | undefined;
  src: string | undefined;
  age: string | undefined;
  tab: string | undefined;
  userIds?: Array<number | string>;
  additionalUserIds?: Array<number | string>;
  mailType?: Array<number | string>;
  subject?: string;
  message?: string;
};

export const emailTemplatesApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getEmailTemplates: build.query<EmailTemplates, EmailTemplatePayload>({
      query({ age, jobid, src, tab }) {
        return {
          method: 'GET',
          params: { age, jobid, src, tab },
          url: 'email-dialog',
        };
      },
    }),
    getEmailUserList: build.query<Array<{ label: string; value: number }>, EmailUserListPayload>({
      query({ term }) {
        return {
          method: 'GET',
          params: { term },
          url: 'email-users',
        };
      },
    }),
    sendEmailFromCart: build.mutation<void, SendEmailNotificationPayload>({
      query({ message, receiver_id: receiverId, sender_id: senderId, subject }) {
        return {
          method: 'POST',
          params: { message, receiver_id: receiverId, sender_id: senderId, subject },
          url: 'sendmail',
        };
      },
    }),
    sendEmailFromJob: build.mutation<void, SendEmailFromJobPayload>({
      query({ additionalUserIds, age, jobId, mailType, message, src, subject, tab, userIds }) {
        return {
          method: 'POST',
          params: {
            additional_user_ids: additionalUserIds,
            age,
            mail_type: mailType,
            message,
            src,
            subject,
            tab,
            user_ids: userIds,
          },
          url: `jobs/${jobId}/emails`,
        };
      },
    }),
  }),
  reducerPath: 'emailTemplatesApi',
});

export const {
  useGetEmailTemplatesQuery,
  useLazyGetEmailUserListQuery,
  useSendEmailFromCartMutation,
  useSendEmailFromJobMutation,
} = emailTemplatesApi;
