import { ActiveFilter } from 'src/utilities/hooks/usePreference';

import { Filters } from 'src/features/JobsTable/components/Filters/types';

export function haveFiltersChanged(activeFilters: Filters, formFilters: Filters): boolean {
  if (activeFilters.length !== formFilters.length) {
    return true;
  }

  const areActiveFiltersUnchanged = activeFilters.every((activeFilter) =>
    formFilters.some((formFilter) => formFilter.textValue === activeFilter.textValue),
  );

  const areFormFiltersUnchanged = formFilters.every((formFilter) =>
    activeFilters.some((activeFilter) => activeFilter.textValue === formFilter.textValue),
  );

  return !(areActiveFiltersUnchanged && areFormFiltersUnchanged);
}

export function isFilterPresent(filters: ActiveFilter[], alias: string, value: string) {
  return filters.some((filter) => {
    const filterKeyValuePair = Object.entries(filter);
    const existingFilterAlias = filterKeyValuePair[0][0];
    const existingFilterValue = filterKeyValuePair[0][1];

    console.log(existingFilterAlias, alias, existingFilterValue, value);

    return existingFilterAlias === alias && existingFilterValue === value;
  });
}
