import { Box, Card, styled } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';

export const StyledImageContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.grey[200]};
`;

export const StyledCard = styled(Card)<{ selected: boolean }>`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  box-shadow: ${({ selected, theme }) =>
    selected ? `0 0 0 2px white, 0 0 0 5px ${theme.palette.secondary.dark}` : undefined};
  transition: none;
  user-select: none;
  cursor: pointer;
`;

export const StyledHeaderContainer = styled('div')`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledWaveIcon = styled(WaveIcon)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.palette.secondary.dark};
`;
