import { Item } from 'react-photoswipe-gallery';

import { useImageDimensions } from 'src/utilities/hooks';

import defaultThumbnail from '../../../images/thumbnails/default_thumbnail.png';

type FileSearchSelectedGalleryItemProps = {
  index: number;
  thumb: string;
};

export function FileSearchSelectedGalleryItem({ thumb }: FileSearchSelectedGalleryItemProps) {
  const { dimensions } = useImageDimensions(thumb);

  return (
    <Item
      height={dimensions?.height || 500}
      original={thumb || defaultThumbnail}
      thumbnail={thumb || defaultThumbnail}
      width={dimensions?.width || 500}
    >
      {({ ref }) => {
        return <div ref={ref as React.MutableRefObject<HTMLImageElement>} />;
      }}
    </Item>
  );
}
