import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

export function useFileGroup() {
  const isGeneralFiles = useLocation().pathname.includes('/general-files');
  const { t: translate } = useTranslation();

  return {
    isGeneralFiles,
    translate,
  };
}
