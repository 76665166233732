import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewSearchDialogProps } from 'src/features/JobsTable/components/ViewSearchDialog/ViewSearchDialog';
import {
  useGetSavedSearchesQuery,
  useGetSavedViewsQuery,
} from 'src/features/JobsTable/JobsTable.service';
import { useJobTableVariants } from 'src/features/JobsTable/utilities/hooks';

type UseViewSearchDialogProps = Omit<ViewSearchDialogProps, 'onClose'>;

export function useViewSearchDialog({ isOpen, variantData }: UseViewSearchDialogProps) {
  const { t: translate } = useTranslation();
  const { age, isSubJobsTable, jobType } = useJobTableVariants();

  const [isNewItemFormOpen, setIsNewItemFormOpen] = useState(false);

  const newLabel =
    variantData?.code === 'saved-views'
      ? translate('new_view_label', 'New View')
      : translate('new_search_label', 'New Search');
  const isSavedViews = variantData?.code === 'saved-views';

  const { data: savedViews } = useGetSavedViewsQuery(
    { age, isSubJobsTable, jobType },
    { skip: !isOpen || !isSavedViews },
  );
  const { data: savedSearches } = useGetSavedSearchesQuery(
    { age, isSubJobsTable, jobType },
    { skip: !isOpen || isSavedViews },
  );

  function handleCloseNewItemForm() {
    setIsNewItemFormOpen(false);
  }

  function handleToggleIsNewItemFormOpen() {
    setIsNewItemFormOpen((previousIsNewItemFormOpen) => !previousIsNewItemFormOpen);
  }

  useEffect(() => {
    setIsNewItemFormOpen(false);
  }, [isOpen]);

  return {
    handleCloseNewItemForm,
    handleToggleIsNewItemFormOpen,
    isNewItemFormOpen,
    isSavedViews,
    newLabel,
    savedSearches,
    savedViews,
  };
}
