import { Container } from '@mui/material';

import { BreadCrumbs } from 'src/components/BreadCrumbs';
import { ConfirmationDialog } from 'src/components/ConfirmationDialog';
import { BulkEditJobFields } from 'src/features/BulkEditJobFields';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { Filters } from 'src/features/JobsTable/components/Filters';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { Workflow } from 'src/features/Workflow';

export function Jobs() {
  const {
    areActiveFiltersVisible,
    areAllSubJobsExpanded,
    areJobsFetching,
    bulkEditType,
    columns,
    deadlines,
    deleteJobId,
    handleBulkEditSuccess,
    handleCancelBulkEdit,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleCloseConfirmationDialog,
    handleDeleteJob,
    handleOpenConfirmationDialog,
    handleToggleActiveFiltersVisibility,
    handleToggleAreAllSubJobsExpanded,
    handleToggleSelectAllRows,
    idsOfExpandedJobs,
    isConfirmationDialogOpen,
    isJobBeingDeleted,
    jobIdsWithSubJobs,
    jobType,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setAreAllSubJobsExpanded,
    setBulkEditType,
    setIdsOfExpandedJobs,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobs();

  if (bulkEditType === 'fields') {
    return (
      <BulkEditJobFields
        onBulkEditSuccess={handleBulkEditSuccess}
        onCancelBulkEdit={handleCancelBulkEdit}
        selectedRows={selectedRows}
      />
    );
  }

  if (bulkEditType === 'flags' || bulkEditType === 'status') return <div>{bulkEditType}</div>;

  return (
    <>
      <BreadCrumbs />

      <Container maxWidth={false} sx={{ mb: 1, mt: 1.5 }}>
        {jobType !== 'all' && jobType !== 'search' && <Workflow rows={rows} />}

        <ActionBar
          areAllSubJobsExpanded={areAllSubJobsExpanded}
          idsOfExpandedJobs={idsOfExpandedJobs}
          jobIdsWithSubJobs={jobIdsWithSubJobs}
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          onToggleAreAllSubJobsExpanded={handleToggleAreAllSubJobsExpanded}
          searchTerm={searchTerm}
          selectedRows={selectedRows}
          setAreAllSubJobsExpanded={setAreAllSubJobsExpanded}
          setBulkEditType={setBulkEditType}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        areJobsFetching={areJobsFetching}
        columns={columns}
        deadlines={deadlines}
        idsOfExpandedJobs={idsOfExpandedJobs}
        jobIdsWithSubJobs={jobIdsWithSubJobs}
        onClickRowCheckbox={handleClickRowCheckbox}
        onOpenConfirmationDialog={handleOpenConfirmationDialog}
        onToggleSelectAllRows={handleToggleSelectAllRows}
        rows={rows}
        selectedRows={selectedRows}
        setIdsOfExpandedJobs={setIdsOfExpandedJobs}
        setPageHistory={setPageHistory}
        setSelectedRows={setSelectedRows}
        totalRowCount={totalRowCount}
      />

      <ConfirmationDialog
        isDisabled={isJobBeingDeleted}
        isOpen={isConfirmationDialogOpen}
        message={`Are you sure you want to delete ${deleteJobId}?`}
        onConfirmation={handleDeleteJob}
        onDialogClose={handleCloseConfirmationDialog}
      />
    </>
  );
}
