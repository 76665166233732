import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { Box, DialogTitle, IconButton } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';

type WaveDialogTitleProps = { title: string | ReactNode; onClose: () => void };

export function WaveDialogTitle({ onClose, title }: WaveDialogTitleProps) {
  return (
    <DialogTitle>
      <Trans>{title}</Trans>

      {onClose && (
        <Box color="white">
          <IconButton color="inherit" onClick={onClose} size="small" sx={{ padding: 0 }}>
            <WaveIcon code="close" />
          </IconButton>
        </Box>
      )}
    </DialogTitle>
  );
}
