import { Enum, EnumValue } from 'ts-enums';

import { JobDataType } from 'src/pages/FileSearch/FileSearch.service';

export class JobDetailsColumnMappings extends EnumValue {
  constructor(
    name: string,
    private _label: string,
    private _translationCode: string,
    private _databaseColumnName: keyof JobDataType,
    private _sortName: string,
  ) {
    super(name);
  }

  get label(): string {
    return this._label;
  }

  get translationCode(): string {
    return this._translationCode;
  }

  get databaseColumnName(): keyof JobDataType {
    return this._databaseColumnName;
  }

  get sortName(): string {
    return this._sortName;
  }
}

export class JobDetailsColumnMappingsEnumType extends Enum<JobDetailsColumnMappings> {
  CATEGORY = new JobDetailsColumnMappings(
    'CATEGORY',
    'Category',
    'lib.cat',
    'sku_zzcatgy',
    'job.sku_zzcatgy',
  );
  JOB_ID = new JobDetailsColumnMappings(
    'JOB_ID',
    'Job ID',
    'shopping_cart_jobid_header',
    'jobid',
    'jobid',
  );
  PROJECT_NAME = new JobDetailsColumnMappings(
    'PROJECT_NAME',
    'Project name',
    'file-ser.project_name',
    'projekt_name',
    'job.projekt_name.keyword',
  );
  PRINTER_NAME = new JobDetailsColumnMappings(
    'PRINTER_NAME',
    'Printer name',
    'file-ser.printer_name',
    'search_printer',
    'job.search_printer',
  );
  BRAND = new JobDetailsColumnMappings(
    'BRAND',
    'Brand',
    'file-ser.brand',
    'sku_zzbrand',
    'job.sku_zzbrand',
  );
  SUBBRAND = new JobDetailsColumnMappings(
    'SUBBREAND',
    'Subbrand',
    'file-ser.subbrand',
    'sku_zzsubbr',
    'job.sku_zzsubbr',
  );
  PRODUCT_NAME = new JobDetailsColumnMappings(
    'PRODUCT_NAME',
    'Product name',
    'file-ser.product_name',
    'sku_zzvarnt',
    'job.sku_zzvarnt',
  );
  KEYWORDS = new JobDetailsColumnMappings(
    'KEYWORDS',
    'Keywords',
    'file-ser.keywords',
    'stichw',
    'job.stichw.keyword',
  );
  PACK_CODE = new JobDetailsColumnMappings(
    'PACK_CODE',
    'Pack code',
    'file-ser.pack_code',
    'zlp_mth_matcode',
    'job.zlp_mth_matcode',
  );
  PRINT_METHOD = new JobDetailsColumnMappings(
    'PRINT_METHOD',
    'Print method',
    'lib.print_method',
    'zlp_mth_printer_spec_name',
    'job.zlp_mth_printer_spec_name',
  );
  TABLE_NAME = new JobDetailsColumnMappings(
    'TABLE_NAME',
    'Table name',
    'file-ser.table_name',
    'db',
    'db',
  );

  constructor() {
    super();
    this.initEnum('JobDetailsColumnMappings');
  }

  find(state: string): JobDetailsColumnMappings | null {
    const found = this.values.find((value) => value.propName === state);

    if (found) return found;

    return null;
  }
}

export const JobDetailsColumnMappingsEnum = new JobDetailsColumnMappingsEnumType();
