import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Flag, jobFormApi, useUpdateFlagMutation } from 'src/features/JobForm/JobForm.service';
import { FieldTransformed, jobApi, Options } from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { filterFormData } from 'src/utilities/helperFunctions';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

export type FormData = { comment: string; reason: Options | [] };
type UseFlagDialogProps = {
  isOpen: boolean;
  onClose: (isSubmitting: boolean) => void;
} & Pick<Flag, 'code' | 'domain'>;

const FIELDS: Record<string, Pick<FieldTransformed, 'isRequired'> | undefined> = {
  comment: { isRequired: false },
  reason: { isRequired: false },
};

export function useFlagDialog({ code, domain, isOpen, onClose }: UseFlagDialogProps) {
  const { jobId, jobType } = useRouteParams();
  const { fields } = useJobContext();
  const [options, setOptions] = useState<Options>([]);
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { dirtyFields, isSubmitting },
    handleSubmit: reactHookFormHandleSubmit,
    reset,
  } = useForm({
    defaultValues: { comment: '', reason: [] },
    mode: 'onChange',
  });

  const [updateFlag] = useUpdateFlagMutation();

  async function handleSubmit(formData: FormData) {
    try {
      await updateFlag({
        flag: code,
        jobId,
        jobType,
        ...filterFormData(dirtyFields, FIELDS, formData),
      })
        .unwrap()
        .then(({ message }) => {
          reset();
          onClose(false);
          dispatch(
            openWaveSnack({
              message: message,
              type: 'success',
            }),
          );
          dispatch(jobApi.util.invalidateTags(['Job']));
          dispatch(jobFormApi.util.invalidateTags(['Flags', 'Steps']));
        });
    } catch (error) {
      console.log(error);
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();

    return reactHookFormHandleSubmit(handleSubmit)(e);
  }

  useEffect(() => {
    if (code === 'onhold' || code === 'cancel') {
      const flagFields =
        code === 'onhold'
          ? fields?.['onhold_reason']?.fieldData
          : fields?.['cancel_reason']?.fieldData;

      setOptions(flagFields as Options);
    }
  }, [isOpen]);

  return {
    control,
    isSubmitting,
    onSubmit,
    options: !domain ? [] : options,
  };
}
