import { ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useUploadFiles() {
  const [files, setFiles] = useState<FileList | null>(null);
  const isDialogOpen = !!files?.length;

  const { t: translate } = useTranslation();

  function handleCloseDialog(isSubmitting: boolean) {
    if (isSubmitting) return false;

    setFiles(null);
  }

  function handleOpenDialog(e: ChangeEvent<HTMLInputElement>) {
    setFiles(e.currentTarget.files);
  }

  function handleResetTargetValue(e: MouseEvent<HTMLInputElement>) {
    // This will reset the input's value and trigger
    // the onChange event even if the same file is selected
    e.currentTarget.value = null as any;
  }

  return {
    files,
    handleCloseDialog,
    handleOpenDialog,
    handleResetTargetValue,
    isDialogOpen,
    translate,
  };
}
