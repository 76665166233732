import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type BaseParams = { id?: number };

type ChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
} & BaseParams;

type UpdatePasswordRequest = {
  newPassword: string;
  confirmPassword: string;
  secretKey: string;
};

type CheckPasswordTokenRequest = {
  secretKey: string;
};

export const passwordApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    changePassword: build.mutation<{ message: string }, ChangePasswordRequest>({
      query({ confirmPassword, id, newPassword, oldPassword }) {
        return {
          data: {
            confirmed_password: confirmPassword,
            new_password: newPassword,
            old_password: oldPassword,
          },
          method: 'PUT',
          url: `/users/${id}/password`,
        };
      },
    }),
    checkPasswordToken: build.query<{ message: string }, CheckPasswordTokenRequest>({
      query({ secretKey }) {
        return {
          method: 'GET',
          params: {
            encoded_data: secretKey,
          },
          url: '/checkpasswordtoken',
        };
      },
    }),
    updatePassword: build.mutation<{ message: string }, UpdatePasswordRequest>({
      query({ confirmPassword, newPassword, secretKey }) {
        return {
          data: {
            confirmed_password: confirmPassword,
            encoded_data: secretKey,
            new_password: newPassword,
          },
          method: 'PUT',
          url: '/updatepassword',
        };
      },
    }),
  }),
  reducerPath: 'passwordApi',
});

export const { useChangePasswordMutation, useCheckPasswordTokenQuery, useUpdatePasswordMutation } =
  passwordApi;
