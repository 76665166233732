import { Trans } from 'react-i18next';

import { t } from 'i18next';

import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Slide,
} from '@mui/material';

import { AddRevisorForm } from 'src/components/AddRevisorForm';
import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Checkbox } from 'src/components/RHF/Checkbox';
import { TextField } from 'src/components/RHF/TextField';
import { StatusIndicator } from 'src/components/StatusIndicator';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { Revisor } from 'src/features/JobForm/components/Revisor';
import { StartApproval } from 'src/features/JobForm/components/StartApproval';
import { useStepDialog } from 'src/features/JobForm/components/StepDialog/useStepDialog';
import { AdditionActionTransformed, StepTransformed } from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions';
import { isNumber } from 'src/utilities/helperFunctions2';

type StepDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  step: AdditionActionTransformed | StepTransformed;
};

export function StepDialog({
  isOpen,
  onClose,
  step: { id, name, status, stepFlags },
}: StepDialogProps) {
  const {
    addRevisorData,
    closeTasks,
    control,
    emailNotifications,
    handleAddRevisor,
    handleClickAddRevisor,
    handleClickBack,
    handleResetRevisor,
    isCommentOptional,
    isLoading,
    isSubmitting,
    jobStatusDisplayValue,
    jobType,
    onSubmit,
    options,
    setValue,
    shouldAmendmentRequest,
    startApprovalData,
    startTasksData,
  } = useStepDialog({
    id,
    onClose,
    stepFlags,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      PaperProps={{ sx: { overflowX: 'hidden' } }}
      {...(!isSubmitting && { onClose })}
    >
      <WaveDialogTitle
        onClose={onClose}
        title={
          <Box display="flex">
            {isNumber(jobStatusDisplayValue) ? (
              <Box sx={{ alignItems: 'center', display: 'flex', mr: 1 }}>
                <StatusIndicator
                  jobType={jobType}
                  label={jobStatusDisplayValue.toString()}
                  size="extraSmall"
                />

                <WaveIcon
                  code="step-change-dialog-title-arrow"
                  fontSize="small"
                  sx={{ color: 'primary.contrastText' }}
                />

                <StatusIndicator jobType={jobType} label={status.toString()} size="extraSmall" />
              </Box>
            ) : null}

            {name}
          </Box>
        }
      />

      <Box
        component={addRevisorData?.isOpen ? 'div' : 'form'}
        onSubmit={onSubmit}
        sx={{ bgcolor: 'white' }}
      >
        <Box sx={{ display: 'flex' }}>
          <Slide direction="left" in={addRevisorData?.isOpen} mountOnEnter unmountOnExit>
            <DialogContent>
              <Button
                color="warning"
                onClick={handleClickBack}
                startIcon={<WaveIcon code="step-change-dialog-add-revisor-back" />}
                sx={{ mb: 2 }}
              >
                <Trans i18nKey="lib.back">Back</Trans>
              </Button>

              <Accordion expanded>
                <AccordionSummary expandIcon={null}>
                  <Trans i18nKey="lib.add_revisor">Add Revisor</Trans>
                </AccordionSummary>

                <AddRevisorForm
                  isNewApproval
                  onSubmit={handleAddRevisor}
                  templateId={addRevisorData?.templateEventId}
                />
              </Accordion>
            </DialogContent>
          </Slide>

          <Slide direction="right" in={!addRevisorData?.isOpen} mountOnEnter unmountOnExit>
            <DialogContent>
              {isLoading ? (
                <Skeleton height={100} variant="rounded" />
              ) : (
                <>
                  {startApprovalData?.sections.length ? (
                    <Accordion defaultExpanded disabled={isSubmitting}>
                      <AccordionSummary>{startApprovalData.type}</AccordionSummary>

                      {startApprovalData.sections.map((section) => (
                        <StartApproval
                          actions={startApprovalData?.actions}
                          code={section.code}
                          control={control}
                          handleClickAddRevisor={handleClickAddRevisor}
                          handleClickResetRevisor={handleResetRevisor}
                          isCommentOptional={isCommentOptional}
                          isSingle={startApprovalData.sections.length === 1}
                          isSubmitting={isSubmitting}
                          key={section.code}
                          name={section.name}
                          setValue={setValue}
                          templates={section.templates}
                        />
                      ))}
                    </Accordion>
                  ) : null}

                  {startTasksData.length ? (
                    <Accordion defaultExpanded disabled={isSubmitting}>
                      <AccordionSummary>Tasks</AccordionSummary>

                      {startTasksData.map(({ event_id: eventId, name, revisors }, index) => (
                        <Accordion
                          defaultExpanded={index === 0}
                          disabled={isSubmitting}
                          key={eventId}
                          sx={{ '&:before': { height: '0px' }, mx: '10px !important ' }}
                        >
                          <AccordionSummary>{name}</AccordionSummary>

                          <AccordionDetails>
                            <Revisor
                              control={control}
                              revisors={revisors}
                              setValue={setValue}
                              variant="startTaskRevisor"
                            />

                            <TextField
                              control={control}
                              disabled={isSubmitting}
                              label={t('lib.msg', 'Comment')}
                              name={`startTaskComment${eventId}`}
                              sx={{ mt: 2 }}
                              type="memo"
                              {...(!isCommentOptional && {
                                rules: { required: { message: 'Required', value: true } },
                              })}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Accordion>
                  ) : null}

                  {closeTasks.length ? (
                    <Accordion defaultExpanded disabled={isSubmitting}>
                      <AccordionSummary>
                        <Trans i18nKey="close_tasks_title">Closing Tasks</Trans>
                      </AccordionSummary>

                      <AccordionDetails>
                        <List>
                          {closeTasks.map(({ code, name }) => (
                            <ListItem disablePadding key={code}>
                              <ListItemText primary={name} />
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}

                  {emailNotifications.length ? (
                    <Accordion defaultExpanded disabled={isSubmitting}>
                      <AccordionSummary>
                        <Trans i18nKey="email_notifications_title">Email Notifications</Trans>
                      </AccordionSummary>

                      <AccordionDetails>
                        {emailNotifications.map(
                          ({ checkbox, eventActionId, group, role, type, username, users }) => {
                            const isRole = type === 'email_gruasrole' || type === 'email_rol';
                            const isUser = type === 'email_rol' || type === 'email_usr';
                            const isCheckBoxDisabled = checkbox.disabled;

                            return (
                              <Box
                                key={eventActionId}
                                sx={{ alignItems: 'center', display: 'flex' }}
                              >
                                <Checkbox
                                  control={control}
                                  disabled={isSubmitting || isCheckBoxDisabled}
                                  label={`${isRole ? `${role}: ` : ''}${isUser ? username : group}`}
                                  name={`emailNotification${eventActionId.toString()}`}
                                />

                                {!isUser ? (
                                  <WaveTooltip
                                    body={sortArrayOfObjectsByKey({
                                      array: Object.entries(users).map((user) => {
                                        return { name: user[1], userId: user[0] };
                                      }),
                                      key: 'name',
                                    })}
                                    component={
                                      <WaveIcon code="job-form-steps-dialog-email-notifications-group" />
                                    }
                                    header={`${group} ${t('lib.users', 'Users')}`}
                                    placement="right"
                                    type="list"
                                  />
                                ) : null}
                              </Box>
                            );
                          },
                        )}

                        <TextField
                          control={control}
                          disabled={isSubmitting}
                          label={t('lib.msg', 'Comment')}
                          name="emailNotificationsComment"
                          type="memo"
                          {...(!isCommentOptional && {
                            rules: { required: { message: 'Required', value: true } },
                          })}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}

                  {shouldAmendmentRequest ? (
                    <Autocomplete
                      control={control}
                      disabled={isSubmitting}
                      label={t('flag_reasons_field', 'Reasons')}
                      multiple
                      name="amendmentReasons"
                      options={options}
                      sx={{ paddingBottom: '10px' }}
                    />
                  ) : null}

                  {!startApprovalData?.sections.length &&
                  !startTasksData.length &&
                  !emailNotifications.length ? (
                    <TextField
                      control={control}
                      disabled={isSubmitting}
                      label={t('lib.msg', 'Comment')}
                      name="comment"
                      type="memo"
                      {...(!isCommentOptional && {
                        rules: { required: { message: 'Required', value: true } },
                      })}
                    />
                  ) : null}
                </>
              )}
            </DialogContent>
          </Slide>
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button color="warning" disabled={isSubmitting} onClick={onClose}>
            <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
          </Button>

          <LoadingButton
            disabled={isLoading || addRevisorData?.isOpen}
            loading={isSubmitting}
            type="submit"
            variant="contained"
          >
            <Trans i18nKey="lib.confirm">Confirm</Trans>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
