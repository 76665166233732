import { Button } from 'src/components/Button';
import { useColumnsButton } from 'src/features/JobsTable/components/ColumnsButton/useColumnsButton';
import { FieldSelector } from 'src/features/JobsTable/components/FieldSelector';
import { WaveIcon } from 'src/features/WaveIcon';

type ColumnsButtonProps = { code: string };

export function ColumnsButton({ code }: ColumnsButtonProps) {
  const {
    columnsPreference,
    fieldSelectorType,
    handleApplyFieldSelector,
    handleCloseFieldSelector,
    handleOpenFieldSelector,
    isFieldSelectorOpen,
    savedAvailableFields,
    savedSelectedFields,
    text,
  } = useColumnsButton();

  return (
    <>
      <Button onClick={handleOpenFieldSelector} startIcon={<WaveIcon code={code} />}>
        {text}
      </Button>

      <FieldSelector
        fieldPreference={columnsPreference}
        isOpen={isFieldSelectorOpen}
        onApplyFieldSelector={handleApplyFieldSelector}
        onCloseFieldSelector={handleCloseFieldSelector}
        savedAvailableFields={savedAvailableFields}
        savedSelectedFields={savedSelectedFields}
        type={fieldSelectorType}
      />
    </>
  );
}
