import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { RichText } from '../../../../../components/RHF/RichText';
import { WaveTooltip } from '../../../../../components/WaveTooltip';
import { WaveIcon } from '../../../../WaveIcon';
import { useGetNoContentPlaceholderQuery } from '../../../content.service';
import { WidgetBase } from '../../WidgetBase';

import { ContentCategoriesList, TemplateSelectionParams } from '../../../Content.types';

type RichTextLayoutProps = {
  selectedCategoryIndex: string;
  selectedCategory?: ContentCategoriesList;
};

export function RichTextLayout({ selectedCategory, selectedCategoryIndex }: RichTextLayoutProps) {
  const { t } = useTranslation();
  const [categoryIndex, itemIndex] = selectedCategoryIndex.split('_');
  const { clearErrors, control, getFieldState, setValue } =
    useFormContext<TemplateSelectionParams>();
  const { data, isFetching } = useGetNoContentPlaceholderQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  function handleClickNoContentNeeded() {
    setValue(`categories.${+categoryIndex}.items.${+itemIndex}.result`, data?.text);
    clearErrors(`categories.${+categoryIndex}.items.${+itemIndex}`);
  }

  function handleChangeContent(e: string) {
    const errorState = getFieldState(`categories.${+categoryIndex}.items.${+itemIndex}`).error;

    if (errorState && e) {
      clearErrors(`categories.${+categoryIndex}.items.${+itemIndex}`);
    }
  }

  return (
    <WidgetBase
      action={
        <Box p={1.5}>
          <Button disabled={isFetching} onClick={handleClickNoContentNeeded}>
            No Content Needed
          </Button>
        </Box>
      }
      headerId="layout-parent-header"
      id="layout-parent"
      title={t('rich_text', 'Rich Text')}
    >
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        pl={2}
        pr={selectedCategory?.readonly ? 1 : 2}
        py={2}
        width="100%"
      >
        <RichText
          control={control}
          disabled={selectedCategory?.readonly}
          name={`categories.${+categoryIndex}.items.${+itemIndex}.result`}
          onChange={handleChangeContent}
          placeholder={t('search_your_content', 'Search your content') as string}
          toolbar={[
            ['bold', 'italic', 'underline'],
            [{ script: 'sub' }, { script: 'super' }],
          ]}
        />

        {selectedCategory?.readonly ? (
          <WaveTooltip
            body={selectedCategory?.tooltip}
            component={<WaveIcon code="input-field-information" />}
            placement="top"
            type="simple"
          />
        ) : null}
      </Box>
    </WidgetBase>
  );
}
