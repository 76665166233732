import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from '../../utilities/baseQuery';
import { ExpandedAges } from '../../utilities/hooks/useRouteParams';

import type {
  Action,
  ContentCategoriesList,
  ContentList,
  ContentTemplate,
  CreateContentRequest,
  Languages,
  MasterContent,
  NoContentPlaceholder,
  PackagingTypes,
  SearchOption,
  SearchResult,
  TemplateItem,
} from './Content.types';

type ExtendedParams = {
  age?: ExpandedAges;
  jobId?: number | string;
  src?: string;
};

type SearchResultParams = {
  category: string;
  content?: string;
  fields?: Record<string, string>;
  languages?: string[];
  status?: string[];
};

type CreateTemplateRequest = {
  type?: ExpandedAges;
  name: string;
  items: Array<TemplateItem>;
};

type UpdateTemplateRequest = {
  template_id: number;
  items: Array<TemplateItem>;
};

export const contentApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createContent: build.mutation<void, CreateContentRequest>({
      query(data) {
        return {
          data,
          method: 'POST',
          url: `/copyhub/${data.jobid}/content`,
        };
      },
    }),
    createTemplate: build.mutation<Array<ContentTemplate>, CreateTemplateRequest>({
      invalidatesTags: ['Templates'],
      query(data) {
        return {
          data,
          method: 'POST',
          url: '/copyhub/templates',
        };
      },
    }),
    getActionBar: build.query<Array<Action>, ExtendedParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${params.jobId}/actionbar`,
        };
      },
    }),
    getCategories: build.query<Array<ContentCategoriesList>, {}>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/categories',
        };
      },
    }),
    getContentList: build.query<Record<string, ContentList>, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/content`,
        };
      },
      transformResponse(response: Array<MasterContent>) {
        const result: Record<string, ContentList> = {};

        response.forEach((data) => {
          const existingContent = result[data.Metadata.Category];
          const contentItem = {
            language: data.Language,
            status: data.Status,
            text: data.Text,
          };

          if (existingContent) {
            existingContent.push(contentItem);
          } else {
            result[data.Metadata.Category] = [contentItem];
          }
        });

        return result;
      },
    }),
    getLanguages: build.query<Record<string, string>, ExtendedParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/languages',
        };
      },
    }),
    getLayouts: build.query<Record<string, string>, {}>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/layouts',
        };
      },
    }),
    getNoContentPlaceholder: build.query<NoContentPlaceholder, {}>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/placeholder',
        };
      },
    }),
    getPackagingTypes: build.query<PackagingTypes, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/packaging-types`,
        };
      },
    }),
    getSearchOptions: build.query<Array<SearchOption>, ExtendedParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${params.jobId}/search-options`,
        };
      },
    }),
    getSearchResults: build.query<Array<SearchResult>, SearchResultParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/search',
        };
      },
    }),
    getTemplate: build.query<string, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/template`,
        };
      },
    }),
    getTemplates: build.query<Array<ContentTemplate>, null>({
      providesTags: ['Templates'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/copyhub/templates',
        };
      },
    }),
    getTranslations: build.query<Languages, ExtendedParams>({
      query({ jobId, ...params }) {
        return {
          method: 'GET',
          params,
          url: `/copyhub/${jobId}/translations`,
        };
      },
    }),
    updateTemplate: build.mutation<void, UpdateTemplateRequest>({
      invalidatesTags: ['Templates'],
      query(data) {
        return {
          data,
          method: 'PUT',
          url: '/copyhub/templates',
        };
      },
    }),
  }),
  reducerPath: 'contentApi',
  tagTypes: ['Templates'],
});

export const {
  useCreateContentMutation,
  useCreateTemplateMutation,
  useGetActionBarQuery,
  useGetCategoriesQuery,
  useGetContentListQuery,
  useGetLanguagesQuery,
  useGetLayoutsQuery,
  useGetNoContentPlaceholderQuery,
  useGetPackagingTypesQuery,
  useGetSearchOptionsQuery,
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useGetTranslationsQuery,
  useLazyGetSearchResultsQuery,
  useUpdateTemplateMutation,
} = contentApi;
