import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { useRouteParams } from '../../../../utilities/hooks';
import {
  Approval,
  SubApproval,
  SubApprovalDetail,
  useDeleteRevisorMutation,
  useGetApprovalsQuery,
  useGetSubApprovalsForExpandedApprovalQuery,
} from '../../Approvals.service';
import { DetailsTable } from '../DetailsTable';
import { MasterContent } from '../MasterContent';

type ApprovalItemProps = {
  approvalItem: Approval;
  index: number;
  isExpanded: boolean;
  onToggleExpand: (arg: number) => void;
};

export function ApprovalItem({
  approvalItem,
  index,
  isExpanded,
  onToggleExpand,
}: ApprovalItemProps) {
  const { age, jobId, jobType } = useRouteParams();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [deletePosition, setDeletePosition] = useState<SubApprovalDetail>();
  const [isMasterContentOpen, setIsMasterContentOpen] = useState(false);
  const [deleteRevisor] = useDeleteRevisorMutation();
  const { isFetching: areApprovalsFetching } = useGetApprovalsQuery({
    age,
    jobid: jobId,
    src: jobType,
  });
  const {
    data: SubApprovalsForExpandedApproval,
    isFetching: areSubApprovalsForExpandedApprovalFetching,
  } = useGetSubApprovalsForExpandedApprovalQuery(
    {
      age,
      loop_id: approvalItem.id,
    },
    { refetchOnMountOrArgChange: true, skip: !isExpanded || index === 0 },
  );

  const subApprovals = index === 0 ? approvalItem.approval : SubApprovalsForExpandedApproval;
  const isFetching =
    index === 0 ? areApprovalsFetching : areSubApprovalsForExpandedApprovalFetching;
  const isPhraseApproval = approvalItem.type.includes('apl-ph');
  const { t } = useTranslation();

  function handleDeleteRevisor() {
    if (deletePosition) {
      const isGroupRevisor = deletePosition.gru_id !== 0;

      deleteRevisor({
        age,
        gid: isGroupRevisor ? deletePosition.gru_id : null,
        jobid: jobId,
        loop_id: deletePosition.loop_id,
        loop_user_id: isGroupRevisor ? null : deletePosition.user_id,
        src: jobType,
        states_id: deletePosition.id,
      })
        .unwrap()
        .then(() => {
          setIsConfirmationDialogOpen(false);
        });
    }
  }

  function handleOpenDeleteConfirmationDialog(position: SubApprovalDetail) {
    setDeletePosition(position);
    setIsConfirmationDialogOpen(true);
  }

  return (
    <Accordion expanded={isExpanded || false} key={approvalItem.id}>
      <AccordionSummary onClick={() => onToggleExpand(approvalItem.id)}>
        <Typography fontWeight={500}>
          {`${t('job-apl.menu', 'Approval Workflow')}: ${approvalItem.title}`}&nbsp;
        </Typography>

        <Typography>
          {`| ${t('lib.created', 'Created ')}: ${approvalItem.creation}`}&nbsp;
        </Typography>

        {approvalItem.closed ? (
          <Typography>{`| ${t('lib.close', 'Closed')}: ${approvalItem.closed}`}&nbsp;</Typography>
        ) : null}

        {approvalItem.deadline ? (
          <Typography>{`| ${t('lib.ddl', 'Deadline')}: ${approvalItem.deadline}`}&nbsp;</Typography>
        ) : null}
      </AccordionSummary>

      <Box component={AccordionDetails} p={0} px={1}>
        {(subApprovals as Array<SubApproval>)?.map((approval) =>
          approval.prefix ? (
            <Accordion defaultExpanded key={approval.prefix}>
              <AccordionSummary sx={{ backgroundColor: 'filler.main', color: 'primary.main' }}>
                <Typography variant="body1">{approval.prefix}</Typography>
              </AccordionSummary>

              {approval.details.length ? (
                <DetailsTable
                  approvalDetails={approval.details}
                  isFetching={isFetching}
                  isPhraseApproval={isPhraseApproval}
                  onHandleOpenMasterContent={() => setIsMasterContentOpen(true)}
                  onSubApprovalDelete={handleOpenDeleteConfirmationDialog}
                />
              ) : (
                <Box p={1} textAlign="center">
                  <Typography color="text.secondary" variant="button">
                    Nothing to show
                  </Typography>
                </Box>
              )}
            </Accordion>
          ) : (
            <DetailsTable
              approvalDetails={approval.details}
              isFetching={isFetching}
              isPhraseApproval={isPhraseApproval}
              key={approval.id}
              onHandleOpenMasterContent={() => setIsMasterContentOpen(true)}
              onSubApprovalDelete={handleOpenDeleteConfirmationDialog}
            />
          ),
        )}
      </Box>

      {isPhraseApproval && (
        <MasterContent
          isMasterContentOpen={isMasterContentOpen}
          onClose={() => setIsMasterContentOpen(false)}
        />
      )}

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        message={t('remove_user_group_from_approval', {
          defaultValue: 'Are you sure you want to remove {{ userGroup }} from the approval loop?',
          userGroup: deletePosition?.user || deletePosition?.group,
        })}
        onConfirmation={handleDeleteRevisor}
        onDialogClose={() => setIsConfirmationDialogOpen(false)}
      />
    </Accordion>
  );
}
