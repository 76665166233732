import { TextField, InputAdornment } from '@mui/material';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

export function MultipleSelect({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onChange,
  options,
  required,
  value,
}) {
  const { t } = useTranslation();
  options = options.map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));

  value = !value ? [] : typeof value === 'string' ? value.split(',') : value;

  value.forEach((value) => {
    if (!options.some((option) => option.value === value)) {
      // Add the value as a new option if the value isn't already an option
      options = [...options, { label: value, value }];
    }
  });

  function handleOnChange(e) {
    e.target.arrayValue = e.target.value;
    e.target.id = id;
    e.target.name = label;
    e.target.textValue = options
      .filter(({ value }) => e.target.arrayValue.includes(value))
      .map(({ label }) => label);
    e.target.value = e.target.value.toString();

    onChange(e);
  }

  return (
    <TextField
      disabled={disabled}
      error={(checkRequiredFields && required && !value.length) || isError}
      fullWidth
      id={id}
      InputProps={{
        className: 'pl-0', // Classes applied to input wrapper, allows InputAdornment (Prime React component) to be all the way to the left edge of the MUI input
        inputProps: { className: 'pr-0 w-0' }, // Classes applied to the mui input that comes after the InputAdornment
        startAdornment: (
          <InputAdornment position="start" className="m-0 primary-txt  w-100p">
            <MultiSelect
              disabled={disabled}
              filter={true}
              filterPlaceholder={t('search', 'Search')}
              onChange={handleOnChange}
              options={options}
              required={required}
              value={value}
            />
          </InputAdornment>
        ),
      }}
      label={label}
      required={required}
      size="small"
      variant="outlined"
    />
  );
}
