import { useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

export function DateTimeField({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onChange,
  required,
  value,
}) {
  const [open, setOpen] = useState(false);

  function handleChange(value) {
    const event = {
      target: { id, value: value === null ? null : new Date(value) },
    };

    onChange(event);
  }

  function handleCloseCalendarOverlay() {
    setOpen(false);
  }

  function handleOpenCalendarOverlay() {
    setOpen(disabled ? false : true);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        disabled={disabled}
        id={id}
        label={label}
        value={value}
        onChange={handleChange}
        onClose={handleCloseCalendarOverlay}
        onOpen={handleOpenCalendarOverlay}
        open={open}
        renderInput={(params) => (
          <TextField
            {...params}
            error={(checkRequiredFields && required && !value) || isError}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={handleOpenCalendarOverlay}
            required={required}
            size="small"
            sx={{
              svg: { color: "primary.main" },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
