import { PreferenceValue } from 'src/utilities/hooks/usePreference';

export function convertPreferenceValueToNumber(value: PreferenceValue, defaultValue: string) {
  if (isNumber(value)) {
    return value;
  }

  if (isString(value)) return parseInt(value);

  return parseInt(defaultValue);
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}
