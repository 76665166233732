import { Grid } from '@mui/material';

import { ChartTitle } from '../ChartTitle';
import { useTranslation } from 'react-i18next';

export function NothingToShow({ title }) {
  const { t } = useTranslation();
  return (
    <Grid alignItems="center" container direction="column" item xs>
      <ChartTitle title={title} />

      <Grid alignItems="center" display="flex" item textAlign="center" xs>
        {t('files_nothing_to_show', 'Nothing to Show')}
      </Grid>
    </Grid>
  );
}
