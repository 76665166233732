import { TextField } from '@mui/material';

export function Integer({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onBlur,
  onChange,
  required,
  value,
}) {
  function handleOnBlur(e) {
    e.target.name = label;

    onBlur && onBlur(e);
  }

  return (
    <TextField
      autoComplete="off"
      disabled={disabled}
      error={(checkRequiredFields && required && !value) || isError}
      fullWidth
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      onBlur={handleOnBlur}
      onChange={onChange}
      required={required}
      size="small"
      type="number"
      value={value || ''}
      variant="outlined"
    />
  );
}
