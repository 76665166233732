import React from 'react';

import { BreadCrumbs } from '../../components/BreadCrumbs';

export function GeneralHelp() {
  return (
    <React.Fragment>
      <BreadCrumbs />
    </React.Fragment>
  );
}
