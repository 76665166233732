import { MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

import { Approval } from 'src/features/Approvals/Approvals.service';
import { ApprovalCellProps } from 'src/features/JobsTable/components/ApprovalCell/ApprovalCell';
import { isArchivedStatus } from 'src/features/JobsTable/utilities/helperFunctions';
import { usePreferencePrefix } from 'src/utilities/hooks';

type useApprovalCellProps = Omit<ApprovalCellProps, 'isSubRow' | 'paddingY'>;

export function useApprovalCell({ jobId, rowJobType, status, value }: useApprovalCellProps) {
  const { age } = usePreferencePrefix({
    forcedAge: isArchivedStatus(status) ? 'arc' : 'job',
  });
  const navigate = useNavigate();
  const approvals = computeApprovals(value);

  function computeApprovals(value: Approval | {}) {
    if ('approval' in value) {
      if (Array.isArray(value.approval)) return value.approval;
      else return [value.approval];
    }

    return [];
  }

  function handleClickCell(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/jobs-${age}-${rowJobType}/${jobId}/apl`);
  }

  return { age, approvals, handleClickCell };
}
