import { memo } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { Trans } from 'react-i18next';

import { objectToOrderedArray } from '../../../../utilities/helperFunctions';

const Selection = ({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  loadingCascadingFields,
  onChange,
  options,
  required,
  value,
}) => {
  options = objectToOrderedArray(
    value && !options.hasOwnProperty(value)
      ? // Add the value as a new option if the value isn't already an option
        { ...options, [value]: value.toString() }
      : options,
  );

  function handleOnChange(e) {
    e.target.name = label;

    e.target.blur();
    onChange(e);
  }

  return (
    <div className="flex-grow-1 p-relative">
      {loadingCascadingFields?.includes(id) && (
        <CircularProgress className="buttonProgress" size={24} />
      )}
      <TextField
        disabled={loadingCascadingFields?.includes(id) || disabled || false}
        error={
          (checkRequiredFields &&
            required &&
            (!value || (options.length && value.toString() === options[0][0].toString()))) ||
          isError
        }
        fullWidth
        id={id}
        InputLabelProps={{
          shrink: true,
        }}
        label={<Trans>{label}</Trans>}
        onChange={handleOnChange}
        required={required}
        select
        SelectProps={{
          native: true,
        }}
        size="small"
        value={value || ''}
        variant="outlined"
      >
        {options.map((option) => (
          <option key={option[0]} value={option[0]}>
            {option[1]}
          </option>
        ))}
      </TextField>
    </div>
  );
};

export const MemoizedSelection = memo(Selection);
