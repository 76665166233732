import { memo } from 'react';

import { Box, Collapse, IconButton, TableRow, Typography } from '@mui/material';

import { getGeneratedEmailFile } from 'src/features/History/components/EmailListItem/utils';
import { FormattedText } from 'src/features/History/components/FormattedText';
import { StyledTableCell, StyleEmailTableCell } from 'src/features/History/components/styled';
import { GetEmailListResponse } from 'src/features/History/History.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { formatDate } from 'src/utilities/helperFunctions';

type EmailListItemProps = {
  emailData: GetEmailListResponse;
  index: number;
  isLastEmailListItem: boolean;
  isEmailListItemExpanded: boolean;
  onClickToggleEmailListItemExpanded: (id: number) => void;
};

function EmailListItem({
  emailData,
  index,
  isEmailListItemExpanded,
  isLastEmailListItem,
  onClickToggleEmailListItemExpanded,
}: EmailListItemProps) {
  const {
    from_name: fromName,
    id,
    mail_body: mailBody,
    mail_date: mailDate,
    mail_entry: mailEntry,
    mail_errmsg: mailErrorMessage,
    mail_state: mailState,
    mail_subject: mailSubject,
    to_name: toName,
  } = emailData;

  const mailDateDateType = new Date(mailDate);
  const mailEntryDateType = new Date(mailEntry);

  const formattedMailDate = formatDate(mailDateDateType, 'dd.mm.yyyy hh:00');
  const formattedMailEntry = formatDate(mailEntryDateType, 'dd.mm.yyyy hh:00');
  const numberedRow = index + 1;

  const shouldShowLastEmailListItemBorder = !isLastEmailListItem || isEmailListItemExpanded;

  function handleDownloadEmail(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();

    getGeneratedEmailFile(emailData);
  }

  return (
    <>
      <TableRow onClick={() => onClickToggleEmailListItemExpanded(id)}>
        <StyledTableCell
          isIndexCell
          shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}
        >
          {numberedRow}
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          <WaveIcon code="email" />
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          {formattedMailEntry}
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          {formattedMailDate}
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          {fromName}
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          {toName}
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          {mailSubject}
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          <IconButton onClick={handleDownloadEmail}>
            <WaveIcon code="download" />
          </IconButton>
        </StyledTableCell>

        <StyledTableCell shouldShowLastEmailListItemBorder={shouldShowLastEmailListItemBorder}>
          <WaveIcon code={isEmailListItemExpanded ? 'expand-less' : 'expand-more'} />
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <StyleEmailTableCell isEmailListItemExpanded={isEmailListItemExpanded} isIndexCell />

        <StyleEmailTableCell colSpan={8} isEmailListItemExpanded={isEmailListItemExpanded}>
          <Collapse in={isEmailListItemExpanded}>
            <Box margin={2}>
              <Typography paddingBottom={1}>
                <Box component="span" fontWeight="bold" paddingRight={1}>
                  State
                </Box>

                {`[${mailState}] ${mailErrorMessage}`}
              </Typography>

              <Box bgcolor="filler.main" padding={1}>
                <FormattedText text={mailBody} />
              </Box>
            </Box>
          </Collapse>
        </StyleEmailTableCell>
      </TableRow>
    </>
  );
}

export const MemoizedEmailListItem = memo(EmailListItem);
export { MemoizedEmailListItem as EmailListItem };
