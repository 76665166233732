import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Typography } from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import {
  useGetUserProfileQuery,
  UserDetailsType,
  useUpdateUserProfileMutation,
} from 'src/pages/UserProfile/UserProfile.service';
import { getUserDetails } from 'src/store/userSlice';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { filterFormData } from 'src/utilities/helperFunctions';
import { useAppDispatch, useAppSelector } from 'src/utilities/hooks';
import { ChangePasswordDialog } from './components/ChangePasswordDialog';

const FIELDS: Record<string, Pick<FieldTransformed, 'isRequired'> | undefined> = {
  company: { isRequired: true },
  firstName: { isRequired: true },
  lastName: { isRequired: true },
  location: { isRequired: true },
  username: { isRequired: true },
};

export function UserDetails() {
  const dispatch = useAppDispatch();
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const userId = useAppSelector((state) => state.user.details.id);
  const { t } = useTranslation();
  const REQUIRED_RULE = { required: { message: `${t('lib.required')}`, value: true } };

  const {
    control,
    formState: { dirtyFields, isDirty, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      company: '',
      department: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      location: '',
      phoneNumber: '',
      username: '',
    },
    mode: 'onChange',
  });
  const { data, isFetching } = useGetUserProfileQuery(
    { userId },
    { refetchOnMountOrArgChange: true },
  );
  const [updateUserProfile] = useUpdateUserProfileMutation();

  async function onSubmit(formData: UserDetailsType) {
    await updateUserProfile({
      userId,
      ...(filterFormData(dirtyFields, FIELDS, formData) as UserDetailsType),
    })
      .unwrap()
      .then(async (message) => {
        await dispatch(getUserDetails()).then(() => {
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
        });
      });
  }

  function handleOnClickChangePassword() {
    setIsPasswordDialogOpen(true);
  }

  function handleOnClose() {
    setIsPasswordDialogOpen(false);
  }

  useEffect(() => {
    if (!isFetching && data) {
      reset(data);
    }
  }, [isFetching]);

  return (
    <>
      <Box component="form" display="flex" onSubmit={handleSubmit(onSubmit)}>
        <Box component={Paper} display="flex" flexDirection="column" flexGrow={1} gap={3} p={2}>
          <Box alignItems="center" display="flex" gap={5} justifyContent="space-between">
            <Typography component="h2" variant="h4">
              <Trans i18nKey="user_details_title">User Details</Trans>
            </Typography>

            <Box>
              <Box display="flex" gap={2}>
                <WaveTooltip
                  body={!isDirty ? 'Nothing has changed.' : ''}
                  component={
                    <Button color="warning" disabled={!isDirty} onClick={() => reset()}>
                      {t('lib.reset')}
                    </Button>
                  }
                  type="simple"
                />

                <Button
                  onClick={handleOnClickChangePassword}
                  startIcon={<WaveIcon code="user-profile-action-bar-change-password" />}
                >
                  {t('hom.pwd.change')}
                </Button>

                <WaveTooltip
                  body={!isDirty ? 'Nothing to update.' : !isValid ? 'The form is invalid.' : ''}
                  component={
                    <LoadingButton
                      disabled={!isDirty || !isValid}
                      loading={isSubmitting}
                      startIcon={<WaveIcon code="user-profile-action-bar-save" />}
                      type="submit"
                      variant="contained"
                    >
                      {t('clip.save')}
                    </LoadingButton>
                  }
                  type="simple"
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('lib.firstname')}
                name="firstName"
                rules={REQUIRED_RULE}
              />

              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('usr.name')}
                name="username"
                rules={REQUIRED_RULE}
              />
            </Box>

            <Box display="flex" gap={2}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('lib.lastname')}
                name="lastName"
                rules={REQUIRED_RULE}
              />

              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('lib.company')}
                name="company"
                rules={REQUIRED_RULE}
              />
            </Box>

            <Box display="flex" gap={2}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('lib.location')}
                name="location"
                rules={REQUIRED_RULE}
              />

              <TextField
                control={control}
                disabled
                isFetching={isFetching}
                label={t('lib.email')}
                name="emailAddress"
                rules={{
                  pattern: {
                    message: 'Must be a valid email address.',
                    value:
                      /* eslint-disable-next-line max-len */
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  },
                }}
              />
            </Box>

            <Box display="flex" gap={2}>
              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('usr.department')}
                name="department"
              />

              <TextField
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('user_details_phone_number_field', 'Phone number')}
                name="phoneNumber"
                rules={{
                  pattern: {
                    message: 'Must be a valid phone number.',
                    value:
                      /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <ChangePasswordDialog isOpen={isPasswordDialogOpen} onClose={handleOnClose} />
    </>
  );
}
