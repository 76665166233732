import { styled } from '@mui/material';

type StyledImageProps = {
  height?: string;
  width?: string;
};

export const StyledImage = styled('img')<StyledImageProps>`
  height: ${({ height }) => (height ? height : '200px')};
  width: ${({ width }) => (width ? width : '100%')};
  object-fit: cover;
  user-select: none;
`;
