import { useEffect } from 'react';

import { useSelection } from '@viselect/react';

type FileSearchSelectionAreaContainerProps = {
  children: React.ReactNode;
};

export function FileSearchSelectionAreaContainer({
  children,
}: FileSearchSelectionAreaContainerProps): JSX.Element {
  const selection = useSelection();

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        selection?.clearSelection();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  return <>{children}</>;
}
