import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react';

import { Button, IconButton } from '@mui/material';

import { ConfirmationDialog } from 'src/components/ConfirmationDialog';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';
import {
  FilesType,
  useDeleteFileMutation,
  useDeleteFolderMutation,
} from '../../FileExplorer.service';

type FileIcon = {
  code?: never;
  disabledText?: never;
  fileId: number;
  folderPathName?: never;
  files: FilesType;
  folderCode?: never;
  location: string;
  name?: never;
  onDeleteCallback: () => void;
  onToggleSelectAll: (arg0: boolean) => void;
  selectedFileIds?: never;
  setSelectedFolder?: never;
  variant: 'fileIcon';
};
type FolderIcon = {
  code?: never;
  disabledText?: never;
  fileId?: never;
  files?: never;
  folderCode: string;
  folderPathName: string;
  location: string;
  name?: never;
  onDeleteCallback?: never;
  onToggleSelectAll?: never;
  selectedFileIds?: never;
  setSelectedFolder: Dispatch<SetStateAction<string>>;
  variant: 'folderIcon';
};
type Named = {
  code: string;
  disabledText: string;
  fileId?: never;
  files: FilesType;
  folderCode?: never;
  folderPathName?: never;
  location?: never;
  name: string;
  onDeleteCallback: () => void;
  onToggleSelectAll: (arg0: boolean) => void;
  selectedFileIds: number[];
  setSelectedFolder?: never;
  variant: 'named';
};
type DeleteButtonProps = FileIcon | FolderIcon | Named;

export function DeleteButton({
  code,
  disabledText,
  fileId,
  files,
  folderCode,
  folderPathName,
  location,
  name,
  onDeleteCallback,
  onToggleSelectAll,
  selectedFileIds,
  setSelectedFolder,
  variant,
}: DeleteButtonProps) {
  const [areItemsBeingDeleted, setAreItemsBeingDeleted] = useState(false);
  const [deleteFile] = useDeleteFileMutation();
  const [deleteFolder] = useDeleteFolderMutation();
  const dispatch = useAppDispatch();
  const [filesToDelete, setFilesToDelete] = useState<FilesType>([]);
  const [folderToDelete, setFolderToDelete] = useState('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  function handleCloseConfirmationDialog() {
    if (areItemsBeingDeleted) return;

    if (variant !== 'folderIcon') {
      onDeleteCallback();
      onToggleSelectAll(false);
    }

    setIsConfirmationDialogOpen(false);
  }

  function handleDeleteItems() {
    setAreItemsBeingDeleted(true);
    if (variant === 'folderIcon') {
      deleteFolder({
        path: folderCode,
      })
        .unwrap()
        .then((message) => {
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          setAreItemsBeingDeleted(false);
          setSelectedFolder(folderCode.slice(0, folderCode.lastIndexOf('/')));
        });
    } else {
      filesToDelete.map(({ id }) => {
        deleteFile({ fileId: id })
          .unwrap()
          .then(async (message) => {
            dispatch(
              openWaveSnack({
                message,
                type: 'success',
              }),
            );
            setFilesToDelete((previousFilesToDelete) => {
              if (previousFilesToDelete.length === 1) {
                setAreItemsBeingDeleted(false);

                return previousFilesToDelete;
              }

              return previousFilesToDelete.filter(({ id: previousId }) => previousId !== id);
            });
          });
      });
    }
  }

  function handleOpenConfirmationDialog(fileIds?: number[]) {
    variant === 'folderIcon'
      ? setFolderToDelete(folderPathName)
      : setFilesToDelete(files.filter(({ id }) => fileIds?.includes(id)));

    setIsConfirmationDialogOpen(true);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !areItemsBeingDeleted && (filesToDelete.length || folderToDelete)) {
      handleCloseConfirmationDialog();
    }

    return () => {
      isMounted = false;
    };
  }, [areItemsBeingDeleted]);

  return (
    <>
      {variant === 'named' ? (
        <WaveTooltip
          body={!selectedFileIds.length ? disabledText : ''}
          component={
            <Button
              color="error"
              disabled={!selectedFileIds.length}
              onClick={() => handleOpenConfirmationDialog(selectedFileIds)}
              startIcon={<WaveIcon code={`general-files-action-bar-${code}`} />}
            >
              {name}
            </Button>
          }
          key={code}
          placement="top"
          type="simple"
        />
      ) : (
        <IconButton
          color="error"
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handleOpenConfirmationDialog(variant !== 'folderIcon' ? [fileId] : undefined);
          }}
          size="small"
        >
          <WaveIcon code={`${location}-delete`} />
        </IconButton>
      )}

      <ConfirmationDialog
        isDisabled={areItemsBeingDeleted}
        isOpen={isConfirmationDialogOpen}
        message={`Are you sure you want to delete ${
          variant === 'folderIcon'
            ? folderToDelete
            : filesToDelete.map(({ file_name: filename }) => filename).join(', ')
        }?`}
        onConfirmation={handleDeleteItems}
        onDialogClose={handleCloseConfirmationDialog}
      />
    </>
  );
}
