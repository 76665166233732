import { Control, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';

type CompareReplaceRenameTemplateDialogProps = {
  isOpen: boolean;
  onRenameTemplate: () => void;
  onCloseRenameTemplateDialog: () => void;
  isRenameTemplateLoading: boolean;
  control: Control<any>;
};

export function CompareReplaceRenameTemplateDialog({
  control,
  isOpen,
  isRenameTemplateLoading,
  onCloseRenameTemplateDialog,
  onRenameTemplate,
}: CompareReplaceRenameTemplateDialogProps) {
  const { t } = useTranslation();
  const templateNameUpdateValue = useWatch({
    control,
    defaultValue: '',
    name: 'templateNameUpdate',
  });

  return (
    <Dialog fullWidth open={isOpen}>
      <WaveDialogTitle
        onClose={onCloseRenameTemplateDialog}
        title={t('rename_template', 'Rename template')}
      />

      <DialogContent>
        <Box
          autoFocus
          component={TextField}
          control={control}
          InputLabelProps={{
            shrink: true,
          }}
          label={t('template_name', 'Template Name')}
          name="templateNameUpdate"
        />
      </DialogContent>

      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Box marginRight={2}>
            <Button
              color="warning"
              onClick={onCloseRenameTemplateDialog}
              size="small"
              variant="contained"
            >
              <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
            </Button>
          </Box>

          <LoadingButton
            disabled={!templateNameUpdateValue || templateNameUpdateValue.trim() === ''}
            loading={isRenameTemplateLoading}
            onClick={onRenameTemplate}
            size="small"
            variant="contained"
          >
            <Trans i18nKey="lib.confirm">Cancel</Trans>
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
