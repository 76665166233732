import { useMemo, useRef, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import { useGetCreateCopyToItemsQuery } from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useRouteParams } from 'src/utilities/hooks';

type CopyToJobParams = {
  copyMode: 'copy-to';
  copyToJobType?: string;
  copyJobId?: string | number;
};

type CopyInProjectParams = {
  copyMode: 'copy-in-project';
  copyToJobType?: string;
  copyJobId?: string | number;
  copyToProjectId?: string | number;
  copyToProjectType?: string;
};

type CopyParams = CopyInProjectParams | CopyToJobParams;

export function useCreateCopy() {
  const { age, jobId, jobType } = useRouteParams();

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const createCopyButton = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const { isDirty } = useFormState();

  const { data: createCopyToItems, isFetching } = useGetCreateCopyToItemsQuery(
    { age, jobId, jobType },
    { skip: jobId === 'new' },
  );

  const createCopyButtons = useMemo(
    () =>
      createCopyToItems?.map(({ code, data, subItems, text }) => ({
        code,
        icon: (
          <WaveIcon
            code={`job-form-action-bar-create-copy-${code}`}
            color="primary"
            fontSize="small"
          />
        ),
        navigateIcon: <WaveIcon code="navigate-next" color="primary" fontSize="small" />,
        onClick: () => {
          navigate(`/jobs-job-${jobType}/new/job`);
        },
        subItems:
          code !== 'new-job'
            ? Object.entries(subItems).map(([copyJobType, text]) => ({
                code: copyJobType,
                icon: (
                  <WaveIcon
                    code={`job-form-action-bar-create-copy-${code}-job-type`}
                    color="primary"
                    fontSize="small"
                  />
                ),
                onClick: () => {
                  handleCreateCopy({
                    copyJobId: jobId,
                    copyMode: code,
                    copyToJobType: copyJobType,
                    ...(code === 'copy-in-project' && {
                      copyToProjectId: data.jobid,
                      copyToProjectType: data.src,
                    }),
                  });
                },
                text,
              }))
            : undefined,
        text,
      })) ?? [],
    [createCopyToItems],
  );

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function isCopyInProjectParams(params: CopyParams): params is CopyInProjectParams {
    return params.copyMode === 'copy-in-project';
  }

  function handleCreateCopy(params: CopyParams) {
    handleCloseSubMenu();

    const { copyJobId, copyMode, copyToJobType } = params;
    const mode = copyMode === 'copy-in-project' ? 'copy-in-project' : 'copy';
    let copyToProjectParams = '';

    if (isCopyInProjectParams(params)) {
      copyToProjectParams = `&copyToProject=${params.copyToProjectId}&copyToProjectType=${params.copyToProjectType}`;
    }

    const redirectUrl = `/jobs-job-${copyToJobType}/new/job?mode=${mode}&copyFromJobId=${copyJobId}&copyFromJobType=${jobType}${copyToProjectParams}`;

    navigate(redirectUrl);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  return {
    createCopyButton,
    createCopyButtons,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isFetching,
    isSubMenuOpen,
  };
}
