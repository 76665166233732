import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NavLink, useLocation } from 'react-router-dom';

import { Breadcrumbs } from '@mui/material';

import { BreadCrumbItem } from 'src/components/BreadCrumbs/components/BreadCrumbItem';
import { ExtendedLocation, getBreadCrumbs } from 'src/components/BreadCrumbs/utils';
import { RootState } from 'src/store/store';

export function BreadCrumbList() {
  const mainMenu = useSelector((state: RootState) => state.user.mainMenu);
  const location = useLocation() as ExtendedLocation;

  const breadcrumbData = getBreadCrumbs(mainMenu, location);

  return (
    <Breadcrumbs
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: 'primary.contrastText',
        },
      }}
    >
      <NavLink to="/jobs-hom-dash">
        <Trans i18nKey={'hom-dash.menu'}>Dashboard</Trans>
      </NavLink>

      {breadcrumbData.map(({ code, link, name }, index) => {
        const isLink = index !== breadcrumbData.length - 1;

        return (
          <BreadCrumbItem
            code={code}
            isLink={isLink}
            key={`breadbrumb-${code}`}
            link={link}
            name={name}
          />
        );
      })}
    </Breadcrumbs>
  );
}
