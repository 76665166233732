import { useState } from 'react';

import { Avatar, Badge, Box, IconButton, Typography, useTheme } from '@mui/material';

import { Dialog } from 'src/features/EditableAvatar/components/Dialog';
import { WaveIcon } from 'src/features/WaveIcon';
import { useGetUserProfileQuery } from 'src/pages/UserProfile/UserProfile.service';
import { useAppSelector } from 'src/utilities/hooks';

type EditableAvatarProps = {
  backgroundColor: string;
  iconCode: string;
  iconColor: string;
  isSubmitting?: boolean;
  newAvatar?: string;
  shouldDeleteAvatar?: boolean;
  onClick?: (isAvatarSaved: boolean) => void;
};

export function EditableAvatar({
  backgroundColor,
  iconCode,
  iconColor,
  isSubmitting,
  newAvatar,
  onClick,
  shouldDeleteAvatar,
}: EditableAvatarProps) {
  const avatarUrl = useAppSelector((state) => state.user.details.avatar_url);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const userId = useAppSelector((state) => state.user.details.id);
  const { data } = useGetUserProfileQuery({ userId }, { refetchOnMountOrArgChange: true });
  const {
    palette: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filler: { contrastText, main: filler },
    },
  } = useTheme();

  const avatarDimensions = { height: 120, width: 120 };
  const isDeleteIcon = iconCode.endsWith('delete');
  const userInitials =
    data?.firstName && data.lastName
      ? `${data?.firstName.charAt(0).toUpperCase()}${data?.lastName.charAt(0).toUpperCase()}`
      : '';

  function handleClickAvatarIcon() {
    onClick ? onClick(!!avatarUrl) : handleOpenDialog();
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleOpenDialog() {
    setIsDialogOpen(true);
  }

  return (
    <>
      <Badge
        {...((!isDeleteIcon ||
          (isDeleteIcon && ((!shouldDeleteAvatar && avatarUrl) || newAvatar))) && {
          badgeContent: (
            <IconButton
              disabled={isSubmitting}
              onClick={handleClickAvatarIcon}
              sx={{
                ':hover': { bgcolor: iconColor, color: backgroundColor },
                bgcolor: backgroundColor,
                color: iconColor,
              }}
            >
              <WaveIcon code={iconCode} fontSize="small" />
            </IconButton>
          ),
          overlap: 'circular',
        })}
      >
        <Box
          alt="User Avatar"
          bgcolor={filler}
          component={Avatar}
          src={
            shouldDeleteAvatar && !newAvatar
              ? ''
              : newAvatar || `${import.meta.env.VITE_API}${avatarUrl}`
          }
          {...avatarDimensions}
        >
          <Typography color={contrastText} component="span" variant="h3">
            {userInitials}
          </Typography>
        </Box>
      </Badge>

      <Dialog isDialogOpen={isDialogOpen} onCloseDialog={handleCloseDialog} />
    </>
  );
}
