import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from '@mui/material';

import { Button } from 'src/components/Button';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useCopyJobDialog } from 'src/features/JobsTable/components/CopyJobDialog/useCopyJobDialog';
import { CreateCopyButtonTransformed } from 'src/features/JobsTable/JobsTable.service';
import { JobIdRequired } from 'src/pages/Job/Job.service';
import { JobType } from 'src/utilities/hooks/useRouteParams';

export type CopyJobDialogProps = {
  areOptionsFetching: boolean;
  copyJobOptions?: CreateCopyButtonTransformed[];
  onClose: () => void;
} & JobIdRequired &
  Required<JobType>;

export function CopyJobDialog({
  areOptionsFetching,
  copyJobOptions = [],
  jobId,
  jobType,
  onClose,
}: CopyJobDialogProps) {
  const {
    copyMode,
    copyToJobType,
    handleChangeCopyOptions,
    handleChangeCopyToJobTypeSelection,
    handleConfirm,
    selectedSubItems,
    translate,
  } = useCopyJobDialog({ copyJobOptions, jobId, jobType });

  return (
    <Dialog fullWidth onClose={onClose} open={true}>
      <WaveDialogTitle onClose={onClose} title={translate('copy_job_title', 'Copy Job')} />

      <DialogContent>
        {areOptionsFetching ? (
          [...Array(4)].map((_, index) => (
            <Box display="flex" gap={1} key={index} margin={1}>
              <Skeleton height={20} variant="circular" width={20} />

              <Skeleton height={20} sx={{ flexGrow: 1 }} variant="rectangular" />
            </Box>
          ))
        ) : (
          <>
            <Autocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleChangeCopyOptions}
              options={copyJobOptions}
              renderInput={(params) => <TextField {...params} label="Copy Options" />}
              size="small"
              sx={{ width: 300 }}
              value={copyMode}
            />

            {copyMode ? (
              <FormControl sx={{ pt: 3 }}>
                <FormLabel>Job Type</FormLabel>

                <RadioGroup
                  name="copy-to-job-type-list"
                  onChange={handleChangeCopyToJobTypeSelection}
                  value={copyToJobType}
                >
                  {Object.keys(selectedSubItems).map((item) => (
                    <FormControlLabel
                      control={<Radio />}
                      key={item}
                      label={selectedSubItems?.[item]}
                      value={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : null}
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="warning" onClick={onClose}>
          lib.cancel
        </Button>

        <Button disabled={!copyToJobType || !copyMode} onClick={handleConfirm}>
          lib.confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
