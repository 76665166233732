import { useNavigate } from 'react-router-dom';

import { useIsExternalRevisor } from 'src/utilities/hooks/useIsExternalRevisor';
import { usePreference } from 'src/utilities/hooks/usePreference';
import { useRouteParams } from 'src/utilities/hooks/useRouteParams';

import { RowJobType } from 'src/features/JobsTable/types';

export function useViewer() {
  const { age, jobId, jobType, tab } = useRouteParams();

  const navigate = useNavigate();

  const isExternalRevisor = useIsExternalRevisor();
  const pathname = window.location.pathname;
  const token = pathname.split('/')[2];

  const viewerPreference = usePreference('pdf-viewer.open', '_blank');

  function openViewerPage(
    fileId?: number,
    fileJobID?: number | string,
    rowJobType?: RowJobType | string,
  ) {
    const queryParams = new URLSearchParams({
      age: age === 'hom' ? 'dash' : '',
      fileId: fileId?.toString() || '',
      tab: tab || '',
    });

    let url = '';

    if (isExternalRevisor) {
      url = `${window.location.origin}/external-revisor/${token}/jobs-job-${
        rowJobType || jobType
      }/${jobId ? jobId : fileJobID}?${queryParams}`;
    } else {
      url = `${window.location.origin}/jobs-job-${rowJobType || jobType}/${
        jobId ? jobId : fileJobID
      }/viewer?${queryParams}`;
    }

    if (viewerPreference.value === 'self') {
      navigate(url);
    } else {
      window.open(url, `${viewerPreference.value === 'window' ? 'popup' : '_blank'}`);
    }
  }

  return {
    openViewerPage,
  };
}
