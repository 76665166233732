import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type GetUserSettingsRequest = {
  id?: number;
};

type UserSettings = {
  fields: Partial<Record<string, Fields>>;
  values: Partial<Record<string, Values>>;
};

type Fields = {
  alias: string;
  description: string;
  name: string;
  typ: string;
  field_data: Record<string, string>;
  is_disable: boolean;
  is_hidden: boolean;
  is_mandatory: boolean;
  is_error: boolean;
};

type Values = {
  name: string;
  type: string;
  value: string;
};

type UpdateUserSettings = {
  id?: number;
  val: Record<string, string>;
};

export const userSettingsApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getUserSettings: build.query<UserSettings, GetUserSettingsRequest>({
      providesTags: ['UserSettings'],
      query({ id }) {
        return {
          method: 'GET',
          params: { id },
          url: `/users/${id}/settings`,
        };
      },
    }),
    updateUserSettings: build.mutation<{ message: string }, UpdateUserSettings>({
      invalidatesTags: ['UserSettings'],
      query({ id, val }) {
        return {
          method: 'PUT',
          params: { id, val },
          url: `/users/${id}/settings`,
        };
      },
    }),
  }),
  reducerPath: 'userSettingsApi',
  tagTypes: ['UserSettings'],
});

export const { useGetUserSettingsQuery, useUpdateUserSettingsMutation } = userSettingsApi;
