import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import ReCAPTCHA from 'react-google-recaptcha';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';

import { WaveDialogTitle } from '../WaveDialogTitle';

import { openWaveSnack } from '../../store/waveSnackSlice';

import { API } from '../../utilities/api';

export function ForgotPassword() {
  const dispatch = useDispatch();

  const recaptchaRef = useRef();

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const { t } = useTranslation();

  function handleCloseForgotPassword() {
    setForgotPasswordOpen(false);
    setUserName('');
  }

  function handleOpenForgotPassword() {
    setForgotPasswordOpen(true);
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    setLoading(true);
    recaptchaRef.current.execute();
  }

  async function resetPassword() {
    try {
      await API.post(
        '/forgotpassword',
        {
          user: userName,
        },
        {
          onError: (error) => {
            dispatch(
              openWaveSnack({
                type: 'error',
                message: error.message,
              }),
            );
            recaptchaRef.current.reset();
          },
          onSuccess: (res) => {
            handleCloseForgotPassword();
            dispatch(
              openWaveSnack({
                type: 'success',
                message: res,
              }),
            );
          },
        },
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        className="mt-5"
        color="primary"
        onClick={handleOpenForgotPassword}
        size="small"
        variant="text"
      >
        {`${t('log.pwd.forgot', 'Forgot Password?')}`}
      </Button>
      <Dialog
        onClose={() => {
          if (!loading) {
            handleCloseForgotPassword();
          }
        }}
        open={forgotPasswordOpen}
      >
        <form onSubmit={handleSubmitForm}>
          <WaveDialogTitle
            title={t('forgot_password_title', 'Forgot Password')}
            onClose={handleCloseForgotPassword}
          />

          <DialogContent>
            <DialogContentText>
              <Trans i18nKey="forgot_password_content">
                Enter your username here to have a update password link emailed to you.
              </Trans>
            </DialogContentText>

            <TextField
              autoFocus
              disabled={loading}
              fullWidth
              label={t('usr.name', 'Username')}
              onChange={(e) => setUserName(e.target.value)}
              required
              size="small"
              value={userName}
            />
          </DialogContent>

          <DialogActions className="space-between">
            <Button color="warning" disabled={loading} onClick={handleCloseForgotPassword}>
              <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
            </Button>

            <LoadingButton loading={loading} onClick={handleSubmitForm} variant="contained">
              <Trans i18nKey="send_password">Send Password</Trans>
            </LoadingButton>
          </DialogActions>

          <ReCAPTCHA
            onChange={resetPassword} // If user is not a bot submit form
            ref={recaptchaRef}
            size="invisible"
            sitekey={
              import.meta.env.VITE_RECAPTCHA_KEY || '6LcDyK4pAAAAAL1YKcS34HM7Psy8Ez6Xij3rz9r5'
            }
          />
        </form>
      </Dialog>
    </>
  );
}
