import { useFormState } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { useQueryParams } from '../../../../utilities/hooks';

export function SaveButton() {
  const { isDirty, isSubmitting } = useFormState();
  const queryParams = useQueryParams();
  const mode = queryParams.get('mode');
  const isCopyOrSubJobMode = mode === 'copy' || mode === 'sub' || mode === 'copy-in-project';

  return (
    <WaveTooltip
      component={
        <LoadingButton
          disabled={isCopyOrSubJobMode ? false : !isDirty}
          loading={isSubmitting}
          startIcon={<WaveIcon code="job-form-action-bar-save" />}
          type="submit"
          variant="contained"
        >
          <Trans i18nKey="lib.ok">Save</Trans>
        </LoadingButton>
      }
      type="simple"
      {...(!isCopyOrSubJobMode && !isDirty && { body: 'Nothing to update.' })}
    />
  );
}
