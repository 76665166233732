import { Fragment, MouseEvent, useState } from 'react';
import { Trans } from 'react-i18next';

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { SummaryTable } from 'src/features/Approvals/components/SummaryTable';
import { ShowMore } from '../../../../components/ShowMore';
import { WaveTooltip } from '../../../../components/WaveTooltip';
import { CellSkeleton } from '../../../JobsTable/components/CellSkeleton';
import { WaveIcon } from '../../../WaveIcon';
import { SubApprovalDetail } from '../../Approvals.service';

const COLUMNS = [
  { code: 'status', text: 'lib.status' },
  { code: 'position', text: 'lib.pos' },
  { code: 'date', text: 'lib.date' },
  { code: 'user', text: 'lib.user' },
  { code: 'annotations', text: 'lib.annotation' },
  { code: 'comment', text: 'lib.msg' },
  { code: 'days', text: 'lib.days' },
  { code: 'tasks', text: 'lib.tasks' },
];

export const ICON_COLORS: Record<string, string> = {
  amendment: 'var(--error)',
  approval: 'var(--success)',
  conditional: 'var(--warning)',
  unactioned: '#D9D9D9',
};

type DetailsTableProps = {
  approvalDetails: SubApprovalDetail[];
  isFetching: boolean;
  isPhraseApproval: boolean;
  onHandleOpenMasterContent: () => void;
  onSubApprovalDelete: (arg: SubApprovalDetail) => void;
};

export function DetailsTable({
  approvalDetails,
  isFetching,
  isPhraseApproval,
  onHandleOpenMasterContent,
  onSubApprovalDelete,
}: DetailsTableProps) {
  const [idsOfExpandedApproval, setIdsOfExpandedApproval] = useState<number[]>([]);

  function handleExpandCollapseSummaryTable(id: number) {
    if (!idsOfExpandedApproval.includes(id)) {
      setIdsOfExpandedApproval([...idsOfExpandedApproval, id]);
    } else {
      setIdsOfExpandedApproval(idsOfExpandedApproval.filter((approvalId) => approvalId !== id));
    }
  }

  function renderApprovalDetails() {
    return approvalDetails.map((detail) => (
      <Fragment key={detail.id}>
        <TableRow>
          <TableCell width={75}>
            <Box
              code={`job-approvals-revisor-status-${detail.status}`}
              color={ICON_COLORS[detail.status] || 'primary'}
              component={WaveIcon}
              sx={{ verticalAlign: 'middle' }}
            />
          </TableCell>

          <TableCell width={60}>{detail.position}</TableCell>

          <TableCell width={110}>{detail.date}</TableCell>

          <TableCell width={180}>
            {detail.approver_type === 'group' && detail.users ? (
              <Box alignItems="center" display="flex" gap={0.5}>
                {detail.group}

                <WaveTooltip
                  body={detail.users}
                  component={
                    <Box alignItems="center" display="flex">
                      <WaveIcon code="group" />
                    </Box>
                  }
                  header={`${detail.group} Users`}
                  placement="top"
                  type="list"
                />
              </Box>
            ) : (
              detail.user
            )}
          </TableCell>

          <TableCell width={105}>
            {detail.annotations?.length > 0 ? (
              <Box alignItems="center" display="flex">
                <Typography variant="caption">{detail.annotations.length}</Typography>

                <WaveTooltip
                  body={detail.annotations}
                  component={<WaveIcon code="input-field-information" />}
                  placement="top"
                  type="annotation"
                />
              </Box>
            ) : (
              0
            )}
          </TableCell>

          <TableCell>{detail.comment ? <ShowMore text={detail.comment} /> : null}</TableCell>

          <TableCell width={65}>{detail.days}</TableCell>

          <TableCell width={250}>
            {detail.status === 'unactioned' &&
              (isPhraseApproval ? (
                <Typography
                  onClick={onHandleOpenMasterContent}
                  sx={{ color: '#0288D1', cursor: 'pointer', textDecoration: 'underline' }}
                  variant="body2"
                >
                  <Trans i18nKey="apl.phrase.content.title">Review Master Content</Trans>
                </Typography>
              ) : null)}
          </TableCell>

          <TableCell
            align="right"
            colSpan={isPhraseApproval && detail.status !== 'unactioned' ? 2 : 0}
            width={55}
          >
            <IconButton
              color="error"
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                onSubApprovalDelete(detail);
              }}
              size="small"
              sx={{ visibility: detail.can_delete ? 'visible' : 'hidden' }}
            >
              <WaveIcon code="delete" fontSize="small" />
            </IconButton>
          </TableCell>

          {isPhraseApproval && detail.status !== 'unactioned' ? (
            <TableCell align="right" width={55}>
              <IconButton onClick={() => handleExpandCollapseSummaryTable(detail.id)}>
                <WaveIcon
                  code={idsOfExpandedApproval.includes(detail.id) ? 'expand-less' : 'expand-more'}
                />
              </IconButton>
            </TableCell>
          ) : null}
        </TableRow>

        {idsOfExpandedApproval.includes(detail.id) ? (
          // TODO: because there is no clue about translation for now
          // change the props to true or false
          <SummaryTable
            isSummaryTableOpen={idsOfExpandedApproval.includes(detail.id)}
            isTranslationApproval={true}
          />
        ) : null}
      </Fragment>
    ));
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          {isFetching ? (
            [...Array(4)].map((row, index) => (
              <TableRow key={index}>
                {COLUMNS.map((column) => (
                  <CellSkeleton key={column.code} />
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              {COLUMNS.map(({ code, text }: { code: string; text: string }) => (
                <Box component={TableCell} fontWeight="bold" key={code}>
                  <Trans>{text}</Trans>
                </Box>
              ))}

              <TableCell colSpan={3} />
            </TableRow>
          )}
        </TableHead>

        {!isFetching ? <TableBody>{renderApprovalDetails()}</TableBody> : null}
      </Table>
    </TableContainer>
  );
}
