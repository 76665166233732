import { createApi } from '@reduxjs/toolkit/query/react';

import { JobId } from 'src/pages/Job/Job.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';

type UpdateAssignToProject = {
  assignToJobId: number;
  assignToJobType: string;
  jobType?: string;
} & JobId;

export const assignToProjectApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    updateAssignToProject: build.mutation<string, UpdateAssignToProject>({
      query({ assignToJobId, assignToJobType, jobId, jobType }) {
        return {
          method: 'PUT',
          params: {
            assign_jobid: assignToJobId,
            assign_src: assignToJobType,
            src: jobType,
          },
          url: `/jobs/${jobId}/assign`,
        };
      },
    }),
  }),
  reducerPath: 'assignToProjectApi',
});

export const { useUpdateAssignToProjectMutation } = assignToProjectApi;
