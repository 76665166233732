import { Dispatch, SetStateAction, useState } from 'react';

import { IconButton } from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';
import { Dialog } from '../Dialog';

type BasicProps = {
  expandedFolders: Record<string, boolean>;
  folderCode: string;
  onToggleExpandFolder: (folderCode: string) => void;
  setSelectedFolder: Dispatch<SetStateAction<string>>;
};
type CreateFolder = {
  previousFoldername?: never;
  variant: 'create';
};
type CreateOrRenameFolderButtonProps = BasicProps & (CreateFolder | RenameFolder);
type RenameFolder = {
  previousFoldername: string;
  variant: 'rename';
};

export function CreateOrRenameFolderButton({
  expandedFolders,
  folderCode,
  onToggleExpandFolder,
  previousFoldername,
  setSelectedFolder,
  variant,
}: CreateOrRenameFolderButtonProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleOpenDialog(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setIsDialogOpen(true);
  }

  return (
    <>
      <IconButton onClick={handleOpenDialog} size="small">
        <WaveIcon code={`general-files-folder-${variant}`} />
      </IconButton>

      <Dialog
        expandedFolders={expandedFolders}
        folderCode={folderCode}
        onToggleExpandFolder={onToggleExpandFolder}
        open={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setSelectedFolder={setSelectedFolder}
        {...(variant === 'rename' ? { previousFoldername, variant } : { variant })}
      />
    </>
  );
}
