import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { Box, LinearProgress, LinearProgressProps } from '@mui/material';

import { Typography } from 'src/components/Typography';
import { isNumber } from 'src/utilities/helperFunctions2';

type ProgressBarProps = Omit<LinearProgressProps, 'defaultValue'>;

export function ProgressBar<T extends FieldValues>({
  control,
  fieldValueIndex,
  name,
  rules,
}: ProgressBarProps & Omit<UseControllerProps<T>, 'defaultValue'> & { fieldValueIndex?: number }) {
  const {
    field: { value = 0 },
  } = useController({
    control,
    name,
    rules,
  });

  const fieldValue =
    isNumber(value) || fieldValueIndex === undefined ? value : value[fieldValueIndex] || 0;
  const roundedValue = Math.round(fieldValue);

  return (
    <Box alignItems="center" display="flex" gap={1} width="100%">
      <LinearProgress
        color="primary"
        sx={{ borderRadius: 2.5, height: 8, width: '100%' }}
        value={roundedValue}
        variant="determinate"
      />

      <Typography
        color="text.primary"
        minWidth={35}
        variant="body2"
      >{`${roundedValue}%`}</Typography>
    </Box>
  );
}
