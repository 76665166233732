import { Button, styled, TableCell } from '@mui/material';

export const StyledUnderlineButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: none;
  background-color: transparent;
  padding: 5px 0;
  text-decoration: underline;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

export const StyledTableCell = styled(TableCell)<{
  isIndexCell?: boolean;
  shouldShowLastEmailListItemBorder?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing(1, 2)};
  cursor: pointer;
  background-color: ${({ isIndexCell, theme }) =>
    isIndexCell ? theme.palette.grey[100] : 'transparent'};
  border-bottom-color: ${({ shouldShowLastEmailListItemBorder = true, theme }) =>
    shouldShowLastEmailListItemBorder ? theme.palette.divider : 'transparent'};
  transition: ${({ shouldShowLastEmailListItemBorder = true }) =>
    shouldShowLastEmailListItemBorder
      ? 'border-bottom-color 0ms ease-out'
      : 'border-bottom-color 0ms ease-out 300ms'};
`;

export const StyleEmailTableCell = styled(TableCell)<{
  isEmailListItemExpanded: boolean;
  isIndexCell?: boolean;
}>`
  border-bottom: 1px solid;
  border-bottom-color: ${({ isEmailListItemExpanded, theme }) =>
    isEmailListItemExpanded ? theme.palette.divider : 'transparent'};
  transition: border-bottom-color 300ms ease-out;
  padding: 0;
  background-color: ${({ isIndexCell, theme }) =>
    isIndexCell ? theme.palette.grey[100] : 'transparent'};
`;
