import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const version = import.meta.env.VITE_VERSION;

export function Footer() {
  const { installationLogo } = useTheme();

  return (
    <Box
      alignItems="center"
      display="flex"
      sx={{ alignItems: 'center', display: 'flex', mx: 0.5, my: 1 }}
    >
      {installationLogo ? (
        <>
          <Box alt="Footer Logo" component="img" src={installationLogo} sx={{ width: 60 }} />

          <Typography sx={{ color: 'primary.contrastText', fontSize: 10 }} variant="button">
            {version} | developed by 5Flow
          </Typography>
        </>
      ) : (
        'null'
      )}
    </Box>
  );
}
