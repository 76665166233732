import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button } from 'src/components/Button';
import { TextField } from 'src/components/RHF/TextField';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { useGetNoContentPlaceholderQuery } from 'src/features/Content/content.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { WidgetBase } from '../../WidgetBase';

import type {
  ContentCategoriesList,
  TemplateSelectionParams,
} from 'src/features/Content/Content.types';

type StandardLayoutProps = {
  selectedCategoryIndex: string;
  selectedCategory?: ContentCategoriesList;
};

export function StandardLayout({ selectedCategory, selectedCategoryIndex }: StandardLayoutProps) {
  const { t } = useTranslation();
  const [categoryIndex, itemIndex] = selectedCategoryIndex.split('_');
  const { clearErrors, control, getFieldState, setValue } =
    useFormContext<TemplateSelectionParams>();
  const { data, isFetching } = useGetNoContentPlaceholderQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  function handleClickNoContentNeeded() {
    setValue(`categories.${+categoryIndex}.items.${+itemIndex}.result`, data?.text);
    clearErrors(`categories.${+categoryIndex}.items.${+itemIndex}`);
  }

  function handleChangeContent(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const errorState = getFieldState(`categories.${+categoryIndex}.items.${+itemIndex}`).error;

    if (errorState && e.target.value) {
      clearErrors(`categories.${+categoryIndex}.items.${+itemIndex}`);
    }
  }

  return (
    <WidgetBase
      action={
        <Box p={1.5}>
          <Button disabled={isFetching} onClick={handleClickNoContentNeeded}>
            lib.nocontent
          </Button>
        </Box>
      }
      headerId="layout-parent-header"
      id="layout-parent"
      title={t('standard', 'Standard')}
    >
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        pl={2}
        pr={selectedCategory?.readonly ? 1 : 2}
        py={2}
        width="100%"
      >
        <TextField
          control={control}
          disabled={selectedCategory?.readonly}
          fullHeight
          key={`categories.${+categoryIndex}.items.${+itemIndex}.result`}
          multiline
          name={`categories.${+categoryIndex}.items.${+itemIndex}.result`}
          onChange={handleChangeContent}
          placeholder={t('search_your_content', 'Search your content') as string}
          sx={{
            '& .Mui-disabled.MuiOutlinedInput-root': {
              bgcolor: 'var(--filler)',
            },
          }}
          variant="outlined"
        />

        {selectedCategory?.readonly ? (
          <WaveTooltip
            body={selectedCategory?.tooltip}
            component={<WaveIcon code="input-field-information" />}
            placement="top"
            type="simple"
          />
        ) : null}
      </Box>
    </WidgetBase>
  );
}
