export type IconType = (typeof ICONS)[keyof typeof ICONS][number];
type IconMapping = { [K: string]: readonly string[] };

export const ICONS: IconMapping = {
  account_circle: ['account-circle', 'job-approvals-action-dialog-account-circle'] as const,
  account_tree: ['job-form-action-bar-related-jobs'] as const,
  add: [
    'add',
    'job-form-action-bar-create-copy-new-job',
    'job-phrase-action-bar-add-content',
    'jobs-action-bar-new-job',
    'sub-jobs-action-bar-new-job',
  ] as const,
  add_box: [
    'add-box',
    'job-history-table-row-action-type-approval-user-added',
    'job-phrase-action-templates-list-add',
    'sub-jobs-action-bar-new-job-sub-menu',
  ] as const,
  add_circle: ['job-files-action-bar-compare-other-files'] as const,
  add_task: ['job-form-action-bar-actions-new-webcenter-project'] as const,
  archive: ['main-menu-archive', 'job-archive'] as const,
  arrow_back: ['step-change-dialog-add-revisor-back', 'not-found-go-back', 'arrow-back'] as const,
  arrow_drop_down: ['arrow-drop-down'] as const,
  arrow_right: ['arrow-right'] as const,
  arrow_right_alt: [
    'job-history-table-row-action-type-status-change',
    'step-change-dialog-title-arrow',
  ] as const,
  assignment: ['job-header-task-active', 'job-form-action-bar-task', 'job-tasks-active'] as const,
  assignment_turned_in: [
    'job-header-task-confirmed',
    'job-tasks-task',
    'job-tasks-confirmed',
  ] as const,
  attach_file: ['job-form-actions-send-deep-link', 'file'] as const,
  bar_chart: ['main-menu-rep'] as const,
  bookmark: ['bookmark'] as const,
  bookmark_add: ['job-form-action-bar-actions-add-bookmark'] as const,
  bookmark_remove: ['job-form-action-bar-actions-remove-bookmark'] as const,
  brightness_1: ['input-field-empty'] as const,
  brightness_low: [
    'job-form-action-bar-create-copy-copy-to-job-type',
    'job-form-action-bar-create-copy-new-job-job-type',
    'job-form-action-bar-create-copy-copy-in-project-job-type',
  ] as const,
  business: ['business'] as const,
  call_merge: ['job-form-action-bar-subscriptions-list'] as const,
  cancel: [
    'cancel',
    'input-field-invalid',
    'job-flags-cancel',
    'job-form-action-bar-cancel',
    'job-form-action-bar-flags-flag-button-cancel',
    'job-form-action-bar-flags-flag-dialog-cancel',
    'job-header-flag-cancel',
    'job-history-table-row-action-type-approval-user-deleted',
    'user-profile-action-bar-cancel',
  ] as const,
  check: ['check'] as const,
  check_box: ['job-files-action-bar-compare-selected-files', 'check-box'] as const,
  check_box_outline_blank: ['job-history-table-row-action-type-flag'] as const,
  check_circle: ['check-circle', 'input-field-valid'] as const,
  check_circle_outline: [
    'job-flags-revive',
    'job-form-action-bar-flags-flag-button-revive',
    'job-form-action-bar-flags-flag-dialog-revive',
    'job-history-table-row-action-type-checklist-ok',
    'job-history-table-row-action-type-checklist-reject',
  ] as const,
  close: ['close'] as const,
  cloud_download: [
    'general-files-action-bar-files-download',
    'job-files-action-bar-actions-download',
    'job-files-action-bar-download',
    'job-files-action-bar-actions-download-with-annotations',
    'cloud-download',
  ] as const,
  cloud_upload: [
    'cloud-upload',
    'general-files-action-bar-file-upload',
    'job-files-upload-file',
    'job-history-table-row-action-type-file-upload',
  ] as const,
  comment: ['job-history-action-bar-comment'] as const,
  compare: [
    'job-approvals-file-actions-compare',
    'job-files-action-bar-compare-files',
    'job-phrase-actions-compare',
  ] as const,
  content_copy: ['copy', 'translate-copy'] as const,
  control_camera: ['job-form-actions-import-reference-job'] as const,
  create_new_folder: ['general-files-folder-create', 'create-new-folder'] as const,
  dashboard: ['main-menu-hom'] as const,
  date_range: ['date-range', 'job-form-action-bar-actions-get-project-timings'] as const,
  delete: [
    'delete',
    'general-files-action-bar-files-delete',
    'general-files-file-delete',
    'general-files-folder-delete',
    'job-approvals-action-dialog-delete',
    'jobs-table-row-delete',
    'user-profile-avatar-dialog-delete',
  ] as const,
  description: ['job-form-action-bar-steps-additional-actions-file', 'main-menu-cms-menu'] as const,
  difference: ['job-form-clipboard'] as const,
  disabled_by_default: ['disabled-by-default'] as const,
  done: ['done'] as const,
  download: ['download'] as const,
  edit: [
    'edit',
    'general-files-folder-rename',
    'job-history-table-row-action-type-job-edit',
    'job-phrase-action-bar-edit-content',
    'job-phrase-category-language-needed',
    'jobs-action-bar-bulk',
    'user-profile-avatar-edit',
  ] as const,
  email: [
    'email',
    'job-apl-email',
    'job-files-action-bar-actions-send-link-email',
    'job-form-action-bar-actions-email',
    'job-history-action-bar-email',
    'job-history-table-row-action-type-email',
    'job-phrase-actions-email',
    'job-approvals-action-bar-email',
  ] as const,

  exit_to_app: ['exit-app'] as const,
  expand_less: [
    'expand-less',
    'general-files-folder-expand-less',
    'job-files-action-bar-expand-less',
    'job-history-action-bar-expand-less',
  ] as const,
  expand_more: [
    'expand-more',
    'expand-all',
    'general-files-folder-expand-more',
    'job-history-action-bar-expand-more',
    'job-files-action-bar-expand-more',
  ] as const,
  file_copy: ['jobs-table-row-copy', 'job-form-action-bar-create-copy-copy-to'] as const,
  filter_list: [
    'dashboard-action-bar-filters',
    'filter-list',
    'jobs-action-bar-filters',
    'sub-jobs-action-bar-filters',
    'job-history-action-bar-filters',
  ] as const,
  first_page: ['first-page'] as const,
  flag: [
    'job-actions-flags',
    'job-history-table-row-action-type-flag-activate',
    'job-history-table-row-action-type-flag-confirm',
  ] as const,
  folder: ['general-files-folder', 'main-menu-job-pro'] as const,
  folder_copy: ['job-form-action-bar-create-copy-copy-in-project'] as const,
  folder_open: [
    'compare-files-folder-open',
    'job-files-dalim',
    'job-files-pdf',
    'job-files-doc',
    'job-files-wec',
    'job-files-dms',
    'job-files-cloudflow',
    'job-files-gv',
    'job-files-pixelboxx',
  ] as const,
  format_color_fill: ['color-dropdown'] as const,
  format_quote: [
    'job-history-table-row-action-type-question-answered',
    'job-history-table-row-action-type-phrase-edit',
  ] as const,
  forward: ['job-approvals-actions-forward'] as const,
  fullscreen: ['fullscreen'] as const,
  fullscreen_exit: ['exit-fullscreen'] as const,
  help: ['help'] as const,
  history: ['history'] as const,
  import_export: [
    'dashboard-action-bar-export',
    'job-phrase-actions-export',
    'jobs-action-bar-export',
    'sub-jobs-action-bar-export',
  ] as const,
  indeterminate_check_box: [
    'job-approvals-revisor-status-unactioned',
    'indeterminate-check-box',
  ] as const,
  info: ['input-field-information', 'info'] as const,
  insert_drive_file: ['job-history-table-row-action-type-job-reuse'] as const,
  invert_colors: ['job-flags-color-approve'] as const,
  keyboard_arrow_down: [
    'job-history-table-row-arrow-down',
    'job-phrase-action-bar-collapse-all',
    'jobs-action-bar-keyboard-arrow-down',
    'jobs-table-row-arrow-down',
    'keyboard-arrow-down',
  ] as const,
  keyboard_arrow_left: ['keyboard-arrow-left'] as const,
  keyboard_arrow_right: ['keyboard-arrow-right'] as const,
  keyboard_arrow_up: [
    'job-history-table-row-arrow-up',
    'job-phrase-action-bar-expand-all',
    'jobs-action-bar-keyboard-arrow-up',
    'jobs-table-row-arrow-up',
    'keyboard-arrow-up',
  ] as const,
  language: ['job-form-language', 'job-phrase-action-bar-translations'] as const,
  last_page: ['last-page'] as const,
  library_books: ['library-books'] as const,
  linear_scale: ['main-menu-job', 'main-menu-additional-tabs'] as const,
  link_off: ['job-phrase-actions-remove'] as const,
  lock: ['user-profile-action-bar-change-password'] as const,
  mode_comment: ['job-history-table-row-action-type-comment'] as const,
  more: ['job-form-action-bar-steps-additional-actions-more'] as const,
  more_vert: [
    'dashboard-action-bar-actions',
    'jobs-action-bar-actions',
    'job-approvals-action-bar-more-vert',
    'job-files-action-bar-actions',
    'job-form-action-bar-actions',
    'job-phrase-action-bar-actions',
    'sub-jobs-action-bar-actions',
  ] as const,
  navigate_next: ['job-form-action-bar-steps-additional-actions-next', 'navigate-next'] as const,
  notifications: ['notifications'] as const,
  open_in_new: [
    'job-form-action-bar-actions-assign-to-project',
    'job-form-action-bar-actions-reassign-project',
  ] as const,
  open_with: ['job-approvals-actions-expand', 'job-files-open-viewer'] as const,
  pageview: ['job-form-actions-webcenter-view'] as const,
  pause_circle_outline: [
    'job-flags-onHold',
    'job-form-action-bar-flags-flag-button-onhold',
    'job-form-action-bar-flags-flag-dialog-onhold',
    'job-header-flag-onhold',
    'job-history-table-row-action-type-flag-change',
  ] as const,
  people: [
    'group',
    'job-form-steps-dialog-email-notifications-group',
    'job-form-steps-dialog-revisor-group',
  ] as const,
  perm_media: ['job-form-actions-build-pdf'] as const,
  person_add: ['job-approvals-action-bar-add'] as const,
  photo_library: ['photo-library'] as const,
  picture_as_pdf: ['job-flags-pdf-approve'] as const,
  play_circle_outline: [
    'job-flags-resume',
    'job-form-action-bar-flags-flag-button-resume',
    'job-form-action-bar-flags-flag-dialog-resume',
  ] as const,
  post_add: [
    'job-history-table-row-action-type-file-upload',
    'job-history-table-row-action-type-file-delete',
  ] as const,
  preview: ['jobs-view-search-preview'] as const,
  print: [
    'job-print',
    'job-form-action-bar-actions-print-job',
    'job-phrase-actions-print',
  ] as const,
  question_answer: ['job-form-actions-create-questions'] as const,
  queue: ['job-form-action-bar-create-copy'] as const,
  refresh: [
    'job-history-table-row-action-type-status',
    'job-history-table-row-action-type-approval-backtogroup',
    'job-history-table-row-action-type-webcenter-comment',
  ] as const,
  remove: ['remove'] as const,
  repeat: ['job-form-action-bar-steps'] as const,
  restart_alt: ['job-form-action-bar-actions-reuse'] as const,
  save: [
    'job-form-action-bar-save',
    'job-phrase-action-templates-list-save',
    'save',
    'user-profile-action-bar-save',
  ] as const,
  save_as: [
    'job-history-table-row-action-type-approval-amendment',
    'job-history-table-row-action-type-approval-approve',
    'job-history-table-row-action-type-approval-conditional',
  ] as const,
  search: [
    'dashboard-action-bar-saved-searches',
    'jobs-action-bar-saved-searches',
    'main-menu-job-ser',
    'sub-jobs-action-bar-saved-searches',
    'search',
  ] as const,
  select_all: ['job-files-action-bar-select-all', 'job-phrase-action-bar-select-all'] as const,
  send: ['send'] as const,
  settings: ['settings'] as const,
  shopping_basket: ['shopping-basket'] as const,
  shopping_cart: ['job-files-action-bar-add-to-cart'] as const,
  start: ['nav-bar-menu'] as const,
  subscriptions: ['job-form-actions-add-subscriptions'] as const,
  tab_unselected: [
    'job-files-action-bar-tab-unselected',
    'job-phrase-action-bar-deselect-all',
  ] as const,
  thumb_down: [
    'job-approvals-action-dialog-amendment',
    'job-approvals-actions-amendment',
    'job-approvals-revisor-status-amendment',
    'job-approvals-revisor-summary-table-amendment',
    'job-form-action-bar-task-dialog-flag-amendment',
    'job-form-action-bar-task-flag-amendment',
    'job-start-apl-amendment',
  ] as const,
  thumb_up: [
    'approval',
    'job-approvals-action-dialog-approval',
    'job-approvals-actions-approval',
    'job-approvals-revisor-status-approval',
    'job-approvals-revisor-summary-table-approval',
    'job-form-action-bar-task-dialog-flag-approve',
    'job-form-action-bar-task-flag-approve',
    'job-phrase-category-language-approved',
    'job-start-apl-approval',
  ] as const,
  thumbs_up_down: [
    'conditional',
    'job-approvals-action-dialog-conditional',
    'job-approvals-actions-conditional',
    'job-approvals-revisor-status-conditional',
    'job-approvals-revisor-summary-table-conditional',
    'job-form-action-bar-task-dialog-flag-conditional',
    'job-form-action-bar-task-flag-conditional',
    'job-phrase-category-language-pending',
    'job-start-apl-conditional',
  ] as const,
  tune: ['job-phrase-search-results-options'] as const,
  upload_file: ['user-profile-upload-file'] as const,
  view_comfy: ['view-compact'] as const,
  view_list: [
    'dashboard-action-bar-columns',
    'jobs-action-bar-columns',
    'sub-jobs-action-bar-columns',
    'view-list',
  ] as const,
  view_module: ['jobs-action-bar-list-view-options', 'view-module'] as const,
  visibility: [
    'dashboard-action-bar-saved-views',
    'jobs-action-bar-saved-views',
    'sub-jobs-action-bar-saved-views',
    'visibility',
  ] as const,
  visibility_off: ['visibility_off'] as const,
  zoom_out_map: ['files_zoom_out_map'] as const,
};
