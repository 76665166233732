import { Trans } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';

import { ConfirmationDialog } from 'src/components/ConfirmationDialog';
import { FileExplorer } from 'src/components/FileExplorer';
import { FilesTable } from 'src/components/FilesTable';
import { ActionBar } from 'src/features/JobFiles/components/ActionBar';
import { FileId } from 'src/features/JobFiles/JobFiles.service';
import { useJobFiles } from 'src/features/JobFiles/useJobFiles';

export type FileIds = FileId[];

export function JobFiles() {
  const {
    areAllItemsExpanded,
    areAllItemsSelected,
    areFilesFetching,
    areFilesLoadedSuccessfully,
    areFoldersFetching,
    categorizedFiles,
    deleteItem,
    expandedItems,
    folders,
    isConfirmationDialogOpen,
    onCloseConfirmationDialog,
    onDeleteFile,
    onOpenConfirmationDialog,
    onSelectFolder,
    rawFiles,
    selectedFileIds,
    selectedFolder,
    toggleExpandAll,
    toggleExpandItem,
    toggleSelectAll,
    toggleSelectItem,
    toggleSelectMultiple,
  } = useJobFiles();

  return (
    <Box role="tabpanel" sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}>
      <ActionBar
        areAllExpanded={areAllItemsExpanded}
        areAllSelected={areAllItemsSelected}
        files={rawFiles}
        onToggleExpandAll={toggleExpandAll}
        onToggleSelectAll={toggleSelectAll}
        selectedFileIds={selectedFileIds as FileIds}
        selectedFolderCode={selectedFolder.code}
      />

      <FileExplorer
        areFilesLoading={areFilesFetching}
        areFoldersLoading={areFoldersFetching}
        folders={folders}
        onFolderSelected={onSelectFolder}
        selectedFolder={selectedFolder.path}
      >
        <Box sx={{ p: 1.5 }}>
          {!rawFiles.length && areFilesLoadedSuccessfully ? (
            <Box sx={{ pt: 3, textAlign: 'center' }}>
              <Trans i18nKey="files_nothing_to_show">Nothing to Show</Trans>
            </Box>
          ) : null}

          {Object.keys(categorizedFiles).map((category, index) => {
            const _selectedFileIds = selectedFileIds as FileIds;

            return (
              <Accordion expanded={expandedItems[category] || false} key={index}>
                <AccordionSummary onClick={() => toggleExpandItem(category)}>
                  {category}
                </AccordionSummary>

                <Box component={AccordionDetails} sx={{ p: 0 }}>
                  <FilesTable
                    files={categorizedFiles[category]}
                    onDeleteClick={onOpenConfirmationDialog}
                    onSelectAllFilesToggle={toggleSelectMultiple}
                    onSelectFileClick={toggleSelectItem}
                    selectedFileIds={_selectedFileIds.filter((selectedFileId) =>
                      categorizedFiles[category].some(({ id }) => id === selectedFileId),
                    )}
                  />
                </Box>
              </Accordion>
            );
          })}
        </Box>
      </FileExplorer>

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        message={`Are you sure you want to delete ${deleteItem?.filename}?`}
        onConfirmation={onDeleteFile}
        onDialogClose={onCloseConfirmationDialog}
      />
    </Box>
  );
}
