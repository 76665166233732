import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type CategoryIdValue = number;
export type Categories = { label: string; value: CategoryIdValue }[];

export const uploadFilesApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getCategories: build.query<Categories, undefined>({
      providesTags: ['Categories'],
      query() {
        return {
          method: 'GET',
          url: '/jobs/files/categories',
        };
      },
    }),
  }),
  reducerPath: 'uploadFilesApi',
  tagTypes: ['Categories'],
});

export const { useGetCategoriesQuery } = uploadFilesApi;
