export function parseArrayToLogicalObject<
  L extends string | number,
  K extends keyof T,
  T extends Record<K, L>,
>(array: Array<T>, key: K, defaultState = false) {
  return array.reduce((result, item) => {
    if (item[key]) {
      return {
        ...result,
        [item[key]]: defaultState,
      };
    }

    return result;
  }, {} as Record<T[K], boolean>);
}

export function parseRecordToLogicalObject<T extends string | number>(items: Record<T, unknown>) {
  return Object.keys(items).reduce(
    (result, item) => ({
      ...result,
      [item]: false,
    }),
    {},
  );
}
