import { useFormContext } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import {
  ActionBarButton as ActionBarButtonType,
  ActionBarCode,
} from 'src/features/BulkEditJobFields/BulkEditJobFields.service';

type ActionBarButtonProps = {
  button: ActionBarButtonType;
  codesOfButtonsThatShouldBeVisible: string[];
  onClickActionBarButton: (code: ActionBarCode) => void;
};

export function ActionBarButton({
  button: { code, name },
  codesOfButtonsThatShouldBeVisible,
  onClickActionBarButton,
}: ActionBarButtonProps) {
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext();

  const buttonColor = code === 'cancel' ? 'warning' : 'primary';
  const buttonType = code === 'confirm' ? 'submit' : 'button';
  const isDisabled = (code === 'next' && !isDirty) || isSubmitting;
  const isLoading = code === 'confirm' && isSubmitting;

  if (!codesOfButtonsThatShouldBeVisible.includes(code)) return null;

  return (
    <Grid2 marginX={1} marginY={2}>
      <LoadingButton
        color={buttonColor}
        disabled={isDisabled}
        loading={isLoading}
        onClick={() => onClickActionBarButton(code)}
        type={buttonType}
        variant="contained"
      >
        {name}
      </LoadingButton>
    </Grid2>
  );
}
