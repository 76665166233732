import { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { useAuthenticateIFrameMutation } from 'src/pages/GenericIFrame/IFrame.service';
import { useAppSelector, useRouteParams } from 'src/utilities/hooks';

export function GenericIFrame() {
  const { age, jobId, jobType, tab, tabCode } = useRouteParams();
  const sessionId = useAppSelector((state) => state.user.sessionID);

  const [isLoading, setIsLoading] = useState(true);
  const [iFrameUrl, setIFrameUrl] = useState('');

  const [authenticateIFrame] = useAuthenticateIFrameMutation();

  function hideSpinner() {
    setIsLoading(false);
  }

  useEffect(() => {
    authenticateIFrame({
      age,
      jobid: jobId,
      sessionId,
      src: jobType,
      tabcode: tab ? tab : tabCode,
    })
      .unwrap()
      .then((response) => {
        setIFrameUrl(response.url);
      });
  }, [age, tab, tabCode]);

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      position="relative"
    >
      {isLoading && (
        <Box position="absolute">
          <CircularProgress className="spinner-progress" size={100} />
        </Box>
      )}

      <iframe
        className="border-none"
        height="100%"
        onLoad={hideSpinner}
        src={iFrameUrl}
        width="100%"
      />
    </Box>
  );
}
