import { useEffect, useMemo, useState } from 'react';

import { Box, Skeleton, Unstable_Grid2 as Grid } from '@mui/material';

import { HistoryTable } from 'src/features/History/components/HistoryTable';
import { HistoryTimeline } from 'src/features/History/components/HistoryTimeline';
import { formatDate } from 'src/utilities/helperFunctions';
import { useRouteParams } from '../../utilities/hooks';
import { ActionBar } from './components/ActionBar';
import { useGetHistoryQuery } from './History.service';
import { filterHistoryByMonths, mutateHistorySliderValues } from './utilities/helperFunctions';

import type { Filter, HistoryItemId, HistoryItemType, SliderValue } from './History.types';

export function History() {
  const { age, jobId, jobType } = useRouteParams();
  const {
    data: historyData,
    isFetching,
    isSuccess: isHistoryFetchedSuccessfully,
  } = useGetHistoryQuery(
    {
      age,
      jobid: jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [expandedHistoryItemIds, setExpandedHistoryItemIds] = useState<HistoryItemId[]>([]);
  const [activeHistoryFilters, setActiveHistoryFilters] = useState<Filter[]>([]);
  const [history, setHistory] = useState<HistoryItemType[]>([]);
  const [sliderValues, setSliderValues] = useState<SliderValue[]>([]);
  const [selectedSliderValues, setSelectedSliderValues] = useState<SliderValue[]>([]);

  function handleClickFilter(activeFilters: Filter[], values: SliderValue[] = []) {
    setActiveHistoryFilters(activeFilters);
    // The expandedHistoryItemIds must be reset when changing
    // filters so that items are not expanded that aren't even visible
    setExpandedHistoryItemIds([]);

    if (activeFilters !== activeHistoryFilters) {
      setSelectedSliderValues([]);
    }

    const filteredActiveFilters = activeFilters.filter((filter) => filter.code !== 'month');

    let finalHistory;
    let finalSliderValues;

    if (!filteredActiveFilters.length) {
      finalHistory = historyData?.history || [];
      finalSliderValues = historyData?.sliderValues || [];
    } else {
      const filteredHistory = (historyData?.history || []).filter((originalHistoryItem) =>
        filteredActiveFilters.map((filter) => filter.code).includes(originalHistoryItem.type.code),
      );

      const sliderValues: string[] = [];

      finalHistory = filteredHistory.map((historyItem) => {
        const sliderValue = formatDate(new Date(historyItem.datum), 'month yyyy');

        sliderValues.push(sliderValue);

        return { ...historyItem, sliderValue };
      });

      finalSliderValues = mutateHistorySliderValues(sliderValues).reverse();
    }

    if (values.length) {
      const filteredHistory = filterHistoryByMonths(finalHistory, values);

      setHistory(filteredHistory);
    } else {
      setHistory(finalHistory);
    }

    setSliderValues(finalSliderValues);
  }

  function handleToggleExpand(historyItemId: HistoryItemId) {
    setExpandedHistoryItemIds(
      expandedHistoryItemIds.includes(historyItemId)
        ? expandedHistoryItemIds.filter((id) => id !== historyItemId)
        : [...expandedHistoryItemIds, historyItemId],
    );
  }

  function handleToggleExpandAll(areAllHistoryItemsExpanded: boolean) {
    setExpandedHistoryItemIds(
      areAllHistoryItemsExpanded
        ? history.filter((item) => item.email_list || item.message).map(({ id }) => id)
        : [],
    );
  }

  function handleOnChangeSliderValue(sliderValue: SliderValue[]) {
    setSelectedSliderValues(sliderValue);
    handleClickFilter(activeHistoryFilters, sliderValue);
  }

  const hasMonthFilterSelected = useMemo(
    () => activeHistoryFilters.map((filter) => filter.code).includes('month'),
    [activeHistoryFilters],
  );

  useEffect(() => {
    if (isHistoryFetchedSuccessfully) {
      setHistory(historyData.history);
      setSliderValues(historyData.sliderValues);
    }
  }, [isHistoryFetchedSuccessfully, historyData]);

  return (
    <Box marginX={2} role="tabpanel">
      {history.length && !isFetching ? (
        <>
          <ActionBar
            activeHistoryFilters={activeHistoryFilters}
            expandedHistoryItemIds={expandedHistoryItemIds}
            history={history}
            historyFilters={historyData?.filters || []}
            onClickFilter={handleClickFilter}
            onToggleExpandAll={handleToggleExpandAll}
          />

          <Grid container marginY={2} spacing={1}>
            <Grid xs>
              <HistoryTable
                expandedHistoryItemIds={expandedHistoryItemIds}
                history={history}
                onToggleExpand={handleToggleExpand}
              />
            </Grid>

            {hasMonthFilterSelected && (
              <HistoryTimeline
                onValueChange={handleOnChangeSliderValue}
                selectedValues={selectedSliderValues}
                timelines={sliderValues}
              />
            )}
          </Grid>
        </>
      ) : (
        <>
          <Box display="flex" gap={1} justifyContent="end" marginY={1}>
            {[...Array(3)].map((_, index) => {
              return <Skeleton height="40px" key={index} width="85px" />;
            })}
          </Box>

          <Grid container margin={0} spacing={1}>
            <Grid xs>
              {[...Array(4)].map((_, index) => (
                <Skeleton height="80px" key={index} />
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
