import { useMemo } from 'react';

import { isString } from 'src/utilities/helperFunctions2';
import { usePreference } from 'src/utilities/hooks/usePreference';

const defaultLanguage = 'uk';

export function useLanguageCodePreference() {
  const languageCodePreference = usePreference('sys.lang', defaultLanguage);

  const languageCodePreferenceValue = useMemo(
    () => (isString(languageCodePreference.value) ? languageCodePreference.value : defaultLanguage),
    [languageCodePreference.value],
  );

  return {
    isLoading: languageCodePreference.isLoading,
    set: languageCodePreference.set,
    value: languageCodePreferenceValue,
  };
}
