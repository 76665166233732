import { t as translate } from 'i18next';

import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { TableButtonCell } from 'src/components/TableButtonCell';
import { EmailList } from 'src/features/History/components/EmailList';
import { HistoryTableProps } from 'src/features/History/components/HistoryTable/HistoryTable';
import { ICON_COLOR_MAP } from 'src/features/History/components/HistoryTableRow/utilities/constants';
import { TABLE_CELL_STYLES } from 'src/features/History/components/HistoryTableRow/utilities/styles';
import { GetEmailListResponse } from 'src/features/History/History.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useDateFormat } from 'src/utilities/hooks';
import { JobType } from 'src/utilities/hooks/useRouteParams';

import { HistoryItemType } from 'src/features/History/History.types';

type RowProps = {
  emailListData?: GetEmailListResponse[];
  hasEmailList: boolean;
  row: HistoryItemType;
} & Omit<HistoryTableProps, 'history'> &
  JobType;

export function HistoryTableRow({
  emailListData,
  expandedHistoryItemIds,
  hasEmailList,
  jobType,
  onToggleExpand,
  row: {
    code,
    datum,
    email_list: emails,
    from_status: fromStatus,
    id,
    message,
    snippet,
    to_status: toStatus,
    type: { text: actionType },
    user,
  },
}: RowProps) {
  const { formatDate } = useDateFormat();
  const formattedDate = formatDate('lib.datetime.long', datum);

  const isExpandable = !!emails || !!message;
  const arrowDirection = expandedHistoryItemIds.includes(id) ? 'up' : 'down';
  const arrowCode = `job-history-table-row-arrow-${arrowDirection}`;
  const actionTypeCodePrefix = 'job-history-table-row-action-type-';
  const shouldDisplayEmails = hasEmailList && emailListData;

  function handleToggleExpand() {
    onToggleExpand(id);
  }

  return (
    <>
      <TableRow>
        {isExpandable ? (
          <TableButtonCell muiTableCellProps={{ sx: TABLE_CELL_STYLES }}>
            <IconButton onClick={handleToggleExpand}>
              <WaveIcon className="primary-txt" code={arrowCode} />
            </IconButton>
          </TableButtonCell>
        ) : (
          <TableCell sx={TABLE_CELL_STYLES} />
        )}

        <TableCell sx={TABLE_CELL_STYLES}>
          <Box alignItems="center" display="flex" justifyItems="center">
            {isNaN(parseInt(code)) ? (
              <WaveIcon
                code={`${actionTypeCodePrefix}${code}`}
                color={ICON_COLOR_MAP[code as keyof typeof ICON_COLOR_MAP] || 'primary'}
                fontSize="small"
              />
            ) : (
              <>
                <StatusIndicator jobType={jobType} label={fromStatus.toString()} size="small" />

                <WaveIcon code={`${actionTypeCodePrefix}status-change`} fontSize="small" />

                <StatusIndicator jobType={jobType} label={toStatus.toString()} size="small" />
              </>
            )}
          </Box>
        </TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{actionType}</TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{formattedDate}</TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{user}</TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{snippet}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={6} sx={{ paddingY: 0 }}>
          <Collapse in={expandedHistoryItemIds.includes(id)} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {Array.isArray(message) ? (
                message.map((msg, index: number) => (
                  <Typography key={index} variant="body2">
                    {`- ${translate('his.job.changes.format', {
                      1: msg.field_name,
                      2: msg.old,
                      3: msg.new,
                    })}`}
                  </Typography>
                ))
              ) : (
                <Typography variant="subtitle2">{message}</Typography>
              )}

              {shouldDisplayEmails ? <EmailList emails={emailListData} /> : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
