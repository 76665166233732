import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Control,
  useFormContext,
  UseFormGetValues,
  UseFormSetValue,
  useFormState,
} from 'react-hook-form';

import { Box, Typography } from '@mui/material';

import { CompareReplaceTemplate } from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import { CompareReplaceAccordion } from 'src/features/CompareReplaceDialog/components/CompareReplaceAccordion';
import {
  getCountOfUpdatedFields,
  getFilterSameValues,
  getFilterSearchTerm,
  getIsAccordionExpandedValues,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { CompareReplaceHeaderBar } from 'src/features/CompareReplaceDialog/components/CompareReplaceHeaderBar';
import { CompareReplaceTemplateAccordion } from 'src/features/CompareReplaceDialog/components/CompareReplaceTemplateAccordion';
import { CompareReplaceTemplateConfigurer } from 'src/features/CompareReplaceDialog/components/CompareReplaceTemplateConfigurer';
import { ClipboardData } from 'src/features/JobForm/components/CopyCompareButton/type';
import { getUpdatedJobValue } from 'src/features/JobForm/components/CopyCompareButton/utils';
import { FieldsTransformed } from 'src/pages/Job/Job.service';
import { FieldLayout, useJobContext } from 'src/pages/Job/JobContext';

type CompareReplaceContentProps = {
  copiedData: ClipboardData;
  fieldLayout?: FieldLayout;
  shouldHideSameValuesOption: boolean;
  compareDialogOpenSection: string;
  shouldSelectAllBlocksOption: boolean;
  onOpenSaveTemplate: () => void;
  onOpenRenameTemplate: () => void;
  onOpenDeleteTemplate: () => void;
  isSaveTemplate: boolean;
  control: Control<any>;
  watchedFormData: any;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  templates: Array<CompareReplaceTemplate>;
  isClipboard: boolean;
};

export function CompareReplaceContent({
  compareDialogOpenSection,
  control,
  copiedData,
  fieldLayout,
  getValues,
  isClipboard,
  isSaveTemplate,
  onOpenDeleteTemplate,
  onOpenRenameTemplate,
  onOpenSaveTemplate,
  setValue,
  shouldHideSameValuesOption,
  shouldSelectAllBlocksOption,
  templates,
  watchedFormData,
}: CompareReplaceContentProps) {
  const { fields: jobFields, values } = useJobContext();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<Record<string, boolean>>({});

  const [shouldHideSameValues, setShouldHideSameValues] = useState<boolean>(
    shouldHideSameValuesOption,
  );
  const [searchTerm, setSearchTerm] = useState<string>('');

  const percentAccordionsExpanded =
    (Object.values(isAccordionExpanded).filter((value) => value).length /
      Object.values(isAccordionExpanded).length) *
    100;

  const { dirtyFields } = useFormState();
  const { getValues: getFormValue } = useFormContext();
  const jobData = getUpdatedJobValue(dirtyFields, getFormValue, values);

  const handleAccordionChange = useCallback(
    (section: string) => {
      setIsAccordionExpanded((prev) => ({
        ...prev,
        [`${section}_selected`]: !prev[`${section}_selected`],
      }));
    },
    [setIsAccordionExpanded],
  );

  const handleChangeField = useCallback(
    (sectionId: string, fieldId: string) => {
      const sectionValues = getValues<any>(`${sectionId}.fields`);
      const changedFieldValue = sectionValues[fieldId];
      const areFieldsSame = Object.values(sectionValues).every(
        (value) => value === changedFieldValue,
      );

      if (areFieldsSame) {
        setValue<any>(`${sectionId}.group`, changedFieldValue);
      } else {
        setValue<any>(`${sectionId}.group`, '');
      }
    },
    [getValues, setValue],
  );

  const handleChangeGroup = useCallback(
    (sectionId: string, value: string) => {
      const sectionValues = getValues<any>(`${sectionId}.fields`);

      Object.keys(sectionValues).forEach((fieldKey) => {
        setValue<any>(`${sectionId}.fields.${fieldKey}`, value);
      });
    },
    [getValues],
  );

  function handleCollapseAll() {
    setIsAccordionExpanded((prev) => {
      const newAccordionExpanded = { ...prev };

      Object.keys(newAccordionExpanded).forEach((key) => {
        newAccordionExpanded[key] = false;
      });

      return newAccordionExpanded;
    });
  }

  const handleDeselectEmptyFields = useCallback(
    (sectionId: string) => {
      copiedData.forEach((section) => {
        if (section.sectionId === sectionId) {
          section.fields.forEach((field) => {
            // unique case for approval and stakeholders
            if (section.sectionId === 'approval' || section.sectionId === 'stakeholders') {
              if (field.value === 0) {
                setValue<any>(`${section.sectionId}.fields.${field.fieldId}`, 'current');
                handleChangeField(section.sectionId, field.fieldId);
              }
              // everything else
            } else if (field.value === '' || field.value === null) {
              setValue<any>(`${section.sectionId}.fields.${field.fieldId}`, 'current');
              handleChangeField(section.sectionId, field.fieldId);
            }
          });
        }
      });
    },
    [copiedData, handleChangeField, setValue],
  );

  function handleExpandAll() {
    setIsAccordionExpanded((prev) => {
      const newAccordionExpanded = { ...prev };

      Object.keys(newAccordionExpanded).forEach((key) => {
        newAccordionExpanded[key] = true;
      });

      return newAccordionExpanded;
    });
  }

  function handleSearchTerm(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  const handleSelectNonEmptyFields = useCallback(
    (sectionId: string) => {
      const data =
        fieldLayout &&
        fieldLayout?.sections
          ?.find((section) => section.code === sectionId)
          ?.fields?.map((field) => {
            return {
              fieldId: field.alias,
              value: jobData?.[field.alias],
            };
          });

      data?.forEach((field) => {
        // unique case for approval and stakeholders
        if (sectionId === 'approval' || sectionId === 'stakeholders') {
          if (field.value !== 0) {
            setValue<any>(`${sectionId}.fields.${field.fieldId}`, 'current');
            handleChangeField(sectionId, field.fieldId);
          }
          // everything else
        } else if (field.value !== '' && field.value !== null) {
          setValue<any>(`${sectionId}.fields.${field.fieldId}`, 'current');
          handleChangeField(sectionId, field.fieldId);
        }
      });
    },
    [handleChangeField, setValue, fieldLayout, jobData],
  );

  const filteredSameValuesData = shouldHideSameValues
    ? getFilterSameValues(copiedData, jobData)
    : copiedData;

  const filteredSearchData = searchTerm
    ? getFilterSearchTerm(filteredSameValuesData, jobFields as FieldsTransformed, searchTerm)
    : filteredSameValuesData;

  useEffect(() => {
    const isAccordionExpandedValues = getIsAccordionExpandedValues(
      copiedData,
      compareDialogOpenSection,
      shouldSelectAllBlocksOption,
    );

    setIsAccordionExpanded(isAccordionExpandedValues);
  }, [copiedData, compareDialogOpenSection, shouldSelectAllBlocksOption]);

  return (
    <>
      <Box overflow="auto" width="100%">
        <CompareReplaceHeaderBar
          isClipboard={isClipboard}
          isSaveTemplate={isSaveTemplate}
          onCollapseAll={handleCollapseAll}
          onExpandAll={handleExpandAll}
          onOpenDeleteTemplate={onOpenDeleteTemplate}
          onOpenRenameTemplate={onOpenRenameTemplate}
          onOpenSaveTemplate={onOpenSaveTemplate}
          onSearchTerm={handleSearchTerm}
          onToggleHideSameValues={() => setShouldHideSameValues((prev) => !prev)}
          percentAccordionsExpanded={percentAccordionsExpanded}
          searchTerm={searchTerm}
          shouldHideSameValues={shouldHideSameValues}
        />

        {filteredSameValuesData.length === 0 ? (
          <Box
            alignItems="center"
            component={Typography}
            display="flex"
            height="300px"
            justifyContent="center"
          >
            All values in this comparison are identical.
          </Box>
        ) : (
          filteredSearchData.map((section) => {
            const updatedCount = getCountOfUpdatedFields(
              section.sectionId,
              watchedFormData,
              filteredSameValuesData,
            );
            const isExpanded = isAccordionExpanded[`${section.sectionId}_selected`];

            return isSaveTemplate ? (
              <CompareReplaceTemplateAccordion
                control={control}
                fieldLayout={fieldLayout}
                isExpanded={isExpanded}
                jobData={jobData}
                key={section.sectionId}
                onAccordionChange={handleAccordionChange}
                onDeselectEmptyFields={handleDeselectEmptyFields}
                section={section}
                updatedCount={updatedCount}
              />
            ) : (
              <CompareReplaceAccordion
                control={control}
                fieldLayout={fieldLayout}
                isExpanded={isExpanded}
                jobData={jobData}
                key={section.sectionId}
                onAccordionChange={handleAccordionChange}
                onChangeField={handleChangeField}
                onChangeGroup={handleChangeGroup}
                onDeselectEmptyFields={handleDeselectEmptyFields}
                onSelectNonEmptyFields={handleSelectNonEmptyFields}
                section={section}
                updatedCount={updatedCount}
              />
            );
          })
        )}
      </Box>

      {isSaveTemplate && (
        <CompareReplaceTemplateConfigurer control={control} templates={templates} />
      )}
    </>
  );
}
