import { Box, Button, Stack, Typography } from '@mui/material';

import { WaveIcon } from '../../features/WaveIcon';

import NotFoundImage from '../../images/404.png';

export function NotFound() {
  return (
    <Stack height={1}>
      <Stack alignItems="center" margin="auto" spacing={4}>
        <Typography fontWeight={700} variant="h2">
          404 Error
        </Typography>

        <Typography variant="h4">Page not found!</Typography>

        <Typography textAlign="center" variant="h6">
          We are sorry. The page you were looking for could not be found. <br />
          Please check the link or try another page.
        </Typography>

        <Button
          onClick={() => window.history.back()}
          startIcon={<WaveIcon code="not-found-go-back" />}
        >
          Go Back
        </Button>

        <Box alt="Not Found" component="img" maxWidth="400px" pt={3} src={NotFoundImage} />
      </Stack>
    </Stack>
  );
}
