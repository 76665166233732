import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { CopyJobDialogProps } from 'src/features/JobsTable/components/CopyJobDialog/CopyJobDialog';
import { buildPath } from 'src/features/JobsTable/components/CopyJobDialog/utilities';
import { CreateCopyButtonTransformed } from 'src/features/JobsTable/JobsTable.service';

type CopyMode = {
  id: CreateCopyButtonTransformed['id'];
  label: string;
} | null;
export type CopyToJobType = string | null;
type UseCopyJobDialogProps = Required<
  Pick<CopyJobDialogProps, 'copyJobOptions' | 'jobId' | 'jobType'>
>;

export function useCopyJobDialog({ copyJobOptions, jobId, jobType }: UseCopyJobDialogProps) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const [copyMode, setCopyMode] = useState<CopyMode>(null);
  const selectedSubItems = copyJobOptions.find(({ id }) => id === copyMode?.id)?.subItems ?? {};

  const [copyToJobType, setCopyToJobType] = useState<CopyToJobType>(null);

  function handleConfirm() {
    const selectedCopyOptionData = copyJobOptions.find(({ id }) => id === copyMode?.id)?.data;
    const mode = copyMode?.id === 'copy-in-project' ? 'copy-in-project' : 'copy';
    const path = buildPath(copyToJobType, jobId, jobType, mode, selectedCopyOptionData);

    navigate(path);
  }

  function handleChangeCopyOptions(_: SyntheticEvent<Element, Event>, newValue: CopyMode) {
    setCopyMode(newValue);
    setCopyToJobType(null);
  }

  function handleChangeCopyToJobTypeSelection(event: ChangeEvent<HTMLInputElement>) {
    setCopyToJobType((event.target as HTMLInputElement).value);
  }

  return {
    copyMode,
    copyToJobType,
    handleChangeCopyOptions,
    handleChangeCopyToJobTypeSelection,
    handleConfirm,
    selectedSubItems,
    translate,
  };
}
