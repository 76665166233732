import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SelectionArea, { SelectionEvent } from '@viselect/react';

import { Badge, Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { BreadCrumbs } from 'src/components/BreadCrumbs';
import { FileSearchSortOptions, useFileSearchQuery } from 'src/pages/FileSearch/FileSearch.service';
import { FileSearchBar } from 'src/pages/FileSearch/FileSearchBar';
import { FileSearchFilterCard } from 'src/pages/FileSearch/FileSearchFilterCard';
import { FileSearchGallery } from 'src/pages/FileSearch/FileSearchGallery';
import { getDataAttributes } from 'src/pages/FileSearch/FileSearchGridItem/utils';
import { FileSearchGridView } from 'src/pages/FileSearch/FileSearchGridView';
import { FileSearchListView } from 'src/pages/FileSearch/FileSearchListView';
import { FileSearchPagination } from 'src/pages/FileSearch/FileSearchPagination';
import { FileSearchSelectedGalleryItem } from 'src/pages/FileSearch/FileSearchSelectedGalleryItem';
import { FileSearchSelectionAreaContainer } from 'src/pages/FileSearch/FileSearchSelectionAreaContainer';
import { FileSearchSelectionDrawer } from 'src/pages/FileSearch/FileSearchSelectionDrawer';
import { StyledSelectionButtonContainer } from 'src/pages/FileSearch/styled';
import { addSelected, FileSearchSelectedData, removeSelected } from 'src/store/fileSearchSlice';
import { RootState } from 'src/store/store';
import { useAppSelector, useDebounce, useParentWidth, usePreference } from 'src/utilities/hooks';

export type ViewModeType = 'list' | 'grid' | 'compact';

export type ColumnSortType = 'asc' | 'desc';

let clickTimeout: NodeJS.Timeout | null = null;

export function FileSearch() {
  const clientIdPreference = usePreference('sys.mid', '');
  const viewModePreference = usePreference('file-search.view', 'grid');
  const rowsPerPagePreference = usePreference('file-search.rowsPerPage', '25');
  const parentRef = useRef<HTMLDivElement>(null);
  const parentRefWidth = useParentWidth(parentRef);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const searchTermDebounced = useDebounce(searchTerm);
  const [page, setPage] = useState(0);
  const selected = useSelector((state: RootState) => state.fileSearch);
  const [isSelectionDrawerOpen, setIsSelectionDrawerOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<ColumnSortType | null>(null);
  const userId = useAppSelector((state) => state.user.details.id);
  const [searchAfter, setSearchAfter] = useState<Array<string | number> | null>(null);
  const [searchAfterHistory, setSearchAfterHistory] = useState<Array<Array<string | number>>>([]);

  // default sort via score and id for elastic search search_after unless sorting is applied
  const sortOptions: FileSearchSortOptions =
    sortColumn && sortDirection
      ? [{ [sortColumn]: sortDirection }, { id: 'asc' }]
      : [{ _score: 'desc' }, { id: 'asc' }];

  const { data, isFetching, isLoading } = useFileSearchQuery({
    id: userId,
    mand: parseInt(clientIdPreference.value as string),
    query: searchTermDebounced,
    resultsPerPage: parseInt(rowsPerPagePreference.value as string),
    searchAfter: searchAfter,
    sort: sortOptions,
  });

  const fileItems = useMemo(() => {
    return data?.hits?.hits?.map((hit) => hit._source) ?? [];
  }, [data]);

  const count = data?.hits?.total?.value;

  const handleSelected = useCallback((added: Element[], removed: Element[]): void => {
    const addedIdAndJobIds = getDataAttributes(added);

    addedIdAndJobIds.forEach((id) => {
      dispatch(addSelected(id));
    });

    const removedIdAndJobIds = getDataAttributes(removed);

    removedIdAndJobIds.forEach((id) => {
      dispatch(removeSelected(id));
    });
  }, []);

  const handleOnDeselect = useCallback((tuple: FileSearchSelectedData) => {
    dispatch(removeSelected(tuple));
  }, []);

  function handleMove({ event, store }: SelectionEvent) {
    if (event === null && store.changed.added.length !== 0) {
      if (clickTimeout) {
        clearTimeout(clickTimeout);
        clickTimeout = null;
      } else {
        clickTimeout = setTimeout(() => {
          handleSelected(store.changed.added, store.changed.removed);
          clickTimeout = null;
        }, 160);
      }
    } else {
      handleSelected(store.changed.added, store.changed.removed);
    }
  }

  function handleStart({ event, selection }: SelectionEvent) {
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
    }
  }

  const handleToggleIsFilterOpen = useCallback(() => {
    setIsFilterOpen((prevIsFilterOpen) => !prevIsFilterOpen);
  }, []);

  function handleToggleOpenSelectionDrawer() {
    setIsSelectionDrawerOpen((prevIsSelectionDrawerOpen) => !prevIsSelectionDrawerOpen);
  }

  const handleSortTable = useCallback((sortName: string) => {
    setSortColumn((prevSortName) => {
      if (prevSortName === sortName) {
        setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
      }

      if (prevSortName !== sortName && prevSortName === null) {
        setSortDirection('asc');
      }

      return sortName;
    });
  }, []);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > page) {
        const nextSearchAfter = data?.hits?.hits?.[data?.hits?.hits?.length - 1]?.sort;

        if (nextSearchAfter) {
          setSearchAfter(nextSearchAfter);
          setSearchAfterHistory((prevSearchAfterHistory) => [
            ...prevSearchAfterHistory,
            nextSearchAfter,
          ]);
        }
      }

      if (newPage < page) {
        const prevSearchAfter = newPage === 0 ? null : searchAfterHistory[newPage - 1];

        setSearchAfter(prevSearchAfter);
        setSearchAfterHistory((prevSearchAfterHistory) => {
          return prevSearchAfterHistory.slice(0, prevSearchAfterHistory.length - 1);
        });
      }

      setPage(newPage);
    },
    [data, page],
  );

  const handleViewModeChange = useCallback((viewMode: ViewModeType) => {
    viewModePreference.set(viewMode);
  }, []);

  const handleRowsPerPageChange = useCallback((rowsPerPage: number) => {
    rowsPerPagePreference.set(rowsPerPage.toString());
  }, []);

  // Reset page and sort when search term changes
  useEffect(() => {
    setPage(0);
    setSortColumn(null);
    setSortDirection(null);
    setSearchAfter(null);
    setSearchAfterHistory([]);
  }, [searchTermDebounced]);

  return (
    <Box ref={parentRef}>
      <BreadCrumbs />

      <Box margin={3}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <FileSearchBar
              onChangeSearchTerm={setSearchTerm}
              onToggleIsFilterOpen={handleToggleIsFilterOpen}
              onViewModeChange={handleViewModeChange}
              searchTerm={searchTerm}
              viewMode={viewModePreference.value as ViewModeType}
            />
          </Grid>
        </Grid>

        <SelectionArea onMove={handleMove} onStart={handleStart} selectables=".selectable">
          <FileSearchSelectionAreaContainer>
            <Box display="flex" marginTop={3}>
              <FileSearchFilterCard
                isFilterOpen={isFilterOpen}
                onToggleIsFilterOpen={handleToggleIsFilterOpen}
              />

              {/* This is fix for Grid2 unstable nature, if our ui all of
               a sudden looks odd after an mui update remove width prop */}
              <FileSearchGallery fileItems={fileItems}>
                <Grid container spacing={3} width="calc(100% + 24px)">
                  {viewModePreference.value !== 'list' ? (
                    <Grid xs={12}>
                      <FileSearchPagination
                        count={count}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={parseInt(rowsPerPagePreference.value as string)}
                      />
                    </Grid>
                  ) : null}

                  <Grid xs={12}>
                    {viewModePreference.value === 'list' ? (
                      <FileSearchListView
                        fileItems={fileItems}
                        isLoading={isLoading || isFetching}
                        onSortTable={handleSortTable}
                        page={page}
                        rowsPerPage={parseInt(rowsPerPagePreference.value as string)}
                        selected={selected}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                      >
                        <FileSearchPagination
                          count={count}
                          onPageChange={handlePageChange}
                          onRowsPerPageChange={handleRowsPerPageChange}
                          page={page}
                          rowsPerPage={parseInt(rowsPerPagePreference.value as string)}
                        />
                      </FileSearchListView>
                    ) : (
                      <FileSearchGridView
                        fileItems={fileItems}
                        isCompact={viewModePreference.value === 'compact'}
                        isLoading={isLoading || isFetching}
                        selected={selected}
                      />
                    )}
                  </Grid>
                </Grid>
              </FileSearchGallery>
            </Box>
          </FileSearchSelectionAreaContainer>
        </SelectionArea>
      </Box>

      <FileSearchGallery fileItems={fileItems}>
        {selected.map(([fileId, , thumb]: FileSearchSelectedData, index: number) => (
          <FileSearchSelectedGalleryItem index={index} key={`selected_${fileId}`} thumb={thumb} />
        ))}

        <FileSearchSelectionDrawer
          isSelectionDrawerOpen={isSelectionDrawerOpen}
          onCloseSelectionDrawer={handleToggleOpenSelectionDrawer}
          onDeselect={handleOnDeselect}
          selected={selected}
          width={parentRefWidth}
        />
      </FileSearchGallery>

      <StyledSelectionButtonContainer>
        <Badge badgeContent={selected.length} color="error">
          <Button onClick={handleToggleOpenSelectionDrawer}>
            <Trans i18nKey="file-ser.selected_files">Selected Files</Trans>
          </Button>
        </Badge>
      </StyledSelectionButtonContainer>
    </Box>
  );
}
