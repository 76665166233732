import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Checkbox } from 'src/components/RHF/Checkbox';
import { DateField } from 'src/components/RHF/DateField';
import { DateTime } from 'src/components/RHF/DateTime';
import { ProgressBar } from 'src/components/RHF/ProgressBar';
import { RichText } from 'src/components/RHF/RichText';
import { TextField } from 'src/components/RHF/TextField';
import {
  isNumber,
  isSelectField,
  isTextField,
} from 'src/features/JobForm/components/FieldMapper2/utilities';
import { useJobForm } from 'src/features/JobForm/useJobForm';
import { UploadFiles } from 'src/features/UploadFiles';
import { FieldType } from 'src/pages/Job/Job.service';

export type FieldMapper2Props = {
  alias: string;
  description?: string;
  fieldData?: any;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  onChangeSteeredField?: (fieldName: string) => void;
  onClickAssociatedCheckbox?: (isChecked: boolean) => void;
  isDisabled: boolean;
  isError?: boolean;
  isRequired?: boolean;
  isLabelledText?: boolean;
  isRawText?: boolean;
  isReadOnly?: boolean;
  name?: string;
  param?: Record<string, string>;
  type: FieldType;
  fieldValueIndex?: number;
};

export function FieldMapper2({
  alias,
  description = '',
  fieldData = [],
  fieldValueIndex,
  isDisabled,
  isError = false,
  isLabelledText = false,
  isRawText = false,
  isReadOnly = false,
  isRequired,
  name = '',
  onChangeLogicBuilderField,
  onChangeSteeredField,
  onClickAssociatedCheckbox,
  param,
  type,
}: FieldMapper2Props) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const { control } = useFormContext();
  const { isNewJob } = useJobForm();
  const { t } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const requiredRule = isRequired
    ? {
        required: {
          message: `${t('lib.required')}`,
          value: isRequired,
          // This value isn't working.
          // Even if you set it to false,
          // it still shows that the field is required.
          // For that reason, I used a ternary operator
          // and return an empty object.
        },
      }
    : {};

  if (!isVisible) {
    return (
      <Skeleton
        height={type === 'memo' ? 132 : 40}
        id={name}
        ref={componentRef}
        variant="rounded"
        width="100%"
      />
    );
  }

  return (
    <div ref={componentRef} style={{ display: 'flex', flex: 1 }}>
      {isSelectField(type) ? (
        <Autocomplete
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          label={name}
          multiple={type === 'pick' || type === 'pickselect'}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          onChangeSteeredField={onChangeSteeredField}
          options={fieldData}
          rules={requiredRule}
          shouldShowHelperText={false}
        />
      ) : type === 'boolean' ? (
        <Checkbox
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          label={name}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          onClickAssociatedCheckbox={onClickAssociatedCheckbox}
          rules={requiredRule}
          shouldShowHelperText={false}
        />
      ) : type === 'date' ? (
        <DateField
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          label={name}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          rules={requiredRule}
          shouldShowHelperText={false}
        />
      ) : type === 'datetime' ? (
        <DateTime
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          label={name}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          rules={requiredRule}
          shouldShowHelperText={false}
        />
      ) : type === 'file' ? (
        <UploadFiles
          destination={param?.dest as string}
          isDisabled={isDisabled}
          isMultipleFiles={false}
          isNewJob={isNewJob}
          key={name}
          label={name}
          variant="jobForm"
        />
      ) : type === 'pbar' ? (
        <ProgressBar control={control} name={alias} />
      ) : isTextField(type) ? (
        <TextField
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          isLabelledText={isLabelledText}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          label={name}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          rules={requiredRule}
          shouldShowHelperText={false}
          {...(type === 'ean' && { type: 'barcode' })}
          {...(type === 'memo' && { type: 'memo' })}
          {...(type === 'color' && { type: 'core_color' })}
          {...(isNumber(type) && { type: 'number' })}
        />
      ) : type === 'rich' ? (
        <RichText
          control={control}
          fieldValueIndex={fieldValueIndex}
          name={alias}
          placeholder={name}
        />
      ) : null}
    </div>
  );
}
