import { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  FormGroup,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { Checkbox } from 'src/components/RHF/Checkbox';
import { WidgetBase } from '../WidgetBase';

import type { TemplateSelectionParams } from '../../Content.types';

type TranslationsProps = {
  selectedCategory: string;
  data: Record<string, string>;
  columns: number;
};

export function Translations({ columns, data, selectedCategory }: TranslationsProps) {
  const { t } = useTranslation();
  const [areAllChecked, setAreAllChecked] = useState(false);
  const [categoryIndex, itemIndex] = selectedCategory.split('_');
  const { control, getValues, setValue } = useFormContext<TemplateSelectionParams>();
  const LANGUAGE_CODES = Object.keys(data);

  function handleChangeAll(e: ChangeEvent<HTMLInputElement>) {
    setAreAllChecked(e.target.checked);
    const translationsValues = LANGUAGE_CODES.reduce((accumulator, currentValue) => {
      return { ...accumulator, [currentValue]: e.target.checked };
    }, {});

    setValue(`categories.${+categoryIndex}.items.${+itemIndex}.translations`, translationsValues);
  }

  function handleCheckboxChange() {
    const values = getValues(`categories.${+categoryIndex}.items.${+itemIndex}.translations`);

    let areAllItemsChecked = true;

    LANGUAGE_CODES.forEach((language) => {
      if (!values[language]) {
        areAllItemsChecked = false;
      }
    });

    if (areAllChecked !== areAllItemsChecked) {
      setAreAllChecked(areAllItemsChecked);
    }
  }

  return (
    <WidgetBase
      action={
        <FormGroup>
          <FormControlLabel
            control={<MUICheckbox checked={areAllChecked} onChange={handleChangeAll} />}
            label={t('lib.all', 'All')}
          />
        </FormGroup>
      }
      title="lib.id_translations"
    >
      <Grid container px={2} width={1}>
        {LANGUAGE_CODES.map((item, index) => (
          <Grid key={index} xs={columns}>
            <Checkbox
              control={control}
              key={`categories.${+categoryIndex}.items.${+itemIndex}.translations.${item}`}
              label={data[item]}
              name={`categories.${+categoryIndex}.items.${+itemIndex}.translations.${item}`}
              onChange={handleCheckboxChange}
            />
          </Grid>
        ))}
      </Grid>
    </WidgetBase>
  );
}
