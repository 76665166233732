import React from "react";

import { Editor } from "primereact/editor";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

export function RichText({ disabled, id, onChange, value }) {
  return (
    <Editor
      headerTemplate={
        <React.Fragment>
          <span className="ql-formats">
            <select className="ql-font"></select>
            <select className="ql-size"></select>
          </span>
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <select className="ql-align">
              <option value=""></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>
        </React.Fragment>
      }
      id={id}
      onTextChange={onChange}
      readOnly={disabled}
      style={{ height: "200px" }}
      value={value}
    />
  );
}
