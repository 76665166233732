import { Button } from 'src/components/Button';
import { useNewJobButton } from 'src/features/JobsTable/components/NewJobButton/useNewJobButton';
import { ActionTransformed } from 'src/features/JobsTable/JobsTable.service';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export type NewJobButtonProps = {
  code: string;
  subActions?: ActionTransformed[];
};
export function NewJobButton({ code, subActions }: NewJobButtonProps) {
  const {
    anchorEl,
    handleClickNewJob,
    handleCloseSubMenu,
    isSubMenuOpen,
    jobId,
    jobType,
    navigate,
  } = useNewJobButton({ subActions });

  const text = 'job.new';

  const subMenuActions = subActions?.map(({ code, text }) => ({
    code,
    icon: (
      <WaveIcon
        className={`color-${code}`}
        code="sub-jobs-action-bar-new-job-sub-menu"
        fontSize="small"
      />
    ),
    onClick: () =>
      navigate(
        `/jobs-job-${code}/new/job?mode=sub&copyFromJobId=${jobId}&copyFromJobType=${jobType}`,
      ),
    text,
  }));

  return (
    <>
      <Button
        onClick={handleClickNewJob}
        startIcon={<WaveIcon code={code} />}
        sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
      >
        {text}
      </Button>

      {subMenuActions ? (
        <SubMenu
          anchorEl={anchorEl}
          anchorOriginHorizontalSubitem="left"
          anchorOriginVerticalSubitem="top"
          fontSize="caption"
          items={subMenuActions}
          onClose={handleCloseSubMenu}
          open={isSubMenuOpen}
          transformOriginHorizontalSubitem="right"
          transformOriginVerticalSubitem="top"
        />
      ) : null}
    </>
  );
}
