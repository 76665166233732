import { Fragment, MouseEvent, useState } from 'react';

import { List, Popover, PopoverOrigin, PopoverProps } from '@mui/material';

import { SubMenuItem } from './components/SubMenuItem';

import type { SubMenuItemType } from './SubMenu.types';

type SubMenuProps = {
  anchorEl: Element | null;
  anchorElMinWidth?: number;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  anchorOriginHorizontalSubitem?: PopoverOrigin['horizontal'];
  anchorOriginVerticalSubitem?: PopoverOrigin['vertical'];
  checkedItems?: Array<{
    code: string;
    text: string;
  }>;
  className?: string;
  fontSize?: string;
  items: Array<SubMenuItemType>;
  onClose: () => void;
  // eslint-disable-next-line react/boolean-prop-naming
  open: boolean;
  transformOrigin?: PopoverProps['transformOrigin'];
  transformOriginHorizontalSubitem?: PopoverOrigin['horizontal'];
  transformOriginVerticalSubitem?: PopoverOrigin['vertical'];
};

export function SubMenu({
  anchorEl,
  anchorElMinWidth,
  anchorOrigin = {
    horizontal: 'left',
    vertical: 'bottom',
  },
  anchorOriginHorizontalSubitem = 'left',
  anchorOriginVerticalSubitem = 'bottom',
  checkedItems = [],
  className,
  fontSize,
  items,
  onClose,
  open,
  transformOrigin = {
    horizontal: 'left',
    vertical: 'top',
  },
  transformOriginHorizontalSubitem = 'left',
  transformOriginVerticalSubitem = 'top',
}: SubMenuProps) {
  const [anchorSubEl, setAnchorSubEl] = useState<Element | null>(null);
  const [subItemsOpen, setSubItemsOpen] = useState<Record<string, boolean>>({});

  function handleSubMenuClick(e: MouseEvent, code: string) {
    e.stopPropagation();
    const currentTarget = e.currentTarget; // Needed because setState clears the event

    setSubItemsOpen((prevState) => ({
      [`${code}Open`]: prevState[`${code}Open`] === true ? false : true,
    }));

    setAnchorSubEl(currentTarget);
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      classes={{
        paper: className,
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        style: { minWidth: anchorElMinWidth },
      }}
      transformOrigin={transformOrigin}
    >
      <List dense disablePadding>
        {items.map((item) => (
          <Fragment key={item.link || item.code}>
            <SubMenuItem
              checkedItems={checkedItems}
              fontSize={fontSize}
              isDisabled={item.disabled}
              isTooltipDisabled={item.tooltipDisabled}
              item={item}
              items={items}
              onClose={onClose}
              onSubMenuClick={handleSubMenuClick}
              subItemsOpen={subItemsOpen}
              tooltipBody={item.tooltipBody}
              tooltipHeader={item.tooltipHeader}
            />

            {item.subItems && (
              <SubMenu
                anchorEl={anchorSubEl}
                anchorOrigin={{
                  horizontal: anchorOriginHorizontalSubitem,
                  vertical: anchorOriginVerticalSubitem,
                }}
                fontSize={fontSize}
                items={item.subItems}
                onClose={() => {
                  onClose();
                  setAnchorSubEl(null);
                  setSubItemsOpen({});
                }}
                open={Boolean(subItemsOpen[`${item.code}Open`])}
                transformOrigin={{
                  horizontal: transformOriginHorizontalSubitem,
                  vertical: transformOriginVerticalSubitem,
                }}
              />
            )}
          </Fragment>
        ))}
      </List>
    </Popover>
  );
}
