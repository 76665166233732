import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { openWaveSnack } from '../../../../store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from '../../../../utilities/hooks';
import { useCreateContentMutation, useGetCategoriesQuery } from '../../content.service';
import { ContentSelection } from '../ContentSelection';
import { CreationHeader } from '../CreationHeader';
import { TemplateSelection } from '../TemplateSelection';

import type { CategoryItem, CreationStep, PhraseContent } from '../../Content.types';

export function ContentCreate() {
  const [step, setStep] = useState<CreationStep>('TEMPLATE_SELECTION');
  const [selectedCategory, setSelectedCategory] = useState('0_0');
  const { age, jobId, jobType } = useRouteParams();
  const navigate = useNavigate();
  const [createContent, { isLoading }] = useCreateContentMutation();
  const dispatch = useAppDispatch();
  const { data: categoriesList = [] } = useGetCategoriesQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );
  const methods = useForm({
    defaultValues: {
      categories: [
        { amount: undefined, categoryId: '', items: [{ status: 'NEEDED' }], layout: '' },
      ],
      initialSetupComplete: false,
      template: '',
    },
    mode: 'onChange',
  });

  const categories = useFieldArray({
    control: methods.control,
    name: 'categories',
  });

  async function handleChangeStep(step: CreationStep) {
    if (step === 'CONTENT_SELECTION') {
      if (await methods.trigger()) {
        setStep(step);
      }
    } else {
      setStep(step);
    }
  }

  function handleSaveContent() {
    methods.handleSubmit((data) => {
      let isValid = true;
      const contents: Array<PhraseContent> = [];

      data.categories.forEach((category, categoryIndex) => {
        const currentCategory = categoriesList.find((x) => x.code === category.categoryId);

        if (category.items && currentCategory) {
          (category.items as CategoryItem[]).forEach((item, itemIndex) => {
            if (item.result) {
              const nonRequiredTranslations =
                item.translations && Object.keys(item.translations)
                  ? Object.keys(item.translations).filter(
                      (translation) => !item.translations[translation],
                    )
                  : undefined;

              const packTypes =
                item.packTypes && Object.keys(item.packTypes)
                  ? Object.keys(item.packTypes).filter((packType) => item.packTypes[packType])
                  : undefined;

              contents.push({
                category: category.categoryId,
                layout: category.layout,
                position: [categoryIndex, itemIndex, 0],
                status: (item as CategoryItem).status,
                text: (item as CategoryItem).result || '',
                ...((nonRequiredTranslations || packTypes) && {
                  features: {
                    pack_types: packTypes,
                    translations_not_required: nonRequiredTranslations,
                  },
                }),
              });
            } else {
              if (currentCategory.required) {
                isValid = false;
                methods.setError(`categories.${categoryIndex}.items.${itemIndex}`, {
                  message: 'error',
                });

                dispatch(
                  openWaveSnack({
                    message: 'Please fill required categories',
                    type: 'error',
                  }),
                );
              }
            }
          });
        }
      });

      if (isValid) {
        createContent({
          age,
          contents,
          jobid: jobId,
          src: jobType,
        })
          .unwrap()
          .then(() => {
            navigate(`/jobs-${age}-${jobType}/${jobId}/cms`);
            dispatch(
              openWaveSnack({
                message: 'Content added successfully',
                type: 'success',
              }),
            );
          });
      }
    })();
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={1}>
      <CreationHeader
        currentStep={step}
        isSubmitDisabled={isLoading}
        onCategoryChange={setSelectedCategory}
        onContentSave={handleSaveContent}
        onStepChange={handleChangeStep}
      />

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FormProvider {...methods} fieldArrays={{ categories }}>
        {step === 'TEMPLATE_SELECTION' ? (
          <TemplateSelection />
        ) : (
          <ContentSelection
            onCategoryChange={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        )}
      </FormProvider>
    </Box>
  );
}
