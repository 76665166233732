type Values = {
  name: string;
  type: string;
  value: boolean;
};

export function getDefaultValues(values: Record<string, Values>): Record<string, boolean> {
  return Object.keys(values).reduce((acc: Record<string, boolean>, key) => {
    acc[key] = values[key].value;

    return acc;
  }, {});
}

type NestedObject = {
  [key: string]: any;
};

export function getFlattenObject(obj: NestedObject, prefix = ''): Record<string, number> {
  return Object.keys(obj).reduce((acc: Record<string, number>, key: string) => {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(acc, getFlattenObject(obj[key], newKey));
    } else {
      acc[newKey] = obj[key] ? 1 : 0;
    }

    return acc;
  }, {});
}
