import { useEffect, useState } from 'react';

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  function getInitialValue() {
    const storedValue = localStorage.getItem(key);

    return storedValue ? JSON.parse(storedValue) : initialValue;
  }

  const [state, setState] = useState<T>(getInitialValue);

  function setLocalStorage(value: T) {
    localStorage.setItem(key, JSON.stringify(value));
    setState(value);

    const event = new StorageEvent('storage', {
      key,
      newValue: JSON.stringify(value),
    });

    window.dispatchEvent(event);
  }

  useEffect(() => {
    function handleStorageChange(e: StorageEvent) {
      if (e.key === key) {
        setState(e.newValue ? JSON.parse(e.newValue) : initialValue);
      }
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  return [state, setLocalStorage];
}
