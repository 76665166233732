import { Grid } from '@mui/material';

import { BreadCrumbList } from 'src/components/BreadCrumbs/components/BreadCrumbList';
import { HeaderSwitch } from 'src/components/BreadCrumbs/components/HeaderSwitch';
import { StyledGrid } from 'src/components/BreadCrumbs/styled';
import { usePreferencePrefix, withFullScreen } from 'src/utilities/hooks';

export function BreadCrumbs() {
  const { jobType } = usePreferencePrefix();

  return (
    <StyledGrid container id="breadCrumbs" jobType={jobType}>
      <Grid item>
        <BreadCrumbList />
      </Grid>

      <Grid item>
        <HeaderSwitch />
      </Grid>
    </StyledGrid>
  );
}

export const VisibilityBreadCrumbs = withFullScreen(BreadCrumbs, { hideInFullScreen: true });
