import { Trans, useTranslation } from 'react-i18next';

import { Box, Paper, Skeleton, Typography, useTheme } from '@mui/material';

import { BreadCrumbs } from 'src/components/BreadCrumbs';
import { EditableAvatar } from 'src/features/EditableAvatar';
import { UserDetails } from 'src/features/UserDetails';
import { useGetUserProfileQuery } from 'src/pages/UserProfile/UserProfile.service';
import { useAppSelector } from 'src/utilities/hooks';

export function UserProfile() {
  const userId = useAppSelector((state) => state.user.details.id);
  const { data, isFetching } = useGetUserProfileQuery(
    { userId },
    { refetchOnMountOrArgChange: true },
  );
  const {
    palette: {
      primary: { contrastText, main: primary },
    },
  } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <BreadCrumbs />

      <Box display="flex" gap={1} m={2}>
        <Box
          alignItems="center"
          component={Paper}
          display="flex"
          flexDirection="column"
          paddingX={8}
          paddingY={4}
        >
          <EditableAvatar
            backgroundColor={primary}
            iconCode="user-profile-avatar-edit"
            iconColor={contrastText}
          />

          <Box alignItems="center" display="flex" flexDirection="column" gap={2} mt={4}>
            <Box display="flex" gap={1}>
              <Typography fontWeight="bold">{t('lib.domain')}</Typography>

              {isFetching ? (
                <Skeleton variant="rounded" width={80} />
              ) : (
                <Typography>{data?.domain}</Typography>
              )}
            </Box>

            <Box display="flex" gap={1}>
              <Typography fontWeight="bold">
                <Trans i18nKey="user_profile_employee_number">Employee Number</Trans>:
              </Typography>

              {isFetching ? (
                <Skeleton variant="rounded" width={80} />
              ) : (
                <Typography>{data?.employeeNumber}</Typography>
              )}
            </Box>
          </Box>
        </Box>

        <UserDetails />
      </Box>
    </>
  );
}
