import { memo, useCallback } from 'react';

import { TableSortLabel } from '@mui/material';

import { ColumnSortType } from 'src/pages/FileSearch/FileSearch';
import { StyledTableCell } from 'src/pages/FileSearch/FileSearchListView/styled';

const MemoizedTableSortLabel = memo(TableSortLabel);

type FileSearchSortableTableCellProps = {
  label: string;
  sortName: string;
  sortColumn?: string | null;
  sortDirection?: ColumnSortType | null;
  onSortTable: (sortName: string) => void;
};

export function FileSearchSortableTableCell({
  label,
  onSortTable,
  sortColumn,
  sortDirection,
  sortName,
}: FileSearchSortableTableCellProps) {
  const handleSortTable = useCallback(() => {
    onSortTable(sortName);
  }, []);

  return (
    <StyledTableCell>
      <MemoizedTableSortLabel
        active={sortColumn === sortName}
        direction={sortDirection ?? 'asc'}
        onClick={handleSortTable}
      >
        {label}
      </MemoizedTableSortLabel>
    </StyledTableCell>
  );
}
