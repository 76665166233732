import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Step, StepLabel, Stepper } from '@mui/material';

import { WaveTooltip } from '../../../../components/WaveTooltip';
import { useRouteParams } from '../../../../utilities/hooks';
import type { CompareStep } from '../../CompareFiles';

type WizardProps = {
  currentStep: CompareStep;
  onConfirm: () => void;
  onStepChange: (step: CompareStep) => void;
  isConfirmationDisabled: boolean;
};

export function Wizard({
  currentStep,
  isConfirmationDisabled,
  onConfirm,
  onStepChange,
}: WizardProps) {
  const { jobId, jobType } = useRouteParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleCancel() {
    navigate(`/jobs-job-${jobType}/${jobId}/fil`);
  }

  function renderConfirmButton() {
    return (
      <Button
        color="primary"
        disabled={isConfirmationDisabled}
        onClick={() =>
          currentStep === 'CHOOSE_FILES' ? onStepChange('CONFIRMATION') : onConfirm()
        }
      >
        {currentStep === 'CHOOSE_FILES' ? `${t('lib.next')}` : `${t('lib.confirm')}`}
      </Button>
    );
  }

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid rgba(0, 0, 0, 0.12)"
      display="flex"
      justifyContent="space-between"
      px={2}
      py={1.5}
    >
      <Box display="flex" flex="1 1 0px" gap={1} />

      <Box flex="1 1 0px">
        <Stepper activeStep={currentStep === 'CHOOSE_FILES' ? 0 : 1}>
          <Step completed={false}>
            <StepLabel>{t('lib.choose_files')}</StepLabel>
          </Step>

          <Step completed={false}>
            <StepLabel>{t('lib.confirmation')}</StepLabel>
          </Step>
        </Stepper>
      </Box>

      <Box display="flex" flex="1 1 0px" gap={1} justifyContent="flex-end">
        <Button color="warning" onClick={handleCancel}>
          {t('lib.cancel')}
        </Button>

        {currentStep !== 'CHOOSE_FILES' ? (
          <Box
            component={Button}
            onClick={() => onStepChange('CHOOSE_FILES')}
            size="small"
            variant="outlined"
          >
            {t('lib.back')}
          </Box>
        ) : null}

        {isConfirmationDisabled ? (
          <WaveTooltip
            body={t('compare_files_select_two', 'At least 2 files need to be selected') as string}
            component={renderConfirmButton()}
            placement="top"
            type="simple"
          />
        ) : (
          renderConfirmButton()
        )}
      </Box>
    </Box>
  );
}
