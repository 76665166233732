import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Link } from '@mui/material';

type ShowMoreProps = {
  text: string;
};

export function ShowMore({ text }: ShowMoreProps) {
  const [isShowMore, setIsShowMore] = useState(true);

  function toggleShowMore() {
    setIsShowMore(!isShowMore);
  }

  return text.length > 100 ? (
    <>
      {isShowMore ? `${text.slice(0, 100)}... ` : text}

      <Link color="info.main" href="#" onClick={toggleShowMore} underline="always">
        <Trans i18key={isShowMore ? 'show_more_link' : 'show_less_link'}>
          {isShowMore ? 'show more' : 'show less'}
        </Trans>
      </Link>
    </>
  ) : (
    <>{text}</>
  );
}
