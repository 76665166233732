import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  List,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useFieldSelector } from 'src/features/JobsTable/components/FieldSelector/useFieldSelector';
import { FieldSelectorFieldWell } from 'src/features/JobsTable/components/FieldSelectorFieldWell';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { Preference } from 'src/utilities/hooks/usePreference';

export type FieldSelectorProps = {
  fieldPreference: Preference;
  isOpen: boolean;
  onApplyFieldSelector: (selectedFields?: FieldTransformed[]) => void;
  onCloseFieldSelector: () => void;
  savedAvailableFields?: FieldTransformed[];
  savedSelectedFields?: FieldTransformed[];
  type: string;
};

export function FieldSelector({
  fieldPreference,
  isOpen,
  onApplyFieldSelector,
  onCloseFieldSelector,
  savedAvailableFields,
  savedSelectedFields,
  type,
}: FieldSelectorProps) {
  const {
    areAvailableFieldsChecked,
    areSelectedFieldsChecked,
    availableFields,
    checkedAvailableFields,
    handleChangeSearchField,
    handleClickAdd,
    handleClickApply,
    handleClickDown,
    handleClickRemove,
    handleClickUp,
    handleClose,
    handleReset,
    haveSelectedFieldsChanged,
    indexesOfCheckedSelectedFields,
    searchValue,
    selectedFields,
    setCheckedAvailableFields,
    setIndexesOfCheckedSelectedFields,
    translate,
  } = useFieldSelector({
    fieldPreference,
    onApplyFieldSelector,
    onCloseFieldSelector,
    savedAvailableFields,
    savedSelectedFields,
  });

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} open={isOpen}>
      <WaveDialogTitle
        onClose={handleClose}
        title={`${type} ${translate('lib.field_selector', 'Field Selector')}`}
      />

      <DialogContent>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          marginTop={0}
          spacing={2}
        >
          <Grid xs={12}>
            <TextField
              disabled={fieldPreference.isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WaveIcon code="search" />
                  </InputAdornment>
                ),
              }}
              label={translate('search_fields_field', 'Search Fields')}
              onChange={handleChangeSearchField}
              size="small"
              type="search"
              value={searchValue}
              variant="outlined"
            />
          </Grid>

          <Grid justifySelf="flex-start" xs={5}>
            <Typography fontWeight="bold" variant="subtitle2">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {translate('lib.available_fields_selected', {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                count: `${checkedAvailableFields.length}/${availableFields.length}`,
                defaultValue: 'Available fields: {{ count }} selected',
              })}
            </Typography>
          </Grid>

          <Grid justifySelf="flex-end" xs={5}>
            <Typography fontWeight="bold" variant="subtitle2">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {translate('lib.available_fields_selected', {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                count: `${indexesOfCheckedSelectedFields.length}/${selectedFields.length}`,
                defaultValue: 'Selected fields: {{ count }} selected',
              })}
            </Typography>
          </Grid>

          <Grid xs={5}>
            <FieldSelectorFieldWell
              checkedFields={checkedAvailableFields}
              fieldPreference={fieldPreference}
              fields={availableFields}
              setCheckedAvailableFields={setCheckedAvailableFields}
              type="available"
            />
          </Grid>

          <Grid container direction="column" height="200px" justifyContent="space-between" xs={1}>
            <Grid>
              <Button
                disabled={!areAvailableFieldsChecked || fieldPreference.isLoading}
                onClick={handleClickAdd}
              >
                <WaveIcon code="keyboard-arrow-right" />
              </Button>
            </Grid>

            <Grid>
              <Button
                disabled={!areSelectedFieldsChecked || fieldPreference.isLoading}
                onClick={handleClickRemove}
              >
                <WaveIcon code="keyboard-arrow-left" />
              </Button>
            </Grid>

            <Grid>
              <Button
                disabled={
                  !areSelectedFieldsChecked ||
                  fieldPreference.isLoading ||
                  indexesOfCheckedSelectedFields.includes(0)
                }
                onClick={handleClickUp}
              >
                <WaveIcon code="keyboard-arrow-up" />
              </Button>
            </Grid>

            <Grid>
              <Button
                disabled={
                  !areSelectedFieldsChecked ||
                  fieldPreference.isLoading ||
                  indexesOfCheckedSelectedFields.includes(selectedFields.length - 1)
                }
                onClick={handleClickDown}
              >
                <WaveIcon code="keyboard-arrow-down" />
              </Button>
            </Grid>
          </Grid>

          <Grid xs={5}>
            <List component="nav" disablePadding>
              <FieldSelectorFieldWell
                checkedFields={indexesOfCheckedSelectedFields}
                fieldPreference={fieldPreference}
                fields={selectedFields}
                setIndexesOfCheckedSelectedFields={setIndexesOfCheckedSelectedFields}
                type="selected"
              />
            </List>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="warning" disabled={fieldPreference.isLoading} onClick={handleReset}>
          {translate('lib.reset')}
        </Button>

        <LoadingButton
          disabled={!haveSelectedFieldsChanged}
          loading={fieldPreference.isLoading}
          onClick={handleClickApply}
          variant="contained"
        >
          <Trans i18next="apply_button">Apply</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
