import { FieldValues, UseControllerProps } from 'react-hook-form';

import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material';

import { Option } from 'src/pages/Job/Job.service';
import { MultipleAutocomplete } from './MultipleAutocomplete';
import { SimpleAutocomplete } from './SimpleAutocomplete';

import { AutocompleteProps } from './types';

export function Autocomplete<
  T extends string | Option,
  P extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  multiple,
  ...rest
}: Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'defaultValue' | 'renderInput'
> &
  Omit<UseControllerProps<P>, 'defaultValue'> &
  AutocompleteProps<T>) {
  if (!multiple) {
    return <SimpleAutocomplete {...rest} />;
  }

  return <MultipleAutocomplete<T, P, typeof multiple, DisableClearable, FreeSolo> {...rest} />;
}
