import { FieldValues, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { Checkbox } from 'src/components/RHF/Checkbox';
import { Typography } from 'src/components/Typography';
import {
  CompareReplaceOptions as CompareReplaceOptionsType,
  useUpdateCompareReplaceOptionsMutation,
} from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import {
  getDefaultValues,
  getFlattenObject,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceOptions/utils';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';

type CompareReplaceOptionsProps = {
  options: CompareReplaceOptionsType['values'];
};

export function CompareReplaceOptions({ options }: CompareReplaceOptionsProps) {
  const dispatch = useAppDispatch();

  const [updateCompareReplaceOptions, { isLoading }] = useUpdateCompareReplaceOptionsMutation();

  const { control, getValues } = useForm<FieldValues>({
    defaultValues: getDefaultValues(options),
  });

  function handleSaveOptions() {
    const formValues = getValues();
    const flattenedFormValues = getFlattenObject(formValues);

    updateCompareReplaceOptions({
      val: {
        ...flattenedFormValues,
      },
    })
      .unwrap()
      .then(() => {
        dispatch(openWaveSnack({ message: 'Settings saved successfully', type: 'success' }));
      });
  }

  return (
    <Box display="flex" flex={1} justifyContent="space-between" margin={4}>
      <Box display="flex" flexDirection="column">
        <Box component={Typography} fontWeight={500} marginBottom={5}>
          <Trans i18nKey="choose_your_options">Choose your options</Trans>
        </Box>

        {Object.keys(options).map((value) => (
          <Box key={value} marginBottom={2}>
            <Checkbox control={control} label={options?.[value].name} name={value} />
          </Box>
        ))}
      </Box>

      <Box>
        <LoadingButton
          loading={isLoading}
          onClick={handleSaveOptions}
          startIcon={<WaveIcon code="save" />}
          variant="contained"
        >
          <Trans i18nKey="save_settings">Save Settings</Trans>
        </LoadingButton>
      </Box>
    </Box>
  );
}
