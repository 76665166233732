import ReactQuill from 'react-quill';

import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledReactQuill = styled(ReactQuill)<{ muiTheme: Theme }>`
  .ql-container {
    flex-grow: 1;
  }
  .ql-toolbar {
    background-color: ${({ muiTheme }) => muiTheme.palette.filler.main};
    display: flex;
  }
  .ql-toolbar .ql-formats {
    display: flex;
    gap: ${({ muiTheme }) => muiTheme.spacing(1)};
    margin-right: 0;
  }
  .ql-formats:not(:last-child) {
    border-color: ${({ muiTheme }) => muiTheme.palette.divider};
    border-width: thin;
    border-right-style: solid;
  }
  .ql-formats:not(:first-of-type) {
    padding-left: ${({ muiTheme }) => muiTheme.spacing(1)};
  }
  button {
    border-radius: ${({ muiTheme }) => muiTheme.spacing(0.5)};
  }
  button:hover,
  button.ql-active {
    background-color: rgba(25, 25, 25, 0.12);
  }
  .ql-toolbar button:focus .ql-stroke,
  .ql-toolbar button:hover .ql-stroke,
  .ql-toolbar button.ql-active .ql-stroke,
  .ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-toolbar .ql-picker-label.ql-active,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-toolbar .ql-picker-item:hover {
    color: ${({ muiTheme }) => muiTheme.palette.primary.main};
    stroke: ${({ muiTheme }) => muiTheme.palette.primary.main};
  }
  .ql-toolbar button:focus .ql-fill,
  .ql-toolbar button:hover .ql-fill,
  .ql-toolbar button.ql-active .ql-fill {
    fill: ${({ muiTheme }) => muiTheme.palette.primary.main};
  }
  .ql-picker {
    background-color: ${({ muiTheme }) => muiTheme.palette.common.white};
    border-color: ${({ muiTheme }) => muiTheme.palette.divider};
    border-style: solid;
    border-width: thin;
    border-radius: ${({ muiTheme }) => muiTheme.spacing(0.5)};
    font-family: ${({ muiTheme }) => muiTheme.typography.fontFamily};
    font-size: ${({ muiTheme }) => muiTheme.typography.pxToRem(12)};
    font-weight: ${({ muiTheme }) => muiTheme.typography.fontWeightRegular};
    line-height: 1;
  }
  .ql-picker-options {
    padding-left: 0;
    padding-right: 0;
  }
  .ql-picker-item {
    padding-left: ${({ muiTheme }) => muiTheme.spacing(1)};
    padding-right: ${({ muiTheme }) => muiTheme.spacing(1)};
  }
  .ql-toolbar .ql-picker-item:hover {
    background-color: ${({ muiTheme }) => muiTheme.palette.filler.main};
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
