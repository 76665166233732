import { useCallback, useState } from 'react';

import i18next from 'i18next';

import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { CompareReplaceDialog } from 'src/features/CompareReplaceDialog/CompareReplaceDialog';
import { useAccordion } from 'src/features/JobForm/components/Accordion/useAccordion';
import { BlocksFieldsSection } from 'src/features/JobForm/components/BlocksFieldSection';
import { CopyCompareButton } from 'src/features/JobForm/components/CopyCompareButton';
import { FieldsSection } from 'src/features/JobForm/components/FieldsSection/FieldsSection';
import { TableFieldsRowSection } from 'src/features/JobForm/components/TableFieldsRowSection';
import { useJobForm } from 'src/features/JobForm/useJobForm';
import { Section } from 'src/pages/Job/JobContext';

type AccordionProps = {
  section: Section;
};

export function Accordion({ section }: AccordionProps) {
  const { language } = i18next;
  const { blocks, code, fields: fieldLayoutFields, sections, table, title } = section;
  const [compareDialogOpenSection, setCompareDialogOpenSection] = useState('');
  const {
    areDefaultValuesSet,
    fields,
    handleChangeLogicBuilderField,
    handleChangeSteeredField,
    handleToggleExpand,
    isJobFetching,
    isSectionExpanded,
  } = useAccordion({ code });

  const { fieldLayout } = useJobForm();

  function handleOnClose() {
    setCompareDialogOpenSection('');
  }

  function handleOnConfirm() {
    setCompareDialogOpenSection('');
  }

  const handleCompareDialogOpen = useCallback((section: string) => {
    setCompareDialogOpenSection(section);
  }, []);

  return (
    <MUIAccordion expanded={isSectionExpanded}>
      <AccordionSummary onClick={handleToggleExpand}>{title}</AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={1.5}>
          {fieldLayoutFields?.map(({ alias, columns, leftSpan, readonly, rightSpan }) => {
            const jobField = fields?.[alias];

            return (
              <FieldsSection
                alias={alias}
                columns={columns}
                isJobFetching={isJobFetching}
                isReadOnly={readonly}
                jobField={jobField}
                key={alias}
                language={language}
                leftSpan={leftSpan}
                onChangeLogicBuilderField={handleChangeLogicBuilderField}
                onChangeSteeredField={handleChangeSteeredField}
                rightSpan={rightSpan}
              />
            );
          })}

          {areDefaultValuesSet && blocks && !isJobFetching
            ? blocks.map((block, index) => (
                <BlocksFieldsSection block={block} key={`block_field_section${index}`} />
              ))
            : null}

          {table && fields && !isJobFetching ? (
            <Grid container>
              <TableFieldsRowSection
                fields={fields}
                isFixedFields
                language={language}
                table={table}
              />

              <TableFieldsRowSection fields={fields} language={language} table={table} />
            </Grid>
          ) : null}

          {sections?.map((section) => (
            <Accordion key={section.code} section={section} />
          ))}
        </Grid>

        <Box display="flex" height="30px" justifyContent="end" m={1.5}>
          <CopyCompareButton onOpenCompareDialog={handleCompareDialogOpen} section={section} />
        </Box>

        <CompareReplaceDialog
          compareDialogOpenSection={compareDialogOpenSection}
          fieldLayout={fieldLayout}
          onClose={handleOnClose}
          onConfirm={handleOnConfirm}
        />
      </AccordionDetails>
    </MUIAccordion>
  );
}
