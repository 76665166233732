import { useMemo } from 'react';

import { convertPreferenceValueToNumber } from 'src/utilities/helperFunctions2';
import { usePreference } from 'src/utilities/hooks/usePreference';

type UsePreferenceAsNumberProps = { code: string; defaultValue: string };

export function usePreferenceAsNumber({ code, defaultValue }: UsePreferenceAsNumberProps) {
  const preference = usePreference(code, defaultValue);

  const preferenceValue = useMemo(
    () => convertPreferenceValueToNumber(preference.value, defaultValue),
    [preference.value],
  );

  return {
    isLoading: preference.isLoading,
    set: preference.set,
    value: preferenceValue,
  };
}
