import { TextField } from '@mui/material';
import { Trans } from 'react-i18next';

export function StandardField({
  checkRequiredFields,
  disabled,
  id,
  isError,
  label,
  onBlur,
  onChange,
  required,
  value,
}) {
  function handleOnBlur(e) {
    e.target.name = label;

    if (onBlur) onBlur(e);
  }

  return (
    <TextField
      autoComplete="off"
      disabled={disabled || !label ? true : false}
      error={!label || (checkRequiredFields && required && !value) || isError}
      fullWidth
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      label={<Trans>{label}</Trans>}
      onBlur={handleOnBlur}
      onChange={onChange}
      placeholder={label ? '' : `alias: ${id}`}
      required={required}
      size="small"
      type="search"
      value={value || ''}
      variant={label ? 'outlined' : 'filled'}
    />
  );
}
