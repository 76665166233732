import React, { forwardRef } from 'react';

import { Icon, IconProps, SvgIcon } from '@mui/material';

import { ICONS } from 'src/features/WaveIcon/utils';

type CustomIcons = {
  [key: string]: React.ReactElement;
};

const customIcons: CustomIcons = {
  'main-menu-additional-tabs-roadrunner': (
    <SvgIcon viewBox="0 0 567.9 567.9">
      <path d="m555 215-6 1c-2 1-5 0-6-2l-3-1-9 1-12 3-34 8c-61 13-123 20-184 29-17 2-33-1-49-7-29-13-60-21-92-23-20-2-31-16-40-32v-4l3-6a42 42 0 0 1 2-3l2-1h1l2-1 1-4 2-1 1-2v-3a3792 3792 0 0 1 6-5c2 0 2-3 1-3l-2-1 2-1h4c2-1 1-3 0-4a552 552 0 0 0-27-8h-5l-8 2a37 37 0 0 0-17 7l-7 5c-7 5-13 11-18 19-8 12-18 18-32 20-11 2-22 5-30 14-2 2-1 3 1 3l15-2a1242 1242 0 0 1 37 0l30 22c8 5 11 12 13 21l1 7c4 31 22 49 50 61 10 4 18 10 28 16 2 1 2 3 1 5l-15 15-39 33c-8 7-17 9-28 7l-10-1h-8l-5 1v2h3l5 2c1 1 0 2-2 3l-4 2-8 1-6 4-1 4h1c2-2 5-2 8-3l8-1 24-4c13-3 24-2 36 3 5 2 11 3 17 3l8 3 8 2 1-1a139 139 0 0 1-3-4l-2-1-2-2-2-1-4-3c-6-7-15-8-24-8-2 1-4 0-5-1l3-3 34-25 33-23 3-3c9-5 40 2 47 10l16 20 18 17 14 18c4 7 4 7 14 7l5 1 7 3c3 1 5 1 8-1v-1l-1-1a1185 1185 0 0 0-11-4l-28-27c-1-1-2-4 0-6l8-12 5-7 6-5 7 1 1-1v-1l-1-1-1-1-4-1h-7c-13 0-17 11-22 21 0 2-3 3-5 2-8-4-14-12-30-37-2-2-1-4 1-6 9-7 18-15 28-20 10-6 22-8 33-12 3 0 3-2 1-4l-1-1v-6c13-14 32-13 49-15 19-1 36-6 54-12l23-6 62-11 8-1 36-6c3-1 9-3 9-7l-1-3-2-2h2l2-1 11-4c2-1 5-2 6-4 1-7-10-4-13-4z" />
    </SvgIcon>
  ),
};

type WaveIconProps = Omit<IconProps, 'ref'> & {
  code: string;
};

function computeIcon(code: string) {
  let adjustedCode = code;

  if (code.includes('main-menu')) {
    const codes = ['main-menu-job-pro', 'main-menu-job-ser'];

    if (code.includes('main-menu-job-') && !codes.includes(code)) {
      adjustedCode = 'main-menu-job';
    }

    if (code.includes('main-menu-arc-')) {
      adjustedCode = 'main-menu-archive';
    }
  } else if (code.includes('job-tasks') && code.includes('job-tasks-task-')) {
    adjustedCode = 'job-tasks-task';
  }

  if (customIcons[adjustedCode]) {
    return customIcons[adjustedCode];
  }

  const iconMatch = Object.entries(ICONS).find(([, value]) => value.includes(adjustedCode));

  return iconMatch ? iconMatch[0] : null;
}

// eslint-disable-next-line react/display-name
export const WaveIcon = forwardRef<HTMLSpanElement, WaveIconProps>(({ code, ...props }, ref) => {
  return (
    <Icon {...props} ref={ref}>
      {computeIcon(code)}
    </Icon>
  );
});
