import { useEffect, useState } from 'react';

import { Box, Divider, IconButton, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Bookmarks } from 'src/features/Frame/components/Bookmarks';
// import { Emails } from 'src/features/Frame/components/Emails';
// import { Notifications } from 'src/features/Frame/components/Notifications';
import { RecentJobs } from 'src/features/Frame/components/RecentJobs';
import { UserMenu } from 'src/features/Frame/components/UserMenu';
import { ShoppingCart } from 'src/features/ShoppingCart';
import { WaveIcon } from 'src/features/WaveIcon';
import { useAppSelector, usePreference, withFullScreen } from 'src/utilities/hooks';

type NavBarProps = { isOpen?: boolean; onToggleDrawer?: () => void };

export function NavBar({ isOpen, onToggleDrawer }: NavBarProps) {
  const clientIdPreference = usePreference('sys.mid', '');
  const { installationLogo } = useTheme();
  const [logo, setLogo] = useState('');
  const canReadShoppingCart = useAppSelector((state) =>
    state.user.rights.read.includes('job-cart'),
  );
  const { VITE_INSTALLATION } = import.meta.env;
  const installationModules = import.meta.glob('/src/installations/*/client_*/logo.png');

  useEffect(() => {
    (async () => {
      try {
        const logoPath = `src/installations/${VITE_INSTALLATION}/client_${clientIdPreference.value}/logo.png`;
        const response: any = await installationModules[logoPath]();

        setLogo(response.default);
      } catch (error) {
        setLogo(installationLogo);
      }
    })();
  }, [clientIdPreference.value]);

  return (
    <Toolbar disableGutters sx={{ bgcolor: 'primary.main', px: 1 }}>
      <Box alignItems="center" display="flex" gap={1}>
        {logo && (
          <Box height={56} width={180}>
            <Box
              alt="Navbar Logo"
              component="img"
              height="100%"
              src={logo}
              sx={{ objectFit: 'contain' }}
              width="100%"
            />
          </Box>
        )}

        {onToggleDrawer ? (
          <IconButton color="secondary" onClick={onToggleDrawer}>
            <WaveIcon
              code="nav-bar-menu"
              sx={{
                transform: `${isOpen ? 'rotate(180deg)' : ''}`,
                transition: 'transform 150ms ease',
              }}
            />
          </IconButton>
        ) : null}
      </Box>

      {onToggleDrawer ? (
        <Box alignItems="center" display="flex" flexGrow={1} justifyContent="end">
          <RecentJobs />

          <Bookmarks />

          {canReadShoppingCart ? <ShoppingCart /> : null}

          {/* <Grid item>
          <Emails />
        </Grid>

        <Grid item>
          <Notifications />
        </Grid> */}

          <Box alignSelf="normal" marginLeft={1}>
            <Divider
              orientation="vertical"
              sx={{ bgcolor: 'filler.main', m: 0 }}
              variant="middle"
            />
          </Box>

          <UserMenu />
        </Box>
      ) : null}
    </Toolbar>
  );
}

export const VisibilityNavBar = withFullScreen(NavBar, { hideInFullScreen: true });
