import { Ref } from 'react';

import i18next from 'i18next';

import { Box, Unstable_Grid2 as Grid } from '@mui/material';

import { BlockFieldAddRemoveButton } from 'src/features/JobForm/components/BlockFieldAddRemoveButton';
import { BlockFieldButtons } from 'src/features/JobForm/components/BlockFieldButtons';
import { BlockMoveType } from 'src/features/JobForm/components/BlocksFieldSection';
import {
  StyledBlockContainer,
  StyledBlocksFieldSection,
} from 'src/features/JobForm/components/BlocksFieldSection/styled';
import { FieldsSection } from 'src/features/JobForm/components/FieldsSection';
import { TableFieldsRowSection } from 'src/features/JobForm/components/TableFieldsRowSection';
import { FieldsTransformed } from 'src/pages/Job/Job.service';
import { Block } from 'src/pages/Job/JobContext';

type BlockFieldRowProps = {
  block: Block;
  fields?: FieldsTransformed;
  isJobFetching: boolean;
  handleChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  handleChangeSteeredField?: (fieldName: string) => void;
  isNewBlockRow?: boolean;
  isFirstBlockRow?: boolean;
  isLastBlockRow?: boolean;
  fieldValueIndex?: number;
  onRemoveBlockRow?: (fieldValueIndex: number) => void;
  onMoveBlockRow?: (direction: BlockMoveType, fieldValueIndex: number) => void;
  onAddBlockRow?: () => void;
  isTable: boolean;
  scrollRef?: Ref<HTMLDivElement>;
  isDisabled: boolean;
};

export function BlocksFieldRow({
  block,
  fieldValueIndex,
  fields,
  handleChangeLogicBuilderField,
  handleChangeSteeredField,
  isDisabled,
  isFirstBlockRow = true,
  isJobFetching,
  isLastBlockRow = false,
  isNewBlockRow,
  isTable,
  onAddBlockRow,
  onMoveBlockRow,
  onRemoveBlockRow,
  scrollRef,
}: BlockFieldRowProps) {
  const { language } = i18next;

  function handleRemoveBlock() {
    if (onRemoveBlockRow && fieldValueIndex !== undefined) {
      onRemoveBlockRow(fieldValueIndex);
    }
  }

  function handleMoveBlock(direction: BlockMoveType) {
    if (onMoveBlockRow && fieldValueIndex !== undefined) {
      onMoveBlockRow(direction, fieldValueIndex);
    }
  }

  function handleAddBlockRow() {
    if (onAddBlockRow) {
      onAddBlockRow();
    }
  }

  return (
    <Box
      flexDirection="column"
      marginBottom={isTable ? undefined : 1}
      marginLeft={2}
      marginRight={2}
      marginTop={isTable ? undefined : 1}
      width="100%"
    >
      <StyledBlocksFieldSection>
        <BlockFieldAddRemoveButton
          isDisabled={isDisabled}
          isNewBlockRow={isNewBlockRow}
          onAddBlockRow={handleAddBlockRow}
          onRemoveBlockRow={handleRemoveBlock}
        />

        <StyledBlockContainer isTable={isTable}>
          <Grid
            display="flex"
            gap={1}
            marginBottom={isTable ? undefined : 1}
            marginLeft={isTable ? undefined : 1}
            marginRight={isTable ? undefined : 1}
            marginTop={isTable ? undefined : 1}
            xs={12}
          >
            {block.table ? (
              <>
                <TableFieldsRowSection
                  fields={fields}
                  fieldValueIndex={fieldValueIndex}
                  isFixedFields
                  isNewBlockRow={isNewBlockRow}
                  language={language}
                  table={block.table}
                />

                <TableFieldsRowSection
                  fields={fields}
                  fieldValueIndex={fieldValueIndex}
                  isNewBlockRow={isNewBlockRow}
                  language={language}
                  ref={scrollRef}
                  table={block.table}
                />
              </>
            ) : (
              <>
                {block.fields.map(({ alias, columns, leftSpan, readonly, rightSpan }) => {
                  const jobField = fields?.[alias];

                  return (
                    <FieldsSection
                      alias={alias}
                      columns={columns}
                      fieldValueIndex={fieldValueIndex}
                      isFirstBlockRow={isFirstBlockRow}
                      isJobFetching={isJobFetching}
                      isReadOnly={readonly}
                      jobField={jobField}
                      key={alias}
                      language={language}
                      leftSpan={leftSpan}
                      onChangeLogicBuilderField={handleChangeLogicBuilderField}
                      onChangeSteeredField={handleChangeSteeredField}
                      rightSpan={rightSpan}
                    />
                  );
                })}
              </>
            )}
          </Grid>
        </StyledBlockContainer>

        <BlockFieldButtons
          isDisabled={isDisabled}
          isFirstBlockRow={isFirstBlockRow}
          isLastBlockRow={isLastBlockRow}
          isNewBlockRow={isNewBlockRow || false}
          onMoveBlockRow={handleMoveBlock}
        />
      </StyledBlocksFieldSection>
    </Box>
  );
}
