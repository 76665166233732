import { DialogActions, FormControl, FormLabel } from '@mui/material';

import { Button } from 'src/components/Button';
import { RadioButton, RadioGroup } from 'src/components/RHF/RadioButton';
import { TextField } from 'src/components/RHF/TextField';
import { useViewSearchNewItemForm } from 'src/features/JobsTable/components/ViewSearchNewItemForm/useViewSearchNewItemForm';

export type ViewSearchNewItemFormProps = {
  isSavedViews: boolean;
  newLabel: string;
  onCloseNewItemForm: () => void;
};

export function ViewSearchNewItemForm({
  isSavedViews,
  newLabel,
  onCloseNewItemForm,
}: ViewSearchNewItemFormProps) {
  const { control, isDirty, onSubmit, translate } = useViewSearchNewItemForm({
    isSavedViews,
    onCloseNewItemForm,
  });

  return (
    <form onSubmit={onSubmit}>
      <DialogActions>
        <TextField
          autoFocus
          control={control}
          label={newLabel}
          name="name"
          rules={{ required: { message: `${translate('lib.required', 'Required')}`, value: true } }}
        />

        {isSavedViews ? (
          <FormControl component="fieldset" sx={{ mx: 2 }}>
            <FormLabel component="legend">{translate('view_type_label', 'View Type')}</FormLabel>

            <RadioGroup
              control={control}
              MuiRadioGroupProps={{ sx: { whiteSpace: 'nowrap' } }}
              name="viewType"
            >
              <RadioButton
                label={translate('global_view_label', 'Global View')}
                value="globalView"
              />

              <RadioButton label={translate('user_view_label', 'User View')} value="userView" />
            </RadioGroup>
          </FormControl>
        ) : null}
      </DialogActions>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="warning" onClick={onCloseNewItemForm}>
          lib.cancel
        </Button>

        <Button disabled={!isDirty} type="submit">
          clip.save
        </Button>
      </DialogActions>
    </form>
  );
}
