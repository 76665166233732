import { Enum, EnumValue } from 'ts-enums';

import { FileDataType } from 'src/pages/FileSearch/FileSearch.service';

export class FileColumnMappings extends EnumValue {
  constructor(
    name: string,
    private _label: string,
    private _translationCode: string,
    private _databaseColumnName: keyof FileDataType,
    private _sortName: string,
  ) {
    super(name);
  }

  get label(): string {
    return this._label;
  }

  get translationCode(): string {
    return this._translationCode;
  }

  get databaseColumnName(): keyof FileDataType {
    return this._databaseColumnName;
  }

  get sortName(): string {
    return this._sortName;
  }
}

export class FileColumnMappingsEnumType extends Enum<FileColumnMappings> {
  LAST_UPDATED = new FileColumnMappings(
    'LAST_UPDATED',
    'Last Updated',
    'file-ser.last_updated',
    'DatelastChange',
    'DatelastChange',
  );
  THUMBNAIL = new FileColumnMappings(
    'THUMBNAIL',
    'Thumbnail',
    'lib.thumbnail.created',
    'thumb',
    'thumb.keyword',
  );
  FILE_NAME = new FileColumnMappings(
    'FILE_NAME',
    'File name',
    'shopping_cart_filename_header',
    'filename',
    'filename.keyword',
  );
  JOB_ID = new FileColumnMappings(
    'JOB_ID',
    'Job ID',
    'shopping_cart_jobid_header',
    'jobid',
    'jobid',
  );
  ID = new FileColumnMappings('ID', 'ID', 'file-ser.id', 'id', 'id');
  FILE_SIZE = new FileColumnMappings(
    'FILE_SIZE',
    'File size',
    'file-ser.file_size',
    'size',
    'size',
  );
  VERSION = new FileColumnMappings('VERSION', 'Version', 'lib.version', 'version', 'version');
  SOURCE = new FileColumnMappings('SOURCE', 'Source', 'lib.src', 'src', 'src');
  SUB = new FileColumnMappings('SUB', 'Sub', 'sub', 'sub', 'sub');

  constructor() {
    super();
    this.initEnum('FileColumnMappings');
  }

  find(state: string): FileColumnMappings | null {
    const found = this.values.find((value) => value.propName === state);

    if (found) return found;

    return null;
  }
}

export const FileColumnMappingsEnum = new FileColumnMappingsEnumType();
