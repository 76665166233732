import { ChangeEvent } from 'react';

import { Box, Divider, Paper, Skeleton } from '@mui/material';

import { UploadFiles } from 'src/features/UploadFiles';
import { useAppSelector, useSelect } from 'src/utilities/hooks';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';
import { useGetActionBarQuery, useGetFilesQuery } from '../../FileExplorer.service';
import { DeleteButton } from '../DeleteButton';
import { DownloadButton } from '../DownloadButton';
import { FilesTable } from '../FilesTable';

type FilesProps = { fetchFolders: () => void; selectedFolder: string };
export type SelectedFiles = Record<string | number, boolean>;

export function Files({ fetchFolders, selectedFolder }: FilesProps) {
  const insertRights = useAppSelector((state) => state.user.rights.insert);

  const { data: actionBar } = useGetActionBarQuery({}, { refetchOnMountOrArgChange: true });
  const {
    data: files = [],
    isFetching: areFilesFetching,
    refetch: fetchFiles,
  } = useGetFilesQuery(
    { path: selectedFolder },
    { refetchOnMountOrArgChange: true, skip: !selectedFolder },
  );
  const {
    selectedItemIds: selectedFileIds,
    selectedItems: selectedFiles,
    toggleSelectAll,
    toggleSelectItem: toggleSelectFile,
  } = useSelect(parseArrayToLogicalObject(files, 'id'));

  const disabledButtonText = 'Select a file first.';

  function getFilesAndFolders() {
    fetchFiles();
    fetchFolders();
  }

  function handleClickRowCheckbox(e: ChangeEvent<HTMLInputElement>, fileId: number) {
    e.stopPropagation();
    toggleSelectFile(fileId);
  }

  return (
    <Box borderRadius="0 4px 4px 0" component={Paper} elevation={3} flexGrow={1}>
      {selectedFolder ? (
        <Box id="actionBar">
          <Box id="buttonContainer" m={1}>
            <Box
              display="flex"
              justifyContent={insertRights.includes(selectedFolder) ? 'space-between' : 'end'}
            >
              {actionBar?.single.map(({ code, name }) =>
                insertRights.includes(selectedFolder) ? (
                  <UploadFiles
                    destination={selectedFolder}
                    isMultipleFiles
                    key={code}
                    label={name}
                    variant="fileExplorer"
                  />
                ) : null,
              )}

              <Box display="flex" gap={2}>
                {!areFilesFetching ? (
                  actionBar?.bulk.map(({ code, name }) =>
                    code === 'files-delete' &&
                    files.some(({ can_delete: canDelete }) => canDelete) ? (
                      <DeleteButton
                        code={code}
                        disabledText={disabledButtonText}
                        files={files}
                        key={code}
                        name={name}
                        onDeleteCallback={getFilesAndFolders}
                        onToggleSelectAll={toggleSelectAll}
                        selectedFileIds={selectedFileIds as number[]}
                        variant="named"
                      />
                    ) : code === 'files-download' && files.length ? (
                      <DownloadButton
                        code={code}
                        disabledText={disabledButtonText}
                        key={code}
                        name={name}
                        onToggleSelectAll={toggleSelectAll}
                        selectedFileIds={selectedFileIds as number[]}
                        variant="named"
                      />
                    ) : null,
                  )
                ) : (
                  <>
                    <Skeleton height={30} variant="rounded" width={100}></Skeleton>

                    <Skeleton height={30} variant="rounded" width={100}></Skeleton>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Divider id="divider" orientation="horizontal" />
        </Box>
      ) : null}

      <FilesTable
        areFilesFetching={areFilesFetching}
        files={files}
        onDeleteCallback={getFilesAndFolders}
        onHandleClickRowCheckbox={handleClickRowCheckbox}
        onToggleSelectAll={toggleSelectAll}
        onToggleSelectFile={toggleSelectFile}
        selectedFileIds={selectedFileIds as number[]}
        selectedFiles={selectedFiles}
        selectedFolder={selectedFolder}
      />
    </Box>
  );
}
