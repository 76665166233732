import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useColorDialog } from 'src/features/JobForm/components/ColorDialog/useColorDialog';
import { useGetBooksQuery } from 'src/features/JobForm/JobForm.service';
import { LoadingHeaderAndBody } from 'src/features/JobsTable/components/LoadingHeaderAndBody';
import { PaginationActions } from 'src/features/JobsTable/components/PaginationActions';
import { WaveIcon } from 'src/features/WaveIcon';
import { usePreference } from 'src/utilities/hooks';
import { usePagePreference } from 'src/utilities/hooks/usePagePreference';
import { usePreferenceAsNumber } from 'src/utilities/hooks/usePreferenceAsNumber';

type ColorDialogProps = {
  fieldValueIndex?: number;
  isColorDialogOpen: boolean;
  onHandleChange: (value: string) => void;
  onSetIsColorDialogOpen: (arg: boolean) => void;
};

type SelectedRowType = {
  book: string;
  color: string;
  index: number;
};

const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export function ColorDialog({
  fieldValueIndex,
  isColorDialogOpen,
  onHandleChange,
  onSetIsColorDialogOpen,
}: ColorDialogProps) {
  const {
    columns,
    debouncedValue,
    isFetchingGetColor,
    rows,
    searchColor,
    setSearchColor,
    totalRowCount,
  } = useColorDialog();
  const { t } = useTranslation();

  const { data: books } = useGetBooksQuery();
  const bookPreference = usePreference('colorbook.book', '');
  const pagePreference = usePagePreference({
    preferencePrefix: 'colorbook',
    totalRowCount: totalRowCount ?? 0,
  });
  const rowsPerPagePreference = usePreferenceAsNumber({
    code: 'colorbook.rowsPerPage',
    defaultValue: '25',
  });

  const [selectedRow, setSelectedRow] = useState<SelectedRowType | null>(null);
  const searchPreference = usePreference('colorbook.search', '');
  const [shouldTriggerRequest, setShouldTriggerRequest] = useState(true);
  const { getValues, setValue } = useFormContext();

  function handleChangeBook(event: ChangeEvent<HTMLInputElement>, value: string | null) {
    bookPreference.set(value ?? '');
    pagePreference.set('0');
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
    pagePreference.set(page.toString());
  }

  function handleChangeRowPerPage(e: ChangeEvent<HTMLInputElement>) {
    const rowsPerPage = e.target.value;

    rowsPerPagePreference.set(rowsPerPage.toString());
    pagePreference.set('0');
  }

  function handleClickRadio(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: SelectedRowType,
  ) {
    e.stopPropagation();
    e.preventDefault();
    setSelectedRow(value);
  }

  function handleCloseDialog() {
    onSetIsColorDialogOpen(false);
  }

  function handleConfirm() {
    if (selectedRow && fieldValueIndex !== undefined) {
      const currentValues = getValues('zlp_ag_col_book_01') || [];
      const updatedValues = Array.isArray(currentValues) ? [...currentValues] : [];

      updatedValues[fieldValueIndex] = selectedRow.book;
      onHandleChange(selectedRow.color);
      setValue('zlp_ag_col_book_01', updatedValues);
    }

    handleCloseDialog();
  }

  function handleSearchColor(event: ChangeEvent<HTMLInputElement>) {
    setShouldTriggerRequest(false);
    setSearchColor(event.target.value);
  }

  function labelDisplayedRows() {
    return null;
  }

  useEffect(() => {
    if (shouldTriggerRequest) {
      pagePreference.set('0');
      searchPreference.set(searchColor);
    } else setShouldTriggerRequest(true);
  }, [debouncedValue, searchColor]);

  return (
    <Dialog fullWidth maxWidth={'md'} onClose={handleCloseDialog} open={isColorDialogOpen}>
      <WaveDialogTitle
        onClose={handleCloseDialog}
        title={t('add_color_template', 'Add Color Template')}
      />

      <DialogContent>
        <Grid2 container marginBottom={1} spacing={1}>
          <Grid2 xs>
            <Autocomplete
              disablePortal
              onChange={(event, value) =>
                handleChangeBook(event as ChangeEvent<HTMLInputElement>, value)
              }
              options={books || []}
              renderInput={(params) => <TextField {...params} label="Books" />}
              size="small"
              value={bookPreference.value as string}
            />
          </Grid2>

          <Grid2 xs>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WaveIcon code="search" />
                  </InputAdornment>
                ),
              }}
              label={t('search_colors', 'Search Colors')}
              onChange={handleSearchColor}
              size="small"
              value={searchColor}
            />
          </Grid2>
        </Grid2>

        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            {isFetchingGetColor ? (
              <LoadingHeaderAndBody totalColumns={3} totalRows={rowsPerPagePreference.value} />
            ) : (
              <>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <TableRow>
                    {columns?.map((column) => (
                      <StyledTableCell key={column.field}>{column.title}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows?.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Radio
                          checked={selectedRow?.index === index}
                          onClick={(e) =>
                            handleClickRadio(e, { book: data.book, color: data.name, index })
                          }
                          size="small"
                          sx={{ marginRight: 1, padding: 0 }}
                          value={data.name}
                        />

                        {data.name}
                      </TableCell>

                      <TableCell>{data.book}</TableCell>

                      <TableCell>{data.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          ActionsComponent={PaginationActions}
          component="div"
          count={totalRowCount || 0}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={t('file-ser.rows_per_page', 'Rows per page')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowPerPage}
          page={pagePreference.value}
          rowsPerPage={rowsPerPagePreference.value}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          sx={{ background: grey[100], marginTop: 1 }}
        />
      </DialogContent>

      <Box component={DialogActions} justifyContent="space-between">
        <Button color="warning" onClick={handleCloseDialog}>
          {t('close_button', 'Close')}
        </Button>

        <Button color="primary" onClick={handleConfirm}>
          {t('confirm_button', 'Confirm')}
        </Button>
      </Box>
    </Dialog>
  );
}
