import { SyntheticEvent } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useGetTabsQuery } from 'src/pages/Job/Job.service';
import { useIsExternalRevisor, useRouteParams } from 'src/utilities/hooks';

export function useJob() {
  const { age, jobId, jobType, tab } = useRouteParams();
  const isNewJob = jobId === 'new';

  const location = useLocation();
  const isExternalRevisorView = useIsExternalRevisor();

  const { data: tabs } = useGetTabsQuery(
    {
      age,
      jobType,
      ...(!isNewJob && { jobId }),
    },
    { skip: isExternalRevisorView },
  );

  const navigate = useNavigate();

  function handleChangeTab(event: SyntheticEvent, tab: string) {
    navigate({
      pathname: `/jobs-${age}-${jobType}/${jobId}/${tab}`,
      search: location.search,
    });
  }

  return {
    isExternalRevisorView,
    isNewJob,
    onChangeTab: handleChangeTab,
    tab,
    tabs,
  };
}
