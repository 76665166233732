import { createApi } from '@reduxjs/toolkit/query/react';

import { BaseRequest, JobId } from 'src/pages/Job/Job.service';
import { UserId } from 'src/pages/UserProfile/UserProfile.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

type BaseParams = Omit<BaseRequest, 'age'> & { age?: BasicAge };
type GetDeadlinesRequest = BaseParams & JobId & UserId;
type Deadlines = {
  current_web_status: number;
  status: Record<number, Dates | undefined>;
};
type Dates = {
  actual_date: DeadlineDateString;
  deadline_date: DeadlineDateString;
};
export type DeadlineDateString = string;
type DeadlinesTransformed = {
  jobStatusValue: number;
  dates: Record<number, DatesTransformed | undefined>;
};
type DatesTransformed = { actualDate: DeadlineDateString; deadlineDate: DeadlineDateString };
type Workflow = Record<number, Status | undefined>;
type Status = {
  desc_ar: string;
  desc_cn: string;
  desc_de: string;
  desc_en: string;
  desc_es: string;
  desc_fr: string;
  desc_jp: string;
  desc_po: string;
  desc_sgk: string;
  desc_TR: string;
  desc_uk: string;
  display: number;
  flags: { delete: boolean };
  id: number;
  name_ar: string;
  name_cn: string;
  name_de: string;
  name_en: string;
  name_es: string;
  name_fr: string;
  name_jp: string;
  name_po: string;
  name_sgk: string;
  name_TR: string;
  name_uk: string;
  status: number;
};
type WorkflowTransformed = Record<number, StatusTransformed | undefined>;
export type StatusTransformed = Omit<Status, 'display' | 'flags' | 'status'> & {
  displayValue: number;
  flags: { canDelete: boolean };
  value: number;
};

export const workflowApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getDeadline: build.query<DeadlinesTransformed, GetDeadlinesRequest>({
      providesTags: ['Deadline'],
      query({ age, jobId, jobType, userId }) {
        return {
          method: 'GET',
          params: { age, id: userId, jobid: jobId, src: jobType },
          url: '/jobs/deadline',
        };
      },
      transformResponse({
        current_web_status: jobStatusValue,
        status: dates,
      }: Deadlines): DeadlinesTransformed {
        const datesTransformed = Object.entries(dates).reduce(
          (datesTransformed, [statusValue, dates]) => {
            if (!dates) return datesTransformed;

            const { actual_date: actualDate, deadline_date: deadlineDate } = dates;

            return {
              ...datesTransformed,
              [statusValue]: { actualDate, deadlineDate },
            };
          },
          {},
        );

        return { dates: datesTransformed, jobStatusValue };
      },
    }),
    getWorkflow: build.query<WorkflowTransformed, BaseParams>({
      providesTags: ['Workflow'],
      query({ age, jobType }) {
        return {
          method: 'GET',
          params: { age, src: jobType },
          url: '/criticalpath/get',
        };
      },
      transformResponse(workflow: Workflow): WorkflowTransformed {
        return Object.values(workflow).reduce((transformedWorkflow, status) => {
          if (!status) return transformedWorkflow;

          const {
            display: displayValue,
            flags: { delete: canDelete, ...restOfFlags },
            status: value,
            ...restOfStatus
          } = status;

          return {
            ...transformedWorkflow,
            [value]: { displayValue, flags: { canDelete, ...restOfFlags }, value, ...restOfStatus },
          };
        }, {});
      },
    }),
  }),
  reducerPath: 'workflowApi',
  tagTypes: ['Deadline', 'Workflow'],
});

export const { useGetDeadlineQuery, useGetWorkflowQuery } = workflowApi;
