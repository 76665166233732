import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { computeAssociatedCheckboxAlias } from 'src/features/BulkEditJobFields/utilities';
import { FieldTransformed } from 'src/pages/Job/Job.service';

type UseJobFieldProps = { activeStep: number } & Pick<FieldTransformed, 'alias'>;

export function useJobField({ activeStep, alias }: UseJobFieldProps) {
  const { control, resetField, setValue } = useFormContext();
  const {
    fieldState: { isDirty },
  } = useController({ control, name: alias });

  const associatedCheckboxAlias = computeAssociatedCheckboxAlias(alias);
  const {
    fieldState: { isDirty: isAssociatedCheckboxDirty },
  } = useController({ control, name: associatedCheckboxAlias });

  const shouldRender = activeStep !== 1 || (activeStep === 1 && isAssociatedCheckboxDirty);

  function handleClickAssociatedCheckbox(isChecked: boolean) {
    if (!isChecked) resetField(alias);
  }

  useEffect(() => {
    if (isDirty) setValue(associatedCheckboxAlias, true, { shouldDirty: true });
    else resetField(associatedCheckboxAlias);
  }, [isDirty]);

  return { associatedCheckboxAlias, handleClickAssociatedCheckbox, shouldRender };
}
