import { useCallback, useRef, useState } from 'react';
import { Gallery } from 'react-photoswipe-gallery';
import PhotoSwipeLightboxStub from 'react-photoswipe-gallery/dist/lightbox-stub';

import { PhotoSwipeOptions } from 'photoswipe';

import { FileDataType } from 'src/pages/FileSearch/FileSearch.service';
import { PhotoSwipeSlideOffset } from 'src/pages/FileSearch/FileSearchGridView/PhotoSwipeSlideOffset';
import {
  FILE_SEARCH_INFO_SECTION_OFFSET,
  PHOTO_SWIPE_PADDING_Y,
} from 'src/pages/FileSearch/FileSearchGridView/utils';
import { FileSearchInfoSection } from 'src/pages/FileSearch/FileSearchInfoSection';

import 'photoswipe/dist/photoswipe.css';

const photoSwipeOptions: PhotoSwipeOptions = {
  padding: { bottom: PHOTO_SWIPE_PADDING_Y, left: 0, right: 0, top: PHOTO_SWIPE_PADDING_Y },
  preload: [4, 4],
};

type FileSearchGalleryProps = {
  children: React.ReactNode;
  fileItems: FileDataType[];
};

export function FileSearchGallery({ children, fileItems }: FileSearchGalleryProps) {
  const photoSwipeRef = useRef<PhotoSwipeLightboxStub | null>(null);
  const photoSwipeSlideOffsetPluginRef = useRef<PhotoSwipeSlideOffset | null>(null);
  const [shouldDisplaySideBar, setShouldDisplaySideBar] = useState(false);
  const [shouldStartAnimation, setShouldStartAnimation] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [isFileSearchInfoSectionExpanded, setFileSearchInfoSectionExpanded] = useState(false);

  const handleToggleFileSearchInfoSection = useCallback(() => {
    setFileSearchInfoSectionExpanded((prevExpanded) => {
      if (photoSwipeSlideOffsetPluginRef.current) {
        photoSwipeSlideOffsetPluginRef.current.setSlideOffsetValue(
          prevExpanded ? 0 : FILE_SEARCH_INFO_SECTION_OFFSET,
        );
      }

      return !prevExpanded;
    });
  }, []);

  if (!fileItems[currentFileIndex]) return null;

  return (
    <>
      <FileSearchInfoSection
        fileItem={fileItems[currentFileIndex]}
        isExpanded={isFileSearchInfoSectionExpanded}
        onClick={handleToggleFileSearchInfoSection}
        shouldDisplaySideBar={shouldDisplaySideBar}
        shouldStartAnimation={shouldStartAnimation}
      />

      <Gallery
        options={photoSwipeOptions}
        plugins={(photoSwipeLightBox) => {
          photoSwipeSlideOffsetPluginRef.current = new PhotoSwipeSlideOffset(photoSwipeLightBox, {
            slideOffsetValue: isFileSearchInfoSectionExpanded ? FILE_SEARCH_INFO_SECTION_OFFSET : 0,
          });

          photoSwipeRef.current = photoSwipeLightBox;
          photoSwipeLightBox.on('beforeOpen', () => {
            setShouldDisplaySideBar(true);
          });
          photoSwipeLightBox.on('openingAnimationStart', () => {
            const currentIndex = photoSwipeLightBox.pswp.currIndex;

            setCurrentFileIndex(currentIndex);
            setShouldStartAnimation(true);
          });

          photoSwipeLightBox.on('closingAnimationStart', () => {
            setShouldStartAnimation(false);
          });
          photoSwipeLightBox.on('closingAnimationEnd', () => {
            setCurrentFileIndex(0);
            setShouldDisplaySideBar(false);
          });
          photoSwipeLightBox.on('slideLoad', () => {
            const currentIndex = photoSwipeLightBox.pswp.currIndex;

            setCurrentFileIndex(currentIndex);
          });
        }}
      >
        {children}
      </Gallery>
    </>
  );
}
