import { StatusCellProps } from 'src/features/JobsTable/components/StatusCell/StatusCell';
import { isArchivedStatus } from 'src/features/JobsTable/utilities/helperFunctions';
import { useJobTableVariants } from 'src/features/JobsTable/utilities/hooks';
import { useGetWorkflowQuery } from 'src/features/Workflow/Workflow.service';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

type UseStatusCellProps = Pick<StatusCellProps, 'rowJobType' | 'value'>;

export function useStatusCell({ rowJobType, value }: UseStatusCellProps) {
  const { age, isSubJobsTable, jobType } = useJobTableVariants({
    ...(!isArchivedStatus(value) && { forcedAge: 'job' }),
  });

  const shouldWorkflowHaveArchiveStatus = isSubJobsTable || jobType === 'search';
  const isAgeBasic = age === 'arc' || age === 'job' || age === 'global';
  const ageForWorkflow = shouldWorkflowHaveArchiveStatus ? 'arc' : (age as BasicAge);

  const { data: workflow, isFetching: isWorkflowFetching } = useGetWorkflowQuery(
    {
      age: ageForWorkflow,
      jobType: rowJobType,
    },
    { skip: !isAgeBasic },
  );
  const workflowStatus = workflow?.[value];

  return { isWorkflowFetching, workflow, workflowStatus };
}
