import { MouseEvent, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { NewJobButtonProps } from 'src/features/JobsTable/components/NewJobButton/NewJobButton';
import { useRouteParams } from 'src/utilities/hooks';

type UseNewJobButtonProps = Pick<NewJobButtonProps, 'subActions'>;

export function useNewJobButton({ subActions }: UseNewJobButtonProps) {
  const { jobId, jobType } = useRouteParams();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  function handleClickNewJob(event: MouseEvent<HTMLButtonElement>) {
    subActions ? handleOpenSubMenu(event) : navigate(`/jobs-job-${jobType}/new/job`);
  }

  function handleOpenSubMenu({ currentTarget }: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(currentTarget);
    setIsSubMenuOpen(true);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  return {
    anchorEl,
    handleClickNewJob,
    handleCloseSubMenu,
    isSubMenuOpen,
    jobId,
    jobType,
    navigate,
  };
}
