import { useTranslation } from 'react-i18next';

import { Box, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { formatBytes, formatDate } from 'src/utilities/helperFunctions';

type ConfirmationStepProps = {
  filesToCompare: RawFiles;
};

export function ConfirmationStep({ filesToCompare }: ConfirmationStepProps) {
  const { t } = useTranslation();

  function renderFileInfo(key: string, value: number | string) {
    return (
      <>
        <Grid mb={2} xs={3.5}>
          <Typography variant="subtitle2">{t(key)}:</Typography>
        </Grid>

        <Grid xs={8.5}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </>
    );
  }

  return (
    <Grid p={2} xs>
      <Typography mb={2} variant="h6">
        Selected Files
      </Typography>

      <Grid alignItems="stretch" container spacing={2.5} xs>
        {!filesToCompare.length ? (
          <Box pt={3} textAlign="center" width="100%">
            <Typography color="text.secondary" variant="button">
              Please Select Files To Compare
            </Typography>
          </Box>
        ) : null}

        {filesToCompare.map((file) => (
          <Grid display="flex" key={file.id} xs={4}>
            <Paper elevation={2}>
              <Grid container overflow="hidden" p={2} spacing={0}>
                {renderFileInfo('clip.data.job', file.jobid)}

                {renderFileInfo('lib.file.name', file.filename)}

                {renderFileInfo('lib.file.category', file.category)}

                {renderFileInfo('lib.file.size', formatBytes(file.filesize))}

                {renderFileInfo('lib.date', formatDate(file.date))}

                {renderFileInfo('lib.file.owner', file.user)}
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
