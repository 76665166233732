import { StatusTransformed, useGetWorkflowQuery } from 'src/features/Workflow/Workflow.service';
import { OtherJobValues, useGetJobQuery } from 'src/pages/Job/Job.service';
import { useRouteParams } from 'src/utilities/hooks';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

export function useJobStatus() {
  const { age, jobId, jobType } = useRouteParams();

  const isAgeBasic = age === 'arc' || age === 'job';

  const { data: workflow } = useGetWorkflowQuery(
    {
      age: age as BasicAge,
      jobType,
    },
    { skip: !isAgeBasic },
  );

  const { data: job } = useGetJobQuery({
    age,
    jobId,
    jobType,
  });

  const jobValues = job?.values;

  const jobStatus: StatusTransformed | undefined =
    workflow && jobValues
      ? Object.values(workflow).find(
          (status) => status?.value === +(jobValues as OtherJobValues).webstatus,
        )
      : undefined;

  return jobStatus;
}
