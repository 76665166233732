import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Dropdown, Option } from 'src/components/RHF/Dropdown';
import { RadioButton, RadioGroup } from 'src/components/RHF/RadioButton';
import { TextField } from 'src/components/RHF/TextField';
import { CompareReplaceTemplate } from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';

type CompareReplaceTemplateConfigurerProps = {
  control: Control<any>;
  templates: Array<CompareReplaceTemplate>;
};

export function CompareReplaceTemplateConfigurer({
  control,
  templates,
}: CompareReplaceTemplateConfigurerProps) {
  const { t } = useTranslation();
  const templateOptions: Array<Option> = templates.map((template) => ({
    label: template.name,
    value: template.name,
  }));

  return (
    <Box width="100%">
      <Box marginX={4} marginY={2}>
        <RadioGroup control={control} name="templateConfigurationType">
          <Box marginBottom={6}>
            <Box marginBottom={5}>
              <RadioButton label={t('clip.clipboard', 'Clipboard')} value="clipboard" />
            </Box>

            <Box marginBottom={2}>
              <RadioButton label={t('clip.new.template', 'New Template')} value="new" />
            </Box>

            <TextField
              control={control}
              InputLabelProps={{
                shrink: true,
              }}
              label={t('template_name', 'Template Name')}
              name="templateNameNew"
            />
          </Box>

          <Box marginBottom={2}>
            <RadioButton label={t('update_template', 'Update Template')} value="update" />
          </Box>

          <Dropdown
            control={control}
            label={t('template_name', 'Template Name')}
            name="templateNameUpdate"
            options={templateOptions}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
}
