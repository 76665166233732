import { ButtonProps } from '@mui/material';

import { StyledButton } from 'src/components/IconBoxButton/styled';
import { IconType, WaveIcon } from 'src/features/WaveIcon';

type IconButtonProps = {
  code: IconType;
} & ButtonProps;

export function IconBoxButton({ code, ...rest }: IconButtonProps) {
  return (
    <StyledButton {...rest}>
      <WaveIcon code={code} />
    </StyledButton>
  );
}
