import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';

import { FieldMapper } from '../../features/FieldMapper';
import { openWaveSnack } from '../../store/waveSnackSlice';
import { API } from '../../utilities/api';

export function Backup({
  myBackupUserActive,
  onSetMyBackupUserActive,
  onSetUserIBackupActive,
  userIBackupActive,
  users,
}) {
  const dispatch = useDispatch();
  const [loadingMyBackupUser, setLoadingMyBackupUser] = useState(true);
  const [loadingMyBackupUserSwitch, setLoadingMyBackupUserSwitch] = useState(false);
  const [loadingUserIBackup, setLoadingUserIBackup] = useState(true);
  const [loadingUserIBackupSwitch, setLoadingUserIBackupSwitch] = useState(false);
  const [myBackupUser, setMyBackupUser] = useState(0);
  const [userIBackup, setUserIBackup] = useState(0);
  const userID = useSelector((state) => state.user.details.id);
  const { t } = useTranslation();

  function handleSelectionChange({ target: { id: ID, value } }) {
    if (ID === 'myBackupUser') setMyBackupUser(parseInt(value));
    else setUserIBackup(parseInt(value));
  }

  async function handleToggleMyBackupUser() {
    if (!userIBackupActive) setUserIBackup(0);

    setLoadingMyBackupUserSwitch(true);

    await API.put(
      `/users/${userID}/backup/${myBackupUser}`,
      {
        id: userID,
        status: myBackupUserActive ? 0 : 1,
        type: 'mybackup',
      },
      {
        onError: (error) => {
          setLoadingMyBackupUserSwitch(false);

          dispatch(
            openWaveSnack({
              message: error.message,
              type: 'error',
            }),
          );
        },
        onSuccess: (response) => {
          if (myBackupUserActive) setMyBackupUser(0);

          onSetMyBackupUserActive(!myBackupUserActive);
          setLoadingMyBackupUserSwitch(false);

          dispatch(
            openWaveSnack({
              message: response,
              type: 'success',
            }),
          );
        },
      },
    );
  }

  async function handleToggleUserIBackup() {
    setLoadingUserIBackupSwitch(true);
    setMyBackupUser(0);

    await API.put(
      `/users/${userIBackup}/backup/${userID}`,
      {
        status: userIBackupActive ? 0 : 1,
        type: 'ibackup',
      },
      {
        onError: (error) => {
          setLoadingUserIBackupSwitch(false);

          dispatch(
            openWaveSnack({
              message: error.message,
              type: 'error',
            }),
          );
        },
        onSuccess: (response) => {
          if (userIBackupActive) setUserIBackup(0);

          onSetUserIBackupActive(!userIBackupActive);
          setLoadingUserIBackupSwitch(false);

          dispatch(
            openWaveSnack({
              message: response,
              type: 'success',
            }),
          );
        },
      },
    );
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (Object.keys(users).length) {
      (async (cancelToken) => {
        await API.get('/users/backup', {
          cancelToken: cancelToken,

          onError: (error) => {
            dispatch(
              openWaveSnack({
                message: error.message,
                type: 'error',
              }),
            );
            setLoadingMyBackupUser(false);
          },
          onSuccess: (myBackup) => {
            setMyBackupUser(myBackup.active ? myBackup.user_id : 0);
            onSetMyBackupUserActive(myBackup.active || false);
            setLoadingMyBackupUser(false);
          },
          params: {
            userid: userID,
          },
        });

        await API.get('/users/backup', {
          cancelToken: cancelToken,
          onError: (error) => {
            dispatch(
              openWaveSnack({
                message: error.message,
                type: 'error',
              }),
            );
            setLoadingUserIBackup(false);
          },
          onSuccess: (userIBackup) => {
            setUserIBackup(userIBackup.active ? userIBackup.user_id : 0);
            onSetUserIBackupActive(userIBackup.active || false);
            setLoadingUserIBackup(false);
          },
          params: {
            backupuserid: userID,
          },
        });
      })(source.token);
    }

    return () => source.cancel();
  }, [users]);

  return (
    <Card className="d-flex flex-direction-column h-100p">
      <CardHeader
        classes={{ content: 'mx-10' }}
        className="p-5 primary-bg white-txt"
        title={<Typography component="p">{t('lib.backuprole', 'Backup')}</Typography>}
      />

      <CardContent className="d-flex flex-direction-column flex-grow-1 space-around">
        <Grid container>
          <Grid item marginRight="10px" position="relative" xs>
            <FieldMapper
              alias="myBackupUser"
              disabled={
                myBackupUserActive ||
                loadingMyBackupUser ||
                loadingMyBackupUserSwitch ||
                loadingUserIBackupSwitch
              }
              label={t('usr-backup.mybackupuser', 'My Backup User')}
              options={users}
              transferChangeField={handleSelectionChange}
              type="select"
              value={myBackupUser}
            />

            {loadingMyBackupUser && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>

          <Grid item position="relative" xs={4}>
            <FieldMapper
              alias="myBackupUserActive"
              disabled={!myBackupUser || loadingMyBackupUserSwitch}
              label={myBackupUserActive ? t('toggle_on', 'ON') : t('toggle_off', 'OFF')}
              transferChangeField={handleToggleMyBackupUser}
              type="switch"
              value={myBackupUserActive}
            />

            {loadingMyBackupUserSwitch && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item marginRight="10px" position="relative" xs>
            <FieldMapper
              alias="userIBackup"
              disabled={
                myBackupUserActive ||
                userIBackupActive ||
                loadingUserIBackup ||
                loadingMyBackupUserSwitch ||
                loadingUserIBackupSwitch
              }
              label={t('usr-backup.useribackup', 'User I Backup')}
              options={users}
              transferChangeField={handleSelectionChange}
              type="select"
              value={userIBackup}
            />

            {loadingUserIBackup && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>

          <Grid item position="relative" xs={4}>
            <FieldMapper
              alias="userIBackupActive"
              disabled={!userIBackup || myBackupUserActive || loadingUserIBackupSwitch}
              label={userIBackupActive ? t('toggle_on', 'ON') : t('toggle_off', 'OFF')}
              transferChangeField={handleToggleUserIBackup}
              type="switch"
              value={userIBackupActive}
            />

            {loadingUserIBackupSwitch && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
