import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type AddShoppingCartPayload = {
  fileIds: number[];
  type: string;
};

export type ShoppingCartItem = {
  file_id: number;
  filename: string;
  id: number;
  jobid: number;
  keyword: string;
};

type GetShoppingCartItemsResponse = {
  total: number;
  items: ShoppingCartItem[];
};

type DeleteShoppingCartPayload = {
  cartid: number[];
};

export const shoppingCartApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    addShoppingCartItem: build.mutation<void, AddShoppingCartPayload>({
      invalidatesTags: ['CartItems'],
      query({ fileIds, type }) {
        return {
          data: { fileid: fileIds, type },
          method: 'POST',
          url: '/shoppingcart/create',
        };
      },
    }),
    deleteShoppingCartItem: build.mutation<void, DeleteShoppingCartPayload>({
      invalidatesTags: ['CartItems'],
      query(params) {
        return {
          method: 'DELETE',
          params,
          url: '/shoppingcart/delete',
        };
      },
    }),
    getShoppingCartItems: build.query<GetShoppingCartItemsResponse, void>({
      providesTags: ['CartItems'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/shoppingcart/get',
        };
      },
    }),
  }),
  reducerPath: 'shoppingCartApi',
  tagTypes: ['CartItems'],
});

export const {
  useAddShoppingCartItemMutation,
  useDeleteShoppingCartItemMutation,
  useGetShoppingCartItemsQuery,
} = shoppingCartApi;
