import dayjs, { isDayjs } from 'dayjs';

import { isZeroFormatDate } from 'src/utilities/helperFunctions';
import { Translation } from '../language/utils';

let dateFormatsStorage: Translation = {};

export function useDateFormat() {
  function formatDate(type: string, dateValue: string | number) {
    const format = dateFormatsStorage[type] || 'MM/DD/YYYY';
    let dateString = dateValue;

    if (isZeroFormatDate(dateString as string)) {
      return format.replace(/[His]/g, '00').replace(/[A-Za-z]/g, '0');
    }

    if (typeof dateValue === 'number') {
      const date = new Date(dateValue * 1000);

      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      dateString = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
    }

    const dateObj = dayjs(dateString);

    if (!isDayjs(dateObj)) {
      return null;
    }

    return dateObj.format(format);
  }

  function setDateFormats(dateFormats: Translation) {
    dateFormatsStorage = dateFormats;
  }

  return { formatDate, setDateFormats };
}
