import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Skeleton } from '@mui/material';

import { useActionBar } from 'src/features/JobFiles/components/ActionBar/useActionBar';
import { ActionsButton } from 'src/features/JobFiles/components/ActionsButton';
import { CompareButton } from 'src/features/JobFiles/components/CompareButton';
import { FileIds } from 'src/features/JobFiles/JobFiles';
import { FolderCode, RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { UploadFiles } from 'src/features/UploadFiles';
import { WaveIcon } from 'src/features/WaveIcon';

type ActionBarProps = {
  areAllExpanded: boolean;
  areAllSelected: boolean;
  selectedFileIds: FileIds;
  files: RawFiles;
  onToggleExpandAll: () => void;
  onToggleSelectAll: (isSelected?: boolean) => void;
  selectedFolderCode: FolderCode;
};

export function ActionBar({
  areAllExpanded,
  areAllSelected,
  files,
  onToggleExpandAll,
  onToggleSelectAll,
  selectedFileIds,
  selectedFolderCode,
}: ActionBarProps) {
  const { t } = useTranslation();
  const { actions, handleActions, isFetching } = useActionBar({
    onToggleSelectAll,
    selectedFileIds,
    selectedFolderCode,
  });

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Skeleton height={31} variant="rounded" width={600} />
      </Box>
    );
  }

  if (!isFetching && !actions) return null;

  return (
    <>
      <Box
        sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'flex-end', mb: 2 }}
      >
        <Button
          onClick={() => onToggleExpandAll()}
          startIcon={
            <WaveIcon
              code={
                areAllExpanded
                  ? 'job-files-action-bar-expand-less'
                  : 'job-files-action-bar-expand-more'
              }
            />
          }
        >
          {areAllExpanded ? t('lib.collapseall', 'Collapse All') : t('lib.expandall', 'Expand All')}
        </Button>

        <Button
          onClick={() => onToggleSelectAll()}
          startIcon={
            <WaveIcon
              code={
                areAllSelected
                  ? 'job-files-action-bar-tab-unselected'
                  : 'job-files-action-bar-select-all'
              }
            />
          }
        >
          {areAllSelected ? t('lib.desel.all', 'Deselect all') : t('lib.sel.all', 'Select all')}
        </Button>

        {actions?.main_actions.length
          ? actions.main_actions.map(({ code, subItems, text }) => {
              if (code === 'upload-file') {
                return (
                  <UploadFiles
                    destination={selectedFolderCode}
                    isMultipleFiles
                    key={code}
                    label="lib.upload"
                    variant="fileExplorer"
                  />
                );
              }

              if (code === 'compare-files' && subItems?.length) {
                return (
                  <CompareButton
                    files={files}
                    key={code}
                    onToggleSelectAll={onToggleSelectAll}
                    selectedFileIds={selectedFileIds}
                    selectedFolderCode={selectedFolderCode}
                  />
                );
              }

              return (
                <Button
                  disabled={code === 'add-to-cart' && !selectedFileIds.length}
                  key={code}
                  onClick={() => handleActions(code)}
                  startIcon={<WaveIcon code={`job-files-action-bar-${code}`} fontSize="small" />}
                >
                  <Trans>{text}</Trans>
                </Button>
              );
            })
          : null}

        {actions?.other_actions.length ? (
          <ActionsButton
            onToggleSelectAll={onToggleSelectAll}
            selectedFileIds={selectedFileIds}
            selectedFolderCode={selectedFolderCode}
          />
        ) : null}
      </Box>
    </>
  );
}
