import { MouseEvent, useMemo, useState } from 'react';

import { Button } from 'src/components/Button';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

import type { Action } from '../../Content.types';

type TranslationsButtonProps = {
  onSelectLanguage: (selectedLanguages: Action[]) => void;
  selectedLanguages?: Action[];
  languages: Action[];
};

export function TranslationsButton({
  languages,
  onSelectLanguage,
  selectedLanguages,
}: TranslationsButtonProps) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);

  const items = useMemo(() => {
    return languages.map(({ code, text }) => ({
      checkbox: true,
      code: code,
      onClick: onSelectLanguage,
      text: text,
    }));
  }, [languages]);

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  function handleOpenSubMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setAnchorElWidth(e.currentTarget.offsetWidth);
    setIsSubMenuOpen(true);
  }

  return (
    <>
      <Button
        onClick={handleOpenSubMenu}
        startIcon={<WaveIcon code="job-phrase-action-bar-translations" />}
      >
        lib.id_translations
      </Button>

      <SubMenu
        anchorEl={anchorEl}
        anchorElMinWidth={anchorElWidth}
        anchorOriginHorizontalSubitem="right"
        anchorOriginVerticalSubitem="top"
        checkedItems={selectedLanguages}
        fontSize="caption"
        items={items}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginHorizontalSubitem="left"
        transformOriginVerticalSubitem="top"
      />
    </>
  );
}
