import { FieldsTransformed } from 'src/pages/Job/Job.service';
import { Block } from 'src/pages/Job/JobContext';

function getMaxLengthOfFieldRows(fieldValues: Array<Array<any> | null>): number {
  return fieldValues.reduce((previousValue, currentValue) => {
    if (currentValue === null) return previousValue;

    return currentValue?.length > previousValue ? currentValue?.length : previousValue;
  }, 0);
}

function getNormalisedFieldLength(fieldValue: Array<any> | null, length: number): Array<any> {
  return fieldValue === null ? new Array(length).fill(null) : fieldValue;
}

export function getNormalisedFieldsValues(fieldValues: Array<any | null>) {
  const maxLength = getMaxLengthOfFieldRows(fieldValues);
  const effectiveMaxLength = maxLength === 0 ? 1 : maxLength;

  return fieldValues.map((fieldValue) => getNormalisedFieldLength(fieldValue, effectiveMaxLength));
}

export function getNumberOfFieldRows(fieldValues: Array<any>): number {
  const lengths = fieldValues.filter((item) => Array.isArray(item)).map((item) => item.length);

  return lengths.length > 0 ? Math.max(...lengths) : 0;
}

export function getBlockFieldNames(block: Block, fieldsData?: FieldsTransformed) {
  const isTable = block.table || false;

  if (isTable) {
    const fixedFields = block.table?.rows[0].fixedFields || [];
    const scrollableFields = block.table?.rows[0].scrollableFields || [];
    const fields = [...fixedFields, ...scrollableFields];

    return fields.filter((field) => fieldsData?.[field]?.flags.job_blocks);
  }

  return block.fields
    .map((field) => field.alias)
    .filter((field) => fieldsData?.[field]?.flags.job_blocks);
}

function getMoveFieldValues(fieldValues: Array<Array<any>>, fromIndex: number, toIndex: number) {
  if (fromIndex === toIndex) {
    return fieldValues;
  }

  return fieldValues.map((values) => {
    if (values.length <= Math.max(fromIndex, toIndex)) {
      return values;
    }

    const value = values[fromIndex];
    const removedFromPosition = [...values.slice(0, fromIndex), ...values.slice(fromIndex + 1)];
    const updatedNewPosition = [
      ...removedFromPosition.slice(0, toIndex),
      value,
      ...removedFromPosition.slice(toIndex),
    ];

    return updatedNewPosition;
  });
}

export function getMovedFieldValues(
  direction: 'up' | 'down' | 'first' | 'last',
  fieldValues: Array<any | null>,
  fieldValueIndex: number,
) {
  switch (direction) {
    case 'up':
      return getMoveFieldValues(fieldValues, fieldValueIndex, fieldValueIndex - 1);

    case 'down':
      return getMoveFieldValues(fieldValues, fieldValueIndex, fieldValueIndex + 1);

    case 'first':
      return getMoveFieldValues(fieldValues, fieldValueIndex, 0);

    case 'last':
      return getMoveFieldValues(fieldValues, fieldValueIndex, fieldValues[0].length - 1);
    default:
      return fieldValues;
  }
}

export function getAreAllFieldsDisabled(
  fieldNames: Array<string>,
  fieldsData?: FieldsTransformed,
): boolean {
  if (fieldsData === undefined) return true;

  return fieldNames.every((fieldName) => fieldsData?.[fieldName]?.isDisabled ?? true);
}
