import React, { useEffect, useRef } from "react";

export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    let isMounted = true;

    firstRender.current = false;

    return () => (isMounted = false);
  }, []);
  return firstRender.current;
}
