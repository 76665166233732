import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Pie } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Grid, Skeleton, useTheme } from '@mui/material';

import { ChartTitle } from '../ChartTitle';
import { NothingToShow } from '../NothingToShow';

ChartJS.register(ArcElement, Tooltip, Legend);

export function PieChart({ loading, pieces, title }) {
  let colors = [];
  const theme = useTheme();
  const colorsMap = {
    due: theme.palette.warning.main,
    late: theme.palette.error.main,
    pending: theme.palette.success.main,
  };
  let labels = [];
  let total = 0;
  let totals = [];

  pieces.forEach((piece) => {
    colors = [...colors, colorsMap[piece.code]];
    labels = [...labels, piece.label];
    total += piece.total;
    totals = [...totals, piece.total];
  });

  if (loading) {
    return (
      <Grid item>
        <Skeleton className="m-auto" height={100} width={100} variant="circular" />
      </Grid>
    );
  }

  if (!pieces.length) {
    return <NothingToShow title={title} />;
  }

  return (
    <Grid alignItems="center" container direction="column" item wrap="nowrap">
      <ChartTitle title={`${title}: ${total}`} />

      <Grid item>
        <Pie
          data={{
            labels,
            datasets: [
              {
                data: totals,
                backgroundColor: colors,
              },
            ],
          }}
          plugins={[ChartDataLabels]}
          options={{
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  boxWidth: 15,
                },
              },
              datalabels: {
                color: 'white',
              },
            },
            maintainAspectRatio: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
