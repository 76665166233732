import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

import { RawFiles } from 'src/features/JobFiles/JobFiles.service';

type SelectedItemsProps = {
  header: string;
  filesToCompare: RawFiles;
  onToggleSelectedItem: (id: number) => void;
  selectedItems: Record<number, boolean>;
};

export function SelectedItems({
  filesToCompare,
  header,
  onToggleSelectedItem,
  selectedItems,
}: SelectedItemsProps) {
  return (
    <>
      <Box bgcolor="filler.main" paddingLeft={6} paddingY={2}>
        <Typography fontWeight="bold">{header}</Typography>
      </Box>

      <Box component={FormGroup} overflow="hidden" px={2} py={0.5}>
        {Object.keys(selectedItems).map((key) => (
          <FormControlLabel
            componentsProps={{
              typography: {
                variant: 'body2',
              },
            }}
            control={
              <Checkbox
                checked={selectedItems[+key] || false}
                onChange={() => onToggleSelectedItem(+key)}
              />
            }
            key={key}
            label={filesToCompare.find((file) => file.id === +key)?.filename}
          />
        ))}
      </Box>
    </>
  );
}
