import { FileColumnMappings } from 'src/pages/FileSearch/enums/FileColumnMappings';
import { JobDetailsColumnMappings } from 'src/pages/FileSearch/enums/JobDetailsColumnMappings';
import { FileDataType, JobDataType } from 'src/pages/FileSearch/FileSearch.service';

const EMPTY_STRING = '-';

export function getFilteredRowLabelsAndData(
  data: FileDataType,
  filteredDataByColumns: (FileColumnMappings | JobDetailsColumnMappings)[],
): Record<string, { label: string; translationCode: string; value: any }> {
  return filteredDataByColumns.reduce((acc, column) => {
    const value =
      column instanceof FileColumnMappings
        ? data[column.databaseColumnName as keyof FileDataType] ?? EMPTY_STRING
        : data.job[column.databaseColumnName as keyof JobDataType] ?? EMPTY_STRING;

    acc[column.propName] = {
      label: column.label,
      translationCode: column.translationCode,
      value: value,
    };

    return acc;
  }, {} as Record<string, { label: string; translationCode: string; value: any }>);
}

export function getData(
  data: FileDataType[],
  filteredDataByColumns: (FileColumnMappings | JobDetailsColumnMappings)[],
): {
  columns: {
    label: string;
    translationCode: string;
    databaseColumnName: string;
    sortName: string;
  }[];
  rows: Record<string, any>[];
} {
  const columns = filteredDataByColumns.map((column) => ({
    databaseColumnName: column.databaseColumnName,
    label: column.label,
    sortName: column.sortName,
    translationCode: column.translationCode,
  }));

  const rows = data.map((item) => {
    return filteredDataByColumns.reduce((rowObj, column) => {
      const value =
        column instanceof FileColumnMappings
          ? item[column.databaseColumnName as keyof FileDataType] ?? EMPTY_STRING
          : item.job[column.databaseColumnName as keyof JobDataType] ?? EMPTY_STRING;

      rowObj[column.propName] = value;

      return rowObj;
    }, {} as Record<string, any>);
  });

  return {
    columns: columns,
    rows: rows,
  };
}

type NavigationUrlParams = {
  jobId: string;
  src: string;
  tableName: string;
};

export function getNavigationUrl({ jobId, src, tableName }: NavigationUrlParams): string {
  const isArchived = tableName.includes('al_job_arc') ? 'arc' : 'job';
  const url = `/jobs-${isArchived}-${src}/${jobId}/fil`;

  return url;
}
