import { ChangeEvent, memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, styled, TablePagination } from '@mui/material';

import { ROWS_PER_PAGE_OPTIONS } from 'src/pages/FileSearch/FileSearchPagination/utils';

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

type FileSearchPaginationProps = {
  count?: number;
  rowsPerPage: number;
  onRowsPerPageChange: (value: number) => void;
  page: number;
  onPageChange: (value: number) => void;
};

function FileSearchPagination({
  count = 0,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}: FileSearchPaginationProps) {
  const { t } = useTranslation();

  function handlePageChange(event: MouseEvent<HTMLButtonElement> | null, page: number) {
    onPageChange(page);
  }

  function handleRowsPerPageChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    onRowsPerPageChange(parseInt(event?.target.value));
  }

  return (
    <StyledCard elevation={0}>
      <Box display="flex" justifyContent="flex-end" margin={0.5}>
        <TablePagination
          color="primary"
          component="div"
          count={count}
          labelRowsPerPage={t('file-ser.rows_per_page', 'Rows per page')}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          size="small"
        />
      </Box>
    </StyledCard>
  );
}

const MemoizedFileSearchPagination = memo(FileSearchPagination);

export { MemoizedFileSearchPagination as FileSearchPagination };
