import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useGetCategoriesQuery } from '../../content.service';
import { SideBarItem } from '../SideBarItem';

import type { TemplateSelectionParams } from '../../Content.types';

const CategoryItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }: { selected: boolean }) => ({
  '&:hover': {
    background: 'var(--filler)',
  },
  alignItems: 'center',
  background: selected ? 'var(--filler)' : '#fff',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: selected ? '600' : '400',
  gap: 4,
  paddingBottom: 12,
  paddingLeft: 20,
  paddingTop: 12,
}));

type SideBarProps = {
  categories: TemplateSelectionParams['categories'];
  onCategoryChange: (category: string) => void;
  selectedCategory: string;
};

export function SideBar({ categories, onCategoryChange, selectedCategory }: SideBarProps) {
  const { control } = useFormContext<TemplateSelectionParams>();

  const { data: categoriesList = [] } = useGetCategoriesQuery({});

  return (
    <>
      {categories
        .filter((category) => category.categoryId)
        .map((category, index) => (
          <Fragment key={index}>
            {category.items?.map((item, itemIndex) => (
              <CategoryItem
                key={itemIndex}
                onClick={() => onCategoryChange(`${index}_${itemIndex}`)}
                selected={selectedCategory === `${index}_${itemIndex}`}
              >
                <SideBarItem
                  control={control}
                  count={(category.items || []).length > 1 ? ` ${itemIndex + 1}` : ''}
                  label={
                    categoriesList.find((item) => item.code === (category.categoryId || ''))?.value
                  }
                  name={`categories.${index}.items.${itemIndex}.status`}
                  rowIndex={`${index}_${itemIndex}`}
                />
              </CategoryItem>
            ))}
          </Fragment>
        ))}
    </>
  );
}
