import { styled, Typography } from '@mui/material';

export const StyledTypographyWhiteSpace = styled(Typography)`
  white-space: nowrap;
`;

export const StyledSelectionButtonContainer = styled('div')`
  position: fixed;
  right: ${({ theme }) => theme.spacing(3)};
  bottom: ${({ theme }) => theme.spacing(3)};
`;
